import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IMedidorResidencial,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteSML,
  IReporteWRC,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { RegistrosService } from '../../modulos/correctoras/registros.service';
import {
  PointOptionsObject,
  SeriesColumnOptions,
  SeriesOptionsType,
  YAxisOptions,
} from 'highcharts';
import { VERDE } from 'src/assets/estilos/colores';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';

// const colorDatos2 = ACCENT;
const colorDatos = VERDE;

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-medidor-residencial',
  templateUrl: './grafico-medidor-residencial.component.html',
  styleUrls: ['./grafico-medidor-residencial.component.scss'],
})
export class GraficoMedidorResidencialComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() medidor?: IMedidorResidencial;
  @Input() range?: { from: string; to: string };

  // Reportes Historicos
  public chartConsumo?: Highcharts.Options;
  public reportes: IReporte[] = [];

  public volumenAcumulado?: number;

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public registrosService: RegistrosService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################

  private getChartOptions(
    series: SeriesOptionsType[],
    yAxis?: YAxisOptions | YAxisOptions[],
  ) {
    const options: Highcharts.Options = {
      chart: {
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
      },
      title: {
        align: 'left',
        text: undefined,
      },
      yAxis,
      xAxis: {
        type: 'datetime',
      },
      legend: {
        // enabled: false,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          marker: {
            // enabled: false,
          },
          label: {
            connectorAllowed: false,
          },
        },
      },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    return options;
  }

  private getChartOptionsSimple(
    series: SeriesOptionsType[],
    text?: string,
    unidad?: string,
  ) {
    const yAxis: YAxisOptions = {
      title: {
        text,
        style: {
          color: colorDatos,
        },
      },
      // m³
      labels: {
        format: unidad ? `{value} ${unidad}` : '{value}',
        style: {
          color: colorDatos,
        },
      },
    };
    return this.getChartOptions(series, yAxis);
  }

  private async graficoHistorico(): Promise<void> {
    if (!this.reportes.length) {
      this.chartConsumo = undefined;
      return;
    }

    const seriesConsumo: SeriesColumnOptions[] = [];

    const reportes: IReporte[] = JSON.parse(JSON.stringify(this.reportes));

    // TODO: reemplazar por offset del establecimiento
    const timeZoneOffset = -3;
    const timeZoneOffsetMs = 1000 * 60 * 60 * timeZoneOffset;

    // Crea las series del grafico
    const dataConsumo: PointOptionsObject[] = [];

    for (let index = 1; index < reportes.length; index++) {
      const reporte = reportes[index];
      const reporteAnterior = reportes[index - 1];
      const valores = reporte.valores as IReporteWRC | IReporteSML;
      const valoresAnterior = reporteAnterior.valores as
        | IReporteWRC
        | IReporteSML;

      const fecha = new Date(valores.timestamp!).getTime() + timeZoneOffsetMs;
      const consumoActual = valores.consumo || 0;
      const consumoAnterior = valoresAnterior.consumo || 0;
      const consumo = +(consumoActual - consumoAnterior).toFixed(2);

      dataConsumo.push({
        x: fecha,
        y: consumo,
      });
    }

    console.log(`dataConsumo`, dataConsumo);

    const serieConsumo: SeriesColumnOptions = {
      type: 'column',
      name: `Consumo`,
      yAxis: 0,
      data: dataConsumo,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };

    seriesConsumo.push(serieConsumo);

    this.chartConsumo = this.getChartOptionsSimple(
      seriesConsumo,
      'Consumo',
      'm³',
    );
  }

  private calcularVolumenAcumuladoPeriodo() {
    if (this.reportes.length === 0) {
      this.volumenAcumulado = 0;
      return;
    }

    const ultimoValor = this.reportes[this.reportes.length - 1].valores as
      | IReporteWRC
      | IReporteSML;
    const primerValor = this.reportes[0].valores as IReporteWRC | IReporteSML;

    const ultimoConsumo = ultimoValor.consumo || 0;
    const primerConsumo = primerValor.consumo || 0;

    this.volumenAcumulado = ultimoConsumo - primerConsumo;
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  private async listar(): Promise<void> {
    let filter: IFilter<IReporte> | boolean = false;

    if (this.medidor && this.range) {
      filter = {
        idsAsignados: this.medidor._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }
    if (this.puntoMedicion && this.range) {
      filter = {
        idsAsignados: this.puntoMedicion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', query)
        .subscribe(async (data) => {
          console.log(`listado de reportes grafico`, data);
          this.reportes = data.datos;
          this.calcularVolumenAcumuladoPeriodo();
          await this.graficoHistorico();
        });
      await this.listadosService.getLastValue('reportes', query);
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
