<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Cambiar Teléfono
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <mat-form-field>
      <mat-label>Teléfono</mat-label>
      <input matInput [(ngModel)]="telefono" (ngModelChange)="telValido()" />
      <mat-hint *ngIf="!valido"
        >Ingresá el número de teléfono sin el 0 ni el 15</mat-hint
      >
    </mat-form-field>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      (click)="guardar()"
      color="accent"
      fxFlex
      [disabled]="!valido || this.loading.getLoading()"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="volver()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="this.loading.getLoading() ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
