<div class="fondo-85" style="border-radius: 30px">
  <div
    *ngIf="!this.loading.getLoading() && !registros.length"
    style="margin: 1em; text-align: center; padding: 0.5em"
  >
    <h2 style="margin: 0">No hay reportes en el período seleccionado</h2>
  </div>

  <!-- Valores Acumulados -->
  <div *ngIf="registros.length" style="padding: 1em; border-radius: 8px">
    <!-- style="margin: 1em; text-align: center; padding: 0.5em" -->
    <table class="consumos">
      <tr>
        <td>Volumen Base Acumulado</td>
        <td style="padding-left: 1em">
          {{ volumenAcumulado | number: "1.0-1" }} m³
        </td>
      </tr>
      <tr>
        <td>Volumen Corregido Acumulado</td>
        <td style="padding-left: 1em">
          {{ volumenCorregidoAcumulado | number: "1.0-1" }} m³
        </td>
      </tr>
    </table>
  </div>

  <!-- Graficos -->
  <div *ngIf="registros.length" style="margin: 1em">
    <mat-tab-group>
      <!-- Presion -->
      <mat-tab label="Presión">
        <ng-template matTabContent>
          <div *ngIf="chartPresion" class="transparente" style="margin: 1em">
            <app-chart
              [options]="chartPresion"
              style="
                width: 100%;
                height: 400px;
                display: block;
                margin-top: 30px;
              "
            >
            </app-chart>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Temperatura -->
      <mat-tab label="Temperatura">
        <ng-template matTabContent>
          <div
            *ngIf="chartTemperatura"
            class="transparente"
            style="margin: 1em"
          >
            <app-chart
              [options]="chartTemperatura"
              style="
                width: 100%;
                height: 400px;
                display: block;
                margin-top: 30px;
              "
            >
            </app-chart>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Volumen Base Acumulado -->
      <mat-tab label="Volumen Base Acumulado">
        <ng-template matTabContent>
          <div
            *ngIf="chartVolumenTotalizado"
            class="transparente"
            style="margin: 1em"
          >
            <app-chart
              [options]="chartVolumenTotalizado"
              style="
                width: 100%;
                height: 400px;
                display: block;
                margin-top: 30px;
              "
            >
            </app-chart>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Volumen Base Horario -->
      <mat-tab label="Volumen Base Horario">
        <ng-template matTabContent>
          <div
            *ngIf="chartVolumenParcializado"
            class="transparente"
            style="margin: 1em"
          >
            <app-chart
              [options]="chartVolumenParcializado"
              style="
                width: 100%;
                height: 400px;
                display: block;
                margin-top: 30px;
              "
            >
            </app-chart>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
