import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IListado, IQueryParam, ILogReporte } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class LogReportesService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ILogReporte>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/logreportes`, { params });
  }
}
