import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ICentroOperativo,
  IDispositivo,
  IDispositivoVeriboxMicro,
  IListado,
  IQueryParam,
  IUnidadNegocio,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarDispositivosComponent } from '../crear-editar-dispositivos/crear-editar-dispositivos.component';
import { ConfigDispositivosNucComponent } from '../config-dispositivos-nuc/config-dispositivos-nuc.component';
import { ConfigDispositivosNspComponent } from '../config-dispositivos-nsp/config-dispositivos-nsp.component';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';
import { ConfigDispositivosVeriboxComponent } from '../../../standalone/config-dispositivos-veribox/config-dispositivos-veribox.component';
import { MigrarDispositivosVeriboxComponent } from '../migrar-dispositivos-veribox/migrar-dispositivos-veribox.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado-dispositivos',
  templateUrl: './listado-dispositivos.component.html',
  styleUrls: ['./listado-dispositivos.component.scss'],
})
export class ListadoDispositivosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = 'ListadoDispositivosComponent';
  public datos?: IDispositivo[] = [];
  public columnas?: IColumnas<IDispositivo>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['deveui', 'deviceName', 'config.telefono'],
  };
  public tipo: IFiltroTabla = {
    elementos: [
      { nombre: 'NUC', _id: 'NUC' },
      { nombre: 'SML', _id: 'SML' },
      { nombre: 'NSP', _id: 'NSP' },
      { nombre: 'VERIBOX MICRO', _id: 'VERIBOX MICRO' },
    ],
    filter: {
      field: 'tipoDispositivo',
    },
    label: 'Tipo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  //
  public conectividad: IFiltroTabla = {
    elementos: [
      { nombre: '4G', _id: '4G' },
      { nombre: 'Lora', _id: 'Lora' },
    ],
    filter: {
      field: 'conectividad',
    },
    label: 'Conectividad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public unidadNegocio: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idUnidadNegocio',
    },
    label: 'Unidad de Negocio',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public centroOperativo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCentroOperativo',
    },
    label: 'Centro Operativo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public firmwareNuc: IFiltroTabla = {
    filter: {
      field: 'config.firmwareNuc',
    },
    label: 'Firmware NUC',
    tipo: 'input',
  };

  public filtros: IFiltroTabla[] = [
    this.tipo,
    this.conectividad,
    this.unidadNegocio,
    this.centroOperativo,
    this.firmwareNuc,
  ];

  // QUERY+
  private populate = [
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'centroOperativo',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: 'deviceName',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public dispositivos$?: Subscription;
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // ACCIONES
  public async editar(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarDispositivosComponent, config);
  }

  public async configNuc(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigDispositivosNucComponent, config);
  }

  public async configNsp(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigDispositivosNspComponent, config);
  }

  public async configVeribox(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigDispositivosVeriboxComponent, config);
  }

  public async migrarVeribox(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(MigrarDispositivosVeriboxComponent, config);
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    this.dispositivos$?.unsubscribe();
    this.dispositivos$ = this.listadosService
      .subscribe<IListado<IDispositivo>>('dispositivos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de dispositivos`, data);
      });
    await this.listadosService.getLastValue('dispositivos', this.query);
  }

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocio.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as IUnidadNegocio[];
        this.unidadNegocio.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativo.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as ICentroOperativo[];
        this.centroOperativo.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  //

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'DevEUI',
          sortable: true,
        },
        row: {
          field: 'deveui',
        },
      },
      {
        header: {
          label: 'Device Name',
          sortable: true,
          sort: 'deviceName',
        },
        row: {
          html: (dato) => this.parseDeviceName(dato),
          // field: 'deviceName',
        },
      },
      {
        header: {
          label: 'Tipo',
          sortable: true,
        },
        row: {
          field: 'tipoDispositivo',
        },
      },
      {
        header: {
          label: 'Conectividad',
        },
        row: {
          field: 'conectividad',
        },
      },
      {
        header: {
          label: 'Unidad de Negocio',
        },
        row: {
          html: (dato) => this.parseUnidadDeNegocio(dato),
        },
      },
      {
        header: {
          label: 'Centro Operativo',
        },
        row: {
          html: (dato) => this.parseCentroOperativo(dato),
        },
      },
      {
        header: {
          label: 'Última Comunicación',
          sortable: true,
          sort: 'fechaUltimaComunicacion',
        },
        row: {
          html: (dato) => this.parseFecha(dato.fechaUltimaComunicacion),
        },
      },
      {
        header: {
          label: 'Firmware',
          sortable: true,
          sort: 'firmware config.firmwareNsp config.firmwareNuc',
        },
        row: {
          html: (dato) => this.parseFirmware(dato),
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/config.png',
              tooltip: 'Configurar',
              click: (dato) => this.configNsp(dato),
              oculta(dato) {
                return dato.tipoDispositivo !== 'NSP';
              },
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/config.png',
              tooltip: 'Configurar',
              click: (dato) => this.configVeribox(dato),
              oculta(dato) {
                return dato.tipoDispositivo !== 'VERIBOX MICRO';
              },
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/mas.png',
              tooltip: 'Migrar',
              click: (dato) => this.migrarVeribox(dato),
              oculta(dato) {
                return (
                  dato.tipoDispositivo !== 'VERIBOX MICRO'
                  // || dato.fechaUltimaComunicacion
                );
              },
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/config.png',
              tooltip: 'Configurar',
              click: (dato) => this.configNuc(dato),
              oculta(dato) {
                return (
                  dato.tipoDispositivo !== 'NUC' ||
                  !dato.config?.firmwareNuc ||
                  dato.config?.firmwareNuc < 'v15'
                );
              },
            },
          ],
        },
      },
    ];
  }

  private parseUnidadDeNegocio(d: IDispositivo): string {
    if (d.unidadNegocio) {
      return `${d.unidadNegocio.nombre}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  private parseCentroOperativo(d: IDispositivo): string {
    if (d.centroOperativo) {
      return `${d.centroOperativo.nombre}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  private parseFecha(fecha?: string): string {
    if (fecha) {
      return `<div>${new Date(fecha).toLocaleDateString()} - ${new Date(
        fecha,
      ).toLocaleTimeString()}</div>`;
    } else {
      return `<div class="no-info">Sin reportar</div>`;
    }
  }

  private parseFirmware(d: IDispositivo): string {
    if (d.firmware) return d.firmware;
    if (d.config?.firmwareNsp) return d.config?.firmwareNsp;
    if (d.config?.firmwareNuc) return d.config?.firmwareNuc;

    return `<div class="no-info">Sin información</div>`;
  }

  private parseDeviceName(d: IDispositivo): string {
    const deviceName = d.deviceName;
    let html = ` <div> ${deviceName}`;

    if (d.tipoDispositivo === 'VERIBOX MICRO') {
      const telefono = (d.config as IDispositivoVeriboxMicro)?.telefono;
      if (telefono) {
        html += `<div class="no-info">${telefono}</div>`;
      } else {
        html += ` <div class="no-info">Sin teléfono</div>`;
      }
    }
    html += `</div>`;
    return html;
  }

  ////

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([
      this.listar(),
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
    ]);
  }

  ngOnDestroy(): void {
    this.dispositivos$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
  }
}
