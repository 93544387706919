import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
})
export class SpinnerComponent {
  constructor(public loading: LoadingService) {}
}
