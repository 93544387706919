<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 0.5em">
        <!-- Fecha de Aplicación -->
        <mat-form-field>
          <mat-label>Fecha de Aplicación</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            formControlName="fechaAplicacion"
          />
          <mat-hint>MM/AAAA</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker1
            startView="year"
            (monthSelected)="
              setMonthAndYear($event, picker1, 'fechaAplicacion')
            "
          ></mat-datepicker>
        </mat-form-field>

        <!-- Fecha de Vencimiento -->
        <mat-form-field>
          <mat-label>Fecha de Vencimiento</mat-label>
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="fechaVencimiento"
          />
          <mat-hint>MM/AAAA</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker2
            startView="year"
            (monthSelected)="
              setMonthAndYear($event, picker2, 'fechaVencimiento')
            "
          ></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Unidad de Negocio -->
        <mat-form-field>
          <mat-label>Unidad de Negocio</mat-label>
          <mat-select
            formControlName="idUnidadNegocio"
            (selectionChange)="cambioUnidadNegocio()"
          >
            <mat-option
              *ngFor="let dato of unidadNegocios"
              [value]="dato._id"
              >{{ dato.nombre }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <!-- Cuenca -->
        <mat-form-field>
          <mat-label>Zona de Calidad</mat-label>
          <mat-select formControlName="idCuenca">
            <mat-option *ngFor="let dato of cuencas" [value]="dato._id">{{
              dato.nombre
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div formGroupName="elementos">
        <div fxLayout="row" fxLayoutGap="10px">
          <div>
            <!-- Nitrógeno  -->
            <mat-form-field>
              <mat-label>Nitrógeno (N2) Valor entre 0 y 20</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="20"
                step="0.0001"
                placeholder="Nitrógeno"
                formControlName="nitrogeno"
              />
            </mat-form-field>

            <!-- Dioxido de Carbono  -->
            <mat-form-field>
              <mat-label>Dioxido de Carbono (CO2) Valor entre 0 y 20</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="20"
                step="0.0001"
                placeholder="Dioxido de Carbono"
                formControlName="dioxidoCarbono"
              />
            </mat-form-field>

            <!-- Oxígeno  -->
            <mat-form-field>
              <mat-label>Oxígeno (O2) Valor entre 0 y 0.5</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="0.5"
                step="0.0001"
                placeholder="Oxígeno"
                formControlName="oxigeno"
              />
            </mat-form-field>

            <!-- Metano  -->
            <mat-form-field>
              <mat-label>Metano (C1) Valor entre 70 y 100</mat-label>
              <input
                matInput
                type="number"
                min="70"
                max="100"
                step="0.0001"
                placeholder="Metano"
                formControlName="metano"
              />
            </mat-form-field>

            <!-- Etano  -->
            <mat-form-field>
              <mat-label>Etano (C2) Valor entre 0 y 15</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="15"
                step="0.0001"
                placeholder="Etano"
                formControlName="etano"
              />
            </mat-form-field>

            <!-- Propano  -->
            <mat-form-field>
              <mat-label>Propano (C3) Valor entre 0 y 3.5</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="3.5"
                step="0.0001"
                placeholder="Propano"
                formControlName="propano"
              />
            </mat-form-field>

            <!-- isoButano  -->
            <mat-form-field>
              <mat-label>isoButano (iC4) Valor entre 0 y 1.5</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="1.5"
                step="0.0001"
                placeholder="isoButano"
                formControlName="isoButano"
              />
            </mat-form-field>
          </div>

          <div>
            <!-- nButano  -->
            <mat-form-field>
              <mat-label>nButano (nC4) Valor entre 0 y 1.5</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="1.5"
                step="0.0001"
                placeholder="nButano"
                formControlName="nButano"
              />
            </mat-form-field>

            <!-- isoPentano  -->
            <mat-form-field>
              <mat-label>isoPentano (iC5) Valor entre 0 y 0.5</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="0.5"
                step="0.0001"
                placeholder="isoPentano"
                formControlName="isoPentano"
              />
            </mat-form-field>

            <!-- nPentano  -->
            <mat-form-field>
              <mat-label>nPentano (nC5) Valor entre 0 y 0.5</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="0.5"
                step="0.0001"
                placeholder="nPentano"
                formControlName="nPentano"
              />
            </mat-form-field>

            <!-- nHexano  -->
            <mat-form-field>
              <mat-label>nHexano (C6) Valor entre 0 y 0.1</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="0.1"
                step="0.0001"
                placeholder="nHexano"
                formControlName="nHexano"
              />
            </mat-form-field>

            <!-- nHeptano  -->
            <mat-form-field>
              <mat-label>nHeptano (C7) Valor entre 0 y 0.05</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="0.05"
                step="0.0001"
                placeholder="nHeptano"
                formControlName="nHeptano"
              />
            </mat-form-field>

            <!-- nOctano  -->
            <mat-form-field>
              <mat-label>nOctano (C8) Valor entre 0 y 0.05</mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="0.05"
                step="0.0001"
                placeholder="nOctano"
                formControlName="nOctano"
              />
            </mat-form-field>

            <!-- Densidad  -->
            <mat-form-field>
              <mat-label>Densidad (D) Valor entre 0.4 y 0.8</mat-label>
              <input
                matInput
                type="number"
                min="0.4"
                max="0.8"
                placeholder="Densidad"
                formControlName="densidad"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>

  <div class="center-column">
    <p class="label-error">
      {{ form?.get("elementos")?.getError("suma") }}
    </p>

    <p class="label-error">
      {{ form?.getError("fecha") }}
    </p>
  </div>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
