<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Division -->
      <mat-form-field>
        <mat-label>División</mat-label>
        <mat-select formControlName="division">
          <ng-container *ngFor="let division of divisiones">
            <mat-option [value]="division"> {{ division }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <!-- Unidad de Negocio -->
      <mat-form-field>
        <mat-label>Unidad de Negocio</mat-label>
        <mat-select formControlName="idUnidadNegocio" required>
          <mat-option *ngFor="let dato of unidadNegocios" [value]="dato._id">{{
            dato.nombre
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Centro Operativo -->
      <mat-form-field>
        <mat-label>Centro Operativo</mat-label>
        <mat-select formControlName="idCentroOperativo">
          <ng-container *ngFor="let dato of centroOperativos">
            <mat-option *ngIf="mostrarCentroOperativo(dato)" [value]="dato._id">
              {{ dato.nombre }}
            </mat-option>
          </ng-container>
        </mat-select>
        <button
          *ngIf="form?.get('idCentroOperativo')?.value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="
            $event.stopPropagation();
            form?.patchValue({ idCentroOperativo: null })
          "
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Nombre  -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          type="search"
          placeholder="Nombre del Grupo"
          formControlName="nombre"
          required
        />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
