<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Nombre  -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          type="search"
          placeholder="Nombre"
          formControlName="nombre"
        />
      </mat-form-field>

      <div>
        <div fxLayout="row" fxLayoutGap="10px" style="margin-top: 15px">
          <!-- <mat-form-field>
            <mat-label>Consumo Inicial</mat-label>
            <input
              matInput
              type="number"
              placeholder="Valor De Consumo Inicial"
              formControlName="consumoInicial"
              (change)="consumoInicialChange()"
            />
          </mat-form-field> -->

          <mat-form-field>
            <mat-label>Consumo Actual</mat-label>
            <input
              matInput
              type="number"
              placeholder="Valor De Consumo Actual"
              formControlName="consumoActual"
              (change)="consumoActualChange()"
            />
          </mat-form-field>
        </div>
      </div>

      <!-- Cuenca -->
      <mat-form-field>
        <mat-label>Zona de Calidad</mat-label>
        <mat-select formControlName="idCuenca">
          <mat-option *ngFor="let dato of cuencas" [value]="dato._id">{{
            dato.nombre
          }}</mat-option>
        </mat-select>
        <button
          *ngIf="form?.get('idCuenca')?.value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="
            $event.stopPropagation(); form?.patchValue({ idCuenca: null })
          "
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Grupos -->
      <mat-form-field>
        <mat-label>Grupos</mat-label>
        <mat-select formControlName="idsGrupos" multiple>
          <mat-option *ngFor="let dato of grupos" [value]="dato._id">{{
            dato.nombre
          }}</mat-option>
        </mat-select>
        <button
          *ngIf="form?.get('idsGrupos')?.value?.length"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="
            $event.stopPropagation(); form?.patchValue({ idsGrupos: [] })
          "
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Direccion -->
      <app-autocomplete-direccion
        [direccion]="this.form?.get('direccion')?.value"
        (onChange)="selectDireccion($event)"
      ></app-autocomplete-direccion>

      <!-- Localidad  -->
      <mat-form-field>
        <mat-label>Localidad</mat-label>
        <input
          matInput
          type="search"
          placeholder="Localidad"
          formControlName="localidad"
        />
      </mat-form-field>

      <!-- Mapa -->
      <div *ngIf="mapCenter">
        <google-map
          class="map-center"
          width="100%"
          [options]="mapOptions"
          [center]="mapCenter"
          [zoom]="mapOptions.zoom || 12"
        >
          <map-marker
            [options]="markerOptions"
            (mapDragend)="setLocation($event)"
          ></map-marker>
        </google-map>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
