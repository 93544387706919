import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './auxiliares/guards/loging.guard';
import { LoginModule } from './modulos/login/login.module';
import { NavigationModule } from './modulos/navigation/navigation.module';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadChildren: () => LoginModule,
  },
  {
    path: 'main',
    canActivate: [LoginGuard],
    loadChildren: () => NavigationModule,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
