export const CORRECTORA = '#05b3ce';
export const GAS = '#fba400';
export const AGUA = '#75da4e';
export const PRESION = '#802392';
//
export const ACTIVO = '#2de946';
export const INACTIVO = '#e22f2f';
//
export const VERDE = '#00da3d';
export const AMARILLO = '#fded22';
export const ROJO = '#f7000b';
//
export const ACCENT = `#06ACCC`;
export const WARN = `#f7000b`;
