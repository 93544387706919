import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IResumenReporte,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import {
  PointOptionsObject,
  SeriesLineOptions,
  SeriesOptionsType,
  YAxisOptions,
} from 'highcharts';
import { ReportesService } from '../../modulos/reportes/service';
import { IReporteNSP } from 'modelos/src';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';
import { IResumenReporteVeribox } from 'modelos/src/interfaces/gas/resumen-reportes/valores reporte/veribox';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-min-max-multi',
  templateUrl: './grafico-min-max-multi.component.html',
  styleUrls: ['./grafico-min-max-multi.component.scss'],
})
export class GraficoMinMaxMultiComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() puntosMedicion?: IPuntoMedicion[];
  @Input() range?: { from: string; to: string };
  @Input() style =
    'width: 100%; height: 400px; display: block; margin-top: 30px';

  // Reportes Historicos
  public chart?: Highcharts.Options;
  public reportes: IReporte[] = [];
  public resumenReportes: IReporte[] = [];

  public showOptions = ['Minímo', 'Máximo', 'Promedio'];
  public showOptionsSelected: { [key: string]: boolean } = {
    Minímo: true,
    Máximo: true,
    Promedio: false,
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public reportesService: ReportesService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################

  private getChartOptions(
    series: SeriesOptionsType[],
    yAxis?: YAxisOptions | YAxisOptions[],
  ) {
    const options: Highcharts.Options = {
      chart: {
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
      },
      rangeSelector: {
        selected: 5,
      },
      scrollbar: {
        enabled: false,
      },
      title: {
        align: 'left',
        text: undefined,
      },
      yAxis,
      xAxis: {
        type: 'datetime',
      },
      legend: {
        // enabled: false,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          marker: {
            // enabled: false,
          },
          label: {
            connectorAllowed: false,
          },
        },
      },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    return options;
  }

  private getChartOptionsSimple(
    series: SeriesOptionsType[],
    text?: string,
    unidad?: string,
  ) {
    const yAxis: YAxisOptions = {
      title: {
        text,
      },
      // m³
      labels: {
        format: unidad ? `{value} ${unidad}` : '{value}',
      },
      min: 0,
      softMax: 3,
    };
    return this.getChartOptions(series, yAxis);
  }

  private async graficoHistorico(): Promise<void> {
    if (!this.reportes.length || !this.puntosMedicion) {
      this.chart = undefined;
      return;
    }

    const seriesPresion: SeriesLineOptions[] = [];

    for (const p of this.puntosMedicion) {
      const reps = this.reportes.filter(
        (r) => r.idsAsignados?.includes(p._id!),
      );

      // PRESIÓN

      if (p.division === 'Presión') {
        const reportes: IReporte[] = JSON.parse(JSON.stringify(reps));
        // TODO: reemplazar por offset del establecimiento
        const timeZoneOffset = -3;
        const timeZoneOffsetMs = 1000 * 60 * 60 * timeZoneOffset;

        // Crea las series del grafico
        const dataPresionMin: PointOptionsObject[] = [];
        const dataPresionMax: PointOptionsObject[] = [];

        const datosMin: { [timestamp: number]: number } = {};
        const datosMax: { [timestamp: number]: number } = {};

        for (const reporte of reportes) {
          const valores = reporte.valores as IReporteNSP;
          let timestamp = valores?.timestamp
            ? new Date(valores?.timestamp).getTime()
            : new Date().getTime();
          timestamp += timeZoneOffsetMs;
          timestamp = timestamp - (timestamp % 86400000);
          const pres = valores?.presion || 0;
          const valorPresion = +pres?.toFixed(2);

          if (valorPresion !== -1) {
            const presionMinEncontrada = datosMin[timestamp];
            const presionMaxEncontrada = datosMax[timestamp];

            if (
              presionMinEncontrada === undefined ||
              valorPresion < presionMinEncontrada
            ) {
              datosMin[timestamp] = valorPresion;
            }
            if (
              presionMaxEncontrada === undefined ||
              valorPresion > presionMaxEncontrada
            ) {
              datosMax[timestamp] = valorPresion;
            }
          }
        }

        for (const timestamp in datosMin) {
          const valor = datosMin[timestamp];
          dataPresionMin.push([+timestamp, valor]);
        }
        for (const timestamp in datosMax) {
          const valor = datosMax[timestamp];
          dataPresionMax.push([+timestamp, valor]);
        }

        const seriePresionMin: SeriesLineOptions = {
          type: 'line',
          lineWidth: 4,
          // fillColor: gradient,
          name: `${p.nombre} - Min.`,
          data: dataPresionMin,
          // color: colorDatos,
          marker: {
            enabled: true,
          },
          tooltip: {
            xDateFormat: '%d-%m-%Y',
            pointFormat: `<strong>${p.nombre} - {point.y} - Min.</strong>`,
          },
        };
        const seriePresionMax: SeriesLineOptions = {
          type: 'line',
          lineWidth: 4,
          // fillColor: gradient,
          name: `${p.nombre} - Max.`,
          data: dataPresionMax,
          // color: colorDatos,
          marker: {
            enabled: true,
          },
          tooltip: {
            xDateFormat: '%d-%m-%Y',
            pointFormat: `<strong>${p.nombre} - {point.y} - Max.</strong>`,
          },
        };

        seriesPresion.push(seriePresionMin, seriePresionMax);
        this.chart = this.getChartOptionsSimple(
          seriesPresion,
          'Presión',
          'BAR',
        );
      }

      // if (p.division === 'Correctoras') {
      // }
    }
  }

  public async graficoHistoricoResumen(): Promise<void> {
    if (!this.resumenReportes.length || !this.puntosMedicion) {
      this.chart = undefined;
      return;
    }

    const seriesPresion: SeriesLineOptions[] = [];

    for (let index = 0; index < this.puntosMedicion.length; index++) {
      const p = this.puntosMedicion[index];
      const reportes = this.resumenReportes.filter(
        (r) => r.idsAsignados?.includes(p._id!),
      );

      // PRESIÓN

      if (p.division === 'Presión') {
        // Crea las series del grafico
        const dataPresionMin: PointOptionsObject[] = [];
        const dataPresionMax: PointOptionsObject[] = [];
        const dataPresionAvg: PointOptionsObject[] = [];

        for (const reporte of reportes) {
          const valores = reporte.valores as IResumenReporteVeribox;

          const timestamp = valores?.timestamp
            ? new Date(valores?.timestamp).getTime()
            : new Date().getTime();

          const presionMin = valores?.presionMin || 0;
          const presionMax = valores?.presionMax || 0;
          const presionAvg = +(valores?.presionProm?.toFixed(3) || 0);

          dataPresionMin.push([+timestamp, presionMin]);
          dataPresionMax.push([+timestamp, presionMax]);
          dataPresionAvg.push([+timestamp, presionAvg]);
        }

        const seriePresionMin: SeriesLineOptions = {
          type: 'line',
          lineWidth: 4,
          // fillColor: gradient,
          name: `${p.nombre} - Min.`,
          data: dataPresionMin,
          // color: COLOR_MIN,
          marker: {
            enabled: true,
          },
          tooltip: {
            xDateFormat: '%d-%m-%Y',
            pointFormat: `<strong>${p.nombre} - {point.y} - Min.</strong>`,
          },
        };
        const seriePresionMax: SeriesLineOptions = {
          type: 'line',
          lineWidth: 4,
          // fillColor: gradient,
          name: `${p.nombre} - Max.`,
          data: dataPresionMax,
          // color: COLORES_MAX[index],
          marker: {
            enabled: true,
          },
          tooltip: {
            xDateFormat: '%d-%m-%Y',
            pointFormat: `<strong>${p.nombre} - {point.y} - Max.</strong>`,
          },
        };
        const seriePresionAvg: SeriesLineOptions = {
          type: 'line',
          lineWidth: 4,
          // fillColor: gradient,
          name: `${p.nombre} - Promedio.`,
          data: dataPresionAvg,
          // color: COLOR_AVG,
          marker: {
            enabled: true,
          },
          tooltip: {
            xDateFormat: '%d-%m-%Y',
            pointFormat: `<strong>${p.nombre} - {point.y} - Promedio.</strong>`,
          },
        };

        if (this.showOptionsSelected['Minímo']) {
          seriesPresion.push(seriePresionMin);
        }
        if (this.showOptionsSelected['Máximo']) {
          seriesPresion.push(seriePresionMax);
        }
        if (this.showOptionsSelected['Promedio']) {
          seriesPresion.push(seriePresionAvg);
        }

        this.chart = this.getChartOptionsSimple(
          seriesPresion,
          'Presión',
          'BAR',
        );
      }

      // if (p.division === 'Correctoras') {
      // }
    }
  }

  // Listar

  private async listar(): Promise<void> {
    let filter: IFilter<IReporte> | boolean = false;

    if (this.puntosMedicion && this.range) {
      const ids = this.puntosMedicion.map((p) => p._id);
      filter = {
        idsAsignados: { $in: ids },
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', query)
        .subscribe(async (data) => {
          console.log(`listado de reportes`, data);
          this.reportes = data.datos;

          await this.graficoHistorico();
        });
      await this.listadosService.getLastValue('reportes', query);

      // Mock data
      // this.reportes = this.mockData();
      // await this.graficoHistorico();
    }
  }

  private async listarResumen(): Promise<void> {
    let filter: IFilter<IReporte> | boolean = false;

    if (this.puntosMedicion && this.range) {
      const ids = this.puntosMedicion.map((p) => p._id);
      filter = {
        idsAsignados: { $in: ids },
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IResumenReporte>>('resumenReportes', query)
        .subscribe(async (data) => {
          console.log(`listado de resumenReportes`, data);
          this.resumenReportes = data.datos;

          await this.graficoHistoricoResumen();
        });
      await this.listadosService.getLastValue('resumenReportes', query);
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listarResumen()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
