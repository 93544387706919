<div [matMenuTriggerFor]="menu" class="menu" style="margin: 0.5em">
  <span>
    {{ range?.from | date: "short" }} / {{ range?.to | date: "short" }}
  </span>
  <button mat-icon-button>
    <mat-icon>date_range</mat-icon>
  </button>
</div>

<!-- Range Picker -->
<mat-menu #menu="matMenu">
  <div class="container" (click)="$event.stopPropagation();">
    <!-- Calendarios -->
    <div class="calendar-container">
      <div>
        <mat-calendar
          #fromCalendar
          class="from-calendar"
          [(selected)]="from"
          (selectedChange)="cambioFrom($event);"
          [startAt]="from"
          [dateClass]="$any(dateClassFrom())"
        ></mat-calendar>
      </div>

      <mat-calendar
        #toCalendar
        class="to-calendar"
        [minDate]="from"
        [(selected)]="to"
        (selectedChange)="cambioTo($event)"
        [startAt]="to"
        [dateClass]="$any(dateClassTo())"
      ></mat-calendar>
    </div>

    <!-- Horas -->
    <div class="calendar-container">
      <div>
        <mat-form-field class="timepicker form-transparente">
          <input
            matInput
            name="selected_timeFrom"
            [format]="24"
            [ngxMatTimepicker]="timeFrom"
            readonly
            [(ngModel)]="fromHour"
          />
          <mat-icon matSuffix (click)="timeFrom.open()">watch_later</mat-icon>
        </mat-form-field>
        <ngx-mat-timepicker
          color="accent"
          #timeFrom
          (timeChanged)="cambioHoraFrom($event)"
        ></ngx-mat-timepicker>
      </div>

      <div>
        <mat-form-field class="timepicker form-transparente">
          <input
            matInput
            name="selected_timeTo"
            [format]="24"
            [ngxMatTimepicker]="timeTo"
            readonly
            [(ngModel)]="toHour"
          />
          <mat-icon matSuffix (click)="timeTo.open()">watch_later</mat-icon>
        </mat-form-field>
        <ngx-mat-timepicker
          color="accent"
          #timeTo
          (timeChanged)="cambioHoraTo($event)"
        ></ngx-mat-timepicker>
      </div>
    </div>

    <!-- Presets -->
    <div class="presets-container">
      <ng-container *ngFor="let preset of presets">
        <button
          mat-stroked-button
          color="accent"
          (click)="cambioPreset(preset)"
        >
          {{ preset.label }}
        </button>
      </ng-container>
    </div>

    <!-- Aplicar / Cancelar -->
    <div class="actions-container">
      <button
        mat-flat-button
        color="accent"
        (click)="aplicar()"
        [disabled]="!from || !to"
      >
        Aplicar
      </button>

      <button mat-flat-button (click)="cancelar()">Cancelar</button>
    </div>
  </div>
</mat-menu>
