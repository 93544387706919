<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Importar Registros soxis
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <mat-form-field>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      @if (range.controls.start.hasError("matStartDateInvalid")) {
        <mat-error>Invalid start date</mat-error>
      }
      @if (range.controls.end.hasError("matEndDateInvalid")) {
        <mat-error>Invalid end date</mat-error>
      }
    </mat-form-field>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      color="accent"
      fxFlex
      (click)="enviar()"
      [disabled]="!range.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
