import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoComponent } from './listado/listado.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { RoutingModule } from './routing';
import { GeocodeService } from '../../auxiliares/geocode.service';
import { CrearEditarComponent } from './crear-editar/crear-editar.component';
import { DetallesComponent } from './detalles/detalles.component';
import { ListadoReportesComponent } from '../../standalone/listado-reportes/listado-reportes.component';
import { ListadoAlertasComponent } from '../../standalone/listado-alertas/listado-alertas.component';
import { ListadoReportesFaltantesComponent } from '../../standalone/listado-reportes-faltantes/listado-reportes-faltantes.component';
import { ConfigUnidadPresionComponent } from './config-unidad-presion/config-unidad-presion.component';
import { ResumenUnidadPresionComponent } from '../../standalone/resumen-unidad-presion/resumen-unidad-presion.component';
import { UltimoReporteUnidadPresionComponent } from '../../standalone/ultimo-reporte-unidad-presion/ultimo-reporte-unidad-presion.component';
import { CambiarTelefonoComponent } from './cambiar-telefono/cambiar-telefono.component';
import { GraficoMinMaxComponent } from '../../standalone/grafico-min-max/grafico-min-max.component';
import { DetallesVeriboxComponent } from '../../standalone/detalles-veribox/detalles-veribox.component';
import { DetallesNSPComponent } from '../../standalone/detalles-nsp/detalles-nsp.component';
import { GraficoMinMaxPromComponent } from '../../standalone/grafico-min-max-prom/grafico-min-max-prom.component';
import { ListadoAuditoriasVeriboxComponent } from '../../standalone/listado-auditorias-veribox/listado-auditorias-veribox.component';
import { GraficoPresionActualComponent } from '../../standalone/grafico-presion-actual/grafico-presion-actual.component';
import { GraficoAlertasComponent } from '../../standalone/grafico-alertas/grafico-alertas.component';
import { ConfigDispositivosVeriboxComponent } from '../../standalone/config-dispositivos-veribox/config-dispositivos-veribox.component';

@NgModule({
  declarations: [
    ListadoComponent,
    CrearEditarComponent,
    DetallesComponent,
    ConfigUnidadPresionComponent,
    CambiarTelefonoComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    RoutingModule,
    ListadoAuditoriasVeriboxComponent,
    ListadoAlertasComponent,
    ListadoReportesComponent,
    ListadoReportesFaltantesComponent,
    GraficoMinMaxComponent,
    GraficoMinMaxPromComponent,
    GraficoPresionActualComponent,
    DetallesNSPComponent,
    DetallesVeriboxComponent,
    UltimoReporteUnidadPresionComponent,
    ResumenUnidadPresionComponent,
    GraficoAlertasComponent,
    ConfigDispositivosVeriboxComponent,
  ],
  providers: [GeocodeService],
})
export class UnidadPresionsModule {}
