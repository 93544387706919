import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoUnidadNegociosComponent } from './listado-unidad-negocios/listado-unidad-negocios.component';
import { CrearEditarUnidadNegociosComponent } from './crear-editar-unidad-negocios/crear-editar-unidad-negocios.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { UnidadNegociosRoutingModule } from './unidad-negocios.routing.module';

@NgModule({
  declarations: [
    ListadoUnidadNegociosComponent,
    CrearEditarUnidadNegociosComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, UnidadNegociosRoutingModule],
})
export class UnidadNegociosModule {}
