import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent implements OnInit {
  title: string;
  message: string;
  selectName: string;
  btnOkText: string;
  btnCancelText: string;
  options: string[] = [];
  seleccionada?: string;

  input = '';

  constructor(
    public matDialogRef: MatDialogRef<OptionsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.selectName = this.data.selectName;
    this.btnOkText = this.data.btnOkText || 'Si';
    this.btnCancelText = this.data.btnCancelText || 'No';
    this.options = this.data.options;
  }

  ngOnInit() {}
}
