import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { HelperService } from '../../auxiliares/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { firstValueFrom } from 'rxjs';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { ReportesService } from '../../modulos/reportes/service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-generar-resumen-reportes-fecha',
  templateUrl: './generar-resumen-reportes-fecha.component.html',
  styleUrls: ['./generar-resumen-reportes-fecha.component.scss'],
})
export class GenerarResumenReportesFechaComponent implements OnInit, OnDestroy {
  public enviando = false;

  public range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(
    private dialogRef: MatDialogRef<GenerarResumenReportesFechaComponent>,
    private service: ReportesService,
    public loading: LoadingService,
    private helper: HelperService,
    private dialogService: DialogService,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  public async enviar(): Promise<void> {
    this.enviando = true;
    const desde = this.range.get('start')?.value;
    const hasta = this.range.get('end')?.value;

    if (desde && hasta) {
      const from = new Date(desde!).toISOString().slice(0, 10);
      const to = new Date(hasta!).toISOString().slice(0, 10);

      try {
        const initTime = new Date().getTime();
        await firstValueFrom(this.service.generarResumenFecha(from, to));
        this.helper.notifSuccess('Resumen creado');
        const endTime = new Date().getTime();
        console.log(
          'Tiempo de importación: ',
          Math.trunc((endTime - initTime) / 1000),
          'segundos',
        );
        this.close();
      } catch (err) {
        console.error(err);
        this.helper.notifError(err);
      }
    }

    this.enviando = false;
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {}
}
