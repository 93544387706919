<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Unidad de Negocio -->
      <mat-form-field>
        <mat-label>Unidad de Negocio</mat-label>
        <mat-select
          formControlName="idUnidadNegocio"
          (selectionChange)="changeUnidadNegocio()"
          [disabled]="this.loading.getLoading() || !!mensajeDispositivoAsignado"
        >
          <mat-option *ngFor="let dato of unidadNegocios" [value]="dato._id">
            {{ dato.nombre }}
          </mat-option>
        </mat-select>
        <button
          [disabled]="this.loading.getLoading() || !!mensajeDispositivoAsignado"
          *ngIf="form?.get('idUnidadNegocio')?.value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="
            $event.stopPropagation();
            form?.patchValue({ idUnidadNegocio: null });
            changeUnidadNegocio()
          "
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Centro Operativo -->
      <mat-form-field>
        <mat-label>Centro Operativo</mat-label>
        <mat-select
          formControlName="idCentroOperativo"
          [disabled]="this.loading.getLoading() || !!mensajeDispositivoAsignado"
        >
          <mat-option
            *ngFor="let dato of centroOperativos"
            [value]="dato._id"
            >{{ dato.nombre }}</mat-option
          >
        </mat-select>
        <button
          [disabled]="this.loading.getLoading() || !!mensajeDispositivoAsignado"
          *ngIf="form?.get('idCentroOperativo')?.value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="
            $event.stopPropagation();
            form?.patchValue({ idCentroOperativo: null })
          "
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Mensaje dispositivo asignado -->
      <div
        *ngIf="mensajeDispositivoAsignado"
        [innerHTML]="mensajeDispositivoAsignado"
        class="bg-warn"
        style="
          text-align: center;
          border-radius: 7px;
          padding: 0.5em;
          font-size: 1.2em;
        "
      ></div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || enviando || !!mensajeDispositivoAsignado"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
