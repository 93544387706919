import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICreateUnidadPresion,
  IListado,
  IQueryParam,
  IUnidadPresion,
  IUpdateUnidadPresion,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class UnidadPresionsService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IUnidadPresion>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/unidadpresions`, { params });
  }

  public crear(dato: ICreateUnidadPresion): Observable<IUnidadPresion> {
    return this.http.post(`/unidadpresions`, dato);
  }

  public listarPorId(id: string): Observable<IUnidadPresion> {
    return this.http.get(`/unidadpresions/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateUnidadPresion,
  ): Observable<IUnidadPresion> {
    return this.http.put(`/unidadpresions/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/unidadpresions/${id}`);
  }

  //

  public getUltimaComunicacion(unidad?: IUnidadPresion): string {
    const ultimoRegistro = unidad?.ultimoRegistro;
    const ultimaAlerta = unidad?.ultimaAlerta;

    const fechaUltimoRegistro = ultimoRegistro?.fechaCreacion;
    const fechaUltimaAlerta = ultimaAlerta?.fechaCreacion;

    if (fechaUltimoRegistro && fechaUltimaAlerta) {
      return fechaUltimoRegistro > fechaUltimaAlerta
        ? fechaUltimoRegistro
        : fechaUltimaAlerta;
    }

    if (fechaUltimoRegistro) {
      return fechaUltimoRegistro;
    }

    if (fechaUltimaAlerta) {
      return fechaUltimaAlerta;
    }

    return '';
  }
}
