import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from './dialog/dialog.module';
import { MaterialModule } from './material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { AutocompleteDireccionModule } from './autocomplete-direccion/autocomplete-direccion.module';
import { UbicarModule } from './ubicar/ubicar.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartModule } from './chart/chart.module';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';

import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { TablaComponent } from './tabla/tabla.component';
import { DatetimeRangePickerComponent } from './datetime-range-picker/component';

@NgModule({
  imports: [
    TablaComponent,
    DatetimeRangePickerComponent,
    FormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    FlexLayoutModule,
    MaterialModule,
    GoogleMapsModule,
    NgSelectModule,
    DialogModule,
    AutocompleteDireccionModule,
    UbicarModule,
    ChartModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    TablaComponent,
    DatetimeRangePickerComponent,
  ],
})
export class AuxiliaresModule {}
