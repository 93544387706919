<div class="fondo dialog">
  <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2
      mat-dialog-title
      class="modal-header"
      cdkDragHandle
      style="margin-bottom: 1em"
    >
      {{ title | titlecase }}
    </h2>
  </ng-container>

  <mat-dialog-content style="min-height: 50px">
    {{ message }}
  </mat-dialog-content>

  <br />

  <mat-dialog-actions fxLayoutAlign="end center" style="margin: 0.5em">
    <button mat-raised-button (click)="matDialogRef.close(false)">
      {{ btnCancelText }}
    </button>
  </mat-dialog-actions>
</div>
