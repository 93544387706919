<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
    <mat-card-subtitle>
      Cromatografía creada por:
      {{ data.usuario?.username || "Usuario no encontrado" }}
    </mat-card-subtitle>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <div class="mat-elevation-z8" style="margin: 1em">
      <table class="tabla1">
        <tr>
          <th>Fecha de Creación</th>
          <th>Fecha de Aplicación</th>
          <th>Fecha de Vencimiento</th>
          <th>Unidad de Negocio</th>
          <th>Zona de Calidad</th>
        </tr>
        <tr>
          <td>{{ (data.fechaCreacion | date : "shortDate") || "..." }}</td>
          <td>{{ (data.fechaAplicacion | date : "shortDate") || "..." }}</td>
          <td>{{ (data.fechaVencimiento | date : "shortDate") || "..." }}</td>
          <td>{{ data.unidadNegocio?.nombre || "..." }}</td>
          <td>{{ data.cuenca?.nombre || "..." }}</td>
        </tr>
      </table>
    </div>

    <div
      style="margin: 1em; text-align: center"
      *ngIf="data.elementos"
      class="center"
    >
      <table>
        <tr>
          <th style="padding: 0.5em">Densidad</th>
          <td style="padding: 0.5em">
            {{ data.elementos.densidad | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Metano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.metano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Nitrógeno</th>
          <td style="padding: 0.5em">
            {{ data.elementos.nitrogeno | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Dioxido de Carbono</th>
          <td style="padding: 0.5em">
            {{ data.elementos.dioxidoCarbono | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Etano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.etano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Propano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.propano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Oxígeno</th>
          <td style="padding: 0.5em">
            {{ data.elementos.oxigeno | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Iso Butano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.isoButano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">nButano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.nButano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">Iso Pentano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.isoPentano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">nPentano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.nPentano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">nHexano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.nHexano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">nHeptano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.nHeptano | number : "0.3-3" }}
          </td>
        </tr>
        <tr>
          <th style="padding: 0.5em">nOctano</th>
          <td style="padding: 0.5em">
            {{ data.elementos.nOctano | number : "0.3-3" }}
          </td>
        </tr>
      </table>
    </div>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
