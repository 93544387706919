<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Importar Registros soxis
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <div
      class="mb-3"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="15px"
    >
      <mat-progress-spinner
        mode="indeterminate"
        *ngIf="loading.getLoading()"
        color="accent"
      ></mat-progress-spinner>

      <button
        mat-mini-fab
        type="button"
        class="mb-3"
        color="primary"
        (click)="fileInput.click()"
        [disabled]="loading.getLoading()"
        style="width: 40px"
      >
        <mat-icon>upload</mat-icon>
      </button>

      <input
        #fileInput
        type="file"
        style="position: fixed; top: -20000px"
        accept=".csv"
        (change)="setFile($event)"
      />
    </div>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
