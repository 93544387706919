import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { PlacePredictionService } from '../../place-prediction.service';
import { LoadingService } from '../../loading.service';

@Component({
  selector: 'app-autocomplete-direccion',
  templateUrl: './autocomplete-direccion.component.html',
  styleUrls: ['./autocomplete-direccion.component.scss'],
})
export class AutocompleteDireccionComponent implements OnInit {
  public direcciones: string[] = [];

  @ViewChild('inputDireccion', { static: true }) inputDireccion?: ElementRef;

  @Input() direccion = '';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange = new EventEmitter<string>();

  constructor(
    private placePredictionService: PlacePredictionService,
    public loading: LoadingService,
  ) {}

  public onChangeDir(direccion: string): void {
    this.onChange.emit(direccion);
  }

  private listenDirChange(): void {
    fromEvent(this.inputDireccion?.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        filter(() => !this.loading.getLoading()),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(async () => {
        this.direcciones =
          await this.placePredictionService.getPlacePredictions(this.direccion);
      });
  }

  ngOnInit(): void {
    this.listenDirChange();
  }
}
