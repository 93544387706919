import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ICorrectora, IPuntoMedicion, IResponseGetRegistro } from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { RegistrosService } from '../../modulos/correctoras/registros.service';
import { MatPaginator } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-registros-faltantes',
  templateUrl: './listado-registros-faltantes.component.html',
  styleUrls: ['./listado-registros-faltantes.component.scss'],
})
export class ListadoRegistrosFaltantesComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() correctora?: ICorrectora;

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  // TABLA
  public dataSource = new MatTableDataSource<string>([]);
  public totalCount = 0;
  public columnas: string[] = ['fecha'];
  public nombreColumnas: string[] = ['Fecha'];
  public saveColumnas = 'cliente-gas-registros-perdidos';

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public loading: LoadingService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private registrosService: RegistrosService,
  ) {}

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  private async listar(): Promise<void> {
    let resp: IResponseGetRegistro | boolean = false;
    if (this.correctora) {
      resp = await firstValueFrom(
        this.registrosService.faltantes(this.correctora?._id),
      );
    }
    if (this.puntoMedicion) {
      resp = await firstValueFrom(
        this.registrosService.faltantesPorPunto(this.puntoMedicion?._id),
      );
    }

    if (resp) {
      console.log(
        `registros faltantes ${
          this.correctora?.numeroSerie || this.puntoMedicion?.nombre
        }`,
        resp,
      );
      const fechas = resp?.registros?.map((reg) => new Date(reg).toISOString());
      this.dataSource.data = fechas;
      this.totalCount = resp?.registros.length;
    }
  }

  // NG

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }
}
