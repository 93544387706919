import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CHANGELOG_NUC } from './changelog.datos';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrl: './changelog.component.scss',
})
export class ChangelogComponent {
  public datos = JSON.parse(JSON.stringify(CHANGELOG_NUC)).reverse();
  constructor(private dialogRef: MatDialogRef<ChangelogComponent>) {}
  public close(): void {
    this.dialogRef.close();
  }
}
