<div class="column-center">
  <div class="row-start">
    <h2 style="margin: 0.5em 0">Unidad de Negocio</h2>
  </div>
  <div class="row-center" style="margin-bottom: 1em; width: 100%">
    <img
      src="assets/iconos/usuario.png"
      alt="usuario"
      style="width: 50px; margin: 0.5em"
      [matTooltip]="usuario?.username!"
    />
    <mat-form-field appearance="outline" style="width: 215px" class="no-hint">
      <mat-select
        [(ngModel)]="unidadNegocio"
        (selectionChange)="cambioUnidadNegocio($event.value)"
        placeholder="Unidad de Negocio"
      >
        <mat-option *ngFor="let dato of unidadNegocios" [value]="dato">
          {{ dato.nombre }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="unidadNegocio"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="
          $event.stopPropagation();
          unidadNegocio = undefined;
          cambioUnidadNegocio()
        "
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="row-center" style="width: 88%" *ngIf="verDivisiones">
    <mat-form-field
      appearance="outline"
      style="width: 100%"
      class="no-hint"
      class="dense-3"
    >
      <mat-select
        [(ngModel)]="division"
        (selectionChange)="cambioDivision($event.value)"
        placeholder="División"
      >
        <mat-option *ngFor="let dato of divisiones" [value]="dato">
          {{ dato }}
        </mat-option>
      </mat-select>
      <button
        *ngIf="division"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="
          $event.stopPropagation(); division = undefined; cambioDivision()
        "
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
