<div class="container">
  <img [src]="logo" alt="horatech" class="logo" />

  <form
    id="form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="form-container"
  >
    <h2>Nueva Contraseña</h2>

    <mat-form-field class="input" appearance="outline">
      <mat-label>Contraseña</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input
        type="password"
        matInput
        formControlName="password"
        autocomplete="new-password"
      />
    </mat-form-field>

    <mat-form-field class="input" appearance="outline">
      <mat-label>Repetir Contraseña</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input
        type="password"
        matInput
        formControlName="password2"
        autocomplete="new-password"
      />
    </mat-form-field>

    <div class="row">
      <!-- <mat-checkbox>
          <span class="checkbox-text">Recordarme</span>
        </mat-checkbox> -->
      <div style="width: 55px; height: 55px">
        <mat-icon
          svgIcon="claro-oscuro"
          (click)="toggleTheme()"
          style="cursor: pointer"
          inline
        ></mat-icon>
      </div>
      <span
        class="checkbox-text"
        routerLink="/login"
        style="cursor: pointer"
        tabindex="-1"
      >
        Iniciar Sesión
      </span>
    </div>
    <!--  -->
    <button
      mat-flat-button
      class="login-button"
      [disabled]="!form.valid || this.loading.getLoading() || !coinciden()"
      type="submit"
      form="form"
    >
      GUARDAR
    </button>
  </form>
</div>
