import { Injectable } from '@angular/core';
import { IUnidadNegocio } from 'modelos/src';
import { Subject } from 'rxjs';

export interface IFiltroGlobal {
  unidadNegocio?: IUnidadNegocio;
  division?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public modoOscuro$ = new Subject<boolean>();

  public unidadNegocio$ = new Subject<IUnidadNegocio>();
  private unidadNegocio?: IUnidadNegocio;

  public division$ = new Subject<string>();
  private division?: string;

  public filtro$ = new Subject<IFiltroGlobal>();

  constructor() {
    this.modoOscuro$.next(false);
  }

  public reset(): void {
    this.unidadNegocio$ = new Subject<IUnidadNegocio>();
    this.unidadNegocio = undefined;
    this.division$ = new Subject<string>();
    this.division = undefined;
  }

  public subscribeFiltroGlobal() {
    return this.filtro$.asObservable();
  }
  private emitFiltroGlobal() {
    this.filtro$.next({
      unidadNegocio: this.unidadNegocio,
      division: this.division,
    });
  }
  public getFiltroGlobal(): IFiltroGlobal {
    return {
      unidadNegocio: this.unidadNegocio,
      division: this.division,
    };
  }

  public setUnidadNegocio(unidadNegocio?: IUnidadNegocio) {
    this.unidadNegocio = unidadNegocio;
    this.emitFiltroGlobal();
  }

  public setDivision(division?: string) {
    this.division = division;
    this.emitFiltroGlobal();
  }
}
