import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { AuditoriasRoutingModule } from './auditorias.routing.module';
import { ListadoAuditoriasComponent } from './listado-auditorias/listado-auditorias.component';
import { MostrarBodyComponent } from './listado-auditorias/mostrarBody/mostrarBody.component';

@NgModule({
  declarations: [ListadoAuditoriasComponent, MostrarBodyComponent],
  imports: [CommonModule, AuxiliaresModule, AuditoriasRoutingModule],
})
export class AuditoriasModule {}
