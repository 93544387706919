<div *ngIf="chart" class="transparente" style="margin: 1em">
  <app-chart
    [constructorType]="'stockChart'"
    [options]="chart"
    style="width: 100%; height: 400px; display: block; margin-top: 30px"
  >
  </app-chart>
</div>

<!-- NO hay ultimo registro -->
<div
  *ngIf="!resumenReportes?.length"
  style="
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  "
>
  <h3>
    <strong> No hay registros </strong>
  </h3>
</div>
