import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IAuditoriaVeribox,
  IFilter,
  IListado,
  IQueryParam,
  IReporte,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { ReportesService } from '../../modulos/reportes/service';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IRegExpSearch } from '../../auxiliares/tabla/filtro/filtro.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-auditorias-veribox',
  templateUrl: './listado-auditorias-veribox.component.html',
  styleUrls: ['./listado-auditorias-veribox.component.scss'],
})
export class ListadoAuditoriasVeriboxComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() unidadPresion?: IUnidadPresion;

  ///// Tabla nueva
  public name = 'ListadoAuditoriasVeriboxComponent';
  public datos?: IAuditoriaVeribox[] = [];
  public columnas?: IColumnas<IAuditoriaVeribox>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // public botonExportar: IExportarBoton = {
  //   accion: async () => this.exportar(),
  //   mostrar: true,
  //   tooltip: 'Exportar Reportes',
  // };

  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaCreacion',
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public reportesService: ReportesService,
  ) {}

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    if (this.unidadPresion) {
      const filter: IFilter<any> = {
        deveui: this.unidadPresion.deveui,
      };

      this.query.filter = JSON.stringify(filter);

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('auditoriaVeriboxs', this.query)
        .subscribe((data) => {
          console.log(`listado de auditoriaVeriboxs`, data);

          this.totalCount = data.totalCount;
          this.datos = data.datos;
        });
      await this.listadosService.getLastValue('auditoriaVeriboxs', this.query);
    }
  }

  private setColumnas() {
    this.columnas = [
      {
        header: { label: 'Fecha de Creación', sortable: true },
        row: {
          parse(dato) {
            return dato.fechaCreacion;
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: { label: 'Fecha de la Auditoria', sortable: true },
        row: {
          parse(dato) {
            return dato.fecha;
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: { label: 'Comando' },
        row: {
          parse(dato) {
            return dato.comando;
          },
        },
      },
      {
        header: { label: 'Valor Original' },
        row: {
          parse(dato) {
            return dato.valorOriginal!;
          },
          noData: 'Sin Reportar',
        },
      },
      {
        header: { label: 'Valor Nuevo' },
        row: {
          parse(dato) {
            return dato.valorNuevo!;
          },
          noData: 'Sin Reportar',
        },
      },
    ];
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
