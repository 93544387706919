import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IMedidorResidencial, IReporte } from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarMedidorResidencialsComponent } from '../crear-editar-medidores-residenciales/crear-editar-medidores-residenciales.component';
import { MedidorResidencialsService } from '../medidores-residenciales.service';
import { IRangePreset } from '../../../auxiliares/datetime-range-picker/component';

@Component({
  selector: 'app-detalles-medidor-residencial',
  templateUrl: './detalles-medidor-residencial.component.html',
  styleUrls: ['./detalles-medidor-residencial.component.scss'],
})
export class DetallesMedidorResidencialsComponent implements OnInit, OnDestroy {
  public id?: string | null;
  public medidorResidencial?: IMedidorResidencial;
  public ultimoReporte?: IReporte;

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptions),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {};

  public range?: { from: string; to: string };
  public presets: IRangePreset[] = HelperService.presetCorrectoras;

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    public helper: HelperService,
    public matDialog: MatDialog,
    public service: MedidorResidencialsService,
  ) {}

  //
  public async editar(data?: IMedidorResidencial) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMedidorResidencialsComponent, config);
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    if (this.id) {
      this.datos$ = this.listadosService
        .subscribe<IMedidorResidencial>('medidorResidencial', this.id)
        .subscribe((data) => {
          this.medidorResidencial = data;
          if (this.medidorResidencial?.ubicacionGps) {
            this.mapCenter = {
              lat: this.medidorResidencial?.ubicacionGps?.lat,
              lng: this.medidorResidencial?.ubicacionGps?.lng,
            };
            this.markerOptions = {
              position: this.mapCenter,
            };
          }
          this.ultimoReporte = this.medidorResidencial?.ultimoReporte;
          console.log(`listado de medidorResidencial`, data);
        });
      await this.listadosService.getLastValue('medidorResidencial', this.id);
    }
  }

  //

  // public puedeEditar(): boolean {
  //   return !!(
  //     this.medidorResidencial?.idUnidadNegocio &&
  //     HelperService.esAdminUnidadNegocio(
  //       this.medidorResidencial.idUnidadNegocio!
  //     )
  //   );
  // }

  public defaultFrom(dias = 2): Date {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - dias);
    fecha.setHours(7, 0, 0, 0);
    return fecha;
  }

  public defaultTo(): Date {
    const fecha = new Date();
    fecha.setHours(23, 59, 59, 999);
    return fecha;
  }

  ngOnInit(): void {
    this.mapOptions.mapTypeControl = false;
    this.mapOptions.zoomControl = false;
    this.mapOptions.fullscreenControl = true;
    this.mapOptions.zoom = 15;
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.id = params.get('id');
      await this.listar();
    });

    this.range = {
      from: this.defaultFrom().toISOString(),
      to: this.defaultTo().toISOString(),
    };
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
