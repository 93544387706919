import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Nivel, Rol } from 'modelos/src';
import { LoginService } from '../../modulos/login/login.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class RolesGlobalesGuard  {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private helper: HelperService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const user = LoginService.getUsuario();
    const rolesPermitidos = route.data.roles as Rol[];
    const nivelesPermitidos = route.data.niveles as Nivel[];

    if (nivelesPermitidos?.length) {
      const permiso = user.permisos?.find(
        (permiso) =>
          nivelesPermitidos.includes(permiso.nivel!) &&
          (!rolesPermitidos || rolesPermitidos.includes(permiso.rol!))
      );
      if (permiso) {
        return true;
      }
      this.helper.notifWarn('Permiso de nivel denegado');
      return this.router.parseUrl('/main');
    } else {
      const permiso = user.permisos?.find(
        (permiso) => !rolesPermitidos || rolesPermitidos.includes(permiso.rol!)
      );
      if (permiso) {
        return true;
      }
      this.helper.notifWarn('Permiso de rol denegado');
      return this.router.parseUrl('/main');
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    const user = LoginService.getUsuario();
    const rolesPermitidos = route.data.roles as Rol[];
    const nivelesPermitidos = route.data.niveles as Nivel[];

    if (nivelesPermitidos?.length) {
      const permiso = user.permisos?.find(
        (permiso) =>
          nivelesPermitidos.includes(permiso.nivel!) &&
          (!rolesPermitidos || rolesPermitidos.includes(permiso.rol!))
      );
      if (permiso) {
        return true;
      }
      this.helper.notifWarn('Permiso de nivel denegado');
      return this.router.parseUrl('/main');
    } else {
      const permiso = user.permisos?.find(
        (permiso) => !rolesPermitidos || rolesPermitidos.includes(permiso.rol!)
      );
      if (permiso) {
        return true;
      }
      this.helper.notifWarn('Permiso de rol denegado');
      return this.router.parseUrl('/main');
    }
  }
}
