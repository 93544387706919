import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IFilter, IListado, IPuntoMedicion, IQueryParam } from 'modelos/src';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { IRangePreset } from 'src/app/auxiliares/datetime-range-picker/component';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { GraficoMinMaxMultiComponent } from 'src/app/standalone/grafico-min-max-multi/grafico-min-max-multi.component';

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss'],
})
export class EstadisticasComponent implements OnInit, OnDestroy {
  @ViewChild('graficoMinMaxMulti')
  graficoMinMaxMulti?: GraficoMinMaxMultiComponent;
  public puntos?: IPuntoMedicion[];
  public puntos$?: Subscription;
  public puntosSeleccionados: IPuntoMedicion[] = [];

  public divisiones = ['Presión', 'Correctoras'];
  public division = 'Presión';

  public range: IRangePreset = {
    from: this.helper.fechaHaceUnAnio().toISOString(),
    to: this.helper.fechaHaceXDias(-1).toISOString(),
  };

  public fecha5Min = new Date().toISOString();
  public range5Min: IRangePreset = {
    from: this.helper.fechaHaceXDias(1).toISOString(),
    to: this.helper.fechaHaceXDias(-1).toISOString(),
  };

  public filter: IFilter<IPuntoMedicion> = {
    division: this.division,
    // estado: 'Operativa',
  };

  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    limit: 0,
  };

  constructor(
    private listados: ListadosService,
    private helper: HelperService,
  ) {}

  public async onChangeDivision(event: any): Promise<void> {
    this.division = event;
    this.filter.division = this.division;
    this.query.filter = JSON.stringify(this.filter);
    await this.listarPuntos();
  }

  public cambioFecha5Min(value: Moment): void {
    value.hours(0).minutes(0).seconds(0).milliseconds(0);
    const from = value.toISOString();
    value.hours(23).minutes(59).seconds(59).milliseconds(999);
    const to = value.toISOString();

    this.range5Min = {
      from,
      to,
    };
  }

  /// Listados

  public async listarPuntos(): Promise<void> {
    this.puntos$?.unsubscribe();
    this.puntos$ = this.listados
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', this.query)
      .subscribe((data) => {
        this.puntos = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listados.getLastValue('puntosMedicion', this.query);
  }

  ///

  async ngOnInit(): Promise<void> {
    await Promise.all([this.listarPuntos()]);
  }

  ngOnDestroy(): void {
    this.puntos$?.unsubscribe();
  }
}
