import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import Boost from 'highcharts/modules/boost';
import SolidGauge from 'highcharts/modules/solid-gauge';
import more from 'highcharts/highcharts-more';
import HC_stock from 'highcharts/modules/stock';
import IndicatorsCore from 'highcharts/indicators/indicators';
import IndicatorRegressions from 'highcharts/indicators/regressions';
import { Options } from 'highcharts/highstock';
import Timeline from 'highcharts/modules/timeline';
import Theme from 'highcharts/themes/dark-unica';
import Stock from 'highcharts/modules/stock';
import stockTools from 'highcharts/modules/stock-tools';

HC_stock(Highcharts);
noData(Highcharts);
more(Highcharts);
Boost(Highcharts);
SolidGauge(Highcharts);
IndicatorsCore(Highcharts);
IndicatorRegressions(Highcharts);
// Exporting(Highcharts);
// ExportData(Highcharts);
Theme(Highcharts);
Timeline(Highcharts);
Stock(Highcharts);
stockTools(Highcharts);

Highcharts.setOptions({
  lang: {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    shortWeekdays: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  },
});

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  public Highcharts: typeof Highcharts = Highcharts;

  @Input() options!: Options;
  @Input() style?: string;
  @Input() constructorType: string = 'chart';
  public update: boolean = false;
  chart?: Highcharts.Chart;

  @Output() optionsChange = new EventEmitter<Options>();
  @Output() chartPrint = new EventEmitter<void>();

  constructor() {
    this.chartCallback.bind(this);
  }

  public chartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }

  public chartCallback: Highcharts.ChartCallbackFunction = () => {
    setTimeout(() => {
      this.chartPrint.emit();
    }, 100);
  };

  private setDefaults() {
    this.update = true;
    if (this.chart && this.options) {
      if (!this.options.accessibility) {
        this.options.accessibility = {
          enabled: false,
        };
      }
      if (this.options?.chart && !this.options?.chart?.style) {
        this.options!.chart!.style = {
          fontFamily: 'monserrat-regular, sans-serif',
        };
      }

      this.chart?.update(this.options, true, true);
    }
  }

  ngOnChanges() {
    this.setDefaults();
  }

  ngOnInit(): void {
    this.setDefaults();
  }
}
