import { Injectable } from '@angular/core';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { ICoordenadas } from 'modelos/src';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class GeocodeService {
  constructor(private geocoder: MapGeocoder) {}

  public async getAddress(location: ICoordenadas): Promise<string | void> {
    try {
      const request: google.maps.GeocoderRequest = {
        location,
      };
      const results: MapGeocoderResponse = await firstValueFrom(
        this.geocoder.geocode(request)
      );
      const result: google.maps.GeocoderResult = results.results[0];
      if (result) {
        return result.formatted_address;
      }
    } catch (err) {
      console.error(err);
      throw 'Error al obtener la dirección';
    }
  }

  public async getCoordinates(address: string): Promise<ICoordenadas | void> {
    try {
      const request: google.maps.GeocoderRequest = {
        address,
      };
      const results: MapGeocoderResponse = await firstValueFrom(
        this.geocoder.geocode(request)
      );
      const result: google.maps.GeocoderResult = results.results[0];
      if (result) {
        return {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        };
      }
    } catch (err) {
      console.error(err);
      throw 'Error al obtener las coordenadas';
    }
  }
}
