import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDispositivo,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUpdateDispositivo,
  IFilter,
  ICentroOperativo,
  ICorrectora,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { DispositivosService } from '../dispositivos.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar-dispositivos',
  templateUrl: './crear-editar-dispositivos.component.html',
  styleUrls: ['./crear-editar-dispositivos.component.scss'],
})
export class CrearEditarDispositivosComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;
  public correctoras$?: Subscription;
  public unidadPresions$?: Subscription;

  public mensajeDispositivoAsignado = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarDispositivosComponent>,
    private service: DispositivosService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? `Editar Dispositivo ${this.data.deviceName}`
      : 'Crear Dispositivo';

    this.form = this.fb.group({
      idUnidadNegocio: [this.data?.idUnidadNegocio],
      idCentroOperativo: [this.data?.idCentroOperativo],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  public async changeUnidadNegocio(): Promise<void> {
    this.form?.patchValue({ idCuenca: null });
    this.centroOperativos = [];
    await this.listarCentroOperativos();
  }

  //

  private getUpdateData() {
    const data: IUpdateDispositivo = {
      idUnidadNegocio: this.form?.get('idUnidadNegocio')?.value,
      idCentroOperativo: this.form?.get('idCentroOperativo')?.value,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    if (idUnidadNegocio) {
      // Filtro
      const filter: IFilter<any> = { idUnidadNegocio };
      const query: IQueryParam = {
        select: 'nombre',
        sort: 'nombre',
        filter: JSON.stringify(filter),
      };

      // Listado
      this.centroOperativos$?.unsubscribe();
      this.centroOperativos$ = this.listadosService
        .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
        .subscribe((data) => {
          this.centroOperativos = data.datos;
          console.log(`listado de centroOperativos`, data);
        });
      await this.listadosService.getLastValue('centroOperativos', query);
    }
  }

  private async listarCorrectoraPorDeveui(): Promise<void> {
    const deveui = this.data?.deveui;
    if (deveui) {
      const filter: IFilter<any> = {
        deveui,
      };
      const query: IQueryParam = {
        select: 'modelo numeroSerie',
        filter: JSON.stringify(filter),
      };
      // Listado
      this.correctoras$?.unsubscribe();
      this.correctoras$ = this.listadosService
        .subscribe<IListado<ICorrectora>>('correctoras', query)
        .subscribe((data) => {
          const correctora = data.datos[0];

          if (correctora) {
            this.mensajeDispositivoAsignado = `El dispositivo está asignado a la correctora <strong>${correctora.modelo} S/N ${correctora.numeroSerie}.</strong> para poder editarlo primero debe desasignarlo.`;
          } else {
            this.mensajeDispositivoAsignado = '';
          }

          console.log(`listado de correctoras`, data);
        });
      await this.listadosService.getLastValue('correctoras', query);
    }
  }

  private async listarUnidadPresionPorDeveui(): Promise<void> {
    const deveui = this.data?.deveui;
    if (deveui) {
      const filter: IFilter<any> = {
        deveui,
      };
      const query: IQueryParam = {
        select: 'modelo numeroSerie',
        filter: JSON.stringify(filter),
      };
      // Listado
      this.unidadPresions$?.unsubscribe();
      this.unidadPresions$ = this.listadosService
        .subscribe<IListado<IUnidadPresion>>('unidadPresions', query)
        .subscribe((data) => {
          const unidadPresion = data.datos[0];

          if (unidadPresion) {
            this.mensajeDispositivoAsignado = `El dispositivo está asignado a la unidad de presion <strong>${unidadPresion.modelo} S/N ${unidadPresion.numeroSerie}.</strong> para poder editarlo primero debe desasignarlo.`;
          } else {
            this.mensajeDispositivoAsignado = '';
          }

          console.log(`listado de unidadPresions`, data);
        });
      await this.listadosService.getLastValue('unidadPresions', query);
    }
  }

  private async checkDispositivoAsignado() {
    const tipo = this.data?.tipoDispositivo;
    if (tipo === 'NUC') await this.listarCorrectoraPorDeveui();
    if (tipo === 'NSP') await this.listarUnidadPresionPorDeveui();
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
      this.checkDispositivoAsignado(),
    ]);
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.correctoras$?.unsubscribe();
    this.unidadPresions$?.unsubscribe();
  }
}
