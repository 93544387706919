import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  IConfigDispositivo,
  IConfigDispositivoNUC4G,
  ICorrectora,
  IDispositivo,
  IDispositivoNuc4G,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { NOMBRE_ALERTAS_NUC } from '../../../../environments/datos';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarCorrectorasComponent } from '../crear-editar-correctoras/crear-editar-correctoras.component';
import { RegistrosService } from '../registros.service';
import { AlertasService } from '../alertas.service';
import { CorrectorasService } from '../correctoras.service';
import { IRangePreset } from 'src/app/auxiliares/datetime-range-picker/component';

@Component({
  selector: 'app-detalles-correctoras',
  templateUrl: './detalles-correctoras.component.html',
  styleUrls: ['./detalles-correctoras.component.scss'],
})
export class DetallesCorrectorasComponent implements OnInit, OnDestroy {
  public nombreAlertas = NOMBRE_ALERTAS_NUC;

  public range?: { from: string; to: string };
  public presets: IRangePreset[] = HelperService.presetCorrectoras;
  public id?: string | null;
  public correctora?: ICorrectora;

  public configActual?: IDispositivoNuc4G & { claveMercury?: string };
  public configDeseada?: IConfigDispositivoNUC4G;

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptions),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {};

  // Listado Continuo
  public datos$?: Subscription;
  public config$?: Subscription;
  public dispositivo$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public registrosService: RegistrosService,
    public alertasService: AlertasService,
    public correctorasService: CorrectorasService,
  ) {}

  //
  public async editar(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarCorrectorasComponent, config);
  }

  //

  public defaultFromCorrectora(dias = 2): Date {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - dias);
    fecha.setHours(7, 0, 0, 0);
    return fecha;
  }

  public defaultToCorrectora(): Date {
    const fecha = new Date();
    fecha.setHours(6, 0, 0, 0);
    return fecha;
  }

  //

  private async listarConfigDispositivo(): Promise<void> {
    this.config$?.unsubscribe();
    const deveui = this.correctora?.dispositivo?.deveui;
    if (deveui) {
      this.config$ = this.listadosService
        .subscribe<IConfigDispositivo>('configDispositivo', deveui)
        .subscribe((data) => {
          this.configDeseada = data?.config as IConfigDispositivoNUC4G;
          console.log(`listado de configDispositivo`, data);
        });
      await this.listadosService.getLastValue('configDispositivo', deveui);
    }
  }

  // Se lista por separado del punto para actualizar la config actual por WS cuando se modifica
  private async listarDispositivo(): Promise<void> {
    this.dispositivo$?.unsubscribe();
    const deveui = this.correctora?.dispositivo?.deveui;

    if (deveui) {
      this.dispositivo$ = this.listadosService
        .subscribe<IDispositivo>('dispositivoDeveui', deveui)
        .subscribe((data) => {
          console.log(`listado de dispositivo`, data);
          this.configActual = data?.config as IConfigDispositivoNUC4G;
        });
      await this.listadosService.getLastValue('dispositivoDeveui', deveui);
    }
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    if (this.id) {
      this.datos$ = this.listadosService
        .subscribe<ICorrectora>('correctora', this.id)
        .subscribe(async (data) => {
          if (data.ultimoRegistro) {
            data.ultimoRegistro.uncorrectedParcializado =
              this.registrosService.getUncorrectedParcializado(
                data.ultimoRegistro,
                data,
              );
            data.ultimoRegistro.correctedParcializado =
              this.registrosService.getCorrectedParcializado(
                data.ultimoRegistro,
                data,
              );
            data.ultimoRegistro.uncorrectedTotalizado =
              this.registrosService.getUncorrectedTotalizado(
                data.ultimoRegistro,
                data,
              );
            data.ultimoRegistro.correctedTotalizado =
              this.registrosService.getCorrectedTotalizado(
                data.ultimoRegistro,
                data,
              );
          }

          this.correctora = data;
          console.debug(`listado de correctora`, data);
        });
      await this.listadosService.getLastValue('correctora', this.id);
    }
  }

  ngOnInit(): void {
    this.mapOptions.mapTypeControl = false;
    this.mapOptions.zoomControl = false;
    this.mapOptions.fullscreenControl = true;
    this.mapOptions.zoom = 15;
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.id = params.get('id');
      await this.listar();
      await Promise.all([
        this.listarConfigDispositivo(),
        this.listarDispositivo(),
      ]);
    });
    this.range = {
      from: this.defaultFromCorrectora().toISOString(),
      to: this.defaultToCorrectora().toISOString(),
    };
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.config$?.unsubscribe();
  }
}
