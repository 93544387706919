import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ICorrectora,
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IRegistro,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { RegistrosService } from '../../modulos/correctoras/registros.service';
import {
  IColumnas,
  IExportarBoton,
  IRowButton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IRegExpSearch } from '../../auxiliares/tabla/filtro/filtro.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-registros',
  templateUrl: './listado-registros.component.html',
  styleUrls: ['./listado-registros.component.scss'],
})
export class ListadoRegistrosComponent implements OnInit, OnChanges, OnDestroy {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() correctora?: ICorrectora;
  @Input() range?: { from: string; to: string };

  //// Tabla nueva
  public name = 'ListadoRegistrosComponent_detallesCorrectora';
  public datos?: IRegistro[] = [];
  public columnas?: IColumnas<IRegistro>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public botonExportar: IExportarBoton = {
    accion: async () => this.exportar(),
    mostrar: true,
    tooltip: 'Exportar Registros',
  };

  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-timestamp',
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private dialogService: DialogService,
    public registrosService: RegistrosService,
  ) {}

  public async exportar() {
    let filter: IFilter<IRegistro> | boolean = false;
    if (this.correctora && this.range) {
      filter = {
        idCorrectora: this.correctora._id,
        timestamp: {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
    }
    if (this.puntoMedicion && this.range) {
      filter = {
        idPuntoMedicion: this.puntoMedicion._id,
        timestamp: {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
    }

    if (filter) {
      const mensaje = `¿Desea exportar el listado de registros?`;
      const agrupacionTemporal = await this.dialogService.options(
        'Confirme la acción',
        mensaje,
        'Agrupación Temporal',
        ['hora', 'dia', 'mes'],
      );
      if (agrupacionTemporal) {
        const query: IQueryParam = {
          numeroSerieCorrectora: this.correctora?.numeroSerie,
          idCorrectora: this.correctora?._id,
          idPuntoMedicion: this.puntoMedicion?._id,
          filter: JSON.stringify(filter),
          limit: 0,
          agrupacionTemporal,
        };

        console.log(`exportar registros`, query);

        try {
          await this.registrosService.exportar(query);
        } catch (error) {
          this.helper.notifError(error);
        }
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    let listar = false;
    if (this.correctora && this.range) {
      // Filtro
      const filter: IFilter<IRegistro> = {
        idCorrectora: this.correctora._id,
        timestamp: {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
      this.query.filter = JSON.stringify(filter);
      listar = true;
    }

    if (this.puntoMedicion && this.range) {
      // Filtro
      const filter: IFilter<IRegistro> = {
        idPuntoMedicion: this.puntoMedicion._id,
        timestamp: {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
      this.query.filter = JSON.stringify(filter);
      listar = true;
    }

    if (listar) {
      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IRegistro>>('registros', this.query)
        .subscribe((data) => {
          console.log(`listado de registros`, data);

          for (const registro of data.datos) {
            registro.uncorrectedParcializado =
              this.registrosService.getUncorrectedParcializado(
                registro,
                this.correctora,
              );
            registro.correctedParcializado =
              this.registrosService.getCorrectedParcializado(
                registro,
                this.correctora,
              );
            registro.correctedTotalizado =
              this.registrosService.getCorrectedTotalizado(
                registro,
                this.correctora,
              );
            registro.uncorrectedTotalizado =
              this.registrosService.getUncorrectedTotalizado(
                registro,
                this.correctora,
              );
          }

          this.totalCount = data.totalCount;
          this.datos = data.datos;
        });
      await this.listadosService.getLastValue('registros', this.query);
    }
  }

  public async eliminar(registro: IRegistro) {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `Se eliminará el registro del día ${new Date(
        registro.timestamp!,
      ).toLocaleString()} para que la correctora lo intente recuperar, ¿desea continuar?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.registrosService.eliminar(registro._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  //
  private isGTEZero(value: number | undefined) {
    return value !== -1 && value !== undefined;
  }

  private tieneTotalizados() {
    const tieneValorPunto = this.isGTEZero(
      this.puntoMedicion?.correctora?.ultimoRegistro?.uncorrectedTotalizado,
    );
    const tieneValorCorrectora = this.isGTEZero(
      this.correctora?.ultimoRegistro?.uncorrectedTotalizado,
    );
    const tieneValorRegistro = this.isGTEZero(
      this.datos?.[0]?.uncorrectedTotalizado,
    );

    return tieneValorPunto || tieneValorCorrectora || tieneValorRegistro;
  }
  private tieneParcializados() {
    const tieneValorPunto = this.isGTEZero(
      this.puntoMedicion?.correctora?.ultimoRegistro?.uncorrectedParcializado,
    );
    const tieneValorCorrectora = this.isGTEZero(
      this.correctora?.ultimoRegistro?.uncorrectedParcializado,
    );
    const tieneValorRegistro = this.isGTEZero(
      this.datos?.[0]?.uncorrectedParcializado,
    );

    return tieneValorPunto || tieneValorCorrectora || tieneValorRegistro;
  }
  private tieneCaudalPico() {
    const tieneValorPunto = this.isGTEZero(
      this.puntoMedicion?.correctora?.ultimoRegistro?.caudalPico,
    );
    const tieneValorCorrectora = this.isGTEZero(
      this.correctora?.ultimoRegistro?.caudalPico,
    );
    const tieneValorRegistro = this.isGTEZero(this.datos?.[0]?.caudalPico);

    return tieneValorPunto || tieneValorCorrectora || tieneValorRegistro;
  }
  private tieneCaudalPromedio() {
    const tieneValorPunto = this.isGTEZero(
      this.puntoMedicion?.correctora?.ultimoRegistro?.caudalPromedio,
    );
    const tieneValorCorrectora = this.isGTEZero(
      this.correctora?.ultimoRegistro?.caudalPromedio,
    );
    const tieneValorRegistro = this.isGTEZero(this.datos?.[0]?.caudalPromedio);

    return tieneValorPunto || tieneValorCorrectora || tieneValorRegistro;
  }

  private registroFaltante(registro: IRegistro) {
    return registro.temperatura === -1 && registro.presion === -1;
  }

  // Parser
  private parseTotalizadoBase(dato: IRegistro) {
    if (dato.uncorrectedTotalizado === -1) {
      return '';
    } else {
      return dato.uncorrectedTotalizado;
    }
  }
  private parseParcializadoBase(dato: IRegistro) {
    if (dato.uncorrectedParcializado === -1) {
      return '';
    } else {
      return dato.uncorrectedParcializado;
    }
  }
  private parseTotalizadoCorregido(dato: IRegistro) {
    if (dato.correctedTotalizado === -1) {
      return '';
    } else {
      return dato.correctedTotalizado;
    }
  }
  private parseParcializadoCorregido(dato: IRegistro) {
    if (dato.correctedParcializado === -1) {
      return '';
    } else {
      return dato.correctedParcializado;
    }
  }
  private parseTemperatura(dato: IRegistro) {
    if (dato.temperatura === -1) {
      return '<span class="no-info">Registro no encontrado</span>';
    } else {
      return `${dato.temperatura}`;
    }
  }
  private parsePresion(dato: IRegistro) {
    if (dato.presion === -1) {
      return '';
    } else {
      return dato.presion;
    }
  }
  private parseBotonEliminar(
    data: IRegistro,
  ): IRowButton<IRegistro> | undefined {
    if (data.temperatura === -1 && data.presion === -1) {
      const boton: IRowButton<IRegistro> = {
        text: 'Reintentar',
        tooltip: '',
        color: 'warn',
        click: (dato) => this.eliminar(dato),
      };
      return boton;
    }
    return;
  }

  private setColumnas() {
    if ((!this.correctora && !this.puntoMedicion) || this.columnas?.length) {
      return;
    }

    const columnas: IColumnas<IRegistro>[] = [
      // Fecha
      {
        header: { label: 'Fecha', sortable: true },
        row: {
          field: 'timestamp',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      // Temperatura
      {
        header: { label: 'Temperatura', sortable: true },
        row: {
          html: this.parseTemperatura,
        },
      },
      // Presion
      {
        header: { label: 'Presión', sortable: true },
        row: {
          parse: this.parsePresion,
          boton: this.parseBotonEliminar.bind(this),
        },
      },
    ];

    if (this.tieneTotalizados()) {
      columnas.push({
        header: { label: 'Volumen Base Totalizado', sortable: true },
        row: {
          parse: this.parseTotalizadoBase,
        },
      });
      columnas.push({
        header: { label: 'Volumen Corregido Totalizado', sortable: true },
        row: {
          parse: this.parseTotalizadoCorregido,
        },
      });
    }

    if (this.tieneParcializados()) {
      columnas.push({
        header: { label: 'Volumen Base Horario', sortable: true },
        row: {
          parse: this.parseParcializadoBase,
        },
      });
      columnas.push({
        header: { label: 'Volumen Corregido Horario', sortable: true },
        row: {
          parse: this.parseParcializadoCorregido,
        },
      });
    }

    if (this.tieneCaudalPico()) {
      columnas.push({
        header: { label: 'Caudal Pico', sortable: true },
        row: {
          parse(dato) {
            return `${HelperService.roundTo(dato.caudalPico || 0, 1)} m³/h`;
          },
        },
      });
    }

    if (this.tieneCaudalPromedio()) {
      columnas.push({
        header: { label: 'Caudal Promedio', sortable: true },
        row: {
          parse(dato) {
            return `${HelperService.roundTo(dato.caudalPromedio || 0, 1)} m³/h`;
          },
        },
      });
    }

    this.columnas = columnas;
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
    this.setColumnas();
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
