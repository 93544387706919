import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICentroOperativo,
  ICreateUnidadPresion,
  IDispositivo,
  IFilter,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUnidadPresion,
  IUpdateUnidadPresion,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { GeocodeService } from '../../../auxiliares/geocode.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { UnidadPresionsService } from '../service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar',
  templateUrl: './crear-editar.component.html',
  styleUrls: ['./crear-editar.component.scss'],
})
export class CrearEditarComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public enviando = false;
  public hide = true;

  public regTel = /^(?!0|15)\d{10}$/;

  public textoAsignado = '';

  public dispositivos: IDispositivo[] = [];
  public unidadesPresion: IUnidadPresion[] = [];
  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public centrosUnidadNegocio: ICentroOperativo[] = [];

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptionsEditor),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  // Listado Continuo
  public dispositivos$?: Subscription;
  public unidadesPresion$?: Subscription;
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUnidadPresion,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarComponent>,
    private service: UnidadPresionsService,
    private listadosService: ListadosService,
    private geocodeService: GeocodeService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Unidad de Presión'
      : 'Crear Unidad de Presión';

    this.form = this.fb.group({
      idUnidadNegocio: [this.data?.idUnidadNegocio],
      idCentroOperativo: [this.data?.idCentroOperativo],
      estadoActual: [this.data?.estadoActual || 'Operativa'],
      deveui: [this.data?.deveui],
      numeroSerie: [this.data?.numeroSerie],
      modelo: [this.data?.modelo],
      // telefono: [this.data?.telefono, Validators.pattern(this.regTel)],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  // Chequear Correctora Asignada
  public async checkDispositivoAsignado() {
    try {
      const deveui = this.form?.get('deveui')?.value;
      if (deveui) {
        await this.listarUnidadPresionPorDeveui(deveui);
        if (this.unidadesPresion.length > 0) {
          this.textoAsignado = `* El NSP seleccionado está asignado a la unidad de presión: <strong>${this.unidadesPresion[0]?.modelo} S/N: ${this.unidadesPresion[0]?.numeroSerie}</strong>, si continúa será desasignada de la misma para asignarase a esta.`;
        } else {
          this.textoAsignado = '';
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public setDatos() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    const centrosUnidadNegocio = this.centroOperativos.filter(
      (c) => c.idUnidadNegocio === idUnidadNegocio,
    );

    this.centrosUnidadNegocio = centrosUnidadNegocio;
  }

  public selectDispositivo() {
    const deveui = this.form?.get('deveui')?.value;
    const dispositivo = this.dispositivos.find((d) => d.deveui === deveui);

    if (dispositivo) {
      const idUnidadNegocio = dispositivo?.idUnidadNegocio;
      const idCentroOperativo = dispositivo?.idCentroOperativo;

      this.form?.patchValue({ idUnidadNegocio });
      this.form?.patchValue({ idCentroOperativo });

      this.setDatos();
    }

    if (dispositivo?.deviceName?.split('-').length === 2) {
      this.form?.patchValue({
        modelo: dispositivo.deviceName.split('-')[0].trim(),
        numeroSerie: dispositivo.deviceName.split('-')[1].trim(),
      });
    }
  }

  public cambioUnidadNegocio() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;

    this.setDatos();

    if (this.centrosUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCentroOperativo: this.centrosUnidadNegocio[0]._id,
      });
    } else {
      const idCentroSeleccionado = this.form?.value.idCentroOperativo;
      const centroSeleccionado = this.centrosUnidadNegocio.find(
        (c) => c._id === idCentroSeleccionado,
      );
      if (centroSeleccionado?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCentroOperativo: null,
        });
      }
    }
  }

  //

  public async selectDireccion(direccion: string) {
    this.form?.patchValue({ direccion });
    const coordenadas = await this.geocodeService.getCoordinates(direccion);
    if (coordenadas) {
      this.markerOptions = {
        position: coordenadas,
        draggable: true,
      };
      this.mapCenter = coordenadas;
      this.mapOptions.zoom = 16;
      this.form?.get('ubicacion')?.patchValue({ lat: coordenadas.lat });
      this.form?.get('ubicacion')?.patchValue({ lng: coordenadas.lng });
    }
  }

  public setLocation(event: google.maps.MapMouseEvent) {
    const coords = event.latLng?.toJSON();
    if (coords) {
      this.form?.get('ubicacion')?.patchValue({ lat: coords.lat });
      this.form?.get('ubicacion')?.patchValue({ lng: coords.lng });
    }
  }

  //

  private getUpdateData() {
    const data: IUpdateUnidadPresion = this.form?.value;
    return data;
  }

  private getCreateData() {
    const data: ICreateUnidadPresion = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarDispositivos(): Promise<void> {
    const filter: IFilter<IDispositivo> = {
      $or: [{ tipoDispositivo: 'NSP' }, { tipoDispositivo: 'VERIBOX MICRO' }],
    };

    const query: IQueryParam = {
      select: 'deveui deviceName idUnidadNegocio idCentroOperativo',
      sort: 'deviceName',
      filter: JSON.stringify(filter),
    };
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    if (idUnidadNegocio) {
      filter.idUnidadNegocio = idUnidadNegocio;
      query.filter = JSON.stringify(filter);
    }
    this.dispositivos$?.unsubscribe();
    this.dispositivos$ = this.listadosService
      .subscribe<IListado<IDispositivo>>('dispositivos', query)
      .subscribe((data) => {
        this.dispositivos = data.datos;
        console.log(`listado de dispositivos`, data);
      });
    await this.listadosService.getLastValue('dispositivos', query);
  }

  private async listarUnidadPresionPorDeveui(deveui: string): Promise<void> {
    const filter: IFilter<IUnidadPresion> = { deveui };

    const query: IQueryParam = {
      select: 'nombre',
      filter: JSON.stringify(filter),
    };

    this.unidadesPresion$?.unsubscribe();
    this.unidadesPresion$ = this.listadosService
      .subscribe<IListado<IUnidadPresion>>('unidadPresions', query)
      .subscribe((data) => {
        this.unidadesPresion = data.datos;
        console.log(`listado de unidadPresions`, data);
      });
    await this.listadosService.getLastValue('unidadPresions', query);
  }

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;

        if (this.unidadNegocios.length === 1) {
          this.form?.patchValue({
            idUnidadNegocio: this.unidadNegocios[0]._id,
          });
          this.setDatos();
        }

        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  public telValido(telefono?: string) {
    return this.regTel.test(telefono || '');
  }

  //

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([
      this.listarDispositivos(),
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
    ]);
    this.setDatos();
  }

  ngOnDestroy(): void {
    this.dispositivos$?.unsubscribe();
    this.unidadesPresion$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
  }
}
