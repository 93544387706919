<div class="fondo dialog">
  <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2
      mat-dialog-title
      class="modal-header"
      cdkDragHandle
      style="margin-bottom: 1em"
    >
      {{ title }}
    </h2>
  </ng-container>

  <mat-dialog-content>
    {{ message }}
    <br />
    <br />
    <mat-form-field class="mt-3">
      <mat-label>{{ selectName }}</mat-label>
      <mat-select [(ngModel)]="seleccionada">
        <mat-option *ngFor="let o of options" [value]="o">
          {{ o | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <br />

  <mat-dialog-actions fxLayoutAlign="end center" style="margin: 0.5em">
    <button
      mat-raised-button
      color="primary"
      (click)="matDialogRef.close(seleccionada)"
    >
      {{ btnOkText }}
    </button>
    <button mat-raised-button (click)="matDialogRef.close(false)">
      {{ btnCancelText }}
    </button>
  </mat-dialog-actions>
</div>
