<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="form"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="tipo">
          @for (t of tipos; track t) {
            <mat-option [value]="t">{{ t }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Fecha del Evento</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="fecha" />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Descripción</mat-label>
        <textarea matInput formControlName="descripcion"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Entidad</mat-label>
        <mat-select formControlName="tipoAsignado">
          @for (e of entidades; track e) {
            <mat-option [value]="e">{{ e }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (this.form?.get("tipoAsignado")?.value === "Correctora") {
        <ng-select
          *ngIf="correctoras"
          [items]="correctoras"
          placeholder="Correctora"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          bindValue="_id"
          formControlName="idAsignado"
          [appendTo]="'body'"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <span>{{ item.modelo }} </span>
            <span style="font-size: 1em"> {{ item.numeroSerie }}</span>
          </ng-template>

          <ng-template
            ng-label-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <span>{{ item.modelo }} </span>
            <span style="font-size: 1em"> {{ item.numeroSerie }}</span>
          </ng-template>
        </ng-select>
      }

      @if (this.form?.get("tipoAsignado")?.value === "Dispositivo") {
        <ng-select
          *ngIf="dispositivos"
          [items]="dispositivos"
          placeholder="Dispositivos"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          bindLabel="deviceName"
          bindValue="_id"
          formControlName="idAsignado"
          [appendTo]="'body'"
        >
        </ng-select>
      }
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="form"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
