import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IAlerta, ICorrectora, IRegistro } from 'modelos/src';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-ultimo-registro-nuc',
  templateUrl: './ultimo-registro-nuc.component.html',
  styleUrls: ['./ultimo-registro-nuc.component.scss'],
})
export class UltimoRegistroNucComponent implements OnInit, OnChanges {
  @Input() correctora?: ICorrectora;

  public ultimoRegistro?: IRegistro;
  public ultimaAlerta?: IAlerta;

  ngOnChanges() {
    if (this.correctora) {
      this.ultimoRegistro = this.correctora?.ultimoRegistro;
      this.ultimaAlerta = this.correctora?.ultimaAlerta;
    }
  }

  ngOnInit(): void {}
}
