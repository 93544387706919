<ng-container *ngIf="estado">
  @switch (estado) {
    @case ("Operativa") {
      <img
        src="assets/estados/ok.png"
        alt="Operativa"
        style="width: 40px"
        [matTooltip]="estado"
      />
    }
    @case ("Alerta") {
      <img
        [matTooltip]="estado"
        src="assets/estados/alerta.png"
        alt="Alerta"
        style="width: 40px"
      />
    }
    @case ("Sin Reportar") {
      <img
        [matTooltip]="estado"
        src="assets/estados/sin-reportar.png"
        alt="Sin Reportar"
        style="width: 40px"
      />
    }
    @case ("En Mantenimiento") {
      <img
        [matTooltip]="estado"
        src="assets/estados/mantenimiento.png"
        alt="En Mantenimiento"
        style="width: 40px"
      />
    }
    @case ("Resolver") {
      <img
        [matTooltip]="estado"
        src="assets/estados/resolver.png"
        alt="Resolver"
        style="width: 40px"
      />
    }
    @case ("Sin Asignar") {
      <img
        [matTooltip]="estado"
        src="assets/estados/sin-asignar.png"
        alt="Sin Asignar"
        style="width: 40px"
      />
    }
    @default {
      <img
        [matTooltip]="'Sin Información'"
        src="assets/estados/sin-dispositivo.png"
        alt="Sin Información"
        style="width: 40px"
      />
    }
  }
</ng-container>
