import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrearEditarCuencasComponent } from './crear-editar-cuencas/crear-editar-cuencas.component';
import { ListadoCuencasComponent } from './listado-cuencas/listado-cuencas.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CuencasRoutingModule } from './cuencas.routing.module';

@NgModule({
  declarations: [CrearEditarCuencasComponent, ListadoCuencasComponent],
  imports: [CommonModule, AuxiliaresModule, CuencasRoutingModule],
})
export class CuencasModule {}
