import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICromatografia } from 'modelos/src';

@Component({
  selector: 'app-detalles-cromatografia',
  templateUrl: './detalles-cromatografia.component.html',
  styleUrls: ['./detalles-cromatografia.component.scss'],
})
export class DetallesCromatografiaComponent implements OnInit {
  public title?: string;
  public elementos: { [key: string]: any } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICromatografia,
    private dialogRef: MatDialogRef<DetallesCromatografiaComponent>
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.title = 'Detalles Cromatografía';
    this.elementos = this.data.elementos || [];
  }
}
