<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <div style="display: block">
      <google-map *ngIf="mapOptions.center" [options]="mapOptions">
        <map-marker
          [position]="coordenadas"
          [options]="markerOptions"
          (mapDragend)="setLocation($event)"
          width="100%"
        >
        </map-marker>
      </google-map>
    </div>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      *ngIf="markerOptions.draggable"
      mat-raised-button
      color="primary"
      (click)="guardar()"
      style="width: 50%"
    >
      Guardar
    </button>
    <button
      mat-raised-button
      color="accent"
      type="button"
      (click)="close()"
      [style]="markerOptions.draggable ? 'width: 50%' : 'width: 100%'"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
