import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IMedidorResidencial,
  ICuenca,
  IFilter,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IReporteWRC,
  IReporteSML,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { NavigationService } from '../../navigation/navigation.service';
import { CrearEditarMedidorResidencialsComponent } from '../crear-editar-medidores-residenciales/crear-editar-medidores-residenciales.component';
import { MedidorResidencialsService } from '../medidores-residenciales.service';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';
import { Router } from '@angular/router';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado-medidores-residenciales',
  templateUrl: './listado-medidores-residenciales.component.html',
  styleUrls: ['./listado-medidores-residenciales.component.scss'],
})
export class ListadoMedidorResidencialsComponent implements OnInit, OnDestroy {
  public unidadNegocio?: IUnidadNegocio;

  //// Tabla nueva
  public name = 'ListadoMedidorResidencialsComponent';
  public datos?: IMedidorResidencial[] = [];
  public columnas?: IColumnas<IMedidorResidencial>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public saveColumnas = 'cliente-gas-medidorResidencial';
  // public botonCrear: ICrearBoton = {
  //   mostrar: true,
  //   tooltip: 'Crear Medidor Residencial de Gas ',
  //   color: 'accent',
  //   icon: 'add',
  //   accion: () => this.crear(),
  // };
  //
  public cuenca: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCuenca',
    },
    label: 'Zona de Calidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public centroOperativo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCentroOperativo',
    },
    label: 'Centro Operativo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idGrupo',
    },
    label: 'Grupo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [
    this.cuenca,
    this.centroOperativo,
    this.grupo,
  ];

  // QUERY+
  private populate = [
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'centroOperativo',
      select: 'nombre',
    },
    {
      path: 'cuenca',
      select: 'nombre',
    },
    {
      path: 'grupos',
      select: 'nombre',
    },
    {
      path: 'dispositivo',
      select: 'tipoDispositivo',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: 'nombre',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public cuencas$?: Subscription;
  public unidadNegocio$?: Subscription;
  public centroOperativos$?: Subscription;
  public grupos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    public service: MedidorResidencialsService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}

  // ACCIONES
  public async detalles(d: IMedidorResidencial) {
    await this.router.navigate(['main', 'medidores', `${d._id}`]);
  }
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      // width: '1200px',
      //
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMedidorResidencialsComponent, config);
  }

  public async editar(data: IMedidorResidencial) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMedidorResidencialsComponent, config);
  }

  public async eliminar(dato: IMedidorResidencial): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el Medidor Residencial ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    // Agrega el filtro de unidad de negocio seleccionada
    const filter: IFilter<any> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    if (this.unidadNegocio) {
      filter.idUnidadNegocio = this.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }
    this.query.filter = JSON.stringify(filter);

    // Listado
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IMedidorResidencial>>(
        'medidorResidencials',
        this.query,
      )
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de medidorResidencials`, data);
      });
    await this.listadosService.getLastValue('medidorResidencials', this.query);
  }

  private async listarCuencas(): Promise<void> {
    // Filtro
    const filter: IFilter<any> = {};
    if (this.unidadNegocio) {
      filter.idUnidadNegocio = this.unidadNegocio._id;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<ICuenca>>('cuencas', query)
      .subscribe((data) => {
        this.cuenca.elementos = data.datos;
        console.log(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  private getFiltroGlobal() {
    this.unidadNegocio$ = this.navigationService
      .subscribeFiltroGlobal()
      .subscribe((filtro) => {
        this.unidadNegocio = filtro.unidadNegocio;
        this.listarCuencas();
        this.listar();
      });
    const filtro = this.navigationService.getFiltroGlobal();
    this.unidadNegocio = filtro.unidadNegocio;
  }

  private async listarCentrosOperativos(): Promise<void> {
    // Filtro
    const filter: IFilter<any> = {};
    if (this.unidadNegocio) {
      filter.idUnidadNegocio = this.unidadNegocio._id;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICuenca>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativo.elementos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarGrupos(): Promise<void> {
    // Filtro
    const filter: IFilter<any> = {};
    if (this.unidadNegocio) {
      filter.idUnidadNegocio = this.unidadNegocio._id;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<ICuenca>>('grupos', query)
      .subscribe((data) => {
        this.grupo.elementos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private setColumnas() {
    this.columnas = [
      // Estado
      {
        header: {
          label: 'Estado',
        },
        row: {
          icono: (dato) => this.helper.iconEstadoMedidor(dato),
        },
      },
      // Nombre
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          html: (dato) => this.nombreTabla(dato),
        },
      },
      // Numero de Serie
      {
        header: {
          label: 'Número de Serie',
          sortable: true,
        },
        row: {
          field: 'deviceMeterNumber',
        },
      },
      // Dispositivo
      {
        header: {
          label: 'Dispositivo',
        },
        row: {
          field: 'deviceName',
        },
      },
      // Fecha Último Reporte
      {
        header: {
          label: 'Fecha Último Reporte',
        },
        row: {
          html: (dato) => this.parseFechaUltimoReporte(dato),
        },
      },
      {
        header: {
          label: 'Consumo Actual',
          sortable: true,
        },
        row: {
          html: (dato) => this.parseConsumo(dato),
        },
      },
      {
        header: {
          label: 'Flujo Inverso',
          sortable: true,
        },
        row: {
          html: (dato) => this.parseFlujoInverso(dato),
        },
      },
      {
        header: {
          label: 'Batería',
        },
        row: {
          html: (dato) => this.parseBateria(dato),
        },
      },
      {
        header: {
          label: 'Unidad de Negocio',
        },
        row: {
          html: (dato) => this.parseUnidadDeNegocio(dato),
        },
      },
      {
        header: {
          label: 'Centro Operativo',
        },
        row: {
          html: (dato) => this.parseCentroOperativo(dato),
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/buscar.png',
              tooltip: 'Detalles',
              click: async (dato) => this.detalles(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  private nombreTabla(d: IMedidorResidencial): string {
    if (d.nombre) {
      return `<div>${d.nombre}</div>`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  private parseFechaUltimoReporte(d: IMedidorResidencial): string {
    const valores = d.ultimoReporte?.valores as IReporteWRC | IReporteSML;
    if (!HelperService.isDefined(valores?.consumo)) {
      return `<div class="no-info">Sin información</div>`;
    }
    return new Date(valores.timestamp!).toLocaleString();
  }

  private parseConsumo(d: IMedidorResidencial): string {
    const valores = d.ultimoReporte?.valores as IReporteWRC | IReporteSML;
    if (!HelperService.isDefined(valores?.consumo)) {
      return `<div class="no-info">Sin información</div>`;
    }
    return `${valores?.consumo} m³`;
  }

  private parseFlujoInverso(d: IMedidorResidencial): string {
    const valores = d.ultimoReporte?.valores as IReporteWRC | IReporteSML;
    if (!HelperService.isDefined(valores?.consumo)) {
      return `<div class="no-info">Sin información</div>`;
    }
    return `${valores?.consumoNegativo} m³`;
  }

  private parseBateria(d: IMedidorResidencial): string {
    const valores = d.ultimoReporte?.valores as IReporteWRC | IReporteSML;
    if (!HelperService.isDefined(valores?.consumo)) {
      return `<div class="no-info">Sin información</div>`;
    }
    return `${valores?.bateria} v.`;
  }

  private parseUnidadDeNegocio(d: IMedidorResidencial): string {
    if (d.unidadNegocio) {
      return `${d.unidadNegocio.nombre}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  private parseCentroOperativo(d: IMedidorResidencial): string {
    if (d.centroOperativo) {
      return `${d.centroOperativo.nombre}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  private parseZonaDeCalidad(d: IMedidorResidencial): string {
    if (d.cuenca) {
      return `${d.cuenca.nombre}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  private parseGrupos(d: IMedidorResidencial): string {
    if (!d.grupos || d.grupos.length < 1)
      return `<div class="no-info">Sin información</div>`;
    let grupos = '';
    if (d.grupos) {
      for (const g of d.grupos) {
        grupos += `<div>${g.nombre}</div>`;
      }
    }
    return grupos;
  }

  private parseLocalidad(d: IMedidorResidencial): string {
    if (d.localidad) {
      return `${d.localidad}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.getFiltroGlobal();
    this.setColumnas();
    await Promise.all([
      this.listar(),
      this.listarCuencas(),
      this.listarCentrosOperativos(),
      this.listarGrupos(),
    ]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.cuencas$?.unsubscribe();
    this.unidadNegocio$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.grupos$?.unsubscribe();
  }
}
