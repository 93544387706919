import { Injectable } from '@angular/core';
import { IUsuario } from 'modelos/src';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class CambiarPasswordService {
  constructor(private http: HttpService) {}

  public cambiarPassword(
    clave: string,
    claveNueva: string
  ): Observable<IUsuario> {
    return this.http.put(`/usuarios/password`, { clave, claveNueva });
  }
}
