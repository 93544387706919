import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteNSP,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { ReportesService } from '../../modulos/reportes/service';
import {
  IColumnas,
  IExportarBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ImportarSoxisComponent } from '../importar-soxis/importar-soxis.component';
import { GenerarResumenReportesFechaComponent } from '../generar-resumen-reportes-fecha/generar-resumen-reportes-fecha.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-reportes',
  templateUrl: './listado-reportes.component.html',
  styleUrls: ['./listado-reportes.component.scss'],
})
export class ListadoReportesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() unidadPresion?: IUnidadPresion;
  @Input() range?: { from: string; to: string };

  ///// Tabla nueva
  public name = 'ListadoReportesComponent';
  public datos?: IReporte[] = [];
  public columnas?: IColumnas<IReporte>[];
  public totalCount = 0;

  public botonExportar: IExportarBoton = {
    accion: async () => this.exportar(),
    mostrar: true,
    tooltip: 'Exportar Reportes',
  };

  public botonImportar: IExportarBoton = {
    accion: async () => this.importar(),
    mostrar: true,
    tooltip: 'Importar Reportes',
  };

  public botonGenerarResumenFecha: IExportarBoton = {
    accion: async () => this.generarResumenFecha(),
    mostrar: true,
    tooltip: 'Generar Resumen Reportes Por Fecha',
  };

  // public botonGenerarResumen: IExportarBoton = {
  //   accion: async () => this.generarResumen(),
  //   mostrar: true,
  //   tooltip: 'Generar Resumen Reportes',
  // };

  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-valores.timestamp',
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private dialogService: DialogService,
    public reportesService: ReportesService,
  ) {}

  public async exportar() {
    if ((this.unidadPresion || this.puntoMedicion) && this.range) {
      const mensaje = `¿Desea exportar el listado de reportes?`;
      // const agrupacionTemporal = await this.dialogService.options(
      //   'Confirme la acción',
      //   mensaje,
      //   'Agrupación Temporal',
      //   ['hora', 'dia', 'mes'],
      // );
      // if (agrupacionTemporal) {
      //   // Filtro
      //   let filter: IFilter<any> | boolean = false;
      //   if (this.unidadPresion && this.range) {
      //     filter = {
      //       idsAsignados: this.unidadPresion._id,
      //       'valores.timestamp': {
      //         $gte: this.range.from,
      //         $lte: this.range.to,
      //       },
      //     };
      //   }
      //   if (this.puntoMedicion && this.range) {
      //     filter = {
      //       idsAsignados: this.puntoMedicion._id,
      //       'valores.timestamp': {
      //         $gte: this.range.from,
      //         $lte: this.range.to,
      //       },
      //     };
      //   }

      //   if (filter) {
      //     const query: IQueryParam = {
      //       idUnidadPresion: this.unidadPresion?._id,
      //       idPuntoMedicion: this.puntoMedicion?._id,
      //       filter: JSON.stringify(filter),
      //       limit: 0,
      //       agrupacionTemporal,
      //     };

      //     try {
      //       await this.reportesService.exportar(query);
      //     } catch (error) {
      //       this.helper.notifError(error);
      //     }
      //   }
      // }

      const confirm = await this.dialogService.confirm(
        'Confirme la acción',
        mensaje,
      );
      if (confirm) {
        try {
          await this.reportesService.exportar(this.query);
        } catch (error) {
          this.helper.notifError(error);
        }
      }
    }
  }

  public async importar() {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ImportarSoxisComponent, config);
  }

  // public async generarResumen() {
  //   this.reportesService.generarResumenHistorico().subscribe(() => {
  //     this.helper.notifSuccess('Resumen Generado');
  //   });
  // }

  public async generarResumenFecha() {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(GenerarResumenReportesFechaComponent, config);
  }

  // Listar

  public async listar(): Promise<void> {
    let filter: IFilter<any> | boolean = false;
    if (this.unidadPresion && this.range) {
      filter = {
        idsAsignados: this.unidadPresion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
    }
    if (this.puntoMedicion && this.range) {
      filter = {
        idsAsignados: this.puntoMedicion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
    }

    if (filter) {
      this.query.filter = JSON.stringify(filter);

      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', this.query)
        .subscribe((data) => {
          console.log(`listado de reportes`, data);

          this.totalCount = data.totalCount;
          this.datos = data.datos;
        });
      await this.listadosService.getLastValue('reportes', this.query);
    }
  }

  private parsePresion(dato: IReporte) {
    const valores = dato.valores as IReporteNSP;
    if (valores.presion === -1) {
      return;
    } else {
      return `${valores.presion} ${valores.unidad}`;
    }
  }

  private setColumnas() {
    this.columnas = [
      // Fecha
      {
        header: { label: 'Fecha', sortable: true, sort: 'valores.timestamp' },
        row: {
          parse(dato) {
            return dato.valores?.timestamp;
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      // Presion
      {
        header: { label: 'Presión', sortable: true, sort: 'valores.presion' },
        row: {
          parse: this.parsePresion,
          noData: 'Registro faltante',
        },
      },
    ];

    // Bateria solo en veribox
    const dispositivo =
      this.puntoMedicion?.unidadPresion?.dispositivo ||
      this.unidadPresion?.dispositivo;
    if (dispositivo?.tipoDispositivo === 'VERIBOX MICRO') {
      this.columnas.push({
        header: { label: 'Batería', sortable: true, sort: 'valores.bateria' },
        row: {
          parse(dato) {
            const valores = dato.valores as IReporteNSP;
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            return valores?.bateria! / 10;
          },
          pipe: {
            pipe: DecimalPipe,
            args: '1.0-3',
          },
          suffix: 'V.',
          noData: 'Sin Reportar',
        },
      });
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
