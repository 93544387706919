import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ICorrectora,
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IRegistro,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { RegistrosService } from '../../modulos/correctoras/registros.service';
import {
  PointOptionsObject,
  SeriesLineOptions,
  SeriesOptionsType,
  YAxisOptions,
} from 'highcharts';
import { ACCENT, VERDE } from 'src/assets/estilos/colores';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';

const colorDatos2 = ACCENT;
const colorDatos = VERDE;

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-correctora',
  templateUrl: './grafico-correctora.component.html',
  styleUrls: ['./grafico-correctora.component.scss'],
})
export class GraficoCorrectoraComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() correctora?: ICorrectora;
  @Input() range?: { from: string; to: string };

  // Reportes Historicos
  public chartVolumenTotalizado?: Highcharts.Options;
  public chartVolumenParcializado?: Highcharts.Options;
  public chartTemperatura?: Highcharts.Options;
  public chartPresion?: Highcharts.Options;
  public chartCaudal?: Highcharts.Options;
  public registros: IRegistro[] = [];

  public volumenAcumulado?: number;
  public volumenCorregidoAcumulado?: number;

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public registrosService: RegistrosService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################

  private getChartOptions(
    series: SeriesOptionsType[],
    yAxis?: YAxisOptions | YAxisOptions[],
  ) {
    const options: Highcharts.Options = {
      chart: {
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
      },
      title: {
        align: 'left',
        text: undefined,
      },
      yAxis,
      xAxis: {
        type: 'datetime',
      },
      legend: {
        // enabled: false,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          marker: {
            // enabled: false,
          },
          label: {
            connectorAllowed: false,
          },
        },
      },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    return options;
  }

  private getChartOptionsSimple(
    series: SeriesOptionsType[],
    text?: string,
    unidad?: string,
  ) {
    const yAxis: YAxisOptions = {
      title: {
        text,
        style: {
          color: colorDatos,
        },
      },
      // m³
      labels: {
        format: unidad ? `{value} ${unidad}` : '{value}',
        style: {
          color: colorDatos,
        },
      },
    };
    return this.getChartOptions(series, yAxis);
  }

  private getChartOptionsDual(series: SeriesOptionsType[]) {
    const yAxis: YAxisOptions[] = [
      {
        title: {
          text: 'Volumen Corregido',
          style: {
            color: colorDatos,
          },
        },
        labels: {
          format: '{value} m³',
          style: {
            color: colorDatos,
          },
        },
      },
      {
        title: {
          text: 'Volumen',
          style: {
            color: colorDatos2,
          },
        },
        labels: {
          format: '{value} m³',
          style: {
            color: colorDatos2,
          },
        },
        opposite: true,
      },
    ];
    return this.getChartOptions(series, yAxis);
  }

  private async graficoHistorico(): Promise<void> {
    if (!this.registros.length) {
      this.chartVolumenTotalizado = undefined;
      this.chartVolumenParcializado = undefined;
      this.chartTemperatura = undefined;
      this.chartPresion = undefined;
      this.chartCaudal = undefined;
      return;
    }

    const seriesVolumenTotalizado: SeriesLineOptions[] = [];
    const seriesVolumenParcializado: SeriesLineOptions[] = [];
    const seriesTemperatura: SeriesLineOptions[] = [];
    const seriesPresion: SeriesLineOptions[] = [];
    const seriesCaudal: SeriesLineOptions[] = [];

    const registros: IRegistro[] = JSON.parse(JSON.stringify(this.registros));

    // TODO: reemplazar por offset del establecimiento
    const timeZoneOffset = -3;
    const timeZoneOffsetMs = 1000 * 60 * 60 * timeZoneOffset;

    // Crea las series del grafico
    const dataCorrectedParcializado: PointOptionsObject[] = [];
    const dataCorrectedTotalizado: PointOptionsObject[] = [];
    const dataUncorrectedParcializado: PointOptionsObject[] = [];
    const dataUncorrectedTotalizado: PointOptionsObject[] = [];
    const dataTemperatura: PointOptionsObject[] = [];
    const dataPresion: PointOptionsObject[] = [];
    const dataCaudalPico: PointOptionsObject[] = [];
    const dataCaudalPromedio: PointOptionsObject[] = [];

    for (const reporte of registros) {
      const fecha = new Date(reporte.timestamp!).getTime() + timeZoneOffsetMs;
      const valorCorrectedParcializado = reporte.correctedParcializado;
      const valorCorrectedTotalizado = reporte.correctedTotalizado;
      const valorUncorrectedParcializado = reporte.uncorrectedParcializado;
      const valorUncorrectedTotalizado = reporte.uncorrectedTotalizado;
      const valorTemperatura = +reporte.temperatura!.toFixed(1);
      const valorPresion = +reporte.presion!.toFixed(3);
      const caudalPico = reporte.caudalPico;
      const caudalPromedio = reporte.caudalPromedio;

      if (valorCorrectedParcializado !== -1) {
        dataCorrectedParcializado.push({
          x: fecha,
          y: valorCorrectedParcializado,
        });
      }
      if (valorCorrectedTotalizado !== -1) {
        dataCorrectedTotalizado.push({
          x: fecha,
          y: valorCorrectedTotalizado,
        });
      }
      if (valorUncorrectedParcializado !== -1) {
        dataUncorrectedParcializado.push({
          x: fecha,
          y: valorUncorrectedParcializado,
        });
      }
      if (valorUncorrectedTotalizado !== -1) {
        dataUncorrectedTotalizado.push({
          x: fecha,
          y: valorUncorrectedTotalizado,
        });
      }
      if (valorTemperatura !== -1) {
        dataTemperatura.push({
          x: fecha,
          y: valorTemperatura,
        });
      }
      if (valorPresion !== -1) {
        dataPresion.push({
          x: fecha,
          y: valorPresion,
        });
      }
      if (caudalPico !== -1) {
        dataCaudalPico.push({
          x: fecha,
          y: caudalPico,
        });
      }
      if (caudalPromedio !== -1) {
        dataCaudalPromedio.push({
          x: fecha,
          y: caudalPromedio,
        });
      }
    }

    const serieCorrectedParcializado: SeriesLineOptions = {
      type: 'line',
      name: `Volumen Corregido Horario`,
      yAxis: 0,
      data: dataCorrectedParcializado,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const serieCorrectedTotalizado: SeriesLineOptions = {
      type: 'line',
      name: `Volumen Corregido Acumulado`,
      yAxis: 0,
      data: dataCorrectedTotalizado,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const serieUncorrectedParcializado: SeriesLineOptions = {
      type: 'line',
      name: `Volumen Base Horario`,
      yAxis: 1,
      data: dataUncorrectedParcializado,
      color: colorDatos2,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const serieUncorrectedTotalizado: SeriesLineOptions = {
      type: 'line',
      name: `Volumen Base Acumulado`,
      yAxis: 1,
      data: dataUncorrectedTotalizado,
      color: colorDatos2,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const serieCaudalCorregido: SeriesLineOptions = {
      type: 'line',
      name: `Caudal Corregido`,
      data: dataCaudalPico,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const serieCaudal: SeriesLineOptions = {
      type: 'line',
      name: `Caudal`,
      data: dataCaudalPromedio,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const serieTemperatura: SeriesLineOptions = {
      type: 'line',
      name: `Temperatura`,
      data: dataTemperatura,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const seriePresion: SeriesLineOptions = {
      type: 'line',
      name: `Presion`,
      data: dataPresion,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };

    seriesVolumenTotalizado.push(
      serieUncorrectedTotalizado,
      serieCorrectedTotalizado,
    );
    seriesVolumenParcializado.push(
      serieUncorrectedParcializado,
      serieCorrectedParcializado,
    );
    seriesCaudal.push(serieCaudalCorregido, serieCaudal);
    seriesTemperatura.push(serieTemperatura);
    seriesPresion.push(seriePresion);

    this.chartVolumenParcializado = this.getChartOptionsDual(
      seriesVolumenParcializado,
    );
    this.chartVolumenTotalizado = this.getChartOptionsDual(
      seriesVolumenTotalizado,
    );
    this.chartCaudal = this.getChartOptionsSimple(
      seriesCaudal,
      'Caudal',
      'm³/min',
    );
    this.chartTemperatura = this.getChartOptionsSimple(
      seriesTemperatura,
      'Temperatura',
      '°C',
    );
    this.chartPresion = this.getChartOptionsSimple(
      seriesPresion,
      'Presión',
      'BAR',
    );
  }

  private calcularVolumenAcumuladoPeriodo() {
    if (this.registros.length === 0) {
      this.volumenAcumulado = 0;
      return;
    }

    const volumenAcumulado = this.registros.reduce((acum, dato) => {
      acum +=
        dato.uncorrectedParcializado !== -1
          ? dato.uncorrectedParcializado || 0
          : 0;
      return acum;
    }, 0);

    const valorUltimo =
      this.registros[this.registros.length - 1].uncorrectedTotalizado || 0;
    const valorPrimero = this.registros[0].uncorrectedTotalizado || 0;
    const volumenAcumulado2 = valorUltimo - valorPrimero;

    if (volumenAcumulado > volumenAcumulado2) {
      this.volumenAcumulado = volumenAcumulado;
    } else {
      this.volumenAcumulado = volumenAcumulado2;
    }
  }
  private calcularVolumenCorregidoAcumuladoPeriodo() {
    if (this.registros.length === 0) {
      this.volumenCorregidoAcumulado = 0;
      return;
    }

    const volumenCorregidoAcumulado = this.registros.reduce((acum, dato) => {
      acum +=
        dato.correctedParcializado !== -1 ? dato.correctedParcializado || 0 : 0;
      return acum;
    }, 0);

    const valorUltimo =
      this.registros[this.registros.length - 1].correctedTotalizado || 0;
    const valorPrimero = this.registros[0].correctedTotalizado || 0;
    const volumenCorregidoAcumulado2 = valorUltimo - valorPrimero;

    if (volumenCorregidoAcumulado > volumenCorregidoAcumulado2) {
      this.volumenCorregidoAcumulado = volumenCorregidoAcumulado;
    } else {
      this.volumenCorregidoAcumulado = volumenCorregidoAcumulado2;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  private async listar(): Promise<void> {
    let filter: IFilter<IRegistro> | boolean = false;

    if (this.correctora && this.range) {
      filter = {
        idCorrectora: this.correctora._id,
        timestamp: {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
    }
    if (this.puntoMedicion && this.range) {
      filter = {
        idPuntoMedicion: this.puntoMedicion._id,
        timestamp: {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      };
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IRegistro>>('registros', query)
        .subscribe(async (data) => {
          console.log(`listado de registros`, data);

          for (const registro of data.datos) {
            registro.uncorrectedParcializado =
              this.registrosService.getUncorrectedParcializado(
                registro,
                this.correctora,
              );
            registro.correctedParcializado =
              this.registrosService.getCorrectedParcializado(
                registro,
                this.correctora,
              );
            registro.correctedTotalizado =
              this.registrosService.getCorrectedTotalizado(
                registro,
                this.correctora,
              );
            registro.uncorrectedTotalizado =
              this.registrosService.getUncorrectedTotalizado(
                registro,
                this.correctora,
              );
          }

          this.registros = data.datos;

          this.calcularVolumenAcumuladoPeriodo();
          this.calcularVolumenCorregidoAcumuladoPeriodo();
          await this.graficoHistorico();
        });
      await this.listadosService.getLastValue('registros', query);
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
