import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as Moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-yearSelect',
  templateUrl: './yearSelect.component.html',
  styleUrls: ['./yearSelect.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class YearSelectComponent implements OnInit {
  @Input() mostrar = false;
  public anio = new Date();
  @Output() onChange = new EventEmitter<Date>();

  constructor() {}

  public setYear(
    normalizedMonthAndYear: Moment.Moment,
    datepicker: MatDatepicker<Moment.Moment>
  ) {
    const y = normalizedMonthAndYear.year();
    const date = new Date(y, 0, 1, 7, 0, 0, 0);
    this.anio = date;
    datepicker.close();
    this.onChange.emit(date);
  }

  //

  async ngOnInit(): Promise<void> {
    const date = new Date();
    date.setMonth(0);
    date.setDate(1);
    date.setHours(7);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.anio = date;
    this.onChange.emit(date);
  }

  ngOnDestroy(): void {}
}
