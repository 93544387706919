/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IMedidorResidencial } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { NavigationService } from 'src/app/modulos/navigation/navigation.service';
import { CrearEditarMedidorResidencialsComponent } from '../../modulos/medidores-residenciales/crear-editar-medidores-residenciales/crear-editar-medidores-residenciales.component';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-resumen-medidor-residencial',
  templateUrl: './resumen-medidor-residencial.component.html',
  styleUrls: ['./resumen-medidor-residencial.component.scss'],
})
export class ResumenMedidorResidencialComponent implements OnInit {
  @Input() medidorResidencial?: IMedidorResidencial;

  // MAPA
  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptions),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {};

  constructor(
    public helper: HelperService,
    public matDialog: MatDialog,
    public navigationService: NavigationService,
  ) {}

  public async editar(data?: IMedidorResidencial) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMedidorResidencialsComponent, config);
  }

  private change(b: boolean) {
    const options = b
      ? HelperService.mapOptionsDark
      : HelperService.mapOptionsLight;
    this.mapOptions = JSON.parse(JSON.stringify(options));
  }

  ngOnInit(): void {
    this.mapOptions.mapTypeControl = false;
    this.mapOptions.zoomControl = false;
    this.mapOptions.fullscreenControl = true;
    this.mapOptions.zoom = 15;
    if (this.medidorResidencial?.ubicacionGps) {
      this.mapCenter = {
        lat: this.medidorResidencial?.ubicacionGps?.lat!,
        lng: this.medidorResidencial?.ubicacionGps?.lng!,
      };
    }
    this.markerOptions = {
      position: this.mapCenter,
    };
    this.navigationService.modoOscuro$.subscribe({
      next: (v: boolean) => this.change(v),
    });
  }
}
