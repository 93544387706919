import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteNSP,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import {
  PointOptionsObject,
  SeriesLineOptions,
  SeriesOptionsType,
  YAxisOptions,
} from 'highcharts';
import { ReportesService } from '../../modulos/reportes/service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-presion-5-min',
  templateUrl: './grafico-presion-5-min.component.html',
  styleUrls: ['./grafico-presion-5-min.component.scss'],
})
export class GraficoPresion5MinutosComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() puntosMedicion?: IPuntoMedicion[];
  @Input() range?: { from: string; to: string };
  @Input() style =
    'width: 100%; height: 400px; display: block; margin-top: 30px';

  // Reportes Historicos
  public chart?: Highcharts.Options;
  public reportes: IReporte[] = [];

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public reportesService: ReportesService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################

  private getChartOptions(
    series: SeriesOptionsType[],
    yAxis?: YAxisOptions | YAxisOptions[],
  ) {
    const options: Highcharts.Options = {
      chart: {
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
      },
      rangeSelector: {
        enabled: false,
        selected: 5,
      },
      scrollbar: {
        enabled: false,
      },
      title: {
        align: 'left',
        text: undefined,
      },
      yAxis,
      xAxis: {
        type: 'datetime',
      },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    return options;
  }

  private getChartOptionsSimple(
    series: SeriesOptionsType[],
    text?: string,
    unidad?: string,
  ) {
    const yAxis: YAxisOptions = {
      title: {
        text,
      },
      // m³
      labels: {
        format: unidad ? `{value} ${unidad}` : '{value}',
      },
      min: 0,
      softMax: 3,
    };
    return this.getChartOptions(series, yAxis);
  }

  private async graficoHistorico(): Promise<void> {
    if (!this.reportes.length || !this.puntosMedicion) {
      this.chart = undefined;
      return;
    }

    const seriesPresion: SeriesLineOptions[] = [];

    for (const p of this.puntosMedicion) {
      const reps = this.reportes.filter(
        (r) => r.idsAsignados?.includes(p._id!),
      );

      // PRESIÓN

      if (p.division === 'Presión') {
        const reportes: IReporte[] = JSON.parse(JSON.stringify(reps));
        // TODO: reem plazar por offset del establecimiento
        const timeZoneOffset = -3;
        const timeZoneOffsetMs = 1000 * 60 * 60 * timeZoneOffset;

        // Crea las series del grafico
        const presion: PointOptionsObject[] = [];

        const datos: { [timestamp: number]: number } = {};

        for (const reporte of reportes) {
          const valores = reporte.valores as IReporteNSP;
          let timestamp = valores?.timestamp
            ? new Date(valores?.timestamp).getTime()
            : new Date().getTime();
          timestamp += timeZoneOffsetMs;
          // timestamp = timestamp - (timestamp % 86400000);
          const pres = valores?.presion || 0;
          const valorPresion = +pres?.toFixed(2);

          if (valorPresion !== -1) {
            datos[timestamp] = valorPresion;
          }
        }

        for (const timestamp in datos) {
          const valor = datos[timestamp];
          presion.push([+timestamp, valor]);
        }

        const seriePresion: SeriesLineOptions = {
          type: 'line',
          lineWidth: 4,
          // fillColor: gradient,
          name: `${p.nombre}`,
          data: presion,
          // color: colorDatos,
          marker: {
            enabled: true,
          },
          tooltip: {
            xDateFormat: '%A %d de %B, %k:%M',
            pointFormat: `<strong>${p.nombre} - {point.y}</strong>`,
          },
        };

        seriesPresion.push(seriePresion);
        this.chart = this.getChartOptionsSimple(
          seriesPresion,
          'Presión',
          'BAR',
        );
      }

      // if (p.division === 'Correctoras') {
      // }
    }
  }

  // Listar

  private async listar(): Promise<void> {
    let filter: IFilter<IReporte> | boolean = false;

    if (this.puntosMedicion && this.range) {
      const ids = this.puntosMedicion.map((p) => p._id);
      filter = {
        idsAsignados: { $in: ids },
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', query)
        .subscribe(async (data) => {
          console.log(`listado de reportes`, data);
          this.reportes = data.datos;

          await this.graficoHistorico();
        });
      await this.listadosService.getLastValue('reportes', query);
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
