import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '../../../auxiliares/helper.service';
import { LoginService } from '../../login/login.service';
import {
  ICentroOperativo,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUpdateUsuario,
  IUsuario,
  Nivel,
} from 'modelos/src';
import { UsuariosService } from '../../usuarios/usuarios.service';
import { ICodigoNotificacion } from '../../../../environments/datos';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-configurar-notificaciones',
  templateUrl: './configurar-notificaciones.component.html',
  styleUrls: ['./configurar-notificaciones.component.scss'],
})
export class ConfigurarNotificacionesComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public enviando = false;

  get notificacionesForm() {
    return this.form?.get('notificaciones') as UntypedFormArray;
  }
  public habilitadasForm(i: number) {
    return (this.form?.get('notificaciones') as UntypedFormArray)
      .at(i)
      .get('habilitados') as UntypedFormArray;
  }

  public usuario: IUsuario = LoginService.getUsuario();

  public niveles: Nivel[] = [];
  public notificaciones = ICodigoNotificacion;
  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private helper: HelperService,
    private usuariosService: UsuariosService,
    private listadosService: ListadosService,
    private loginService: LoginService,
    public loading: LoadingService,
  ) {}

  public isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  private configNiveles(): void {
    const nivelUser = HelperService.nivelMasAlto();
    if (nivelUser === 'Global') {
      this.niveles = ['Global', 'Unidad de Negocio', 'Centro Operativo'];
    } else if (nivelUser === 'Unidad de Negocio') {
      this.niveles = ['Unidad de Negocio', 'Centro Operativo'];
    } else {
      this.niveles = ['Centro Operativo'];
    }
  }

  private createForm(): void {
    const notifUser = this.usuario.notificaciones || [];
    const notificacionesForm: UntypedFormGroup[] = [];

    for (const notif of notifUser) {
      const notifHabilitadas: UntypedFormGroup[] = [];

      Object.keys(this.notificaciones).forEach((n) => {
        if (this.isNumber(n)) {
          const codigo = Number(n);
          const habilitado = notif.habilitados?.includes(codigo);

          notifHabilitadas.push(
            this.fb.group({
              codigo: [codigo],
              habilitado: [habilitado],
            }),
          );
        }
      });

      notificacionesForm.push(
        this.fb.group({
          nivel: [notif.nivel, Validators.required],
          idUnidadNegocio: [notif.idUnidadNegocio],
          idCentroOperativo: [notif.idCentroOperativo],
          habilitados: this.fb.array(notifHabilitadas),
        }),
      );
    }

    this.form = this.fb.group({
      notificaciones: this.fb.array(notificacionesForm),
    });

    if (notifUser.length === 0) {
      this.agregar(false);
    }
  }

  public agregar(habilitadas = true) {
    const notifHabilitadas: UntypedFormGroup[] = [];
    Object.keys(this.notificaciones).forEach((n) => {
      if (this.isNumber(n)) {
        const codigo = Number(n);

        notifHabilitadas.push(
          this.fb.group({
            codigo: [codigo],
            habilitado: [habilitadas],
          }),
        );
      }
    });

    this.notificacionesForm.push(
      this.fb.group({
        nivel: [null, Validators.required],
        idUnidadNegocio: [],
        idCentroOperativo: [],
        habilitados: this.fb.array(notifHabilitadas),
      }),
    );
  }

  public eliminar(i: number) {
    this.notificacionesForm.removeAt(i);
  }

  //

  private getData(): IUpdateUsuario {
    const data = this.form?.value;

    const update: IUpdateUsuario = {
      notificaciones: [],
    };

    console.log(`data`, data);

    for (const notif of data.notificaciones) {
      const habilitados = notif.habilitados as {
        codigo: number;
        habilitado: boolean;
      }[];
      const codigosHabilitados = habilitados
        .filter((h) => h.habilitado)
        .map((h) => h.codigo);
      update.notificaciones?.push({
        nivel: notif.nivel,
        idCentroOperativo: notif.idCentroOperativo,
        idUnidadNegocio: notif.idUnidadNegocio,
        habilitados: codigosHabilitados,
      });
    }

    console.log(`update`, update);

    return update;
  }

  public async guardar(): Promise<void> {
    try {
      this.enviando = true;
      const data = this.getData();
      await firstValueFrom(this.usuariosService.editarPropio(data));
      this.loginService.updateNotificaciones(data.notificaciones || []);
      this.enviando = false;
      this.helper.notifSuccess('Configuración de notificaciones guardada');
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  //

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.configNiveles();
    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
    ]);
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
  }
}
