<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Versiones de Firmware
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    @for (d of datos; track $index) {
      <!-- <div class="container">
        <div class="version">{{ d.version }}</div>
        @for (desc of d.descripcion; track $index) {
          <div class="descripcion">{{ desc }}</div>
        }
      </div> -->
      <ul>
        <li>
          <div class="version">{{ d.version }}</div>
          <ul>
            @for (desc of d.descripcion; track $index) {
              <li class="descripcion">{{ desc }}</li>
            }
          </ul>
        </li>
      </ul>
    }
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em" align="end">
    <button
      mat-raised-button
      color="accent"
      type="button"
      style="width: 50%"
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
