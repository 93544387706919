import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ICorrectora } from 'modelos/src';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-ultima-cromatografia-correctora',
  templateUrl: './ultima-cromatografia.component.html',
  styleUrls: ['./ultima-cromatografia.component.scss'],
})
export class UltimaCromatografiaComponent {
  @Input() correctora?: ICorrectora;
}
