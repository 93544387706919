import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { IPuntoMedicion, IReporteNSP, IReporteVeribox } from 'modelos/src';
import { HelperService } from '../../../auxiliares/helper.service';

@Component({
  selector: 'app-info-window-unidad-presion',
  templateUrl: './info-window-unidad-presion.component.html',
  styleUrls: ['./info-window-unidad-presion.component.scss'],
})
export class InfoWindowUnidadPresionComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() punto?: IPuntoMedicion;
  public valores?: IReporteNSP | IReporteVeribox;

  constructor(public helper: HelperService) {}

  ngOnChanges(): void {
    if (this.punto) {
      this.valores = this.punto.unidadPresion?.ultimoRegistro?.valores;
    }
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {}
}
