import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ICorrectora } from 'modelos/src';
import { CrearEditarCorrectorasComponent } from '../../modulos/correctoras/crear-editar-correctoras/crear-editar-correctoras.component';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IconoEstadoComponent } from '../icono-estado/icono-estado.component';
import { CrearEditarMantenimientoComponent } from '../mantenimientos/crear-editar-mantenimiento/crear-editar-mantenimiento.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule, IconoEstadoComponent],
  selector: 'app-resumen-correctora',
  templateUrl: './resumen-correctora.component.html',
  styleUrls: ['./resumen-correctora.component.scss'],
})
export class ResumenCorrectoraComponent implements OnInit {
  @Input() correctora?: ICorrectora;

  constructor(
    public helper: HelperService,
    public matDialog: MatDialog,
  ) {}

  public async editar(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarCorrectorasComponent, config);
  }

  public async crearMantenimiento(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data: { correctora: data },
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMantenimientoComponent, config);
  }

  ngOnInit(): void {}
}
