import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HelperService } from '../../../auxiliares/helper.service';
import { LoginService } from '../login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NavigationService } from '../../navigation/navigation.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public logo?: string = 'assets/images/logo-light.png';
  public form: UntypedFormGroup = this.createForm();
  public esModoOscuro = HelperService.esModoOscuro();

  private token: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private router: Router,
    private helper: HelperService,
    private navigationService: NavigationService,
    public loading: LoadingService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
  ) {}

  public coinciden() {
    const password = this.form.get('password')?.value;
    const password2 = this.form.get('password2')?.value;
    return password === password2;
  }

  public async onSubmit() {
    try {
      const token = this.token;
      const password = this.form.get('password')?.value;
      await this.loginService.resetPassword(token, password);

      const title = 'Contraseña restablecida';
      const message =
        'Se ha restablecido la contraseña correctamente, por favor inicie sesión nuevamente';
      this.dialogService.dialog(title, message, 'Aceptar');
      this.router.navigateByUrl('login');
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  private createForm() {
    return this.formBuilder.group({
      password: [null, [Validators.required]],
      password2: [null, [Validators.required]],
    });
  }

  public setClaro() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
    this.renderer.addClass(this.document.body, 'light-theme');
    this.logo = 'assets/images/logo-light.png';
    this.navigationService.modoOscuro$.next(false);
    localStorage.removeItem('temaOscuro');
  }

  public setOscuro() {
    this.renderer.addClass(this.document.body, 'dark-theme');
    this.renderer.removeClass(this.document.body, 'light-theme');
    this.logo = 'assets/images/logo-dark.png';
    this.navigationService.modoOscuro$.next(true);
    localStorage.setItem('temaOscuro', 'true');
  }

  public toggleTheme() {
    if (this.document.body.classList.contains('dark-theme')) {
      this.setClaro();
    } else {
      this.setOscuro();
    }
  }

  private temaInicial() {
    if (this.esModoOscuro) {
      this.setOscuro();
    } else {
      this.setClaro();
    }
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token;
    this.temaInicial();
  }
}
