<div fxLayout="row" fxLayoutAlign="center center">
  <button
    mat-button
    style="margin: 0.5em; height: 54px; width: 54px"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon
      style="transform: scale(1.8)"
      [matBadge]="notificacionesSinLeer ? notificacionesSinLeer : null"
      matBadgePosition="above after"
      matBadgeColor="accent"
      matBadgeSize="small"
      class="material-icons-outlined"
      aria-hidden="false"
    >
      notifications_active
    </mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu" xPosition="before" [hasBackdrop]="true">
  <app-listado-notificaciones></app-listado-notificaciones>
</mat-menu>
