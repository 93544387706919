import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICentroOperativo,
  ICorrectora,
  ICuenca,
  IDispositivo,
  IFilter,
  IGrupo,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IUnidadNegocio,
  Nivel,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import {
  IInformeExportacion,
  RegistrosService,
} from '../../correctoras/registros.service';
import { LoginService } from '../../login/login.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-exportar',
  templateUrl: './exportar.component.html',
  styleUrls: ['./exportar.component.scss'],
})
export class ExportarComponent implements OnInit, OnDestroy {
  public informeExportacion?: IInformeExportacion;
  public enviando = false;

  public query: {
    desde: Date;
    hasta: Date;
    agrupacion?: string;
    agrupacionTemporal?: string;
    dayPicker?: 'rango' | 'mes' | 'anio';
    mes?: Date;
    anio?: Date;
    formato?: 'xls' | 'simec';
  } = {
    desde: this.initDesde,
    hasta: this.initHasta,
    dayPicker: 'mes',
    formato: 'xls',
    agrupacionTemporal: 'hora',
  };
  // FALTANTES
  public tipo: string = 'Registros';

  public agrupaciones: { value: string; niveles: Nivel[] }[] = [
    {
      value: 'Unidad de Negocio',
      niveles: ['Global', 'Unidad de Negocio'],
    },
    {
      value: 'Centro Operativo',
      niveles: ['Global', 'Unidad de Negocio', 'Centro Operativo'],
    },
    {
      value: 'Zona de Calidad',
      niveles: ['Global', 'Unidad de Negocio'],
    },
    {
      value: 'Grupo',
      niveles: ['Global', 'Unidad de Negocio'],
    },
    // {
    //   value: 'Correctora',
    //   niveles: ['Global', 'Unidad de Negocio', 'Centro Operativo'],
    // },
    {
      value: 'NUC',
      niveles: ['Global', 'Unidad de Negocio', 'Centro Operativo'],
    },
    {
      value: 'Punto de Medición',
      niveles: ['Global', 'Unidad de Negocio', 'Centro Operativo'],
    },
  ];

  public agrupacionesTemporales = ['hora', 'dia', 'mes'];

  public unidadNegocios: IUnidadNegocio[] = [];
  public unidadNegocio?: IUnidadNegocio;
  public centroOperativos: ICentroOperativo[] = [];
  public centroOperativo?: ICentroOperativo;
  public cuencas: ICuenca[] = [];
  public cuenca?: ICuenca;
  public grupos: IGrupo[] = [];
  public grupo?: IGrupo;
  public correctoras: ICorrectora[] = [];
  public correctora?: ICorrectora;
  public dispositivos: IDispositivo[] = [];
  public dispositivo?: IDispositivo;
  public puntoMedicions: IPuntoMedicion[] = [];
  public puntoMedicion?: IPuntoMedicion;

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;
  public cuencas$?: Subscription;
  public grupos$?: Subscription;
  public correctoras$?: Subscription;
  public dispositivos$?: Subscription;
  public puntoMedicions$?: Subscription;

  get initDesde() {
    const desde = new Date();
    desde.setDate(desde.getDate() - 1);
    desde.setHours(7, 0, 0, 0);
    return desde;
  }

  get initHasta() {
    const hasta = new Date();
    hasta.setHours(6, 59, 59, 999);
    return hasta;
  }

  constructor(
    private listadosService: ListadosService,
    private registrosService: RegistrosService,
    private helperService: HelperService,
    public loading: LoadingService,
  ) {}

  public nivelDeUsuario(niveles: Nivel[]) {
    const user = LoginService.getUsuario();
    return user?.permisos?.some((e) => niveles.includes(e.nivel!));
  }

  private getFilename(formato: 'xls' | 'simec') {
    if (formato === 'simec') {
      return 'simec.zip';
    }
    const dateFrom = new Date(this.query.desde);
    const dateTo = new Date(this.query.hasta);
    const yearFrom = dateFrom.getFullYear();
    const monthFrom = dateFrom.getMonth() + 1;
    const dayFrom = dateFrom.getDate();
    const yearTo = dateTo.getFullYear();
    const monthTo = dateTo.getMonth() + 1;
    const dayTo = dateTo.getDate();
    const period = `${yearFrom}-${monthFrom}-${dayFrom} al ${yearTo}-${monthTo}-${dayTo}`;

    if (this.query.agrupacion === 'Unidad de Negocio') {
      const unidadNegocio = this.unidadNegocios.find(
        (e) => e._id === this.unidadNegocio?._id,
      );
      return `${unidadNegocio?.nombre} - ${period}.xlsx`;
    }
    if (this.query.agrupacion === 'Centro Operativo') {
      const centroOperativo = this.centroOperativos.find(
        (e) => e._id === this.centroOperativo?._id,
      );
      return `${centroOperativo?.nombre} - ${period}.xlsx`;
    }
    if (this.query.agrupacion === 'Zona de Calidad') {
      const cuenca = this.cuencas.find((e) => e._id === this.cuenca?._id);
      return `${cuenca?.nombre} - ${period}.xlsx`;
    }
    if (this.query.agrupacion === 'Grupo') {
      const grupo = this.grupos.find((e) => e._id === this.grupo?._id);
      return `${grupo?.nombre} - ${period}.xlsx`;
    }
    return `Exportación - ${period}.xlsx`;
  }

  public async verificar() {
    this.enviando = true;
    try {
      let query: IQueryParam = {};
      // El picker me cambia de formato la fecha, así que la convierto de nuevo a DATE
      if (this.query.dayPicker === 'rango') {
        const desdeEnDate = new Date(this.query.desde);
        const hastaEnDate = new Date(this.query.hasta);
        this.query.desde = desdeEnDate;
        this.query.hasta = hastaEnDate;
        this.query.desde.setHours(7, 0, 0, 0);
        this.query.hasta.setHours(6, 59, 59, 999);
      } else if (this.query.dayPicker === 'mes') {
        const hastaEnDate = new Date(this.query.mes!);
        hastaEnDate.setMonth(hastaEnDate.getMonth() + 1);
        hastaEnDate.setMilliseconds(hastaEnDate.getMilliseconds() - 1);
        this.query.desde = this.query.mes!;
        this.query.hasta = hastaEnDate;
        this.query.desde.setHours(7, 0, 0, 0);
        this.query.hasta.setHours(6, 59, 59, 999);
      } else if (this.query.dayPicker === 'anio') {
        const hastaEnDate = new Date(this.query.anio!);
        hastaEnDate.setFullYear(hastaEnDate.getFullYear() + 1);
        hastaEnDate.setMilliseconds(hastaEnDate.getMilliseconds() - 1);
        this.query.desde = this.query.anio!;
        this.query.hasta = hastaEnDate;
        this.query.desde.setHours(7, 0, 0, 0);
        this.query.hasta.setHours(6, 59, 59, 999);
      }

      const filter: IFilter<any> = {
        timestamp: {
          $gte: this.query.desde?.toISOString(),
          $lte: this.query.hasta?.toISOString(),
        },
      };
      query = {
        filter: JSON.stringify(filter),
        desde: this.query.desde?.toISOString(),
        hasta: this.query.hasta?.toISOString(),
      };

      if (this.query.agrupacion === 'Unidad de Negocio') {
        query.idUnidadNegocio = this.unidadNegocio?._id;
      }
      if (this.query.agrupacion === 'Centro Operativo') {
        query.idCentroOperativo = this.centroOperativo?._id;
      }
      if (this.query.agrupacion === 'Zona de Calidad') {
        query.idCuenca = this.cuenca?._id;
      }
      if (this.query.agrupacion === 'Grupo') {
        query.idGrupo = [this.grupo?._id];
      }
      if (this.query.agrupacion === 'Correctora') {
        query.numeroSerieCorrectora = this.correctora?.numeroSerie;
      }
      if (this.query.agrupacion === 'NUC') {
        query.deveui = this.dispositivo?.deveui;
      }
      if (this.query.agrupacion === 'Punto de Medición') {
        query.idPuntoMedicion = this.puntoMedicion?._id;
      }

      const response = await firstValueFrom(
        this.registrosService.verificar(query),
      );
      this.informeExportacion = response;
    } catch (error) {
      this.helperService.notifError(error);
    }
    this.enviando = false;
  }

  public async exportar(tipo: 'faltantes' | 'datos'): Promise<void> {
    this.enviando = true;
    try {
      let query: IQueryParam = {};
      // El picker me cambia de formato la fecha, así que la convierto de nuevo a DATE
      if (this.query.dayPicker === 'rango') {
        const desdeEnDate = new Date(this.query.desde);
        const hastaEnDate = new Date(this.query.hasta);
        this.query.desde = desdeEnDate;
        this.query.hasta = hastaEnDate;
        this.query.desde.setHours(7, 0, 0, 0);
        this.query.hasta.setHours(6, 59, 59, 999);
      } else if (this.query.dayPicker === 'mes') {
        const hastaEnDate = new Date(this.query.mes!);
        hastaEnDate.setMonth(hastaEnDate.getMonth() + 1);
        hastaEnDate.setMilliseconds(hastaEnDate.getMilliseconds() - 1);
        this.query.desde = this.query.mes!;
        this.query.hasta = hastaEnDate;
        this.query.desde.setHours(7, 0, 0, 0);
        this.query.hasta.setHours(6, 59, 59, 999);
      } else if (this.query.dayPicker === 'anio') {
        const hastaEnDate = new Date(this.query.anio!);
        hastaEnDate.setFullYear(hastaEnDate.getFullYear() + 1);
        hastaEnDate.setMilliseconds(hastaEnDate.getMilliseconds() - 1);
        this.query.desde = this.query.anio!;
        this.query.hasta = hastaEnDate;
        this.query.desde.setHours(7, 0, 0, 0);
        this.query.hasta.setHours(6, 59, 59, 999);
      }

      const filter: IFilter<any> = {
        timestamp: {
          $gte: this.query.desde?.toISOString(),
          $lte: this.query.hasta?.toISOString(),
        },
      };
      query = {
        filter: JSON.stringify(filter),
        desde: this.query.desde?.toISOString(),
        hasta: this.query.hasta?.toISOString(),
        formato: this.query.formato,
        agrupacionTemporal: this.query.agrupacionTemporal,
      };

      if (this.query.agrupacion === 'Unidad de Negocio') {
        query.idUnidadNegocio = this.unidadNegocio?._id;
      }
      if (this.query.agrupacion === 'Centro Operativo') {
        query.idCentroOperativo = this.centroOperativo?._id;
      }
      if (this.query.agrupacion === 'Zona de Calidad') {
        query.idCuenca = this.cuenca?._id;
      }
      if (this.query.agrupacion === 'Grupo') {
        query.idGrupo = this.grupo?._id;
      }
      if (this.query.agrupacion === 'Correctora') {
        query.numeroSerieCorrectora = this.correctora?.numeroSerie;
      }
      if (this.query.agrupacion === 'NUC') {
        query.deveui = this.dispositivo?.deveui;
      }
      if (this.query.agrupacion === 'Punto de Medición') {
        query.idPuntoMedicion = this.puntoMedicion?._id;
      }
      if (tipo === 'faltantes') {
        await this.registrosService.exportarFaltantes(
          query,
          this.getFilename(query.formato),
        );
      } else {
        await this.registrosService.exportar(
          query,
          this.getFilename(query.formato),
        );
      }
    } catch (error) {
      this.helperService.notifError(error);
    }
    this.enviando = false;
  }

  public valido() {
    return (
      this.query.desde &&
      this.query.hasta &&
      ((this.query.agrupacion === 'Unidad de Negocio' && this.unidadNegocio) ||
        (this.query.agrupacion === 'Centro Operativo' &&
          this.centroOperativo) ||
        (this.query.agrupacion === 'Zona de Calidad' && this.cuenca) ||
        (this.query.agrupacion === 'Grupo' && this.grupo) ||
        (this.query.agrupacion === 'Correctora' && this.correctora) ||
        (this.query.agrupacion === 'NUC' && this.dispositivo) ||
        (this.query.agrupacion === 'Punto de Medición' && this.puntoMedicion))
    );
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    const populate = {
      path: 'unidadNegocio',
      select: 'nombre',
    };
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarCuencas(): Promise<void> {
    const populate = {
      path: 'unidadNegocio',
      select: 'nombre',
    };
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<ICuenca>>('cuencas', query)
      .subscribe((data) => {
        this.cuencas = data.datos;
        console.log(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  private async listarGrupos(): Promise<void> {
    const populate = {
      path: 'unidadNegocio',
      select: 'nombre',
    };
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private async listarCorrectoras(): Promise<void> {
    const query: IQueryParam = {
      select: 'numeroSerie modelo',
      sort: 'nombre',
    };
    this.correctoras$?.unsubscribe();
    this.correctoras$ = this.listadosService
      .subscribe<IListado<ICorrectora>>('correctoras', query)
      .subscribe((data) => {
        this.correctoras = data.datos;
        console.log(`listado de correctoras`, data);
      });
    await this.listadosService.getLastValue('correctoras', query);
  }

  private async listarNucs(): Promise<void> {
    if (this.helperService.puedeListarDispositivos()) {
      const filter: IFilter<any> = {
        tipoDispositivo: 'NUC',
      };
      const query: IQueryParam = {
        select: 'deveui deviceName',
        sort: 'deviceName',
        filter: JSON.stringify(filter),
      };
      this.dispositivos$?.unsubscribe();
      this.dispositivos$ = this.listadosService
        .subscribe<IListado<IDispositivo>>('dispositivos', query)
        .subscribe((data) => {
          this.dispositivos = data.datos;
          console.log(`listado de dispositivos`, data);
        });
      await this.listadosService.getLastValue('dispositivos', query);
    }
  }

  private async listarPuntosMedicion(): Promise<void> {
    if (this.helperService.puedeListarDispositivos()) {
      const query: IQueryParam = {
        select: 'nombre',
        sort: 'nombre',
      };
      this.puntoMedicions$?.unsubscribe();
      this.puntoMedicions$ = this.listadosService
        .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
        .subscribe((data) => {
          this.puntoMedicions = data.datos;
          console.log(`listado de puntoMedicions`, data);
        });
      await this.listadosService.getLastValue('puntosMedicion', query);
    }
  }

  //

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
      this.listarCuencas(),
      this.listarGrupos(),
      // this.listarCorrectoras(),
      this.listarNucs(),
      this.listarPuntosMedicion(),
    ]);
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.cuencas$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.correctoras$?.unsubscribe();
    this.dispositivos$?.unsubscribe();
    this.puntoMedicions$?.unsubscribe();
  }
}
