import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IMedidorResidencialAgua, IReporte } from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarMedidorResidencialAguaComponent } from '../crear-editar-medidores-residenciales-agua/crear-editar-medidores-residenciales-agua.component';
import { MedidorResidencialesAguaService } from '../medidores-residenciales-agua.service';
@Component({
  selector: 'app-detalles-medidor-residencial-agua',
  templateUrl: './detalles-medidores-residenciales-agua.component.html',
  styleUrls: ['./detalles-medidores-residenciales-agual.component.scss'],
})
export class DetallesMedidorResidencialesAguaComponent implements OnInit {
  public id?: string | null;
  public medidorResidencial?: IMedidorResidencialAgua;
  public ultimoReporte?: IReporte;

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptions),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {};

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    public helper: HelperService,
    public matDialog: MatDialog,
    public service: MedidorResidencialesAguaService,
  ) {}

  //
  public async editar(data?: IMedidorResidencialAgua) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMedidorResidencialAguaComponent, config);
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    if (this.id) {
      this.datos$ = this.listadosService
        .subscribe<IMedidorResidencialAgua>('medidorResidencialAgua', this.id)
        .subscribe((data) => {
          this.medidorResidencial = data;
          if (this.medidorResidencial?.ubicacionGps) {
            this.mapCenter = {
              lat: this.medidorResidencial?.ubicacionGps?.lat,
              lng: this.medidorResidencial?.ubicacionGps?.lng,
            };
            this.markerOptions = {
              position: this.mapCenter,
            };
          }
          this.ultimoReporte = this.medidorResidencial?.ultimoReporte;
          console.log(`listado de medidorResidencialAgua`, data);
        });
      await this.listadosService.getLastValue(
        'medidorResidencialAgua',
        this.id,
      );
    }
  }

  //

  // public puedeEditar(): boolean {
  //   return !!(
  //     this.medidorResidencial?.idUnidadNegocio &&
  //     HelperService.esAdminUnidadNegocio(
  //       this.medidorResidencial.idUnidadNegocio!
  //     )
  //   );
  // }

  ngOnInit(): void {
    this.mapOptions.mapTypeControl = false;
    this.mapOptions.zoomControl = false;
    this.mapOptions.fullscreenControl = true;
    this.mapOptions.zoom = 15;
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.id = params.get('id');
      await this.listar();
    });
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
