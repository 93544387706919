import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IConfigDispositivoNUC4G,
  ICorrectora,
  IDispositivo,
  IDispositivoNuc4G,
  IFilter,
  IListado,
  IMantenimiento,
  IQueryParam,
} from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { CorrectorasService } from 'src/app/modulos/correctoras/correctoras.service';
import { CrearEditarCorrectorasComponent } from 'src/app/modulos/correctoras/crear-editar-correctoras/crear-editar-correctoras.component';
import { ConfigDispositivosNucComponent } from 'src/app/modulos/dispositivos/config-dispositivos-nuc/config-dispositivos-nuc.component';
import { MODOS_OPERACION } from 'src/environments/datos';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CrearEditarMantenimientoComponent } from '../mantenimientos/crear-editar-mantenimiento/crear-editar-mantenimiento.component';
import { Subscription } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/listados.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-nuc',
  templateUrl: './detalles-nuc.component.html',
  styleUrls: ['./detalles-nuc.component.scss'],
})
export class DetallesNucComponent implements OnInit, OnChanges {
  @Input() correctora?: ICorrectora;
  @Input() configActual?: IDispositivoNuc4G & {
    claveMercury?: string;
    nsa?: number;
  };
  @Input() configDeseada?: IConfigDispositivoNUC4G;

  public configAplicada?: boolean;

  public mantenimiento?: IMantenimiento;
  public mantenimientos$?: Subscription;

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
    public service: CorrectorasService,
    private listados: ListadosService,
  ) {}

  public async editarCorrectora(data: ICorrectora) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarCorrectorasComponent, config);
  }

  public async configNuc(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigDispositivosNucComponent, config);
  }

  public async listarUltimoMantenimiento() {
    const filter: IFilter<IMantenimiento> = {
      idAsignado: this.correctora?.dispositivo?._id,
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      limit: 1,
      sort: '-fechaCreacion',
    };
    this.mantenimientos$?.unsubscribe();
    this.mantenimientos$ = this.listados
      .subscribe<IListado<IMantenimiento>>('mantenimientos', query)
      .subscribe((data) => {
        this.mantenimiento = data.datos[0];
        console.debug('listado de mantenimientos', data);
      });
    await this.listados.getLastValue('mantenimientos', query);
  }

  public async crearMantenimiento(data?: IDispositivo) {
    const config: MatDialogConfig = {
      data: { dispositivo: data },
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMantenimientoComponent, config);
  }

  public textoModoOperacion(modoOperacion?: string) {
    if (modoOperacion) {
      const modos = MODOS_OPERACION;
      const modo = modos.find((m) => m.value === modoOperacion);

      if (modo) {
        return modo.viewValue;
      }
    }
    return '...';
  }

  private verificarConfigAplicada() {
    this.configAplicada = true;

    if (this.configDeseada) {
      if (
        +(this.configDeseada.horaInicio as any) !==
          +(this.configActual?.horaInicio as any) ||
        this.configDeseada.modoOperacion !== this.configActual?.modoOperacion
      ) {
        this.configAplicada = false;
        return;
      }

      if (this.correctora?.modelo === 'Mercury') {
        if (
          this.configDeseada.claveMercury !== this.configActual?.claveMercury
        ) {
          this.configAplicada = false;
          return;
        }
      }

      if (this.correctora?.modelo === 'AmericanMeter') {
        if (this.configDeseada.nsa !== this.configActual?.nsa) {
          this.configAplicada = false;
        }
      }
    }
  }

  ngOnChanges() {
    // console.log('config actual', this.configActual);
    // console.log('config deseada', this.configDeseada);
    this.verificarConfigAplicada();
    // console.log('config aplicada', this.configAplicada);
  }

  async ngOnInit(): Promise<void> {
    this.verificarConfigAplicada();
    await this.listarUltimoMantenimiento();
  }
}
