import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListadoMedidorResidencialsComponent } from './listado-medidores-residenciales/listado-medidores-residenciales.component';
import { CrearEditarMedidorResidencialsComponent } from './crear-editar-medidores-residenciales/crear-editar-medidores-residenciales.component';
import { DetallesMedidorResidencialsComponent } from './detalles-medidores-residenciales/detalles-medidor-residencial.component';
import { MedidorResidencialsRoutingModule } from './medidores-residenciales.routing.module';
import { GeocodeService } from '../../auxiliares/geocode.service';
import { GraficoMedidorResidencialComponent } from '../../standalone/grafico-medidor-residencial/grafico-medidor-residencial.component';
import { ResumenMedidorResidencialComponent } from '../../standalone/resumen-medidor-residencial/resumen-medidor-residencial.component';
import { UltimoReporteMedidorResidencialComponent } from '../../standalone/ultimo-reporte-medidor-residencial/ultimo-reporte-medidor-residencial.component';
import { ListadoReportesMedidorResidencialComponent } from '../../standalone/listado-reportes-medidor-residencial/listado-reportes-medidor-residencial.component';

@NgModule({
  declarations: [
    ListadoMedidorResidencialsComponent,
    CrearEditarMedidorResidencialsComponent,
    DetallesMedidorResidencialsComponent,
    // Detalles
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    MedidorResidencialsRoutingModule,
    GraficoMedidorResidencialComponent,
    ResumenMedidorResidencialComponent,
    UltimoReporteMedidorResidencialComponent,
    ListadoReportesMedidorResidencialComponent,
  ],
  providers: [GeocodeService],
})
export class MedidorResidencialsModule {}
