import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICreateCuenca,
  ICuenca,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUpdateCuenca,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CuencasService } from '../cuencas.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar-cuencas',
  templateUrl: './crear-editar-cuencas.component.html',
  styleUrls: ['./crear-editar-cuencas.component.scss'],
})
export class CrearEditarCuencasComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public unidadNegocios: IUnidadNegocio[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICuenca,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarCuencasComponent>,
    private service: CuencasService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Zona de Calidad'
      : 'Crear Zona de Calidad';

    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idUnidadNegocio: [this.data?.idUnidadNegocio, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateCuenca = {
      nombre: this.form?.get('nombre')?.value,
      idUnidadNegocio: this.form?.get('idUnidadNegocio')?.value,
    };
    return data;
  }

  private getUpdateData() {
    const data: IUpdateCuenca = {
      nombre: this.form?.get('nombre')?.value,
      idUnidadNegocio: this.form?.get('idUnidadNegocio')?.value,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await this.listarUnidadNegocios();
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
  }
}
