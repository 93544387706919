import { Injectable } from '@angular/core';

import { WarningTelefonoComponent } from './warning-telefono.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class WarningTelefonoService {
  private ref?: MatDialogRef<WarningTelefonoComponent, any>;

  constructor(public dialog: MatDialog) {}

  public cambiarTelefono() {
    // No hago nada si ya hay un modal abierto
    if (this.ref) return;
    const config: MatDialogConfig = {
      minWidth: '500px',
    };
    this.ref = this.dialog.open(WarningTelefonoComponent, config);

    this.ref.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ref?.close();
      }
    });
  }
}
