import { Rol } from 'modelos/src';

export const ROLES: Rol[] = ['Administrador', 'Usuario'];

export enum ICodigoNotificacion {
  'Correctora sin Reportar' = 0,
  'Error de Comunicación con la Correctora' = 1,
  'Cromatografía Próxima a Vencer' = 2,
  'Fallo en Aplicación de Cromatografía' = 3,
}

export const NOMBRE_ALERTAS_NUC: any = {
  190: 'Correctora no encontrada',
  191: 'Error de comunicación con la correctora',
};

export const MODOS_OPERACION = [
  { value: 'consumos', viewValue: '1 Reporte por día de 24 Registros' },
  { value: 'REG1_DIARIO', viewValue: '1 Reporte por día de 1 Registro' },
  { value: 'REG1_1HORA', viewValue: '24 Reportes por día de 1 Registro' },
  { value: 'REG2_2HORAS', viewValue: '12 Reportes por día de 2 Registros' },
  { value: 'REG3_3HORAS', viewValue: '8 Reportes por día de 3 Registros' },
  { value: 'REG4_4HORAS', viewValue: '6 Reportes por día de 4 Registros' },
  { value: 'REG6_6HORAS', viewValue: '4 Reportes por día de 6 Registros' },
  { value: 'REG8_8HORAS', viewValue: '3 Reportes por día de 8 Registros' },
  { value: 'REG12_12HORAS', viewValue: '2 Reportes por día de 12 Registros' },
  { value: 'REG24_DIARIO', viewValue: '1 Reporte por día de 24 Registros' },
];

export const MODOS_OPERACION_NSP = [
  // { value: 'REG1_DIARIO', viewValue: '1 Reporte por día de 1 Registro' },
  { value: 'REG1_1HORA', viewValue: 'Reporte cada 1 hora' },
  { value: 'REG2_2HORAS', viewValue: 'Reporte cada 2 horas' },
  { value: 'REG3_3HORAS', viewValue: 'Reporte cada 3 horas' },
  { value: 'REG4_4HORAS', viewValue: 'Reporte cada 4 horas' },
  { value: 'REG6_6HORAS', viewValue: 'Reporte cada 6 horas' },
  { value: 'REG8_8HORAS', viewValue: 'Reporte cada 8 horas' },
  { value: 'REG12_12HORAS', viewValue: 'Reporte cada 12 horas' },
  { value: 'REG24_DIARIO', viewValue: 'Reporte cada 24 horas' },
];
