<div style="height: 100%; display: flex; flex-direction: column">
  <div class="titulo">
    <h1
      style="
        font-size: 18px;
        font-weight: 900;
        padding: 0.5em 0 0.5em 0.5em;
        margin: 0;
      "
    >
      Último Reporte
    </h1>
  </div>

  <div class="column-center" style="justify-content: center; height: 100%">
    <!-- Ultimo registro presion -->
    <table style="width: 100%" *ngIf="ultimoRegistro">
      <!-- Fecha -->
      <tr>
        <th>Fecha</th>
        <td>
          <span style="white-space: nowrap">
            {{ (ultimoRegistro.timestamp | date: "short") || "..." }}
          </span>
        </td>
      </tr>
      <!-- Presión -->
      <tr *ngIf="ultimoRegistro.presion !== -1">
        <th>Presión</th>
        <td>
          {{ ultimoRegistro.presion | number }}
          {{ ultimoRegistro.unidad }}
        </td>
      </tr>
      <!-- Batería -->
      <tr *ngIf="ultimoRegistro.bateria">
        <th>Batería</th>
        <td>
          {{ ultimoRegistro.bateria! / 10 | number: "1.0-3" }}
          V.
        </td>
      </tr>
      <!-- GSMQ -->
      <tr *ngIf="$any(ultimoRegistro).gsmq">
        <th>GSM</th>
        <td>
          {{ $any(ultimoRegistro).gsmq }}
        </td>
      </tr>
    </table>

    <!-- NO hay ultimo registro -->
    <div
      *ngIf="!ultimoRegistro"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <h3>
        <strong> No hay registros </strong>
      </h3>
    </div>

    <mat-divider
      class="divider"
      *ngIf="ultimaAlerta"
      style="margin: 1em 1em 1em 1em"
    ></mat-divider>

    <!-- Ultima Alerta -->
    <table *ngIf="ultimaAlerta" style="width: 100%">
      <!-- Fecha -->
      <tr>
        <th>Fecha</th>
        <td>
          {{ (ultimaAlerta.timestamp | date: "short") || "..." }}
        </td>
      </tr>
      <!-- Alerta -->
      <tr>
        <th>Alerta</th>
        <td>
          {{ ultimaAlerta.mensaje || "..." }}
        </td>
      </tr>
    </table>
  </div>
</div>
