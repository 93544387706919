<div style="height: 100%; display: flex; flex-direction: column">
  <!-- "VERIBOX MICRO"-->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Veribox
      </h1>
      <div fxLayout="row" fxLayoutGap="10px" style="padding-right: 0.5em">
        <!-- Editar Config -->
        <img
          src="assets/iconos/config.png"
          alt="editar"
          matTooltip="Configurar"
          style="width: 30px; margin-right: 0.5em; cursor: pointer"
          *ngIf="
            helper.puedeConfigurarVeribox(unidadPresion!.dispositivo!) &&
            unidadPresion?.dispositivo?.tipoDispositivo === 'VERIBOX MICRO'
          "
          (click)="configurarVeribox(unidadPresion!.dispositivo!)"
        />
      </div>
    </div>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <table style="width: 100%">
      <!-- Device Name -->
      <tr>
        <th>Device</th>
        <td style="text-align: end">
          {{ unidadPresion?.dispositivo?.deviceName || "..." }}
        </td>
      </tr>
      <!-- Deveui -->
      <tr>
        <th>Número de Serie</th>
        <td style="text-align: end">
          <span>
            {{ helper.parsearDevEui(unidadPresion?.deveui!) || "..." }}
          </span>
        </td>
      </tr>
      <!-- Telefono -->
      <tr [class.migrar]="puedeMigrar()">
        <th>Teléfono</th>
        <td style="text-align: end">
          <div
            class="row-center"
            style="align-items: center; justify-content: end"
          >
            @if (puedeMigrar()) {
              <button
                mat-icon-button
                (click)="migrarVeribox(unidadPresion?.dispositivo)"
                matTooltip="Migrar Veribox"
                color="accent"
              >
                <mat-icon>phone</mat-icon>
              </button>
            } @else {
              <button
                mat-icon-button
                (click)="cambiarTelefono(unidadPresion)"
                matTooltip="Cambiar Teléfono"
                color="accent"
              >
                <mat-icon>phone</mat-icon>
              </button>
            }

            <span>
              {{ configActual?.telefono }}
            </span>
          </div>
        </td>
      </tr>
      <!-- Ultima Comunicacion -->
      <tr>
        <th>Última Comunicación</th>
        <td style="text-align: end">
          {{
            unidadPresion?.dispositivo?.fechaUltimaComunicacion
              | date: "short" || "..."
          }}
        </td>
      </tr>

      <!-- Config -->
      <ng-container>
        <!-- Separador -->
        <tr>
          <td colspan="100%">
            <mat-divider class="divider" style="margin: 1em 0"></mat-divider>
          </td>
        </tr>

        <div
          *ngIf="!configActual"
          style="
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <h3>
            <strong> Sin configuración </strong>
          </h3>
        </div>

        <!-- ACTUAL -->
        <ng-container *ngIf="configActual">
          <!-- Frecuencia de comunicación -->
          <tr>
            <th>Frecuencia de comunicación</th>
            <td style="text-align: end">
              {{ frec[configActual.frecuenciaComunicacion!] }}
            </td>
          </tr>
          <!-- Limite Min -->
          <tr>
            <th>Límite Mínimo</th>
            <td style="text-align: end">{{ configActual.limiteMin }} Bar.</td>
          </tr>
          <!-- Limite Max -->
          <tr>
            <th>Límite Máximo</th>
            <td style="text-align: end">{{ configActual.limiteMax }} Bar.</td>
          </tr>
          <!-- APN -->
          <tr>
            <th>APN</th>
            <td style="text-align: end">
              {{ configActual.apn }}
            </td>
          </tr>
          <!-- Usuario -->
          <tr>
            <th>Usuario</th>
            <td style="text-align: end">
              {{ configActual.usuario }}
            </td>
          </tr>
          <!-- Contraseña -->
          <tr>
            <th>Contraseña</th>
            <td style="text-align: end">
              {{ configActual.clave }}
            </td>
          </tr>
          <!-- Fecha de Aplicación -->
          <tr *ngIf="configActual.fechaAplicacion">
            <th>Fecha de Aplicación</th>
            <td style="text-align: end">
              <span>{{ configActual.fechaAplicacion | date: "short" }}</span>
            </td>
          </tr>
          <tr></tr>
        </ng-container>
      </ng-container>
    </table>
  </div>

  <!-- Config no aplicada -->
  <div
    *ngIf="configAplicada === false"
    [class]="configVencida ? 'bg-warn2' : 'bg-accent'"
    style="border-bottom-left-radius: 30px"
  >
    <div
      class="column-center"
      style="justify-content: center; padding: 0 0 0.5em 0"
    >
      <h3 style="padding-left: 0.5em">
        <strong> Configuración aún no aplicada</strong>
      </h3>
      <table style="width: 100%">
        <!-- Frecuencia de comunicación -->
        <tr
          *ngIf="
            configDeseada?.frecuenciaComunicacion !==
            configActual?.frecuenciaComunicacion
          "
        >
          <th>Frecuencia de comunicación</th>
          <td style="text-align: end">
            {{ frec[configDeseada?.frecuenciaComunicacion!] }}
          </td>
        </tr>
        <!-- Limite Min -->
        <tr *ngIf="configDeseada?.limiteMin !== configActual?.limiteMin">
          <th>Límite Mínimo</th>
          <td style="text-align: end">{{ configDeseada?.limiteMin }} Bar.</td>
        </tr>
        <!-- Limite Max -->
        <tr *ngIf="configDeseada?.limiteMax !== configActual?.limiteMax">
          <th>Límite Máximo</th>
          <td style="text-align: end">{{ configDeseada?.limiteMax }} Bar.</td>
        </tr>
        <!-- APN -->
        <tr *ngIf="configDeseada?.apn !== configActual?.apn">
          <th>APN</th>
          <td style="text-align: end">
            {{ configDeseada?.apn }}
          </td>
        </tr>
        <!-- Usuario -->
        <tr *ngIf="configDeseada?.usuario !== configActual?.usuario">
          <th>Usuario</th>
          <td style="text-align: end">
            {{ configDeseada?.usuario }}
          </td>
        </tr>
        <!-- Contraseña -->
        <tr *ngIf="configDeseada?.clave !== configActual?.clave">
          <th>Contraseña</th>
          <td style="text-align: end">
            {{ configDeseada?.clave }}
          </td>
        </tr>

        <!-- Fecha de Aplicación -->
        <!-- <tr>
          <th>Reenviar</th>
          <td style="text-align: end">
            <div
              class="row-center"
              style="width: 100%"
              *ngIf="!configDeseada?.fechaAplicacion"
            >
              <button
                mat-icon-button
                color="primary"
                matTooltip="Reaplicar Configuración"
                (click)="Reenviar()"
              >
                <mat-icon>autorenew</mat-icon>
              </button>
            </div>
          </td>
        </tr> -->
      </table>

      @if (configVencida) {
        <h4 class="config-vencida">
          Reenvío automático cancelado por intentos fallidos. Edite la
          configuración para reintentar
        </h4>
      }
    </div>
  </div>
</div>
