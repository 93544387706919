import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Division,
  ICreateAgrupacion,
  IAgrupacion,
  IUnidadNegocio,
  IUpdateAgrupacion,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { AgrupacionsService } from '../agrupacion.service';

@Component({
  selector: 'app-crear-editar-agrupacion',
  templateUrl: './crear-editar-agrupacion.component.html',
  styleUrls: ['./crear-editar-agrupacion.component.scss'],
})
export class CrearEditarAgrupacionsComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public unidadNegocios: IUnidadNegocio[] = [];
  public divisiones: Division[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAgrupacion,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarAgrupacionsComponent>,
    private service: AgrupacionsService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Agrupación' : 'Crear Agrupación';

    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateAgrupacion = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateAgrupacion = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  //

  // Listados

  async ngOnInit(): Promise<void> {
    this.createForm();
  }

  ngOnDestroy(): void {}
}
