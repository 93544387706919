<!-- Detalles -->
<div fxLayout="row wrap" fxLayoutAlign="space-around stretch">
  <ng-container *ngFor="let un of resumen">
    <div
      class="card-container fondo-85"
      fxFlex.lt-sm="100"
      fxFlex.gt-xs="grow shrink initial"
    >
      <div class="titulo">
        <h1
          style="
            margin: 0.5em 18px;
            font-size: 28px;
            font-weight: 800;
            padding: 0.3em 0;
          "
        >
          {{ un?.nombre }}
        </h1>
      </div>

      <div style="padding: 0.5em">
        <table>
          <!-- Centros Operativos -->
          <tr>
            <td>Centros Operativos:</td>
            <td style="padding: 0.25em 0.5em; text-align: right">
              {{ un.centrosOperativos }}
            </td>
          </tr>

          <!-- Zonas de Calidad -->
          <tr>
            <td>Zonas de Calidad:</td>
            <td style="padding: 0.25em 0.5em; text-align: right">
              {{ un.cuencas }}
            </td>
          </tr>
        </table>

        <mat-divider class="divider-card"></mat-divider>

        <table>
          <!-- Puntos Operativos -->
          <tr
            *ngIf="helper.puedeVerPuntosDeMedicion()"
            style="cursor: pointer"
            (click)="irAPuntos(un.nombre)"
          >
            <td>Puntos operativos:</td>
            <td>
              <span class="label" [ngClass]="claseCorrectorasUnidadNegocio(un)">
                <ng-container *ngIf="un.total === un.operativos">
                  {{ un.total }}
                </ng-container>
                <ng-container *ngIf="un.total !== un.operativos">
                  {{ un.operativos }} /
                  {{ un.total }}
                </ng-container>
              </span>
            </td>
          </tr>

          <!-- Puntos en Alerta -->
          <tr
            *ngIf="helper.puedeVerPuntosDeMedicion() && un.enAlerta > 0"
            style="cursor: pointer"
            (click)="irAPuntos(un.nombre)"
          >
            <td>Puntos en alerta:</td>
            <td>
              <span class="label">
                {{ un.enAlerta }}
              </span>
            </td>
          </tr>

          <!-- Puntos en mantenimiento -->
          <tr
            *ngIf="helper.puedeVerPuntosDeMedicion() && un.enMantenimiento > 0"
            style="cursor: pointer"
            (click)="irAPuntos(un.nombre)"
          >
            <td>Puntos en mantenimiento:</td>
            <td>
              <span class="label">
                {{ un.enMantenimiento }}
              </span>
            </td>
          </tr>

          <!-- Puntos a resolver -->
          <tr
            *ngIf="helper.puedeVerPuntosDeMedicion() && un.aResolver > 0"
            style="cursor: pointer"
            (click)="irAPuntos(un.nombre)"
          >
            <td>Puntos a resolver:</td>
            <td>
              <span class="label">
                {{ un.aResolver }}
              </span>
            </td>
          </tr>

          <!-- Puntos sin reportar -->
          <tr
            *ngIf="helper.puedeVerPuntosDeMedicion() && un.sinReportar > 0"
            style="cursor: pointer"
            (click)="irAPuntos(un.nombre)"
          >
            <td>Puntos sin reportar:</td>
            <td>
              <span class="label">
                {{ un.sinReportar }}
              </span>
            </td>
          </tr>

          <!-- Puntos sin dispostivo -->
          <tr
            *ngIf="helper.puedeVerPuntosDeMedicion() && un.sinDispositivo > 0"
            style="cursor: pointer"
            (click)="irAPuntos(un.nombre)"
          >
            <td>Puntos sin dispositivo:</td>
            <td>
              <span class="label">
                {{ un.sinDispositivo }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>
