import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { IPuntoMedicion, IRegistro } from 'modelos/src';
import { HelperService } from '../../../auxiliares/helper.service';

@Component({
  selector: 'app-info-window-correctora',
  templateUrl: './info-window-correctora.component.html',
  styleUrls: ['./info-window-correctora.component.scss'],
})
export class InfoWindowCorrectoraComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() punto?: IPuntoMedicion;
  public valores?: IRegistro;

  constructor(public helper: HelperService) {}

  ngOnChanges(): void {
    if (this.punto) {
      this.valores = this.punto.correctora?.ultimoRegistro;
    }
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {}
}
