import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ICorrectora,
  ICreateMantenimiento,
  IDispositivo,
  IListado,
  IMantenimiento,
  IQueryParam,
  IUpdateMantenimiento,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { MantenimientosService } from '../mantenimientos.service';
import { HelperService } from 'src/app/auxiliares/helper.service';

@Component({
  selector: 'app-crear-editar-mantenimiento',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './crear-editar-mantenimiento.component.html',
  styleUrl: './crear-editar-mantenimiento.component.scss',
})
export class CrearEditarMantenimientoComponent implements OnInit {
  public title?: string;
  public form?: FormGroup;
  public enviando = false;

  public tipos?: string[] = [
    'Cambio de Pila',
    'Mantenimiento General',
    'Reparación',
  ];

  public entidades?: string[] = ['Dispositivo', 'Correctora'];

  public dispositivos?: IDispositivo[] = [];
  public dispositivos$?: Subscription;

  public correctoras?: ICorrectora[] = [];
  public correctoras$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      edit?: IMantenimiento;
      correctora?: ICorrectora;
      dispositivo?: IDispositivo;
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarMantenimientoComponent>,
    public loadingService: LoadingService,
    private listados: ListadosService,
    private service: MantenimientosService,
    private helper: HelperService,
  ) {}

  private createForm(): void {
    this.title = this.data?.edit?._id
      ? 'Editar Mantenimiento'
      : 'Crear Mantenimiento';

    const idAsignado =
      this.data?.edit?.idAsignado ||
      this.data.correctora?._id ||
      this.data.dispositivo?._id;

    const tipoAsignado = this.data.correctora
      ? 'Correctora'
      : this.data.dispositivo
        ? 'Dispositivo'
        : this.correctoras?.find((c) => c._id === idAsignado)
          ? 'Correctora'
          : this.dispositivos?.find((d) => d._id === idAsignado)
            ? 'Dispositivo'
            : '';

    this.form = this.fb.group({
      fecha: [this.data?.edit?.fecha || new Date(), Validators.required],
      descripcion: [this.data?.edit?.descripcion, Validators.required],
      tipo: [this.data?.edit?.tipo, Validators.required],
      idAsignado: [idAsignado, Validators.required],
      tipoAsignado: [tipoAsignado, Validators.required],
    });
  }

  private getCreateData() {
    const data: ICreateMantenimiento = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateMantenimiento = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.enviando = true;
    try {
      if (this.data.edit?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data.edit._id, data));
        this.helper.notifSuccess('Updateado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }

      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.enviando = false;
  }

  private async listarCorrectoras() {
    // const filter: IFilter<ICorrectora> = {};
    // const populate: IPopulate = {};
    const query: IQueryParam = {
      // filter: JSON.stringify(filter),
      // populate: JSON.stringify(populate),
      limit: 0,
      sort: 'deveui',
    };

    // Listado
    this.correctoras$?.unsubscribe();
    this.correctoras$ = this.listados
      .subscribe<IListado<ICorrectora>>('correctoras', query)
      .subscribe((data) => {
        this.correctoras = data.datos;
        console.debug(`listado de correctoras`, data);
      });
    await this.listados.getLastValue('correctoras', query);
  }

  private async listarDispositivos() {
    // const filter: IFilter<IDispositivo> = {};
    // const populate: IPopulate = {};
    const query: IQueryParam = {
      // filter: JSON.stringify(filter),
      // populate: JSON.stringify(populate),
      limit: 0,
      sort: 'deveui',
    };

    // Listado
    this.dispositivos$?.unsubscribe();
    this.dispositivos$ = this.listados
      .subscribe<IListado<IDispositivo>>('dispositivos', query)
      .subscribe((data) => {
        this.dispositivos = data.datos;
        console.debug(`listado de dispositivos`, data);
      });
    await this.listados.getLastValue('dispositivos', query);
  }

  public close(): void {
    this.dialogRef.close();
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([this.listarCorrectoras(), this.listarDispositivos()]);

    if (this.data.edit) {
      const idAsignado =
        this.data?.edit?.idAsignado ||
        this.data.correctora?._id ||
        this.data.dispositivo?._id;

      const tipoAsignado = this.data.correctora
        ? 'Correctora'
        : this.data.dispositivo
          ? 'Dispositivo'
          : this.correctoras?.find((c) => c._id === idAsignado)
            ? 'Correctora'
            : this.dispositivos?.find((d) => d._id === idAsignado)
              ? 'Dispositivo'
              : '';

      this.form?.patchValue({ tipoAsignado });
    }
  }
}
