<div
  *ngIf="medidorResidencial"
  class="fondo-85"
  style="
    margin: 1em 0;
    padding: 0.5em;
    border-bottom-right-radius: 30px;
    overflow: auto;
  "
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <table>
    <tr class="border">
      <th>Estado</th>
      <th>Fecha de Creación</th>
      <th>Nombre</th>
      <th>Número de Serie</th>
      <th>SML</th>
      <th>Unidad de Negocio</th>
    </tr>
    <tr>
      <td class="no-vertical">
        <ng-container
          *ngIf="
            helper.estadoMedidor(medidorResidencial) as estado1;
            let estado
          "
        >
          <img
            *ngIf="estado !== 'Ok'"
            [matTooltip]="estado"
            src="assets/estados/alerta.png"
            alt="ok"
            style="width: 40px"
          />
          <img
            src="assets/estados/ok.png"
            alt="ok"
            style="width: 40px"
            *ngIf="estado === 'Ok'"
            [matTooltip]="estado"
          />
        </ng-container>
      </td>
      <td class="no-vertical">
        {{ (medidorResidencial.fechaCreacion | date : "short") || "..." }}
      </td>
      <td class="no-vertical">{{ medidorResidencial.nombre || "..." }}</td>
      <td class="no-vertical">
        {{ medidorResidencial.deviceMeterNumber || "..." }}
      </td>
      <td class="no-vertical">
        <p>{{ medidorResidencial.deviceName || "..." }}</p>
        <p>{{ medidorResidencial.deveui || "..." }}</p>
      </td>
      <td class="no-vertical">
        <p>{{ medidorResidencial.unidadNegocio?.nombre || "..." }}</p>
        <p>{{ medidorResidencial.centroOperativo?.nombre || "..." }}</p>
        <p>{{ medidorResidencial.cuenca?.nombre || "..." }}</p>
        <p *ngFor="let grupo of medidorResidencial?.grupos">
          {{ grupo?.nombre || "..." }}
        </p>
      </td>
    </tr>
  </table>

  <div
    *ngIf="mapCenter"
    style="margin-right: 14px; border-radius: 30px; overflow: hidden"
  >
    <google-map
      width="200px"
      height="300px"
      [options]="mapOptions"
      [center]="mapCenter"
      [zoom]="mapOptions.zoom || 12"
    >
      <map-marker [options]="markerOptions"></map-marker>
    </google-map>
  </div>

  <div style="margin-right: 7px" *ngIf="medidorResidencial">
    <img
      src="assets/iconos/editar.png"
      alt="editar"
      *ngIf="helper.puedeEditarMedidor(medidorResidencial)"
      matTooltip="Editar"
      style="width: 48px; cursor: pointer"
      (click)="editar(medidorResidencial)"
    />
  </div>
</div>
