import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { GeocodeService } from '../../auxiliares/geocode.service';
import { EstadisticasRoutingModule } from './routing';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { GraficoMinMaxMultiComponent } from 'src/app/standalone/grafico-min-max-multi/grafico-min-max-multi.component';
import { GraficoPresion5MinutosComponent } from '../../standalone/grafico-presion-5-min/grafico-presion-5-min.component';

@NgModule({
  declarations: [EstadisticasComponent],
  imports: [
    CommonModule,
    AuxiliaresModule,
    EstadisticasRoutingModule,
    GraficoMinMaxMultiComponent,
    GraficoPresion5MinutosComponent,
  ],
  providers: [GeocodeService],
})
export class EstadisticasModule {}
