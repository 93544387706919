import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { MapaRoutingModule } from './mapa.routing.module';
import { MapaComponent } from './mapa/mapa.component';
import { InfoWindowCorrectoraComponent } from './info-window-correctora/info-window-correctora.component';
import { InfoWindowUnidadPresionComponent } from './info-window-unidad-presion/info-window-unidad-presion.component';
import { InfoWindowResidencialComponent } from './info-window-residencial/info-window-residencial.component';

@NgModule({
  declarations: [
    MapaComponent,
    InfoWindowCorrectoraComponent,
    InfoWindowUnidadPresionComponent,
    InfoWindowResidencialComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, MapaRoutingModule],
})
export class MapaModule {}
