<section class="layout">
  <div class="password">
    <div class="container">
      <form
        id="loginForm"
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        class="form-container"
      >
        <h2>Cambiar Contraseña</h2>
        <mat-form-field class="input" appearance="outline">
          <mat-label>Contraseña Actual</mat-label>
          <mat-icon matPrefix>lock</mat-icon>
          <input type="password" matInput formControlName="password" />
        </mat-form-field>

        <mat-form-field class="input" appearance="outline">
          <mat-label>Contraseña Nueva</mat-label>
          <mat-icon matPrefix>lock</mat-icon>
          <input type="password" matInput formControlName="passwordNuevo" />
        </mat-form-field>

        <button
          mat-flat-button
          class="login"
          [disabled]="
            !form.pristine && (!form.valid || this.loading.getLoading())
          "
          type="submit"
          form="loginForm"
          style="margin-bottom: 1em"
        >
          CAMBIAR CONTRASEÑA
        </button>
      </form>
    </div>
  </div>
  <div class="telefono">
    <div class="container">
      <div class="form-container">
        <h2>Cambiar Teléfono</h2>
        <mat-form-field class="input" appearance="outline">
          <mat-label>Teléfono Actual</mat-label>
          <mat-icon matPrefix>phone</mat-icon>
          <input
            type="text"
            matInput
            [(ngModel)]="telActual"
            disabled
            readonly
          />
        </mat-form-field>

        <mat-form-field class="input" appearance="outline">
          <mat-label>Nuevo Teléfono</mat-label>
          <mat-icon matPrefix>phone</mat-icon>
          <input
            type="text"
            matInput
            [(ngModel)]="tel"
            (ngModelChange)="checkTel()"
          />
          @if (!telValido) {
            <mat-hint></mat-hint>
          }
        </mat-form-field>

        <button
          mat-flat-button
          class="login"
          [disabled]="!telValido"
          (click)="cambiarTelefono()"
          style="margin-bottom: 1em"
          type="button"
        >
          CAMBIAR TELÉFONO
        </button>
      </div>
    </div>
  </div>
</section>
