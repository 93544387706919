import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICreateCromatografia,
  ICromatografia,
  ICuenca,
  IFilter,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUpdateCromatografia,
} from 'modelos/src';
import { Moment } from 'moment';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CromatografiasService } from '../cromatografias.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-crear-editar-cromatografias',
  templateUrl: './crear-editar-cromatografias.component.html',
  styleUrls: ['./crear-editar-cromatografias.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CrearEditarCromatografiasComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public unidadNegocios: IUnidadNegocio[] = [];
  public cuencas: ICuenca[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public cuencas$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICromatografia,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarCromatografiasComponent>,
    private service: CromatografiasService,
    private listadosService: ListadosService,
    private helper: HelperService,
    private dialogService: DialogService,
    public loading: LoadingService,
  ) {}

  private fechaDentroDe1Mes() {
    const fecha = new Date();
    fecha.setMonth(fecha.getMonth() + 1);
    return fecha;
  }

  private fechaPrimerDiaDelMesXSiguiente(x: number) {
    const fecha = new Date();
    fecha.setMonth(fecha.getMonth() + x);
    fecha.setDate(1);
    fecha.setHours(0, 0, 0, 0);
    return fecha;
  }

  private fechaValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let error: { fecha: string } | null = null;
      const input = control.value;

      const fechaAplicacion = input.fechaAplicacion;
      const fechaVencimiento = input.fechaVencimiento;

      // if (fechaAplicacion < fechaActual) {
      //   error = {
      //     fecha: 'La fecha de aplicación no puede ser menor a la actual',
      //   };
      // }

      if (fechaAplicacion > fechaVencimiento) {
        error = {
          fecha:
            'La fecha de aplicación no puede ser mayor a la fecha de vencimiento',
        };
      }
      return error;
    };
  }

  private elementosValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let error: { suma: string } | null = null;
      const input = control.value;
      let suma = 0;

      for (const key in input) {
        if (
          Object.prototype.hasOwnProperty.call(input, key) &&
          key !== 'densidad'
        ) {
          suma += input[key];
        }
      }

      if (suma > 100.002) {
        error = {
          suma: 'La suma de los elementos no puede ser mayor a 100.002',
        };
      }

      if (suma < 99.998) {
        error = {
          suma: 'La suma de los elementos no puede ser menor a 99.998',
        };
      }
      return error;
    };
  }

  private async diferencia(
    data: IUpdateCromatografia | ICreateCromatografia,
  ): Promise<boolean> {
    const elementos = data.elementos as any;

    let suma = 0;
    for (const key in elementos) {
      if (
        Object.prototype.hasOwnProperty.call(elementos, key) &&
        key !== 'densidad'
      ) {
        suma += elementos[key];
      }
    }

    if (suma < 100) {
      const diferencia = +(100 - suma).toFixed(3);
      const bool = await this.dialogService.confirm(
        'Nomalización',
        `¿Desea normalizar la cromatografía? (Agregar ${diferencia} a Metano )`,
        'Aceptar',
        'Cancelar',
      );
      if (bool) {
        data.elementos!.metano! += diferencia;
      }
      return bool;
    } else if (suma > 100) {
      const diferencia = +(suma - 100).toFixed(3);
      const bool = await this.dialogService.confirm(
        'Nomalización',
        `¿Desea normalizar la cromatografía? (Restar ${diferencia} a Metano )`,
        'Aceptar',
        'Cancelar',
      );
      if (bool) {
        data.elementos!.metano! -= diferencia;
      }
      return bool;
    }
    return true;
  }

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Cromatografia'
      : 'Crear Cromatografia';

    const elementos = this.fb.group(
      {
        oxigeno: [this.data?.elementos?.oxigeno, Validators.required],
        densidad: [this.data?.elementos?.densidad, Validators.required],
        dioxidoCarbono: [
          this.data?.elementos?.dioxidoCarbono,
          Validators.required,
        ],
        nitrogeno: [this.data?.elementos?.nitrogeno, Validators.required],
        metano: [this.data?.elementos?.metano, Validators.required],
        etano: [this.data?.elementos?.etano, Validators.required],
        propano: [this.data?.elementos?.propano, Validators.required],
        isoButano: [this.data?.elementos?.isoButano, Validators.required],
        nButano: [this.data?.elementos?.nButano, Validators.required],
        isoPentano: [this.data?.elementos?.isoPentano, Validators.required],
        nPentano: [this.data?.elementos?.nPentano, Validators.required],
        nHexano: [this.data?.elementos?.nHexano, Validators.required],
        nHeptano: [this.data?.elementos?.nHeptano, Validators.required],
        nOctano: [this.data?.elementos?.nOctano, Validators.required],
      },
      { validators: this.elementosValidator() },
    );
    this.form = this.fb.group(
      {
        fechaAplicacion: [
          this.data?.fechaAplicacion
            ? new Date(this.data?.fechaAplicacion)
            : this.fechaPrimerDiaDelMesXSiguiente(1),
          Validators.required,
        ],
        fechaVencimiento: [
          this.data?.fechaVencimiento
            ? new Date(this.data?.fechaVencimiento)
            : this.fechaPrimerDiaDelMesXSiguiente(2),
          Validators.required,
        ],
        idUnidadNegocio: [this.data?.idUnidadNegocio, Validators.required],
        idCuenca: [this.data?.idCuenca, Validators.required],
        elementos,
      },
      { validators: this.fechaValidator() },
    );
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateCromatografia = {
      idCuenca: this.form?.get('idCuenca')?.value,
      fechaAplicacion: this.form?.get('fechaAplicacion')?.value?.toISOString(),
      fechaVencimiento: this.form
        ?.get('fechaVencimiento')
        ?.value?.toISOString(),
      elementos: this.form?.get('elementos')?.value,
    };
    return data;
  }

  private getUpdateData() {
    const data: IUpdateCromatografia = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        const bool = await this.diferencia(data);
        if (bool) {
          await firstValueFrom(this.service.editar(this.data._id, data));
          this.helper.notifSuccess('Editado correctamente');
          this.dialogRef.close(true);
        }
      } else {
        const data = this.getData();
        const bool = await this.diferencia(data);
        if (bool) {
          await firstValueFrom(this.service.crear(data));
          this.helper.notifSuccess('Creado correctamente');
          this.dialogRef.close(true);
        }
      }
      this.enviando = false;
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Fecha
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Date>,
    form: string,
  ) {
    const fecha: Date = this.form?.get(form)?.value;
    fecha.setFullYear(
      normalizedMonthAndYear.year(),
      normalizedMonthAndYear.month(),
      1,
    );
    fecha.setHours(0, 0, 0, 0);
    this.form?.patchValue({ [form]: fecha });
    datepicker.close();
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        if (this.unidadNegocios.length === 1) {
          this.form?.patchValue({
            idUnidadNegocio: this.unidadNegocios[0]._id,
          });
        }
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCuencas(): Promise<void> {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    if (idUnidadNegocio) {
      // Filtro
      const filter: IFilter<any> = { idUnidadNegocio };
      const query: IQueryParam = {
        select: 'nombre',
        sort: 'nombre',
        filter: JSON.stringify(filter),
      };

      // Listado
      this.cuencas$?.unsubscribe();
      this.cuencas$ = this.listadosService
        .subscribe<IListado<ICuenca>>('cuencas', query)
        .subscribe((data) => {
          if (HelperService.rolesGlobales(['Administrador'])) {
            this.cuencas = data.datos;
          } else {
            const idCuencas = HelperService.cuencasPorRolCroma();
            this.cuencas = data.datos.filter(
              (cuenca) => idCuencas?.includes(cuenca._id),
            );
          }
          if (this.cuencas.length === 1) {
            this.form?.patchValue({
              idCuenca: this.cuencas[0]._id,
            });
          }
          console.log(`listado de cuencas`, data);
        });
      await this.listadosService.getLastValue('cuencas', query);
    }
  }

  //

  public async cambioUnidadNegocio() {
    await this.listarCuencas();
  }

  //

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([this.listarUnidadNegocios(), this.listarCuencas()]);
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.cuencas$?.unsubscribe();
  }
}
