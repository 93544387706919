import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing/landing.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LandingRoutingModule } from './landing.routing.module';

@NgModule({
  declarations: [LandingComponent],
  imports: [CommonModule, AuxiliaresModule, LandingRoutingModule],
})
export class LandingModule {}
