import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { IFilter, IListado, INotificacion, IQueryParam } from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { NotificacionesService } from '../service';
import { ROJO, VERDE } from 'src/assets/estilos/colores';

@Component({
  selector: 'app-listado-notificaciones',
  templateUrl: './listado-notificaciones.component.html',
  styleUrls: ['./listado-notificaciones.component.scss'],
})
export class ListadoNotificacionesComponent implements OnInit, OnDestroy {
  public RED = ROJO;
  public GREEN = VERDE;
  public verLeidas = false;
  public mostrarConfig = false;

  public totalCount = 0;
  public notificaciones: INotificacion[] = [];

  //
  private filter: IFilter<any> = { leido: false };
  public queryParams: IQueryParam = {
    page: 0,
    limit: 5,
    sort: '-fechaCreacion',
    filter: JSON.stringify(this.filter),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private helper: HelperService,
    private notificacionesService: NotificacionesService,
    private listadosService: ListadosService,
    private dialog: DialogService,
    private service: NotificacionesService,
  ) {}

  public async toggleVerTodas(): Promise<void> {
    this.notificaciones = [];
    this.totalCount = 0;
    this.verLeidas = !this.verLeidas;
    this.filter.leido = this.verLeidas ? true : false;
    this.queryParams.filter = JSON.stringify(this.filter);
    await this.listar();
  }

  public async marcarTodasComoLeidas() {
    const confirm = await this.dialog.confirm(
      'Confirme la acción',
      `¿Marcar todas las notificaciones como leídas?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.marcarTodasComoLeidas());
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async pageEvent(event: PageEvent): Promise<void> {
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
  }

  //

  public toggleConfig() {
    this.mostrarConfig = !this.mostrarConfig;
  }

  public async marcarComoLeida(notificacion: INotificacion): Promise<void> {
    try {
      await firstValueFrom(
        this.notificacionesService.marcarComoLeida(notificacion._id!),
      );
      notificacion.leido = true;
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  public async marcarComoNoLeida(notificacion: INotificacion): Promise<void> {
    try {
      await firstValueFrom(
        this.notificacionesService.marcarComoNoLeida(notificacion._id!),
      );
      notificacion.leido = true;
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  public async eliminar(notificacion: INotificacion): Promise<void> {
    try {
      await firstValueFrom(
        this.notificacionesService.eliminar(notificacion._id!),
      );
      this.notificaciones = this.notificaciones.filter(
        (n) => n._id !== notificacion._id,
      );
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  //

  private async listar(): Promise<void> {
    try {
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<INotificacion>>('notificacions', this.queryParams)
        .subscribe((data) => {
          this.totalCount = data.totalCount;
          this.notificaciones = data.datos;
          console.log(`listado de notificacions`, data);
        });
      await this.listadosService.getLastValue(
        'notificacions',
        this.queryParams,
      );
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  //

  async ngOnInit(): Promise<void> {
    await this.listar();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
