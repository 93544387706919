import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDispositivo,
  IConfigDispositivoVeribox,
  IUpdateDispositivo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { DispositivosService } from '../dispositivos.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-migrar-dispositivos-veribox',
  templateUrl: './migrar-dispositivos-veribox.component.html',
  styleUrls: ['./migrar-dispositivos-veribox.component.scss'],
})
export class MigrarDispositivosVeriboxComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public config?: IConfigDispositivoVeribox;

  public regTel = /^(?!0|15)\d{10}$/;
  public telefono?: string;

  // Listado Continuo
  public config$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MigrarDispositivosVeriboxComponent>,
    private service: DispositivosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = `Migrar Dispositivo Veribox ${this.helper.parsearDevEui(
      this.data?.deveui,
    )}`;

    this.form = this.fb.group({
      telefono: [
        this.data?.config?.telefono,
        [Validators.required, Validators.pattern(this.regTel)],
      ],
      migrar: [this.data?.config?.migrar || false],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public telValido(telefono?: string) {
    return this.regTel.test(telefono || '');
  }

  //

  private getData() {
    const config: IConfigDispositivoVeribox = this.form?.value;
    const data: IUpdateDispositivo = {
      config,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getData();
        await firstValueFrom(this.service.migrar(this.data._id, data));
        this.helper.notifSuccess('Migración Iniciada');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.config$?.unsubscribe();
  }
}
