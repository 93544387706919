import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IDispositivoNsp4G,
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteNSP,
  IResumenReporte,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import {
  PointOptionsObject,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesOptionsType,
  YAxisOptions,
} from 'highcharts';
import { ReportesService } from '../../modulos/reportes/service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';
import { IResumenReporteVeribox } from 'modelos/src/interfaces/gas/resumen-reportes/valores reporte/veribox';
// import { AMARILLO } from 'src/assets/estilos/colores';

// const colorDatos = AMARILLO;

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-min-max-prom',
  templateUrl: './grafico-min-max-prom.component.html',
  styleUrls: ['./grafico-min-max-prom.component.scss'],
})
export class GraficoMinMaxPromComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() unidadPresion?: IUnidadPresion;
  @Input() range?: { from: string; to: string };

  // Reportes Historicos
  public chart?: Highcharts.Options;
  public reportes: IReporte[] = [];
  public resumenReportes: IReporte[] = [];

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public reportesService: ReportesService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################

  private getChartOptions(
    series: SeriesOptionsType[],
    yAxis?: YAxisOptions | YAxisOptions[],
  ) {
    const options: Highcharts.Options = {
      chart: {
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
      },
      title: {
        align: 'left',
        text: undefined,
      },
      yAxis,
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: HelperService.esModoOscuro() ? 'white' : 'black',
          },
        },
      },
      legend: {
        // enabled: false,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          marker: {
            // enabled: false,
          },
          label: {
            connectorAllowed: false,
          },
        },
      },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    return options;
  }

  private getChartOptionsSimple(
    series: SeriesOptionsType[],
    text?: string,
    unidad?: string,
  ) {
    const dispositivo =
      this.puntoMedicion?.unidadPresion?.dispositivo ||
      this.unidadPresion?.dispositivo;
    const config = dispositivo?.config as IDispositivoNsp4G;

    const limiteMin = config?.limiteMin || 0;
    const limiteMax = config?.limiteMax || 0;

    const yAxis: YAxisOptions = {
      title: {
        text,
        style: {
          // color: colorDatos,
        },
      },
      // m³
      labels: {
        format: unidad ? `{value} ${unidad}` : '{value}',
        style: {
          color: HelperService.esModoOscuro() ? 'white' : 'black',
        },
      },
      min: 0,
      softMax: 3,
      // max: 10,
      plotLines: [
        {
          value: limiteMin,
          color: '#df5353',
          dashStyle: 'Dot',
          width: 3,
          label: {
            text: `Límite Mínimo ${limiteMin} ${unidad}`,
            style: {
              color: HelperService.esModoOscuro() ? 'white' : 'black',
            },
          },
        },
        {
          value: limiteMax,
          color: '#df5353',
          dashStyle: 'Dot',
          width: 3,
          label: {
            text: `Límite Máximo ${limiteMax} ${unidad}`,
            style: {
              // color: '#df5353',
              color: HelperService.esModoOscuro() ? 'white' : 'black',
            },
          },
        },
      ],
    };
    return this.getChartOptions(series, yAxis);
  }

  private async graficoHistorico(): Promise<void> {
    if (!this.reportes.length) {
      this.chart = undefined;
      return;
    }

    const seriesPresion: SeriesOptionsType[] = [];

    const reportes: IReporte[] = JSON.parse(JSON.stringify(this.reportes));

    // TODO: reemplazar por offset del establecimiento
    const timeZoneOffset = -3;
    const timeZoneOffsetMs = 1000 * 60 * 60 * timeZoneOffset;

    // Crea las series del grafico
    const dataPresionMin: PointOptionsObject[] = [];
    const dataPresionMax: PointOptionsObject[] = [];
    const dataPresionAvg: PointOptionsObject[] = [];

    const datosMin: { [timestamp: number]: number } = {};
    const datosMax: { [timestamp: number]: number } = {};
    const datosSum: {
      [timestamp: number]: { suma: number; cantidad: number };
    } = {};
    const datosAvg: { [timestamp: number]: number } = {};

    for (const reporte of reportes) {
      const valores = reporte.valores as IReporteNSP;
      let timestamp = valores?.timestamp
        ? new Date(valores?.timestamp).getTime()
        : new Date().getTime();
      timestamp += timeZoneOffsetMs;
      timestamp = timestamp - (timestamp % 86400000);
      const pres = valores?.presion || 0;
      const valorPresion = +pres?.toFixed(2);

      if (valorPresion !== -1) {
        const presionMinEncontrada = datosMin[timestamp];
        const presionMaxEncontrada = datosMax[timestamp];
        const presionSumEncontrada = datosSum[timestamp];

        // Actualiza el min
        if (
          presionMinEncontrada === undefined ||
          valorPresion < presionMinEncontrada
        ) {
          datosMin[timestamp] = valorPresion;
        }
        // Actualiza el max
        if (
          presionMaxEncontrada === undefined ||
          valorPresion > presionMaxEncontrada
        ) {
          datosMax[timestamp] = valorPresion;
        }
        // Suma
        if (presionSumEncontrada === undefined) {
          datosSum[timestamp] = { suma: valorPresion, cantidad: 1 };
        } else {
          datosSum[timestamp] = {
            suma: presionSumEncontrada.suma + valorPresion,
            cantidad: presionSumEncontrada.cantidad + 1,
          };
        }
      }
    }

    // Calcula el promedio
    for (const timestamp in datosSum) {
      const suma = datosSum[timestamp].suma;
      const cantidad = datosSum[timestamp].cantidad;
      datosAvg[timestamp] = +(suma / cantidad).toFixed(3);
    }

    for (const timestamp in datosMin) {
      const valor = datosMin[timestamp];
      dataPresionMin.push([+timestamp, valor]);
    }
    for (const timestamp in datosMax) {
      const valor = datosMax[timestamp];
      dataPresionMax.push([+timestamp, valor]);
    }
    for (const timestamp in datosAvg) {
      const valor = datosAvg[timestamp];
      dataPresionAvg.push([+timestamp, valor]);
    }

    const seriePresionMin: SeriesLineOptions = {
      type: 'line',
      lineWidth: 4,
      // fillColor: gradient,
      name: `Min.`,
      data: dataPresionMin,
      // color: colorDatos,
      marker: {
        enabled: true,
      },
      tooltip: {
        xDateFormat: '%d-%m-%Y',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const seriePresionMax: SeriesLineOptions = {
      type: 'line',
      lineWidth: 4,
      // fillColor: gradient,
      name: `Max.`,
      data: dataPresionMax,
      // color: colorDatos,
      marker: {
        enabled: true,
      },
      tooltip: {
        xDateFormat: '%d-%m-%Y',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const seriePresionAvg: SeriesColumnOptions = {
      type: 'column',
      name: `Prom.`,
      opacity: 0.5,
      data: dataPresionAvg,
      tooltip: {
        xDateFormat: '%d-%m-%Y',
        pointFormat: '<strong>{point.y}</strong>',
      },
      zIndex: -1,
    };

    seriesPresion.push(seriePresionMin, seriePresionMax, seriePresionAvg);

    this.chart = this.getChartOptionsSimple(seriesPresion, 'Presión', 'BAR');
  }

  private async graficoHistoricoResumen(): Promise<void> {
    if (!this.resumenReportes.length) {
      this.chart = undefined;
      return;
    }

    const seriesPresion: SeriesOptionsType[] = [];

    // Crea las series del grafico
    const dataPresionMin: PointOptionsObject[] = [];
    const dataPresionMax: PointOptionsObject[] = [];
    const dataPresionAvg: PointOptionsObject[] = [];

    for (const reporte of this.resumenReportes) {
      const valores = reporte.valores as IResumenReporteVeribox;

      const timestamp = valores?.timestamp
        ? new Date(valores?.timestamp).getTime()
        : new Date().getTime();

      const presionMin = valores?.presionMin || 0;
      const presionMax = valores?.presionMax || 0;
      const presionAvg = valores?.presionProm || 0;

      dataPresionMin.push([+timestamp, presionMin]);
      dataPresionMax.push([+timestamp, presionMax]);
      dataPresionAvg.push([+timestamp, +presionAvg.toFixed(3)]);
    }

    const seriePresionMin: SeriesLineOptions = {
      type: 'line',
      lineWidth: 4,
      // fillColor: gradient,
      name: `Min.`,
      data: dataPresionMin,
      // color: colorDatos,
      marker: {
        enabled: true,
      },
      tooltip: {
        xDateFormat: '%d-%m-%Y',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const seriePresionMax: SeriesLineOptions = {
      type: 'line',
      lineWidth: 4,
      // fillColor: gradient,
      name: `Max.`,
      data: dataPresionMax,
      // color: colorDatos,
      marker: {
        enabled: true,
      },
      tooltip: {
        xDateFormat: '%d-%m-%Y',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };
    const seriePresionAvg: SeriesColumnOptions = {
      type: 'column',
      name: `Prom.`,
      opacity: 0.5,
      data: dataPresionAvg,
      tooltip: {
        xDateFormat: '%d-%m-%Y',
        pointFormat: '<strong>{point.y}</strong>',
      },
      zIndex: -1,
    };

    seriesPresion.push(seriePresionMin, seriePresionMax, seriePresionAvg);

    this.chart = this.getChartOptionsSimple(seriesPresion, 'Presión', 'BAR');
  }

  // Listar

  private async listar(): Promise<void> {
    let filter: IFilter<IReporte> | boolean = false;

    if (this.unidadPresion && this.range) {
      filter = {
        idsAsignados: this.unidadPresion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }
    if (this.puntoMedicion && this.range) {
      filter = {
        idsAsignados: this.puntoMedicion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', query)
        .subscribe(async (data) => {
          console.log(`listado de reportes`, data);
          this.reportes = data.datos;

          await this.graficoHistorico();
        });
      await this.listadosService.getLastValue('reportes', query);
    }
  }

  private async listarResumen(): Promise<void> {
    let filter: IFilter<IReporte> | boolean = false;

    if (this.unidadPresion && this.range) {
      filter = {
        idsAsignados: this.unidadPresion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }
    if (this.puntoMedicion && this.range) {
      filter = {
        idsAsignados: this.puntoMedicion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
      } as any;
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IResumenReporte>>('resumenReportes', query)
        .subscribe(async (data) => {
          console.log(`listado de resumenReportes`, data);
          this.resumenReportes = data.datos;

          await this.graficoHistoricoResumen();
        });
      await this.listadosService.getLastValue('resumenReportes', query);
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listarResumen()]);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
