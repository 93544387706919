<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Última Presión
      </h1>
    </div>
  </div>

  <div
    class="transparente"
    style="
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    "
  >
    <app-chart
      *ngIf="chartPresionActual"
      [options]="chartPresionActual"
      style="width: 100%; max-height: 400px; max-width: 400px; display: block"
    >
    </app-chart>
    <div style="translate: 0 -30px">
      {{
        unidadPresion?.ultimoRegistro?.valores?.timestamp
          | date: "dd/MM/yyyy HH:mm"
      }}
    </div>
  </div>
</div>
