<div class="container">
  <img [src]="logo" alt="horatech" class="logo" />

  <form
    id="form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="form-container"
  >
    <h2>Recuperar Contraseña</h2>
    <mat-form-field class="input" appearance="outline">
      <mat-label>Email</mat-label>
      <mat-icon matPrefix>email</mat-icon>
      <input type="text" matInput formControlName="email" />
      @if (form.get("email")?.invalid) {
        <mat-error> El email ingresado es inválido</mat-error>
      }
    </mat-form-field>

    <div class="row">
      <!-- <mat-checkbox>
          <span class="checkbox-text">Recordarme</span>
        </mat-checkbox> -->
      <div style="width: 55px; height: 55px">
        <mat-icon
          svgIcon="claro-oscuro"
          (click)="toggleTheme()"
          style="cursor: pointer"
          inline
        ></mat-icon>
      </div>
      <span
        class="checkbox-text"
        routerLink="/login"
        style="cursor: pointer"
        tabindex="-1"
      >
        Iniciar Sesión
      </span>
    </div>
    <!--  -->
    <button
      mat-flat-button
      class="login-button"
      [disabled]="!form.valid || this.loading.getLoading()"
      type="submit"
      form="form"
    >
      RECUPERAR
    </button>
  </form>
</div>
