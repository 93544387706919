import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IDispositivo, IMedidorResidencial, IPuntoMedicion } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { UnidadPresionsService } from 'src/app/modulos/unidad-presion/service';
import { ConfigDispositivosService } from 'src/app/modulos/dispositivos/config-dispositivos.service';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-sml',
  templateUrl: './detalles-sml.component.html',
  styleUrls: ['./detalles-sml.component.scss'],
})
export class DetallesSMLComponent implements OnInit, OnChanges {
  @Input() punto?: IPuntoMedicion;
  @Input() medidor?: IMedidorResidencial;

  public dispositivo?: IDispositivo;

  constructor(
    public helper: HelperService,
    public service: UnidadPresionsService,
    public listadosService: ListadosService,
    public configsService: ConfigDispositivosService,
    public dialog: DialogService,
  ) {}

  ngOnChanges() {
    this.dispositivo =
      this.punto?.medidorResidencial?.dispositivo || this.medidor?.dispositivo;

    console.log('this.dispositivo', this.dispositivo);
  }

  async ngOnInit() {}
}
