import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CentroOperativosRoutingModule } from './centroOperativos.routing.module';
import { CrearEditarCentroOperativosComponent } from './crear-editar-centro-operativos/crear-editar-centro-operativos.component';
import { ListadoCentroOperativosComponent } from './listado-centro-operativos/listado-centro-operativos.component';

@NgModule({
  declarations: [
    CrearEditarCentroOperativosComponent,
    ListadoCentroOperativosComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, CentroOperativosRoutingModule],
})
export class CentroOperativosModule {}
