import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import * as moment from 'moment';
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
  MatMenuTrigger,
} from '@angular/material/menu';

export interface IRangePreset {
  label?: string;
  from: string;
  to: string;
}
@Component({
  selector: 'app-datetime-range-picker',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    NgxMatTimepickerModule,
    MatMenuModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'shadow' },
    },
  ],
})
export class DatetimeRangePickerComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @ViewChild('fromCalendar') fromCalendar?: MatCalendar<moment.Moment>;
  @ViewChild('toCalendar') toCalendar?: MatCalendar<moment.Moment>;

  @Input() range?: { from: string; to: string };
  @Output() rangeChange = new EventEmitter<{ from: string; to: string }>();

  @Input() presets: IRangePreset[] = [];

  public from?: moment.Moment;
  public to?: moment.Moment;
  public fromHour?: string;
  public toHour?: string;

  constructor() {}

  public cambioFrom(fechaFrom: moment.Moment) {
    if (this.fromHour) {
      const hour = +this.fromHour.slice(0, 2);
      const minutes = +this.fromHour.slice(3, 5);
      fechaFrom.set({ hour, minutes });
    }

    if (this.to) {
      if (fechaFrom > this.to) {
        this.to = undefined;
      }
    }
    this.fromCalendar?.updateTodaysDate();
  }

  public cambioTo(fechaTo: moment.Moment) {
    if (this.toHour) {
      const hour = +this.toHour.slice(0, 2);
      const minutes = +this.toHour.slice(3, 5);
      fechaTo.set({ hour, minutes });
    }
    this.toCalendar?.updateTodaysDate();
  }

  public cambioHoraFrom(horaFrom: string) {
    this.from = moment(`${this.from?.format('YYYY-MM-DD')} ${horaFrom}`);
  }

  public cambioHoraTo(horaTo: string) {
    this.to = moment(`${this.to?.format('YYYY-MM-DD')} ${horaTo}`);
  }

  public cambioPreset(preset: IRangePreset) {
    this.from = moment(preset.from);
    this.to = moment(preset.to);
    this.toHour = this.getHour(new Date(preset.to));
    this.fromHour = this.getHour(new Date(preset.from));
    this.fromCalendar?.updateTodaysDate();
    this.toCalendar?.updateTodaysDate();
  }

  public dateClassFrom() {
    return (date: Date): string => {
      const from = this.from?.toDate();
      const to = this.to?.toDate();
      const highlightDate = date > from! && date < to!;
      return highlightDate ? 'special-date' : '';
    };
  }
  public dateClassTo() {
    return (date: Date): string => {
      const from = this.from?.toDate();
      const to = this.to?.toDate();
      const highlightDate = date > from! && date < to!;
      return highlightDate ? 'special-date' : '';
    };
  }

  //

  public aplicar() {
    const range = {
      from: this.from?.toISOString()!,
      to: this.to?.toISOString()!,
    };
    this.range = range;
    this.rangeChange.emit(range);
    this.trigger?.closeMenu();
  }

  public cancelar() {
    this.trigger?.closeMenu();
  }

  //
  private fechaHaceDias(dias: number, hora?: string): string {
    const fecha = new Date();
    fecha.setUTCHours(3, 0, 0, 0);

    if (hora) {
      const hour = +hora.slice(0, 2);
      const minutes = +hora.slice(3, 5);
      fecha.setHours(hour, minutes, 0, 0);
    }

    fecha.setDate(fecha.getDate() - dias);
    return fecha.toISOString();
  }

  private presetsIniciales() {
    const horaFrom = this.fromHour;
    const horaTo = this.toHour;

    this.presets = [
      {
        label: 'Últimas 24 horas',
        from: this.fechaHaceDias(1, horaFrom),
        to: this.fechaHaceDias(0, horaTo),
      },
      {
        label: 'Últimas 48 horas',
        from: this.fechaHaceDias(2, horaFrom),
        to: this.fechaHaceDias(0, horaTo),
      },
      {
        label: 'Últimos 7 días',
        from: this.fechaHaceDias(7, horaFrom),
        to: this.fechaHaceDias(0, horaTo),
      },
      {
        label: 'Últimos 30 días',
        from: this.fechaHaceDias(30, horaFrom),
        to: this.fechaHaceDias(0, horaTo),
      },
    ];
  }
  //

  private getHour(date: Date): string {
    const hour = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    return `${hour}:${minutes}`;
  }

  ngOnInit() {
    if (this.range) {
      this.fromHour = this.range.from.slice(11, 16);
      this.toHour = this.range.to.slice(11, 16);
    }

    if (!this.presets.length) {
      this.presetsIniciales();
    }

    if (!this.range) {
      this.range = {
        from: this.fechaHaceDias(7),
        to: this.fechaHaceDias(0),
      };
    }

    const from = new Date(this.range.from);
    this.from = moment(this.range.from);
    this.fromHour = this.getHour(from);

    const to = new Date(this.range.to);
    this.to = moment(this.range.to);
    this.toHour = this.getHour(to);
  }
}
