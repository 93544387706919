import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  IPuntoMedicion,
  ICreatePuntoMedicion,
  IUpdatePuntoMedicion,
  IUpdateCorrectora,
  ICorrectora,
  IUnidadPresion,
  IUpdateUnidadPresion,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';
import * as saveAs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class PuntosMedicionService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IPuntoMedicion>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/puntosDeMedicion`, { params });
  }

  public crear(dato: ICreatePuntoMedicion): Observable<IPuntoMedicion> {
    return this.http.post(`/puntosDeMedicion`, dato);
  }

  public listarPorId(id: string): Observable<IPuntoMedicion> {
    return this.http.get(`/puntosDeMedicion/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdatePuntoMedicion,
  ): Observable<IPuntoMedicion> {
    return this.http.put(`/puntosDeMedicion/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/puntosDeMedicion/${id}`);
  }

  public editarCorrectora(
    id: string,
    dato: IUpdateCorrectora,
  ): Observable<ICorrectora> {
    return this.http.put(`/correctoras/${id}`, dato);
  }

  public editarPresion(
    id: string,
    dato: IUpdateUnidadPresion,
  ): Observable<IUnidadPresion> {
    return this.http.put(`/unidadpresions/${id}`, dato);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve) => {
      const params = HelperService.getQueryParams(queryParams);

      this.http
        .get(`/puntosDeMedicion/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'puntos.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }

  public getNombreSimilar(nombre: string): Observable<{
    nombre: string;
    distancia: number;
  }> {
    const params = { nombre };

    return this.http.get(`/puntosDeMedicion/similar`, { params });
  }
}
