<div style="height: 100%; display: flex; flex-direction: column">
  <div class="titulo">
    <h1
      style="
        font-size: 18px;
        font-weight: 900;
        padding: 0.5em 0 0.5em 0.5em;
        margin: 0;
      "
    >
      Último Reporte
    </h1>
  </div>

  <div class="column-center" style="justify-content: center; height: 100%">
    <!-- Ultimo registro presion -->
    <table style="width: 100%">
      <!-- Fecha -->
      <tr>
        <th>Fecha</th>
        <td>
          <span style="white-space: nowrap">
            {{ (valores?.timestamp | date: "short") || "..." }}
          </span>
        </td>
      </tr>
      <!-- Presión -->
      <tr *ngIf="valores?.consumo !== -1">
        <th>Consumo acumulado</th>
        <td>{{ valores?.consumo | number }} m3</td>
      </tr>
      <!-- Bateria -->
      <tr *ngIf="valores?.bateria">
        <th>Batería</th>
        <td>{{ valores?.bateria | number }} V.</td>
      </tr>
    </table>
  </div>
</div>
