<div class="container">
  <img [src]="logo" alt="horatech" class="logo" />

  <form
    id="loginForm"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="form-container"
  >
    <h2>Plataforma de Utilities</h2>
    <mat-form-field class="input" appearance="outline">
      <mat-label>Usuario</mat-label>
      <mat-icon matPrefix>person</mat-icon>
      <input
        type="text"
        matInput
        formControlName="username"
        autocomplete="username"
      />
    </mat-form-field>

    <mat-form-field class="input" appearance="outline">
      <mat-label>Contraseña</mat-label>
      <mat-icon matPrefix>lock</mat-icon>
      <input
        type="password"
        matInput
        formControlName="password"
        autocomplete="current-password"
      />
    </mat-form-field>

    <div class="row">
      <mat-checkbox>
        <span class="checkbox-text">Recordarme</span>
      </mat-checkbox>
      <div style="width: 55px; height: 55px">
        <mat-icon
          svgIcon="claro-oscuro"
          (click)="toggleTheme()"
          style="cursor: pointer"
          inline
        ></mat-icon>
      </div>
      <span class="checkbox-text" routerLink="forgot" style="cursor: pointer">
        Olvidé mi contraseña
      </span>
    </div>
    <button
      mat-flat-button
      class="login-button"
      [disabled]="!form.pristine && (!form.valid || this.loading.getLoading())"
      type="submit"
      form="loginForm"
    >
      INGRESAR
    </button>
  </form>
</div>
