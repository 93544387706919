import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  IAlerta,
  IReporteNSP,
  IReporteVeribox,
  IUnidadPresion,
} from 'modelos/src';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-ultimo-reporte-unidad-presion',
  templateUrl: './ultimo-reporte-unidad-presion.component.html',
  styleUrls: ['./ultimo-reporte-unidad-presion.component.scss'],
})
export class UltimoReporteUnidadPresionComponent implements OnInit {
  @Input() unidadPresion?: IUnidadPresion;

  public ultimoRegistro?: IReporteNSP | IReporteVeribox;
  public ultimaAlerta?: IAlerta;

  ngOnInit(): void {
    this.ultimoRegistro = this.unidadPresion?.ultimoRegistro?.valores;
    this.ultimaAlerta = this.unidadPresion?.ultimaAlerta;
  }
}
