import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListadoDispositivosComponent } from './listado-dispositivos/listado-dispositivos.component';

const routes: Routes = [
  {
    path: '',
    component: ListadoDispositivosComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DispositivosRoutingModule {}
