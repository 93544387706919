import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ICorrectora,
  IFilter,
  IListado,
  IMantenimiento,
  IPuntoMedicion,
  IQueryParam,
} from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { CorrectorasService } from 'src/app/modulos/correctoras/correctoras.service';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CrearEditarCorrectorasComponent } from '../../modulos/correctoras/crear-editar-correctoras/crear-editar-correctoras.component';
import { CrearEditarMantenimientoComponent } from '../mantenimientos/crear-editar-mantenimiento/crear-editar-mantenimiento.component';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-correctora',
  templateUrl: './detalles-correctora.component.html',
  styleUrls: ['./detalles-correctora.component.scss'],
})
export class DetallesCorrectoraComponent implements OnInit {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() correctora?: ICorrectora;

  public mantenimiento?: IMantenimiento;
  public mantenimientos$?: Subscription;

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
    public service: CorrectorasService,
    private listados: ListadosService,
  ) {}

  public async editarCorrectora(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarCorrectorasComponent, config);
  }

  public async crearMantenimiento(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data: { correctora: data },
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMantenimientoComponent, config);
  }

  public async listarUltimoMantenimiento() {
    const filter: IFilter<IMantenimiento> = {
      idAsignado: this.correctora?._id,
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      limit: 1,
      sort: '-fechaCreacion',
    };
    this.mantenimientos$?.unsubscribe();
    this.mantenimientos$ = this.listados
      .subscribe<IListado<IMantenimiento>>('mantenimientos', query)
      .subscribe((data) => {
        this.mantenimiento = data.datos[0];
        console.debug('listado de mantenimientos', data);
      });
    await this.listados.getLastValue('mantenimientos', query);
  }

  async ngOnInit(): Promise<void> {
    await this.listarUltimoMantenimiento();
  }
}
