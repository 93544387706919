import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IConfigDispositivoVeribox,
  ICreateConfigDispositivo,
  IDispositivo,
  IDispositivoVeriboxMicro,
  IUnidadPresion,
} from 'modelos/src';
import { firstValueFrom } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { UnidadPresionsService } from 'src/app/modulos/unidad-presion/service';
import { ConfigDispositivosService } from 'src/app/modulos/dispositivos/config-dispositivos.service';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { CambiarTelefonoComponent } from '../../modulos/unidad-presion/cambiar-telefono/cambiar-telefono.component';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ConfigDispositivosVeriboxComponent } from '../config-dispositivos-veribox/config-dispositivos-veribox.component';
import { MigrarDispositivosVeriboxComponent } from '../../modulos/dispositivos/migrar-dispositivos-veribox/migrar-dispositivos-veribox.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-veribox',
  templateUrl: './detalles-veribox.component.html',
  styleUrls: ['./detalles-veribox.component.scss'],
})
export class DetallesVeriboxComponent implements OnInit, OnChanges {
  @Input() unidadPresion?: IUnidadPresion;
  @Input() configActual?: IConfigDispositivoVeribox;
  @Input() configDeseada?: IConfigDispositivoVeribox;
  @Input() fechaCreacion?: string;

  public configVencida?: boolean;
  public configAplicada?: boolean;

  public frec: { [min: number]: string } = {
    480: '3 / Día',
    720: '2 / Día',
  };

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
    public service: UnidadPresionsService,
    public listadosService: ListadosService,
    public configsService: ConfigDispositivosService,
    public dialog: DialogService,
  ) {}

  public async configurarVeribox(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigDispositivosVeriboxComponent, config);
  }

  public puedeMigrar() {
    const estado = this.unidadPresion?.estadoActual;
    const config = this.unidadPresion?.dispositivo
      ?.config as IDispositivoVeriboxMicro;
    const ultimoReporte = this.unidadPresion?.ultimoRegistro;
    if (!this.helper.puedeVerDispositivos()) {
      return false;
    }
    if (estado === 'Sin Comunicación') {
      return true;
    }
    if (!ultimoReporte && !config?.fechaMigrar) {
      return true;
    }
    return false;
  }

  public async migrarVeribox(data?: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(MigrarDispositivosVeriboxComponent, config);
  }

  public async cambiarTelefono(data?: IUnidadPresion) {
    const config: MatDialogConfig = {
      data: data?.dispositivo,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CambiarTelefonoComponent, config);
  }

  public async Reenviar(): Promise<void> {
    const res = await this.dialog.confirm(
      'Reenviar configuración',
      '¿Está seguro que desea reenviar la configuración?',
    );
    if (res) {
      try {
        const config: IConfigDispositivoVeribox = this.configDeseada || {};
        delete config.fechaAplicacion;
        const data: ICreateConfigDispositivo = {
          deveui: this.unidadPresion?.dispositivo?.deveui,
          config,
        };
        if (data) {
          await firstValueFrom(this.configsService.upsert(data, true));
          this.helper.notifSuccess('Configuración actualizada');
        }
      } catch (err) {
        console.error(err);
        this.helper.notifError(err);
      }
    }
  }

  private verificarConfigAplicada() {
    const configActual = this.configActual as IConfigDispositivoVeribox;
    const configDeseada = this.configDeseada as IConfigDispositivoVeribox;

    // console.log(`**********************`);
    // console.log(`configActual`, configActual);
    // console.log(`configDeseada`, configDeseada);

    if (this.configDeseada) {
      if (
        configDeseada?.apn !== configActual?.apn ||
        configDeseada?.limiteMax?.toString() !==
          configActual?.limiteMax?.toString() ||
        configDeseada?.limiteMin?.toString() !==
          configActual?.limiteMin?.toString() ||
        configDeseada?.clave !== configActual?.clave ||
        configDeseada?.frecuenciaComunicacion?.toString() !==
          configActual?.frecuenciaComunicacion?.toString() ||
        configDeseada?.usuario !== configActual?.usuario
      ) {
        this.configAplicada = false;

        if (this.fechaCreacion) {
          const fechaVencimiento = new Date();
          fechaVencimiento.setDate(fechaVencimiento.getDate() - 3);
          const fechaConfig = new Date(this.fechaCreacion);
          this.configVencida = fechaConfig < fechaVencimiento;
        }
      } else {
        this.configAplicada = true;
      }
    } else {
      this.configAplicada = undefined;
    }

    // console.log(`configAplicada`, this.configAplicada);
    // console.log(`**********************`);
  }

  ngOnChanges() {
    this.verificarConfigAplicada();
  }

  async ngOnInit() {
    this.verificarConfigAplicada();
  }
}
