<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Última Cromatografía
      </h1>
    </div>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <!-- Ultima croma -->
    <ng-container *ngIf="correctora?.ultimaCromatografia">
      <!-- Fechas -->
      <table style="padding: 0 1em 0 1em">
        <tr>
          <th>Fecha</th>
          <td>
            {{
              (correctora?.fechaUltimaCromatografia | date: "shortDate") ||
                "..."
            }}
          </td>
        </tr>
        <tr>
          <th>Fecha de Vencimiento</th>
          <td>
            {{
              (correctora?.ultimaCromatografia?.fechaVencimiento
                | date: "shortDate") || "..."
            }}
          </td>
        </tr>
      </table>

      <mat-divider
        class="divider"
        style="margin: 1em 1em 1em 1em"
      ></mat-divider>

      <!-- Elementos -->
      <table style="width: 100%">
        <tr>
          <td>
            <table>
              <tr>
                <th>Densidad</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.densidad
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>Metano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.metano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>Nitrógeno</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.nitrogeno
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>Dioxido de Carbono</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.dioxidoCarbono
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>Etano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.etano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>Propano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.propano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>Oxígeno</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.oxigeno
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table>
              <tr>
                <th>Iso Butano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.isoButano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>nButano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.nButano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>Iso Pentano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.isoPentano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>nPentano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.nPentano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>nHexano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.nHexano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>nHeptano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.nHeptano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
              <tr>
                <th>nOctano</th>
                <td style="text-align: end">
                  <span *ngIf="correctora?.ultimaCromatografia?.elementos">
                    {{
                      correctora?.ultimaCromatografia?.elementos?.nOctano
                        | number: "0.3-3"
                    }}
                  </span>
                  <span *ngIf="!correctora?.ultimaCromatografia?.elementos">
                    ...
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </ng-container>

    <!-- Si no hay croma -->
    <div
      *ngIf="!correctora?.ultimaCromatografia"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <h3>
        <strong> No hay cromatografía </strong>
      </h3>
    </div>
  </div>
</div>
