import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IPuntoMedicion } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { CrearEditarPuntosMedicionComponent } from '../../modulos/puntos-medicion/crear-editar-puntos-medicion/crear-editar-puntos-medicion.component';
import { NavigationService } from 'src/app/modulos/navigation/navigation.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IconoEstadoComponent } from '../icono-estado/icono-estado.component';
import { IColumnas } from '../../auxiliares/tabla/tabla.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule, IconoEstadoComponent],
  selector: 'app-resumen-punto-medicion',
  templateUrl: './resumen-punto-medicion.component.html',
  styleUrls: ['./resumen-punto-medicion.component.scss'],
})
export class ResumenPuntoMedicionComponent implements OnInit {
  @Input() puntoMedicion?: IPuntoMedicion;
  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptions),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {};

  //// Tabla nueva
  public name = 'ResumenPuntoMedicionComponent_tablaResumen';
  public datos?: any[] = [];
  public columnas?: IColumnas<any>[];
  public totalCount = 0;

  constructor(
    public helper: HelperService,
    public matDialog: MatDialog,
    private navigationService: NavigationService,
  ) {}

  public async editar(data?: IPuntoMedicion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public abrirEnBrowser(punto: IPuntoMedicion) {
    const c = punto.ubicacion;
    if (!c) return;
    const url = `https://www.google.com/maps/search/?api=1&query=${c.lat},${c.lng}`;
    window.open(url, '_blank');
  }

  private cambioTheme(oscuro: boolean) {
    const options = oscuro
      ? HelperService.mapOptionsDark
      : HelperService.mapOptionsLight;
    this.mapOptions = JSON.parse(JSON.stringify(options));
  }

  private initMap() {
    this.mapOptions.mapTypeControl = false;
    this.mapOptions.zoomControl = false;
    this.mapOptions.fullscreenControl = true;
    this.mapOptions.zoom = 15;
    if (
      this.puntoMedicion?.ubicacion?.lat &&
      this.puntoMedicion?.ubicacion?.lng
    ) {
      this.mapCenter = {
        lat: this.puntoMedicion.ubicacion.lat,
        lng: this.puntoMedicion.ubicacion.lng,
      };
    }
    this.markerOptions = {
      position: this.mapCenter,
    };
    this.navigationService.modoOscuro$.subscribe({
      next: (v: boolean) => this.cambioTheme(v),
    });
  }

  // Tabla

  private parsePuntoMedicion(p: IPuntoMedicion) {
    const division = p.division;
    const nombre = p.nombre;
    const descipcion = p.descripcion;
    const simec = p.codigoSimec;

    let html = `
    <div class="flex-column mobile-align-right">
      <div> ${nombre} </div>
    `;

    if (descipcion) {
      html += `<div> ${descipcion} </div>`;
    } else {
      html += `<div class="no-info">Sin descripción</div>`;
    }

    if (division === 'Correctoras') {
      if (simec) {
        html += `<div> ${simec} </div>`;
      } else {
        html += `<div class="no-info">Sin código SIMEC</div>`;
      }
    }

    html += '</div>';

    return html;
  }

  private parseUnidadNegocio(p: IPuntoMedicion) {
    const division = p.division;
    const unidadNegocio = p.unidadNegocio?.nombre;
    const centroOperativo = p.centroOperativo?.nombre;
    const cuenca = p.cuenca?.nombre;
    const grupos = p.grupos?.map((g) => g.nombre).join(', ');
    const agrupaciones = p.agrupaciones?.map((a) => a.nombre).join(', ');

    let html = `<div class="flex-column mobile-align-right">`;

    if (unidadNegocio) {
      html += `<div> ${unidadNegocio} </div>`;
    } else {
      html += `<div class="no-info">Sin unidad de negocio</div>`;
    }

    if (centroOperativo) {
      html += `<div> ${centroOperativo} </div>`;
    } else {
      html += `<div class="no-info">Sin centro operativo</div>`;
    }

    if (division === 'Correctoras') {
      if (cuenca) {
        html += `<div> ${cuenca} </div>`;
      } else {
        html += `<div class="no-info">Sin cuenca</div>`;
      }
    }

    if (grupos) {
      html += `<div> ${grupos} </div>`;
    }

    if (agrupaciones) {
      html += `<div> ${agrupaciones} </div>`;
    }

    html += '</div>';

    return html;
  }

  private parseDireccion(p: IPuntoMedicion) {
    const direccion = p.direccion;
    const localidad = p.localidad;

    let html = `<div class="flex-column mobile-align-right">`;

    if (direccion) {
      html += `<div> ${direccion} </div>`;
    } else {
      html += `<div class="no-info">Sin direccion</div>`;
    }

    if (localidad) {
      html += `<div> ${localidad} </div>`;
    } else {
      html += `<div class="no-info">Sin localidad</div>`;
    }

    html += '</div>';

    return html;
  }

  private setColumnas() {
    if (!this.puntoMedicion || this.columnas?.length) {
      return;
    }

    const columnas: IColumnas<any>[] = [
      {
        header: {
          label: 'Estado',
        },
        row: {
          icono: (dato) => this.helper.iconEstadoPuntoMedicion(dato),
        },
      },
      {
        header: { label: 'Punto de Medición' },
        row: {
          html: (dato) => this.parsePuntoMedicion(dato),
        },
      },
      {
        header: { label: 'Unidad de Negocio' },
        row: {
          html: (dato) => this.parseUnidadNegocio(dato),
        },
      },
      {
        header: { label: 'Dirección' },
        row: {
          html: (dato) => this.parseDireccion(dato),
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              oculta: (dato) => !this.helper.puedeEditarPuntosDeMedicion(dato),
              click: (dato) => this.editar(dato),
            },

            {
              tipo: 'img',
              icon: 'assets/iconos/directions.png',
              tooltip: 'Abrir en Maps',
              click: async (dato) => this.abrirEnBrowser(dato),
            },
          ],
        },
      },
    ];

    this.columnas = columnas;
  }

  ngOnInit(): void {
    this.initMap();
    this.setColumnas();
    this.datos = [this.puntoMedicion];
  }
}
