import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { NavigationRoutingModule } from './navigation-routing.module';
import { NotificacionesModule } from '../notificaciones/module';
import { CambiarPasswordModule } from '../cambiar-password/cambiar-password.module';
import { UsuarioComponent } from './usuario/usuario.component';
import { SpinnerComponent } from 'src/app/standalone/spinner/spinner.component';
import { PushNotificationsService } from 'src/app/auxiliares/push-notifications.service';

@NgModule({
  declarations: [NavigationComponent, UsuarioComponent],
  imports: [
    CommonModule,
    LayoutModule,
    AuxiliaresModule,
    NavigationRoutingModule,
    NotificacionesModule,
    CambiarPasswordModule,
    SpinnerComponent,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {
  constructor(public pushNotificationsService: PushNotificationsService) {}
}
