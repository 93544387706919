import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  IMedidorResidencial,
  IPuntoMedicion,
  IReporteSML,
  IReporteWRC,
} from 'modelos/src';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-ultimo-reporte-medidor-residencial',
  templateUrl: './ultimo-reporte-medidor-residencial.component.html',
  styleUrls: ['./ultimo-reporte-medidor-residencial.component.scss'],
})
export class UltimoReporteMedidorResidencialComponent
  implements OnInit, OnChanges
{
  @Input() punto?: IPuntoMedicion;
  @Input() medidor?: IMedidorResidencial;

  public valores?: IReporteSML | IReporteWRC;

  ngOnChanges(): void {
    const reporte =
      this.punto?.medidorResidencial?.ultimoReporte ||
      this.medidor?.ultimoReporte;
    this.valores = reporte?.valores;
  }

  ngOnInit(): void {}
}
