<h2 mat-dialog-title>Teléfono</h2>
<mat-dialog-content class="mat-typography">
  <h3>Tu usuario no tiene teléfono</h3>
  <mat-form-field style="width: 100%">
    <mat-label>Teléfono</mat-label>
    <input matInput [(ngModel)]="tel" (ngModelChange)="checkTel()" />
    @if (!telValido) {
      <mat-hint>Ingresá el número de teléfono sin el 0 ni el 15</mat-hint>
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin: 0 0.3em 0 0.3em">
  <!-- <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
  <button
    mat-raised-button
    type="buton"
    color="accent"
    fxFlex
    [disabled]="!telValido"
    (click)="cambiarTelefono()"
  >
    GUARDAR
  </button>
  <button
    mat-raised-button
    type="button"
    fxFlex
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Volver
  </button>
</mat-dialog-actions>
