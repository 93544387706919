import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { AgrupacionsRoutingModule } from './agrupacion.routing.module';
import { CrearEditarAgrupacionsComponent } from './crear-editar-agrupacion/crear-editar-agrupacion.component';
import { ListadoAgrupacionsComponent } from './listado-agrupacion/listado-agrupacion.component';

@NgModule({
  declarations: [CrearEditarAgrupacionsComponent, ListadoAgrupacionsComponent],
  imports: [CommonModule, AuxiliaresModule, AgrupacionsRoutingModule],
})
export class AgrupacionsModule {}
