<div style="height: 100%; display: flex; flex-direction: column">
  <!-- "VERIBOX MICRO"-->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        {{ dispositivo?.tipoDispositivo }}
      </h1>
    </div>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <table style="width: 100%">
      <!-- Device Name -->
      <tr>
        <th>Device</th>
        <td style="text-align: end">
          {{ dispositivo?.deviceName || "..." }}
        </td>
      </tr>
      <!-- Deveui -->
      <tr>
        <th>DevEUI</th>
        <td style="text-align: end">
          <span>
            {{ dispositivo?.deveui || "..." }}
          </span>
        </td>
      </tr>
      <!-- Ultima Comunicacion -->
      <tr>
        <th>Última Comunicación</th>
        <td style="text-align: end">
          {{ dispositivo?.fechaUltimaComunicacion | date: "short" || "..." }}
        </td>
      </tr>
    </table>
  </div>
</div>
