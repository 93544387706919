/* eslint-disable prefer-const */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICuenca,
  IListado,
  IQueryParam,
  IGrupo,
  IFilter,
  IMedidorResidencialAgua,
  IUpdateMedidorResidencialAgua,
  IReporteWRC,
  IReporteSML,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { GeocodeService } from '../../../auxiliares/geocode.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { MedidorResidencialesAguaService } from '../medidores-residenciales-agua.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar-medidores-residenciales-agua',
  templateUrl: './crear-editar-medidores-residenciales-agua.component.html',
  styleUrls: ['./crear-editar-medidores-residenciales-agua.component.scss'],
})
export class CrearEditarMedidorResidencialAguaComponent
  implements OnInit, OnDestroy
{
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public grupos: IGrupo[] = [];
  public cuencas: ICuenca[] = [];

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptionsEditor),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  // Listado Continuo
  public grupos$?: Subscription;
  public cuencas$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMedidorResidencialAgua,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarMedidorResidencialAguaComponent>,
    private service: MedidorResidencialesAguaService,
    private listadosService: ListadosService,
    private geocodeService: GeocodeService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Medidor Residencial de Agua'
      : 'Crear Medidor Residencial de Agua';

    const ubicacionGps = this.fb.group({
      lat: [this.data?.ubicacionGps?.lat, Validators.required],
      lng: [this.data?.ubicacionGps?.lng, Validators.required],
    });

    const valores = this.data.ultimoReporte?.valores as
      | IReporteWRC
      | IReporteSML;

    this.form = this.fb.group({
      nombre: [this.data?.nombre],
      idUnidadNegocio: [this.data?.idUnidadNegocio],
      idCentroOperativo: [this.data?.idCentroOperativo],
      idCuenca: [this.data?.idCuenca],
      idsGrupos: [this.data?.idsGrupos || []],
      direccion: [this.data?.direccion],
      localidad: [this.data?.localidad],
      ubicacionGps,
      consumoInicial: [this.data?.consumoInicial || 0],
      consumoActual: [valores?.consumo],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  // Consumos

  public consumoInicialChange() {
    const valor = this.form?.get('consumoInicial')?.value;
    const valores = this.data.ultimoReporte?.valores as
      | IReporteWRC
      | IReporteSML;
    const consumo = valores?.consumo || 0;
    this.form?.get('consumoActual')?.patchValue(+(valor + consumo).toFixed(3));
  }

  public consumoActualChange() {
    const valor = this.form?.get('consumoActual')?.value;
    const valores = this.data.ultimoReporte?.valores as
      | IReporteWRC
      | IReporteSML;
    const consumo = valores?.consumo || 0;
    this.form?.get('consumoInicial')?.patchValue(+(valor - consumo).toFixed(3));
  }

  // Mapa

  public async selectDireccion(direccion: string) {
    this.form?.patchValue({ direccion });
    const coordenadas = await this.geocodeService.getCoordinates(direccion);
    if (coordenadas) {
      this.markerOptions = {
        position: coordenadas,
        draggable: true,
      };
      this.mapCenter = coordenadas;
      this.mapOptions.zoom = 16;
      this.form?.get('ubicacionGps')?.patchValue({ lat: coordenadas.lat });
      this.form?.get('ubicacionGps')?.patchValue({ lng: coordenadas.lng });
    }
  }

  public setLocation(event: google.maps.MapMouseEvent) {
    const coords = event.latLng?.toJSON();
    if (coords) {
      this.form?.get('ubicacionGps')?.patchValue({ lat: coords.lat });
      this.form?.get('ubicacionGps')?.patchValue({ lng: coords.lng });
    }
  }

  private async initMap() {
    // Setear posicion inciial
    if (!this.data?.ubicacionGps?.lat) {
      const currPos = await HelperService.getCurrentPosition();
      this.form?.get('ubicacionGps')?.patchValue({ lat: currPos.lat || -35 });
      this.form?.get('ubicacionGps')?.patchValue({ lng: currPos.lng || -57 });
    }
    // Definir mapa
    this.mapCenter = {
      lat: this.form?.get('ubicacionGps')?.get('lat')?.value,
      lng: this.form?.get('ubicacionGps')?.get('lng')?.value,
    };
    // Definir marcador
    this.markerOptions.position = this.mapCenter;
  }

  //

  private getUpdateData() {
    const data: IUpdateMedidorResidencialAgua = {
      nombre: this.form?.get('nombre')?.value,
      idCuenca: this.form?.get('idCuenca')?.value,
      idsGrupos: this.form?.get('idsGrupos')?.value,
      direccion: this.form?.get('direccion')?.value,
      localidad: this.form?.get('localidad')?.value,
      ubicacionGps: {
        lat: this.form?.get('ubicacionGps')?.get('lat')?.value,
        lng: this.form?.get('ubicacionGps')?.get('lng')?.value,
      },
      consumoInicial: this.form?.get('consumoInicial')?.value,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarGrupos(): Promise<void> {
    // Filtro
    let filter: IFilter<any> = {};
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    if (idUnidadNegocio) {
      filter.idUnidadNegocio = idUnidadNegocio;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private async listarCuencas(): Promise<void> {
    // Filtro
    let filter: IFilter<any> = {};
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    if (idUnidadNegocio) {
      filter.idUnidadNegocio = idUnidadNegocio;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<ICuenca>>('cuencas', query)
      .subscribe((data) => {
        this.cuencas = data.datos;
        console.log(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.initMap();
    await Promise.all([this.listarCuencas(), this.listarGrupos()]);
  }

  ngOnDestroy(): void {
    this.grupos$?.unsubscribe();
    this.cuencas$?.unsubscribe();
  }
}
