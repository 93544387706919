import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IMantenimiento,
  ICreateMantenimiento,
  IUpdateMantenimiento,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { HttpService } from 'src/app/auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class MantenimientosService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IMantenimiento>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/mantenimientos`, { params });
  }

  public crear(dato: ICreateMantenimiento): Observable<IMantenimiento> {
    return this.http.post(`/mantenimientos`, dato);
  }

  public listarPorId(id: string): Observable<IMantenimiento> {
    return this.http.get(`/mantenimientos/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateMantenimiento,
  ): Observable<IMantenimiento> {
    return this.http.put(`/mantenimientos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/mantenimientos/${id}`);
  }
}
