import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrearEditarCromatografiasComponent } from './crear-editar-cromatografias/crear-editar-cromatografias.component';
import { ListadoCromatografiasComponent } from './listado-cromatografias/listado-cromatografias.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CromatografiasRoutingModule } from './cromatografias.routing.module';
import { DetallesCromatografiaComponent } from './detalles-cromatografia/detalles-cromatografia.component';

@NgModule({
  declarations: [
    CrearEditarCromatografiasComponent,
    ListadoCromatografiasComponent,
    DetallesCromatografiaComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, CromatografiasRoutingModule],
})
export class CromatografiasModule {}
