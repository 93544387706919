import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ICromatografia,
  IListado,
  IQueryParam,
  IUnidadNegocio,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarCromatografiasComponent } from '../crear-editar-cromatografias/crear-editar-cromatografias.component';
import { CromatografiasService } from '../cromatografias.service';
import { DetallesCromatografiaComponent } from '../detalles-cromatografia/detalles-cromatografia.component';
import {
  IColumnas,
  ICrearBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { DatePipe } from '@angular/common';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado-cromatografias',
  templateUrl: './listado-cromatografias.component.html',
  styleUrls: ['./listado-cromatografias.component.scss'],
})
export class ListadoCromatografiasComponent implements OnInit, OnDestroy {
  // Tabla nueva
  public name = 'ListadoCromatografiasComponent';

  public datos?: ICromatografia[] = [];
  public columnas?: IColumnas<ICromatografia>[];
  public totalCount = 0;
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeCrearCromatografias(),
    tooltip: 'Crear Cromatografía',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: [],
  };

  public unidadNegocio: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idUnidadNegocio',
    },
    label: 'Unidad de Negocio',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public cuenca: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCuenca',
    },
    label: 'Zona de Calidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [this.unidadNegocio, this.cuenca];

  // QUERY+
  private populate = [
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'cuenca',
      select: 'nombre',
    },
    {
      path: 'usuario',
      select: 'username',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaAplicacion',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public unidadNegocios$?: Subscription;
  public cuencas$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: CromatografiasService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarCromatografiasComponent, config);
  }

  public async editar(data: ICromatografia): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
      data,
    };
    this.matDialog.open(CrearEditarCromatografiasComponent, config);
  }

  public vencimiento(data: ICromatografia): boolean {
    const fechaAplicacion = new Date(data.fechaAplicacion!);
    const fechaActual = new Date();
    return fechaAplicacion.getTime() > fechaActual.getTime() ? true : false;
  }

  public async detalles(data: ICromatografia) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(DetallesCromatografiaComponent, config);
  }

  public async eliminar(dato: ICromatografia): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la cromatografia?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ICromatografia>>('cromatografias', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de cromatografias`, data);
      });
    await this.listadosService.getLastValue('cromatografias', this.query);
  }

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocio.elementos = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCuencas(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('cuencas', query)
      .subscribe((data) => {
        this.cuenca.elementos = data.datos;
        console.log(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Fecha de Creación',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'shortDate',
          },
        },
      },
      {
        header: {
          label: 'Fecha de Aplicación',
          sortable: true,
        },
        row: {
          field: 'fechaAplicacion',
          pipe: {
            pipe: DatePipe,
            args: 'shortDate',
          },
        },
      },
      {
        header: {
          label: 'Fecha de Vencimiento',
          sortable: true,
        },
        row: {
          field: 'fechaVencimiento',
          pipe: {
            pipe: DatePipe,
            args: 'shortDate',
          },
        },
      },
      {
        header: {
          label: 'Zona de Calidad',
        },
        row: {
          parse(dato) {
            return dato.cuenca?.nombre;
          },
        },
      },
      {
        header: {
          label: 'Unidad de Negocio',
        },
        row: {
          parse(dato) {
            return dato.unidadNegocio?.nombre;
          },
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/buscar.png',
              tooltip: 'Detalles',
              click: (dato) => this.detalles(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: (dato) => this.helper.puedeEditarCromatografia(dato),
            },
            {
              tipo: 'img',
              color: 'warn',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: (dato) => this.helper.puedeEliminarCromatografia(dato),
            },
          ],
        },
      },
    ];
  }

  //

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([
      this.listar(),
      this.listarUnidadNegocios(),
      this.listarCuencas(),
    ]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
    this.cuencas$?.unsubscribe();
  }
}
