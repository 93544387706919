<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <section class="titulo layout">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
        class="grow1"
      >
        Consumo en las últimas 24hs.
      </h1>
    </section>
  </div>
  <div
    class="column-center"
    style="
      justify-content: center;
      height: 100%;
      padding: 0.5em 0 0.5em 0;
      text-align: center;
    "
  >
    <span style="font-size: 6em">
      <!-- consumo en metros cúbicos -->
      {{ consumo }} m³
    </span>
  </div>
</div>
