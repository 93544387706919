/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  IAlerta,
  IConfigDispositivo,
  IConfigDispositivoNSP4G,
  IConfigDispositivoVeribox,
  IDispositivo,
  IDispositivoNsp4G,
  IDispositivoVeriboxMicro,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { AlertasService } from '../../alertas/service';
import { UnidadPresionsService } from '../service';
import { CrearEditarComponent } from '../crear-editar/crear-editar.component';
import { ReportesService } from '../../reportes/service';
import { IReporteNSP } from 'modelos/src';
import { IRangePreset } from 'src/app/auxiliares/datetime-range-picker/component';

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss'],
})
export class DetallesComponent implements OnInit, OnDestroy {
  public range?: { from: string; to: string };
  public presets: IRangePreset[] = HelperService.presetUnidadesPresion;

  public range15Dias: IRangePreset = {
    from: this.helper.fechaHaceXDias(15).toISOString(),
    to: this.helper.fechaHaceXDias(-1).toISOString(),
  };

  public id?: string | null;
  public unidadPresion?: IUnidadPresion;

  public ultimoReporte?: IReporteNSP;
  public ultimaAlerta?: IAlerta;

  public configActual?: IDispositivoNsp4G | IDispositivoVeriboxMicro;
  public configDeseada?: IConfigDispositivoNSP4G | IConfigDispositivoVeribox;
  public fechaCreacion?: string;

  // Listado Continuo
  public datos$?: Subscription;
  public config$?: Subscription;
  public dispositivo$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public reportesService: ReportesService,
    public alertasService: AlertasService,
    public service: UnidadPresionsService,
  ) {}

  //

  public async editar(data?: IUnidadPresion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarComponent, config);
  }

  //

  private async listarConfigDispositivo(): Promise<void> {
    this.config$?.unsubscribe();
    const deveui = this.unidadPresion?.dispositivo?.deveui;
    if (deveui) {
      this.config$ = this.listadosService
        .subscribe<IConfigDispositivo>('configDispositivo', deveui)
        .subscribe((data) => {
          this.fechaCreacion = data?.fechaCreacion;
          this.configDeseada = data?.config as IConfigDispositivoNSP4G;
          console.log(`listado de configDispositivo`, data);
          console.log(`configDeseada`, this.configDeseada);
        });
      await this.listadosService.getLastValue('configDispositivo', deveui);
    }
  }

  private async listarDispositivo(): Promise<void> {
    const deveui = this.unidadPresion?.deveui;
    if (deveui) {
      this.dispositivo$?.unsubscribe();
      if (this.id) {
        this.dispositivo$ = this.listadosService
          .subscribe<IDispositivo>('dispositivoDeveui', deveui)
          .subscribe(async (data) => {
            this.configActual = data?.config as IDispositivoNsp4G;
            console.log(`listado de dispositivoDeveui`, data);
            console.log(`configActual`, this.configActual);
          });
        await this.listadosService.getLastValue('dispositivoDeveui', deveui);
      }
    }
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    if (this.id) {
      this.datos$ = this.listadosService
        .subscribe<IUnidadPresion>('unidadPresion', this.id)
        .subscribe(async (data) => {
          this.unidadPresion = data;
          this.ultimaAlerta = data?.ultimaAlerta;
          this.ultimoReporte = data?.ultimoRegistro?.valores as IReporteNSP;

          console.log(`listado de unidadPresion`, data);
        });
      await this.listadosService.getLastValue('unidadPresion', this.id);
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.id = params.get('id');
      await this.listar();
      await Promise.all([
        this.listarConfigDispositivo(),
        this.listarDispositivo(),
      ]);
    });
    this.range = {
      from: this.helper.fechaHaceXAnios(1).toISOString(),
      to: this.helper.fechaHaceXDias(-1).toISOString(),
    };
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.config$?.unsubscribe();
    this.dispositivo$?.unsubscribe();
  }
}
