import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IUsuario,
  IFilter,
  IQueryParam,
  IListado,
  ICentroOperativo,
  IPopulate,
} from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';

import { HelperService } from '../../../auxiliares/helper.service';
import { UsuariosService } from '../usuarios.service';
import { CrearEditarUsuariosComponent } from '../crear-editar-usuarios/crear-editar-usuarios.component';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription, firstValueFrom } from 'rxjs';
import {
  IColumnas,
  ICrearBoton,
  IRowIcon,
} from 'src/app/auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado-usuarios',
  templateUrl: './listado-usuarios.component.html',
  styleUrls: ['./listado-usuarios.component.scss'],
})
export class ListadoUsuariosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = 'ListadoUsuariosComponent';
  public datos?: IUsuario[] = [];
  public columnas?: IColumnas<IUsuario>[];
  public totalCount = 0;
  public botonCrear: ICrearBoton = {
    mostrar: true,
    tooltip: 'Crear Usuario',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['username'],
  };
  // FILTROS
  public estado: IFiltroTabla = {
    elementos: [
      { nombre: 'Activos', _id: 'true' },
      { nombre: 'Inactivos', _id: 'false' },
    ],
    filter: {
      field: 'activo',
      value: true,
    },
    label: 'Estado',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public nivel: IFiltroTabla = {
    elementos: [
      { nombre: 'Global', _id: 'Global' },
      { nombre: 'Unidad de Negocio', _id: 'Unidad de Negocio' },
      { nombre: 'Centro Operativo', _id: 'Centro Operativo' },
    ],
    filter: {
      field: 'permisos.nivel',
    },
    label: 'Nivel',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public rol: IFiltroTabla = {
    elementos: [
      { nombre: 'Administrador', _id: 'Administrador' },
      { nombre: 'Usuario', _id: 'Usuario' },
      { nombre: 'Visualizar', _id: 'Visualizar' },
      { nombre: 'Croma', _id: 'Croma' },
    ],
    filter: {
      field: 'permisos.rol',
    },
    label: 'Rol',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public unidadNegocio: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'permisos.idsUnidadNegocios',
    },
    label: 'Unidad de Negocio',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public centroOperativo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'permisos.idsCentroOperativos',
    },
    label: 'Centro Operativo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [
    this.estado,
    this.nivel,
    this.rol,
    this.unidadNegocio,
    this.centroOperativo,
  ];

  // QUERY+
  private filter: IFilter<any> = { activo: true };
  private populate: IPopulate = [
    {
      path: 'permisos.centroOperativos',
      select: 'nombre',
    },
    {
      path: 'permisos.unidadNegocios',
      select: 'nombre',
    },
    {
      path: 'permisos.cuencas',
      select: 'nombre',
    },
    {
      path: 'permisos.localidades',
      select: 'nombre',
    },
    {
      path: 'permisos.agrupaciones',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: 'username',
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public usuarios$?: Subscription;
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: UsuariosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarUsuariosComponent, config);
  }

  public async editar(data: IUsuario) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarUsuariosComponent, config);
  }

  public async eliminar(dato: IUsuario): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el usuario ${dato.username}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async resetearPassword(dato: IUsuario): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desea resetear el password del usuario ${dato.username}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.resetearPass(dato._id!));
        this.helper.notifSuccess('Contraseña reseteada correctamente');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listadosService
      .subscribe<IListado<IUsuario>>('usuarios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listadosService.getLastValue('usuarios', this.query);
  }

  private async listarUnidadNegocios(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify({}),
    };

    // Listado
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocio.elementos = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentrosOperativos(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify({}),
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativo.elementos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Usuario',
          sortable: true,
        },
        row: {
          field: 'username',
        },
      },
      {
        header: {
          label: 'Teléfono',
          sortable: true,
          sort: 'datosPersonales.telefono',
        },
        row: {
          parse(dato) {
            return dato.datosPersonales?.telefono;
          },
        },
      },
      {
        header: {
          label: 'Email',
          sortable: true,
          sort: 'datosPersonales.email',
        },
        row: {
          parse(dato) {
            return dato.datosPersonales?.email;
          },
        },
      },
      {
        header: {
          label: 'Activo',
        },
        row: {
          icono(dato) {
            return parseIcono(dato);
          },
        },
      },
      {
        header: {
          label: 'Usuarios',
        },
        row: {
          html(dato) {
            return parseRoles(dato);
          },
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'warn',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/config.png',
              tooltip: 'Resetear Contraseña',
              click: (dato) => this.resetearPassword(dato),
              oculta: (dato) => !dato.datosPersonales?.email,
            },
          ],
        },
      },
    ];
  }

  //

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([
      this.listar(),
      this.listarUnidadNegocios(),
      this.listarCentrosOperativos(),
    ]);
  }

  ngOnDestroy(): void {
    this.usuarios$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
  }
}

function parseIcono(dato: IUsuario): IRowIcon {
  return {
    icon: dato.activo ? 'check' : 'close',
    color: dato.activo ? 'green' : 'red',
    tooltip: dato.activo ? 'Activo' : 'Inactivo',
    tipo: 'material',
  };
}

function parseRoles(dato: IUsuario): string {
  let roles = `<div>`;
  for (const rol of dato.permisos || []) {
    const last = dato?.permisos?.[dato.permisos.length - 1] === rol;
    if (!last) {
      roles += `<div style="margin-bottom: 0.5em">`;
    } else {
      roles += `<div>`;
    }

    if (rol.division) {
      roles += `<span style="color: #f7a62c" >${rol.division}</span>`;
    }
    roles += `
    <span style="color: #afeeee">${rol.rol}</span> 
    <span >${rol.nivel}</span>`;
    if (rol.unidadNegocios) {
      for (const un of rol.unidadNegocios) {
        roles += `
      <span style="color: #eeafaf" >${un.nombre}</span>
      `;
      }
    }
    if (rol.centroOperativos) {
      for (const co of rol.centroOperativos) {
        roles += `
        <span style="color: #9064de" >${co.nombre}</span>
        `;
      }
    }
    if (rol.localidades) {
      for (const l of rol.localidades) {
        roles += `
        <span style="color: #da64de" >${l.nombre}</span>
        `;
      }
    }
    if (rol.agrupaciones) {
      for (const a of rol.agrupaciones) {
        roles += `
        <span style="color: #64de64" >${a.nombre}</span>
        `;
      }
    }

    roles += `</div>`;
    // Agregar salto de línea
    // roles += `<br>`;
  }
  roles += `</div>`;
  return roles;
}
