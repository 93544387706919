/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IMedidorResidencial,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteSML,
  IReporteWRC,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { MedidorResidencialsService } from '../../modulos/medidores-residenciales/medidores-residenciales.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-consumo-residencial',
  templateUrl: './consumo-residencial.component.html',
  styleUrls: ['./consumo-residencial.component.scss'],
})
export class ConsumoResidencialComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() punto?: IPuntoMedicion;
  @Input() medidor?: IMedidorResidencial;
  public consumo?: number;

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public service: MedidorResidencialsService,
  ) {}
  //

  private fechaFrom() {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 1);
    return fecha.toISOString();
  }

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();

    let filter: IFilter<IReporte> | boolean = false;
    const from = this.fechaFrom();
    // const to = new Date().toISOString();
    if (this.medidor) {
      filter = {
        idsAsignados: this.medidor._id,
        fechaCreacion: { $gte: from },
      };
    }
    if (this.punto) {
      filter = {
        idsAsignados: this.punto._id,
        fechaCreacion: { $gte: from },
      };
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        select: 'valores.consumo',
        sort: '-fechaCreacion',
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', query)
        .subscribe((data) => {
          console.log(`listado de reportes`, data);
          if (data.datos.length) {
            const ultimoReporte = data.datos[0];
            const primerReporte = data.datos[data.datos.length - 1];
            const ultimoValor = ultimoReporte.valores as
              | IReporteWRC
              | IReporteSML;
            const primerValor = primerReporte.valores as
              | IReporteWRC
              | IReporteSML;
            const ultimoConsumo = ultimoValor.consumo || 0;
            const primerConsumo = primerValor.consumo || 0;
            this.consumo = +(ultimoConsumo - primerConsumo).toFixed(2);
          } else {
            this.consumo = 0;
          }
        });
      await this.listadosService.getLastValue('reportes', query);
    }
  }

  //

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
