<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
  <app-resumen-medidor-residencial
    [medidorResidencial]="medidorResidencial"
    fxFlex
  ></app-resumen-medidor-residencial>

  <!-- Último Registro -->
  <div
    class="fondo-85"
    style="
      margin: 1em 1em 1em 0;
      border-bottom-left-radius: 30px;
      width: 15%;
      height: 250px;
    "
  >
    <app-ultimo-reporte-medidor-residencial
      [medidor]="medidorResidencial"
    ></app-ultimo-reporte-medidor-residencial>
  </div>
</div>

<!-- Time Picker -->
<div style="margin: 1em 0 1em 0; border-radius: 30px" class="fondo-85">
  <app-datetime-range-picker
    [(range)]="range"
    [presets]="presets"
  ></app-datetime-range-picker>
</div>

<!-- Grafico -->
<app-grafico-medidor-residencial [medidor]="medidorResidencial" [range]="range">
</app-grafico-medidor-residencial>

<!-- Tablas -->
<div style="margin: 1em 0">
  <mat-tab-group>
    <!-- Registros -->
    <mat-tab label="Registros">
      <div style="overflow: hidden">
        <app-listado-reportes-medidor-residencial
          *ngIf="medidorResidencial"
          [medidor]="medidorResidencial"
        ></app-listado-reportes-medidor-residencial>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
