<app-resumen-correctora [correctora]="correctora"></app-resumen-correctora>

<!-- Ultimo Registro - NUC - Croma -->
<div fxLayout="row" fxLayout.lt-md="column">
  <!-- Último Registro -->
  <div
    class="fondo-85"
    style="margin: 1em 1em 1em 0; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <app-ultimo-registro-nuc
      *ngIf="correctora"
      [correctora]="correctora"
    ></app-ultimo-registro-nuc>
  </div>

  <!-- NUC / Configuracion -->
  <div
    class="fondo-85"
    style="margin: 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <app-detalles-nuc
      [correctora]="correctora"
      [configActual]="configActual"
      [configDeseada]="configDeseada"
    ></app-detalles-nuc>
  </div>

  <!-- Última Cromatografía -->
  <div
    class="fondo-85"
    style="margin: 1em 0 1em 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <app-ultima-cromatografia-correctora
      [correctora]="correctora"
    ></app-ultima-cromatografia-correctora>
  </div>
</div>

<!-- Time Picker -->
<div style="margin: 1em 0 1em 0; border-radius: 30px" class="fondo-85">
  <app-datetime-range-picker
    [(range)]="range"
    [presets]="presets"
  ></app-datetime-range-picker>
</div>

<!-- Grafico -->
<app-grafico-correctora [correctora]="correctora" [range]="range">
</app-grafico-correctora>

<!-- Tablas -->
<div style="margin: 1em 0">
  <mat-tab-group>
    <!-- Registros -->
    <mat-tab label="Registros">
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-registros
            [correctora]="correctora"
            [range]="range"
          ></app-listado-registros>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Alertas -->
    <mat-tab label="Alertas">
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-alertas [correctora]="correctora"></app-listado-alertas>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Cromatografias -->
    <mat-tab label="Cromatografías">
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-aplicacion-cromatografias
            [correctora]="correctora"
          ></app-listado-aplicacion-cromatografias>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Perdidos -->
    <mat-tab label="Registros Faltantes">
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-registros-faltantes
            [correctora]="correctora"
          ></app-listado-registros-faltantes>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
