import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HelperService } from '../../../auxiliares/helper.service';
import { LoginService } from '../login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NavigationService } from '../../navigation/navigation.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public hide = true;
  public logo?: string = 'assets/images/logo-light.png';
  public form: UntypedFormGroup = this.createForm();
  public esModoOscuro = HelperService.esModoOscuro();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private router: Router,
    private helper: HelperService,
    private navigationService: NavigationService,
    public loading: LoadingService,
    private route: ActivatedRoute,
  ) {}

  public async onSubmit() {
    try {
      const username = this.form.get('username')?.value;
      const password = this.form.get('password')?.value;
      await this.loginService.login(username, password);
      this.router.navigateByUrl('main/landing');
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  public forgotPassword() {
    this.router.navigateByUrl('./forgot-password');
  }

  private createForm() {
    return this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  public setClaro() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
    this.renderer.addClass(this.document.body, 'light-theme');
    this.logo = 'assets/images/logo-light.png';
    this.navigationService.modoOscuro$.next(false);
    localStorage.removeItem('temaOscuro');
  }

  public setOscuro() {
    this.renderer.addClass(this.document.body, 'dark-theme');
    this.renderer.removeClass(this.document.body, 'light-theme');
    this.logo = 'assets/images/logo-dark.png';
    this.navigationService.modoOscuro$.next(true);
    localStorage.setItem('temaOscuro', 'true');
  }

  public toggleTheme() {
    if (this.document.body.classList.contains('dark-theme')) {
      this.setClaro();
    } else {
      this.setOscuro();
    }
  }

  private temaInicial() {
    if (this.esModoOscuro) {
      this.setOscuro();
    } else {
      this.setClaro();
    }
  }

  ngOnInit(): void {
    this.temaInicial();

    // Query params
    this.route.queryParams.subscribe((params) => {
      if (params.user) {
        this.form.get('username')?.setValue(params.user);
      }
      if (params.pass) {
        this.form.get('password')?.setValue(params.pass);
      }
    });
  }
}
