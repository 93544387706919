<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- modoEnv -->
      <mat-form-field>
        <input
          matInput
          [ngxMatDatetimePicker]="picker"
          placeholder="Detener Alertas"
          formControlName="timestampBloqueo"
          (click)="picker.open()"
        />
        <mat-hint>No se recibirán alertas hasta la fecha indicada</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(picker)"
          style="fill: red"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="1"
          [stepMinute]="15"
          [touchUi]="false"
          [enableMeridian]="false"
          [hideTime]="false"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      style="width: 50%"
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      style="width: 50%"
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="this.loading.getLoading() ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
