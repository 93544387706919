<div
  *ngIf="correctora"
  class="fondo-85"
  style="
    margin: 1em 0;
    padding: 0.5em;
    border-bottom-right-radius: 30px;
    overflow: auto;
  "
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <table>
    <tr class="border">
      <th>Estado</th>
      <th>Batería</th>
      <th>Correctora</th>
      <th>Fecha de Creación</th>
      <th>Unidad de Negocio</th>
    </tr>

    <tr>
      <!-- Estado -->
      <td *ngIf="correctora" class="no-vertical">
        <app-icono-estado [correctora]="correctora"></app-icono-estado>
      </td>

      <!-- Batería -->
      <td class="no-vertical">
        <p
          *ngIf="helper.bateriaCorrectora(correctora) as bateria"
          [class.no-info]="bateria === 'Sin Reportar'"
        >
          {{ bateria }}
        </p>
      </td>

      <!-- Correctora -->
      <td>
        <p>{{ correctora.modelo || "..." }}</p>
        <p>{{ correctora.numeroSerie || "..." }}</p>
        <p [class.no-info]="!correctora.firmware">
          {{ correctora.firmware || "Sin informar" }}
        </p>
      </td>

      <!-- Fecha de Creación -->
      <td class="no-vertical">
        {{ (correctora.fechaCreacion | date: "short") || "..." }}
      </td>

      <!-- Unidad de Negocio -->
      <td class="no-vertical">
        <p [class.no-info]="!correctora.unidadNegocio">
          {{ correctora.unidadNegocio?.nombre || "..." }}
        </p>
        <p [class.no-info]="!correctora.centroOperativo">
          {{ correctora.centroOperativo?.nombre || "..." }}
        </p>
      </td>
    </tr>
  </table>

  <div style="margin-right: 7px" *ngIf="correctora">
    <img
      src="assets/iconos/editar.png"
      alt="editar"
      *ngIf="helper.puedeEditarCorrectora(correctora)"
      matTooltip="Editar"
      style="width: 48px; cursor: pointer"
      (click)="editar(correctora)"
    />
  </div>
  <div style="margin-right: 7px" *ngIf="correctora">
    <img
      src="assets/estados/mantenimiento.png"
      alt=""
      *ngIf="helper.puedeEditarCorrectora(correctora)"
      matTooltip="Crear Mantenimiento"
      style="width: 48px; cursor: pointer"
      (click)="crearMantenimiento(correctora)"
    />
  </div>
</div>
