import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { GeocodeService } from '../../auxiliares/geocode.service';
import { ExportarComponent } from './exportar/exportar.component';
import { ExportarRoutingModule } from './routing';
import { MonthSelectComponent } from './exportar/monthSelect/monthSelect.component';
import { YearSelectComponent } from './exportar/yearSelect/yearSelect.component';

@NgModule({
  declarations: [ExportarComponent, MonthSelectComponent, YearSelectComponent],
  imports: [CommonModule, AuxiliaresModule, ExportarRoutingModule],
  providers: [GeocodeService],
})
export class ExportarModule {}
