import { Injectable } from '@angular/core';
import { HttpService } from '../../auxiliares/http.service';
import {
  IQueryParam,
  IListado,
  IAuditoria,
  ICreateAuditoria,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';

@Injectable({ providedIn: 'root' })
export class AuditoriaService {
  constructor(private htpp: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IAuditoria>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.htpp.get(`/auditorias`, { params });
  }

  public crear(dato: ICreateAuditoria): Observable<IAuditoria> {
    return this.htpp.post(`/auditorias`, dato);
  }

  public listarPorId(id: string): Observable<IAuditoria> {
    return this.htpp.get(`/auditorias/${id}`);
  }

  public eliminar(id: string): Observable<void> {
    return this.htpp.delete(`/auditorias/${id}`);
  }
}
