import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICreateConfigDispositivo,
  IDispositivo,
  IDispositivoVeriboxMicro,
  IUpdateDispositivo,
} from 'modelos/src';
import { DispositivosService } from '../../dispositivos/dispositivos.service';
import { firstValueFrom } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-cambiar-telefono',
  templateUrl: './cambiar-telefono.component.html',
  styleUrl: './cambiar-telefono.component.scss',
})
export class CambiarTelefonoComponent implements OnInit {
  public telefono?: string;
  public valido = false;

  public regTel = /^(?!0|15)\d{10}$/;

  constructor(
    public dialogRef: MatDialogRef<CambiarTelefonoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private dipositivoService: DispositivosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private getUpsertData(): IUpdateDispositivo {
    const config: IDispositivoVeriboxMicro = {
      telefono: this.telefono,
    };
    const data: ICreateConfigDispositivo = {
      config,
    };
    return data;
  }

  public telValido() {
    this.valido = this.regTel.test(this.telefono || '');
  }

  public async guardar() {
    try {
      if (this.data._id) {
        const data = this.getUpsertData();
        await firstValueFrom(
          this.dipositivoService.editar(this.data._id, data),
        );
        this.helper.notifSuccess('Configuración actualizada');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  public volver() {
    this.dialogRef.close();
  }

  ///

  ngOnInit(): void {
    this.telefono = this.data.config?.telefono;
  }
}
