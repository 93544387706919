/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IMedidorResidencial,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteSML,
  IReporteWRC,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  IExportarBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IRegExpSearch } from '../../auxiliares/tabla/filtro/filtro.component';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { ReporteSmlsService } from '../../modulos/medidores-residenciales/reporteSmls.service';
import { MedidorResidencialsService } from '../../modulos/medidores-residenciales/medidores-residenciales.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-reportes-medidor-residencial',
  templateUrl: './listado-reportes-medidor-residencial.component.html',
  styleUrls: ['./listado-reportes-medidor-residencial.component.scss'],
})
export class ListadoReportesMedidorResidencialComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() punto?: IPuntoMedicion;
  @Input() medidor?: IMedidorResidencial;

  ///// Tabla nueva
  public name = 'ListadoReporteWRCComponent';
  public datos?: IReporte[] = [];
  public columnas?: IColumnas<IReporte>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public botonExportar: IExportarBoton = {
    accion: async () => this.exportar(),
    mostrar: true,
    tooltip: 'Exportar Registros',
  };

  // QUERY+
  private populate: IPopulate = [
    {
      path: 'puntoMedicion',
    },
    {
      path: 'medidorResidencial',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaCreacion',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private dialogService: DialogService,
    private reporteSmlsService: ReporteSmlsService,
    public service: MedidorResidencialsService,
  ) {}
  //

  public async exportar() {
    const mensaje = `¿Desea exportar el listado de registros?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje,
    );
    if (confirm) {
      try {
        await this.reporteSmlsService.exportarReportesWRC(this.query);
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    let filter: IFilter<IReporte> | boolean = false;

    if (this.medidor) {
      filter = {
        idsAsignados: this.medidor._id,
      };
    }
    if (this.punto) {
      filter = {
        idsAsignados: this.punto._id,
      };
    }

    if (filter) {
      this.query.filter = JSON.stringify(filter);

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', this.query)
        .subscribe((data) => {
          this.totalCount = data.totalCount;
          this.datos = data.datos;
          console.log(`listado de reportes`, data);
        });
      await this.listadosService.getLastValue('reportes', this.query);
    }
  }

  //

  private setColumnas() {
    this.columnas = [
      {
        header: { label: 'Fecha', sort: 'fechaCreacion', sortable: true },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: { label: 'Consumo' },
        row: {
          html: (dato) => this.parseConsumo(dato),
        },
      },
      {
        header: { label: 'Batería' },
        row: {
          html: (dato) => this.parseBateria(dato),
        },
      },
    ];
  }

  private parseConsumo(reporte: IReporte): string {
    const valores = reporte.valores as IReporteWRC | IReporteSML;
    return `${valores.consumo} m<sup>3</sup>`;
  }

  private parseBateria(reporte: IReporte): string {
    const valores = reporte.valores as IReporteWRC | IReporteSML;
    return `${valores.bateria} V.</div>`;
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([this.listar()]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
