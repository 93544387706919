import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICorrectora,
  ICreateCorrectora,
  IListado,
  IQueryParam,
  IUpdateCorrectora,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class CorrectorasService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ICorrectora>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/correctoras`, { params });
  }

  public crear(dato: ICreateCorrectora): Observable<ICorrectora> {
    return this.http.post(`/correctoras`, dato);
  }

  public listarPorId(id: string): Observable<ICorrectora> {
    return this.http.get(`/correctoras/${id}`);
  }

  public editar(id: string, dato: IUpdateCorrectora): Observable<ICorrectora> {
    return this.http.put(`/correctoras/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/correctoras/${id}`);
  }

  //

  public getUltimaComunicacion(correctora?: ICorrectora): string {
    const ultimoRegistro = correctora?.ultimoRegistro;
    const ultimaAlerta = correctora?.ultimaAlerta;

    const fechaUltimoRegistro = ultimoRegistro?.fechaCreacion;
    const fechaUltimaAlerta = ultimaAlerta?.fechaCreacion;

    if (fechaUltimoRegistro && fechaUltimaAlerta) {
      return fechaUltimoRegistro > fechaUltimaAlerta
        ? fechaUltimoRegistro
        : fechaUltimaAlerta;
    }

    if (fechaUltimoRegistro) {
      return fechaUltimoRegistro;
    }

    if (fechaUltimaAlerta) {
      return fechaUltimaAlerta;
    }

    return '';
  }
}
