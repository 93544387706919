import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IPuntoMedicion, IUnidadPresion } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CrearEditarComponent } from '../../modulos/unidad-presion/crear-editar/crear-editar.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-unidad-presion',
  templateUrl: './detalles-unidad-presion.component.html',
  styleUrls: ['./detalles-unidad-presion.component.scss'],
})
export class DetallesUnidadPresionComponent implements OnInit {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() unidadPresion?: IUnidadPresion;

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
  ) {}

  public async editarUnidadPresion(data?: IUnidadPresion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarComponent, config);
  }

  ngOnInit(): void {}
}
