<div class="fondo-85" style="border-radius: 30px">
  <div
    *ngIf="!this.loading.getLoading() && !reportes.length"
    style="margin: 1em; text-align: center; padding: 0.5em"
  >
    <h2 style="margin: 0">No hay reportes en el período seleccionado</h2>
  </div>

  <!-- Valores Acumulados -->
  <div *ngIf="reportes.length" style="padding: 1em; border-radius: 8px">
    <!-- style="margin: 1em; text-align: center; padding: 0.5em" -->
    <table class="consumos">
      <tr>
        <td>Consumo Acumulado</td>
        <td style="padding-left: 1em">
          {{ volumenAcumulado | number: "1.0-2" }} m³
        </td>
      </tr>
    </table>
  </div>

  <!-- Graficos -->
  <div *ngIf="reportes.length" style="margin: 1em">
    <mat-tab-group>
      <!-- Consumo -->
      <mat-tab label="Consumo">
        <ng-template matTabContent>
          <div *ngIf="chartConsumo" class="transparente" style="margin: 1em">
            <app-chart
              [options]="chartConsumo"
              style="
                width: 100%;
                height: 400px;
                display: block;
                margin-top: 30px;
              "
            >
            </app-chart>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
