import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CambiarPasswordRoutingModule } from './cambiar-password-routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CambiarPasswordComponent } from './cambiar-password/cambiar-password.component';

@NgModule({
  declarations: [CambiarPasswordComponent],
  imports: [CommonModule, CambiarPasswordRoutingModule, AuxiliaresModule],
})
export class CambiarPasswordModule {}
