@if (punto) {
  <div class="info-window">
    <div class="info-titulo">
      <p>{{ punto.nombre }}</p>
    </div>
    <br />
    <div class="info-subtitulo">
      <p>Unidad de Presión</p>
    </div>

    <!-- Batería -->
    <div class="item-list">
      <p>Batería:</p>
      @if (valores?.bateria) {
        <p>
          {{ helper.bateriaUnidadPresion(valores?.bateria) }}
        </p>
      } @else {
        <p class="no-info">Indeterminada</p>
      }
    </div>

    <!-- Ultima Comunicacion -->
    <div class="item-list">
      <p>Última Comunicación:</p>
      @if (punto.unidadPresion?.dispositivo?.fechaUltimaComunicacion) {
        <p>
          {{
            punto.unidadPresion!.dispositivo?.fechaUltimaComunicacion
              | date: "dd/MM/yyyy HH:mm"
          }}
        </p>
      } @else {
        <p class="no-info">Sin comunicar</p>
      }
    </div>

    <!-- Alarma / Alerta -->
    <div class="item-list">
      <p>Alarma:</p>
      @if (punto.unidadPresion?.ultimaAlerta) {
        <p>
          {{ (punto.unidadPresion?.ultimaAlerta)!.mensaje }}
          ({{
            (punto.unidadPresion?.ultimaAlerta)!.timestamp
              | date: "dd/MM/yyyy HH:mm"
          }})
        </p>
      } @else {
        <p class="no-info">Sin alarmas</p>
      }
    </div>

    <!-- Presión -->
    @if (punto.unidadPresion) {
      <div class="item-list">
        <p>Presión:</p>
        @if (valores?.presion) {
          <p>
            {{ valores?.presion }}
            BAR
          </p>
        } @else {
          <p class="no-info">Sin Reportar</p>
        }
      </div>
    }

    <!-- DETALLES -->
    <div style="width: 100%; display: flex; justify-content: center">
      <button
        mat-flat-button
        class="info-button"
        style="width: 90%"
        [routerLink]="['../puntosMedicion', punto._id]"
      >
        Detalles
      </button>
    </div>
  </div>
}
