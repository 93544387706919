import { Injectable } from '@angular/core';
import { IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ReporteSmlsService {
  constructor(private http: HttpService) {}

  public async exportarReportesWRC(
    queryParams?: IQueryParam,
    filename = 'export.xlsx',
  ): Promise<void> {
    const params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/reportes/exportar`, filename, params);
  }
}
