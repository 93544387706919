import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  IAlerta,
  IConfigDispositivo,
  IConfigDispositivoNSP4G,
  IConfigDispositivoNUC4G,
  IConfigDispositivoVeribox,
  ICorrectora,
  IDispositivo,
  IDispositivoNsp4G,
  IDispositivoNuc4G,
  IDispositivoVeriboxMicro,
  IMedidorResidencial,
  IPuntoMedicion,
  IReporteSML,
  IReporteWRC,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { NOMBRE_ALERTAS_NUC } from '../../../../environments/datos';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { AlertasService } from '../../correctoras/alertas.service';
import { CorrectorasService } from '../../correctoras/correctoras.service';
import { RegistrosService } from '../../correctoras/registros.service';
import { CrearEditarPuntosMedicionComponent } from '../crear-editar-puntos-medicion/crear-editar-puntos-medicion.component';
import { UnidadPresionsService } from '../../unidad-presion/service';
import { IReporteNSP, IReporteNUC } from 'modelos/src';
import { IRangePreset } from 'src/app/auxiliares/datetime-range-picker/component';

export enum EstadoCorrectora {
  'Sin Correctora' = 1,
  'OK' = 2,
  'Error' = 3,
}

@Component({
  selector: 'app-detalles-puntos-medicion',
  templateUrl: './detalles-puntos-medicion.component.html',
  styleUrls: ['./detalles-puntos-medicion.component.scss'],
})
export class DetallesPuntosMedicionComponent implements OnInit, OnDestroy {
  public nombreAlertas = NOMBRE_ALERTAS_NUC;

  public range?: { from: string; to: string };
  public presets?: IRangePreset[] = [];

  public range15Dias: IRangePreset = {
    from: this.helper.fechaHaceXDias(15).toISOString(),
    to: this.helper.fechaHaceXDias(-1).toISOString(),
  };

  public id?: string | null;
  public puntoMedicion?: IPuntoMedicion;

  public configActualNuc?: IDispositivoNuc4G & { claveMercury?: string };
  public configActualNsp?: IDispositivoNsp4G;
  public configActualVeribox?: IDispositivoVeriboxMicro;

  public configDeseadaNuc?: IConfigDispositivoNUC4G;
  public configDeseadaNsp?: IConfigDispositivoNSP4G;
  public configDeseadaVeribox?: IConfigDispositivoVeribox;
  public fechaCreacion?: string;

  public correctora?: ICorrectora;
  public unidadPresion?: IUnidadPresion;
  public medidor?: IMedidorResidencial;

  public ultimoRegistroCorrectora?: IReporteNUC;
  public ultimoRegistroPresion?: IReporteNSP;
  public ultimoRegistroResidencial?: IReporteSML | IReporteWRC;
  public ultimaAlerta?: IAlerta;

  // Listado Continuo
  public correctora$?: Subscription;
  public unidadPresion$?: Subscription;
  public config$?: Subscription;
  public puntoMedicion$?: Subscription;
  public dispositivo$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public registrosService: RegistrosService,
    public alertasService: AlertasService,
    public correctorasService: CorrectorasService,
    public presionesService: UnidadPresionsService,
  ) {}

  public async editar(data?: IPuntoMedicion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  //

  private obtenerDatos() {
    this.unidadPresion = this.puntoMedicion?.unidadPresion;
    if (this.unidadPresion) {
      this.ultimoRegistroPresion = this.unidadPresion?.ultimoRegistro
        ?.valores as IReporteNSP;
      this.ultimaAlerta = this.unidadPresion?.ultimaAlerta;
      this.configActualNsp = this.unidadPresion?.dispositivo
        ?.config as IConfigDispositivoNSP4G;
      // this.graficoPresionActual();
    }

    this.correctora = this.puntoMedicion?.correctora;
    if (this.correctora) {
      if (this.correctora?.ultimoRegistro) {
        this.correctora.ultimoRegistro.uncorrectedParcializado =
          this.registrosService.getUncorrectedParcializado(
            this.correctora.ultimoRegistro,
            this.correctora,
          );
        this.correctora.ultimoRegistro.correctedParcializado =
          this.registrosService.getCorrectedParcializado(
            this.correctora.ultimoRegistro,
            this.correctora,
          );
        this.correctora.ultimoRegistro.uncorrectedTotalizado =
          this.registrosService.getUncorrectedTotalizado(
            this.correctora.ultimoRegistro,
            this.correctora,
          );
        this.correctora.ultimoRegistro.correctedTotalizado =
          this.registrosService.getCorrectedTotalizado(
            this.correctora.ultimoRegistro,
            this.correctora,
          );
      }
      this.ultimaAlerta = this.correctora?.ultimaAlerta;
      this.configActualNuc = this.correctora?.dispositivo
        ?.config as IConfigDispositivoNUC4G;
    }

    this.medidor = this.puntoMedicion?.medidorResidencial;
    if (this.medidor) {
      this.ultimoRegistroResidencial = this.medidor?.ultimoReporte?.valores;
      // this.ultimaAlerta = this.medidor?.ultimaAlerta;
      // this.configActualNsp = this.medidor?.dispositivo
      // ?.config as IConfigDispositivoNSP4G;
      // this.graficoPresionActual();
    }
  }

  //

  public onRangeChange(range: { from: string; to: string }) {
    this.range = range;
  }

  //

  public colorDeBateria(bateria: number) {
    if (bateria) {
      if (bateria / 100 < 6 && bateria / 100 > 4) {
        return '#c75150';
      } else if (bateria / 100 >= 6 && bateria / 100 <= 7) {
        return '#fbfb98';
      } else if (bateria / 100 > 7 && bateria / 100 < 10) {
        return '#98fb98';
      } else {
        return '#d3d3d3';
      }
    } else {
      return '#d3d3d3';
    }
  }

  public textoBateria(correctora: ICorrectora): string {
    if (correctora.bateria || correctora.bateria === 0) {
      if (correctora.modelo === 'Mercury') {
        const calculada = correctora.bateria / 100;
        return `${calculada}v`;
      }
    }
    return 'Sin Reportar';
  }

  private rangoInicial() {
    switch (this.puntoMedicion?.division) {
      case 'Correctoras': {
        this.range = {
          from: this.defaultFromCorrectora().toISOString(),
          to: this.defaultToCorrectora().toISOString(),
        };
        break;
      }
      case 'Presión': {
        this.range = {
          from: this.helper.fechaHaceXAnios(1).toISOString(),
          to: this.helper.fechaHaceXDias(-1).toISOString(),
        };
        break;
      }
      case 'Residencial': {
        this.range = {
          from: this.helper.fechaHaceXDias(2).toISOString(),
          to: this.helper.fechaHaceXDias(-1).toISOString(),
        };
        break;
      }
      default: {
        this.range = {
          from: this.helper.fechaHaceXAnios(1).toISOString(),
          to: this.helper.fechaHaceXDias(-1).toISOString(),
        };
        break;
      }
    }
  }

  private setPresets() {
    switch (this.puntoMedicion?.division) {
      case 'Correctoras': {
        this.presets = HelperService.presetCorrectoras;
        break;
      }
      case 'Presión': {
        this.presets = HelperService.presetUnidadesPresion;
        break;
      }
      case 'Residencial': {
        this.presets = HelperService.presetResidencial;
        break;
      }
      default: {
        this.presets = HelperService.presetUnidadesPresion;
        break;
      }
    }
  }

  public defaultFromCorrectora(dias = 2): Date {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - dias);
    fecha.setHours(7, 0, 0, 0);
    return fecha;
  }

  public defaultToCorrectora(): Date {
    const fecha = new Date();
    fecha.setHours(6, 0, 0, 0);
    return fecha;
  }

  // Listados

  private async listarConfigDispositivo(): Promise<void> {
    this.config$?.unsubscribe();
    const deveuiNUC = this.puntoMedicion?.correctora?.dispositivo?.deveui;
    const deveuiNSP = this.puntoMedicion?.unidadPresion?.dispositivo?.deveui;
    const deveui = deveuiNUC || deveuiNSP;

    if (deveui) {
      this.config$ = this.listadosService
        .subscribe<IConfigDispositivo>('configDispositivo', deveui)
        .subscribe((data) => {
          console.log(`listado de configDispositivo`, data);

          this.fechaCreacion = data?.fechaCreacion;

          // Config NUC
          if (
            this.puntoMedicion?.correctora?.dispositivo?.tipoDispositivo ===
            'NUC'
          ) {
            this.configDeseadaNuc = data?.config as IConfigDispositivoNUC4G;
          }

          // Config NSP
          if (
            this.puntoMedicion?.unidadPresion?.dispositivo?.tipoDispositivo ===
            'NSP'
          ) {
            this.configDeseadaNsp = data?.config as IConfigDispositivoNSP4G;
          }

          // Config Veribox
          if (
            this.puntoMedicion?.unidadPresion?.dispositivo?.tipoDispositivo ===
            'VERIBOX MICRO'
          ) {
            this.configDeseadaVeribox =
              data?.config as IDispositivoVeriboxMicro;
          }
        });
      await this.listadosService.getLastValue('configDispositivo', deveui);
    }
  }

  // Se lista por separado del punto para actualizar la config actual por WS cuando se modifica
  private async listarDispositivo(): Promise<void> {
    const deveuiNUC = this.puntoMedicion?.correctora?.dispositivo?.deveui;
    const deveuiNSP = this.puntoMedicion?.unidadPresion?.dispositivo?.deveui;
    const deveui = deveuiNUC || deveuiNSP;

    if (deveui) {
      this.dispositivo$?.unsubscribe();
      if (this.id) {
        this.dispositivo$ = this.listadosService
          .subscribe<IDispositivo>('dispositivoDeveui', deveui)
          .subscribe(async (data) => {
            console.log(`listado de dispositivoDeveui`, data);

            // Config NUC
            if (
              this.puntoMedicion?.correctora?.dispositivo?.tipoDispositivo ===
              'NUC'
            ) {
              this.configActualNuc = data?.config as IConfigDispositivoNUC4G;
              this.puntoMedicion.correctora.dispositivo = data;
              this.correctora = this.puntoMedicion.correctora;
            }

            // Config NSP
            if (
              this.puntoMedicion?.unidadPresion?.dispositivo
                ?.tipoDispositivo === 'NSP'
            ) {
              this.configActualNsp = data?.config as IConfigDispositivoNSP4G;
              this.puntoMedicion.unidadPresion.dispositivo = data;
              this.unidadPresion = this.puntoMedicion.unidadPresion;
            }

            // Config Veribox
            if (
              this.puntoMedicion?.unidadPresion?.dispositivo
                ?.tipoDispositivo === 'VERIBOX MICRO'
            ) {
              this.configActualVeribox =
                data?.config as IDispositivoVeriboxMicro;
              this.puntoMedicion.unidadPresion.dispositivo = data;
              this.unidadPresion = this.puntoMedicion.unidadPresion;
            }
          });
        await this.listadosService.getLastValue('dispositivoDeveui', deveui);
      }
    }
  }

  private async listarPuntoMedicion(): Promise<void> {
    this.puntoMedicion$?.unsubscribe();
    if (this.id) {
      this.puntoMedicion$ = this.listadosService
        .subscribe<IPuntoMedicion>('puntoMedicion', this.id)
        .subscribe(async (data) => {
          this.puntoMedicion = data;
          this.obtenerDatos();
          console.debug(`listado de puntoMedicion`, data);
        });
      await this.listadosService.getLastValue('puntoMedicion', this.id);
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.id = params.get('id');
      await this.listarPuntoMedicion();
      this.rangoInicial();
      await Promise.all([
        this.listarConfigDispositivo(),
        this.listarDispositivo(),
      ]);
      this.setPresets();
    });
  }

  ngOnDestroy(): void {
    this.correctora$?.unsubscribe();
    this.config$?.unsubscribe();
    this.puntoMedicion$?.unsubscribe();
    this.unidadPresion$?.unsubscribe();
    this.dispositivo$?.unsubscribe();
  }
}
