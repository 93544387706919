import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDispositivo,
  ICreateConfigDispositivo,
  IConfigDispositivo,
  IConfigDispositivoNSP4G,
  IConfigDispositivoVeribox,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { ConfigDispositivosService } from '../../modulos/dispositivos/config-dispositivos.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

interface IApn {
  label: string;
  apn: string;
  usuario: string;
  clave: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-config-dispositivos-veribox',
  templateUrl: './config-dispositivos-veribox.component.html',
  styleUrls: ['./config-dispositivos-veribox.component.scss'],
})
export class ConfigDispositivosVeriboxComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public config?: IConfigDispositivoVeribox;

  public regTel = /^(?!0|15)\d{10}$/;
  public telefono?: string;

  public enviarSms = true;
  public enviarInmediatamente = false;

  public apnLabel?: string;
  public apns: IApn[] = [
    {
      label: 'Claro',
      apn: 'igprs.claro.com.ar',
      usuario: '',
      clave: '',
    },
    {
      label: 'Movistar',
      apn: 'gm2m.movistar',
      usuario: 'gm2m',
      clave: 'gm2m',
    },
  ];

  // Listado Continuo
  public config$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ConfigDispositivosVeriboxComponent>,
    private service: ConfigDispositivosService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  public cambioApn(label: string) {
    const apnData = this.apns.find((a) => a.label === label);
    this.form?.patchValue({
      apn: apnData?.apn,
      usuario: apnData?.usuario,
      clave: apnData?.clave,
    });
  }

  private createForm(): void {
    this.title = this.data?._id
      ? `Editar Configuración del Dispositivo ${this.data.deviceName}`
      : `Crear Configuración del Dispositivo`;

    this.form = this.fb.group({
      frecuenciaComunicacion: [
        this.data?.config?.frecuenciaComunicacion,
        [Validators.required],
      ],
      limiteMin: [
        this.data?.config?.limiteMin,
        [Validators.required, Validators.min(0)],
      ],
      limiteMax: [
        this.data?.config?.limiteMax,
        [Validators.required, Validators.max(50)],
      ],
      apn: [this.data?.config?.apn, [Validators.required]],
      usuario: [this.data?.config?.usuario],
      clave: [this.data?.config?.clave],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public telValido(telefono?: string) {
    return this.regTel.test(telefono || '');
  }

  //

  private getUpsertData() {
    const config: IConfigDispositivoVeribox = this.form?.value;
    config.telefono = this.telefono;
    const data: ICreateConfigDispositivo = {
      deveui: this.data?.deveui,
      config,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data) {
        const data = this.getUpsertData();
        await firstValueFrom(
          this.service.upsert(
            data,
            true,
            this.enviarSms,
            this.enviarInmediatamente,
          ),
        );
        this.helper.notifSuccess('Configuración actualizada');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarConfigDispositivo(): Promise<void> {
    const deveui = this.data.deveui || '';
    this.config$?.unsubscribe();
    this.config$ = this.listadosService
      .subscribe<IConfigDispositivo>('configDispositivo', deveui)
      .subscribe((data) => {
        this.config = data?.config as IConfigDispositivoNSP4G;
        console.log(`listado de configDispositivo`, data);
      });
    await this.listadosService.getLastValue('configDispositivo', deveui);
  }

  private updateForm() {
    if (this.config || this.data) {
      const frecuenciaComunicacion = +(
        this.config?.frecuenciaComunicacion ||
        this.data?.config?.frecuenciaComunicacion
      );

      this.form?.patchValue({
        frecuenciaComunicacion,
        limiteMin: this.config?.limiteMin || this.data?.config?.limiteMin,
        limiteMax: this.config?.limiteMax || this.data?.config?.limiteMax,
        apn: this.config?.apn || this.data?.config?.apn,
        usuario: this.config?.usuario || this.data?.config?.usuario,
        clave: this.config?.clave || this.data?.config?.clave,
      });

      const apn = this.form?.get('apn')?.value;
      if (apn) {
        this.apnLabel = this.apns.find((a) => a.apn === apn)?.label;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([this.listarConfigDispositivo()]);
    this.telefono = this.data.config?.telefono;
    this.updateForm();
  }

  ngOnDestroy(): void {
    this.config$?.unsubscribe();
  }
}
