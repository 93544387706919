import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IListado,
  IMantenimiento,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';
import { CrearEditarMantenimientoComponent } from '../crear-editar-mantenimiento/crear-editar-mantenimiento.component';
import { MantenimientosService } from '../mantenimientos.service';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-listado-mantenimientos',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './listado-mantenimientos.component.html',
  styleUrl: './listado-mantenimientos.component.scss',
})
export class ListadoMantenimientosComponent implements OnInit, OnDestroy {
  @Input() puntoMedicion?: IPuntoMedicion;

  ///// Tabla nueva
  public name = 'ListadoMantenimientosComponent';
  public datos?: IMantenimiento[] = [];
  public columnas?: IColumnas<IMantenimiento>[];
  public totalCount = 0;

  // QUERY+
  public populate: IPopulate[] = [
    {
      path: 'dispositivo',
      select: 'deviceName deveui',
    },
    {
      path: 'correctora',
      select: 'modelo numeroSerie',
    },
    // {
    //   path: 'unidadPresion',
    //   select: 'modelo numeroSerie',
    // },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaCreacion',
    filter: JSON.stringify({}),
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private matDialog: MatDialog,
    private service: MantenimientosService,
    private dialogService: DialogService,
  ) {}

  public async listar(): Promise<void> {
    const filter = HelperService.filtroToObject<IMantenimiento>(
      this.query.filter,
    );
    const idPuntoMedicion = this.puntoMedicion?._id;
    const idCorrectora = this.puntoMedicion?.correctora?._id;
    const idUnidadPresion = this.puntoMedicion?.unidadPresion?._id;
    const idDispositivo =
      this.puntoMedicion?.correctora?.dispositivo?._id ||
      this.puntoMedicion?.unidadPresion?.dispositivo?._id;

    const ids = [idPuntoMedicion, idCorrectora, idUnidadPresion, idDispositivo];
    const ids2 = ids.filter((id) => id) as string[];

    filter.idAsignado = { $in: ids2 };
    this.query.filter = JSON.stringify(filter);

    //

    this.datos$?.unsubscribe();
    this.datos$ = this.listados
      .subscribe<IListado<IMantenimiento>>('mantenimientos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de mantenimientos`, data);
      });
    await this.listados.getLastValue('mantenimientos', this.query);
  }

  public async editar(data: IMantenimiento) {
    const config: MatDialogConfig = {
      data: { edit: data },
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMantenimientoComponent, config);
  }

  public async eliminar(data: IMantenimiento) {
    const res = await this.dialogService.confirm(
      `Confirmación`,
      `¿Desea eliminar el mantenimiento?`,
    );
    if (!res) {
      return;
    }

    try {
      await firstValueFrom(this.service.eliminar(data._id!));
      this.helper.notifSuccess(`Mantenimiento eliminado`);
    } catch (error) {
      this.helper.notifError(error);
      console.error(`Error al eliminar mantenimiento`, error);
    }
  }

  private setColumnas() {
    this.columnas = [
      {
        header: { label: 'Fecha', sortable: true },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: { label: 'Nombre', sortable: true },
        row: {
          parse(dato) {
            if (dato.correctora) {
              return `${dato.correctora.modelo} S/N: ${dato.correctora.numeroSerie}`;
            }
            // if (dato.unidadPresion) {
            //   return `${dato.unidadPresion.modelo} S/N: ${dato.unidadPresion.numeroSerie}`;
            // }
            if (dato.dispositivo) {
              return `${dato.dispositivo.deviceName} Deveui: ${dato.dispositivo.deveui}`;
            }
            return;
          },
        },
      },
      {
        header: { label: 'Tipo', sortable: true },
        row: {
          field: 'tipo',
        },
      },
      {
        header: { label: 'Descripción' },
        row: {
          field: 'descripcion',
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              oculta: (dato) => !this.helper.puedeEditarMantenimiento(dato),
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              oculta: (dato) => !this.helper.puedeEliminarMantenimiento(dato),
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
