<div
  style="padding: 0.5em; min-width: 500px"
  (click)="$event.stopPropagation()"
  class="fondo redondo-abajo"
>
  <ng-container *ngIf="!mostrarConfig">
    <!-- Titulo -->
    <div fxLayour="row" fxLayoutAlign="space-between center">
      <!-- Sin Notificaciones para mostrar -->
      <h1 *ngIf="!totalCount" style="padding: 0 0.5em; font-size: 30px">
        Sin Notificaciones
      </h1>

      <!-- Viendo notif sin leer -->
      <h1
        *ngIf="totalCount && !verLeidas"
        style="padding-left: 0.5em; font-size: 20px"
      >
        <span
          class="bg-warn"
          style="border-radius: 15px; padding: 0 15px; margin-right: 0.5em"
          >{{ totalCount }}</span
        >
        {{ totalCount === 1 ? "Notificación" : "Notificaciones" }} sin leer
      </h1>

      <!-- Viendo notif leidas -->
      <h1 *ngIf="totalCount && verLeidas" style="padding-left: 0.5em">
        {{ totalCount }}
        {{ totalCount === 1 ? "Notificación" : "Notificaciones" }}
      </h1>

      <div fxLayout="column">
        <!-- No Leídas  -->
        <p style="padding-right: 0.5em; margin: 0">
          <small
            style="cursor: pointer"
            [style.color]="GREEN"
            (click)="toggleVerTodas()"
          >
            <span>{{ !verLeidas ? "ver leídas" : "ver no leídas" }}</span>
          </small>
        </p>
        <!-- Marcar leídas -->
        <p style="padding-right: 0.5em; margin: 0" *ngIf="totalCount">
          <small
            style="cursor: pointer"
            [style.color]="RED"
            (click)="marcarTodasComoLeidas()"
          >
            <span>marcar todas como leídas</span>
          </small>
        </p>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>

    <!-- Imagen -->
    <!-- <ng-container *ngIf="!totalCount">
      <img src="assets/images/no_notif.png" alt="" style="width: 400px" />
    </ng-container> -->

    <!-- Notificaciones -->
    <ng-container *ngIf="totalCount">
      <ng-container *ngFor="let n of notificaciones">
        <div class="row">
          <div class="row-start">
            <mat-icon>notifications</mat-icon>
          </div>
          <div class="row-center">
            <div class="column" style="align-items: flex-start">
              <h3 style="font-size: small; margin: 0; text-align: start">
                {{ n.titulo }}
              </h3>
              <p
                style="
                  font-size: x-small;
                  margin: 0;
                  text-align: start;
                  line-height: normal;
                "
              >
                {{ n.mensaje }}
              </p>
              <p
                style="
                  margin: 0;
                  font-size: xx-small;
                  text-align: start;
                  line-height: normal;
                "
                class="fecha-notificacion"
              >
                {{ n.fechaCreacion | date : "dd/MM/yyyy" }}
              </p>
            </div>
          </div>
          <div class="row-end">
            <button
              *ngIf="!verLeidas"
              mat-icon-button
              matTooltip="Marar como leida"
              (click)="marcarComoLeida(n)"
            >
              <mat-icon color="warn">archive</mat-icon>
            </button>
            <button
              *ngIf="verLeidas"
              mat-icon-button
              matTooltip="Marar como no leida"
              (click)="marcarComoNoLeida(n)"
            >
              <mat-icon color="accent">unarchive</mat-icon>
            </button>
            <button
              *ngIf="verLeidas"
              mat-icon-button
              matTooltip="Eliminar"
              (click)="eliminar(n)"
            >
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Paginacion -->
      <mat-paginator
        [length]="totalCount"
        [pageSize]="5"
        [pageSizeOptions]="[5]"
        [showFirstLastButtons]="true"
        (page)="pageEvent($event)"
      ></mat-paginator>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="mostrarConfig">
    <app-configurar-notificaciones></app-configurar-notificaciones>
  </ng-container>

  <mat-divider class="divider"></mat-divider>

  <!-- Configuracion -->
  <div class="centro-boton">
    <p class="boton-config" (click)="toggleConfig()">
      <!-- <span>Configurar Notificaciones</span> -->
      {{ mostrarConfig ? "Volver" : "Configurar Notificaciones" }}
    </p>
  </div>
</div>
