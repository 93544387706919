import { Injectable } from '@angular/core';
import { HttpService } from '../../auxiliares/http.service';
import { IQueryParam, IListado, INotificacion } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';

@Injectable({ providedIn: 'root' })
export class NotificacionesService {
  constructor(private htpp: HttpService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<INotificacion>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.htpp.get(`/notificacions`, { params });
  }

  public cantidadSinLeer(): Observable<{ totalCount: number }> {
    return this.htpp.get(`/notificacions/countDocuments`);
  }

  public marcarComoLeida(id: string): Observable<INotificacion> {
    return this.htpp.put(`/notificacions/${id}/leida`, {});
  }

  public marcarComoNoLeida(id: string): Observable<INotificacion> {
    return this.htpp.put(`/notificacions/${id}/noLeida`, {});
  }

  public eliminar(id: string): Observable<void> {
    return this.htpp.delete(`/notificacions/${id}`);
  }

  public marcarTodasComoLeidas(): Observable<void> {
    return this.htpp.put(`/notificacions/marcarLeidas`, {});
  }
}
