import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Agrupacion,
  IAgrupacion,
  ICentroOperativo,
  ICreateEnvioSms,
  IEnvioSms,
  IFilter,
  IGrupo,
  IListado,
  ILocalidad,
  IPopulate,
  IQueryParam,
  IUnidadNegocio,
  IUpdateEnvioSms,
  IUsuario,
  TipoAlerta,
  TipoEnvio,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { EnvioSmssService } from '../service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar',
  templateUrl: './crear-editar.component.html',
  styleUrls: ['./crear-editar.component.scss'],
})
export class CrearEditarComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public tiposAlertas: TipoAlerta[] = [
    'Unidades Presión - Fuera de límite',
    'Unidades Presión - Error en reporte de alarma',
    'Unidades Presión - Sensor desconectado',
  ];
  public tiposEnvio: TipoEnvio[] = [
    'SMS',
    'WhatsApp',
    'Llamada',
    'Notificacion Push',
  ];
  public agrupaciones: Agrupacion[] = [];
  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public grupos: IGrupo[] = [];
  public agrupaciones2: IAgrupacion[] = [];
  public localidads: ILocalidad[] = [];
  public usuarios: IUsuario[] = [];
  public usuariosFiltrados: IUsuario[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;
  public grupos$?: Subscription;
  public agrupaciones$?: Subscription;
  public localidads$?: Subscription;
  public usuarios$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEnvioSms,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarComponent>,
    private service: EnvioSmssService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Configuración'
      : 'Crear Configuración';

    this.form = this.fb.group({
      tiposAlerta: [
        this.data?.tiposAlerta || ['Unidades Presión - Fuera de límite'],
      ],
      tipoEnvio: [this.data?.tipoEnvio || 'SMS'],
      agrupacion: [this.data?.agrupacion],
      idUnidadNegocio: [this.data?.idUnidadNegocio],
      idCentroOperativo: [this.data?.idCentroOperativo],
      idGrupo: [this.data?.idGrupo],
      idAgrupacion: [this.data?.idAgrupacion],
      idLocalidad: [this.data?.idLocalidad],
      idsUsuarios: [this.data?.idsUsuarios],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateEnvioSms = this.form?.value;
    switch (data.agrupacion) {
      case 'Centro Operativo': {
        const centroOperativo = this.centroOperativos.find(
          (co) => co._id === data.idCentroOperativo,
        );
        data.idUnidadNegocio = centroOperativo?.idUnidadNegocio;
        break;
      }
      case 'Grupo': {
        const grupo = this.grupos.find((e) => e._id === data.idGrupo);
        data.idUnidadNegocio = grupo?.idUnidadNegocio;
        data.idCentroOperativo = grupo?.idCentroOperativo;
        break;
      }
      case 'Localidad': {
        const localidad = this.localidads.find(
          (e) => e._id === data.idLocalidad,
        );
        data.idUnidadNegocio = localidad?.idUnidadNegocio;
        data.idCentroOperativo = localidad?.idCentroOperativo;
        break;
      }
    }
    return data;
  }

  private getUpdateData() {
    const data: IUpdateEnvioSms = this.form?.value;
    switch (data.agrupacion) {
      case 'Centro Operativo': {
        const centroOperativo = this.centroOperativos.find(
          (co) => co._id === data.idCentroOperativo,
        );
        data.idUnidadNegocio = centroOperativo?.idUnidadNegocio;
        break;
      }
      case 'Grupo': {
        const grupo = this.grupos.find((e) => e._id === data.idGrupo);
        data.idUnidadNegocio = grupo?.idUnidadNegocio;
        data.idCentroOperativo = grupo?.idCentroOperativo;
        break;
      }
      case 'Localidad': {
        const localidad = this.localidads.find(
          (e) => e._id === data.idLocalidad,
        );
        data.idUnidadNegocio = localidad?.idUnidadNegocio;
        data.idCentroOperativo = localidad?.idCentroOperativo;
        break;
      }
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  //

  public searchUsuario = (term: string, item: IUsuario) => {
    if (item.username?.toLowerCase().includes(term.toLowerCase())) return true;
    if (
      item.datosPersonales?.telefono?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    return false;
  };

  public cambioAgrupacion(): void {
    this.usuariosFiltrados = [];
    const agrupacion = this.form?.get('agrupacion')?.value;
    if (agrupacion === 'Global') {
      this.usuariosFiltrados = this.usuarios.filter(
        (usuario) =>
          usuario.permisos?.some((permiso) => {
            return permiso.nivel === 'Global';
          }) ?? false,
      );
    }
  }

  public cambioUnidadNegocio(): void {
    this.usuariosFiltrados = [];
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    if (idUnidadNegocio) {
      this.usuariosFiltrados = this.usuarios.filter(
        (usuario) =>
          usuario.permisos?.some((permiso) => {
            return (
              permiso.nivel === 'Global' ||
              (permiso.division === 'Presión' &&
                permiso.idsUnidadNegocios?.includes(idUnidadNegocio))
            );
          }) ?? false,
      );
    }
  }

  public cambioCentroOperativo(): void {
    this.usuariosFiltrados = [];
    const idCentroOperativo = this.form?.get('idCentroOperativo')?.value;
    const centroOperativo = this.centroOperativos.find(
      (co) => co._id === idCentroOperativo,
    );
    const idUnidadNegocio = centroOperativo?.idUnidadNegocio || '';
    if (idCentroOperativo) {
      this.usuariosFiltrados = this.usuarios.filter(
        (usuario) =>
          usuario.permisos?.some((permiso) => {
            return (
              permiso.nivel === 'Global' ||
              (permiso.division === 'Presión' &&
                (permiso.idsCentroOperativos?.includes(idCentroOperativo) ||
                  permiso.idsUnidadNegocios?.includes(idUnidadNegocio)))
            );
          }) ?? false,
      );
    }
  }

  public cambioGrupo(): void {
    this.usuariosFiltrados = [];
    const idGrupo = this.form?.get('idGrupo')?.value;
    const grupo = this.grupos.find((grupo) => grupo._id === idGrupo);
    const idUnidadNegocio = grupo?.idUnidadNegocio;
    const idCentroOperativo = grupo?.idCentroOperativo || '';
    if (idUnidadNegocio) {
      this.usuariosFiltrados = this.usuarios.filter(
        (usuario) =>
          usuario.permisos?.some((permiso) => {
            return (
              permiso.nivel === 'Global' ||
              (permiso.division === 'Presión' &&
                (permiso.idsUnidadNegocios?.includes(idUnidadNegocio) ||
                  permiso.idsCentroOperativos?.includes(idCentroOperativo)))
            );
          }) ?? false,
      );
    }
  }

  public cambioIdAgrupacion(): void {
    this.usuariosFiltrados = [];
    const idAgrupacion = this.form?.get('idAgrupacion')?.value;

    this.usuariosFiltrados = this.usuarios.filter(
      (usuario) =>
        usuario.permisos?.some((permiso) => {
          return (
            permiso.nivel === 'Global' ||
            (permiso.division === 'Presión' &&
              permiso.idsAgrupaciones?.includes(idAgrupacion))
          );
        }) ?? false,
    );
  }

  public cambioLocalidad(): void {
    this.usuariosFiltrados = [];
    const idLocalidad = this.form?.get('idLocalidad')?.value;
    const localidad = this.localidads.find(
      (localidad) => localidad._id === idLocalidad,
    );
    const idCentroOperativo = localidad?.idCentroOperativo || '';
    const idUnidadNegocio = localidad?.idUnidadNegocio || '';

    this.usuariosFiltrados = this.usuarios.filter(
      (usuario) =>
        usuario.permisos?.some((permiso) => {
          return (
            permiso.nivel === 'Global' ||
            (permiso.division === 'Presión' &&
              (permiso.idsLocalidades?.includes(idLocalidad) ||
                permiso.idsCentroOperativos?.includes(idCentroOperativo) ||
                permiso.idsUnidadNegocios?.includes(idUnidadNegocio!)))
          );
        }) ?? false,
    );
  }

  public cambioUsuarios(): void {
    console.log(this.form);
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    if (
      !(
        HelperService.adminGlobal() ||
        HelperService.rolesEnCualquierUnidadNegocio(['Administrador'])
      )
    )
      return;

    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarGrupos(): Promise<void> {
    const populate: IPopulate = {
      path: 'unidadNegocio',
      select: 'nombre',
    };
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio idCentroOperativo',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private async listarAgrupaciones(): Promise<void> {
    if (!HelperService.adminGlobal()) return;

    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.agrupaciones$?.unsubscribe();
    this.agrupaciones$ = this.listadosService
      .subscribe<IListado<IAgrupacion>>('agrupacions', query)
      .subscribe((data) => {
        this.agrupaciones2 = data.datos;
        console.log(`listado de agrupaciones`, data);
      });
    await this.listadosService.getLastValue('agrupacions', query);
  }

  private async listarLocalidades(): Promise<void> {
    const populate: IPopulate = [
      {
        path: 'unidadNegocio',
        select: 'nombre',
      },
      // {
      //   path: 'centroOperativo',
      //   select: 'nombre',
      // },
    ];
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio idCentroOperativo',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.localidads$?.unsubscribe();
    this.localidads$ = this.listadosService
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidads = data.datos;
        console.log(`listado de localidades`, data);
      });
    await this.listadosService.getLastValue('localidads', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    const populate: IPopulate = {
      path: 'unidadNegocio',
      select: 'nombre',
    };
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarUsuarios(): Promise<void> {
    const filter: IFilter<any> = {
      $and: [
        { 'datosPersonales.telefono': { $exists: true } },
        { 'datosPersonales.telefono': { $ne: null } },
      ],
    };
    const query: IQueryParam = {
      select: 'username datosPersonales.telefono permisos',
      sort: 'username',
      filter: JSON.stringify(filter),
    };
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listadosService
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.usuarios = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listadosService.getLastValue('usuarios', query);
  }

  //

  private setAgrupacionesIniciales() {
    const todas: Agrupacion[] = [
      'Global',
      'Unidad de Negocio',
      'Centro Operativo',
      'Localidad',
      'Grupo',
      'Agrupacion',
    ];
    const unidadNegocio: Agrupacion[] = [
      'Unidad de Negocio',
      'Centro Operativo',
      'Grupo',
      'Localidad',
    ];
    const centroOperativo: Agrupacion[] = [
      'Centro Operativo',
      'Grupo',
      'Localidad',
    ];

    const nivel = HelperService.nivelMasAltoPorDivision('Presión');
    switch (nivel) {
      case 'Global': {
        this.agrupaciones = todas;
        break;
      }
      case 'Unidad de Negocio': {
        this.agrupaciones = unidadNegocio;
        break;
      }
      case 'Centro Operativo': {
        this.agrupaciones = centroOperativo;
        break;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.setAgrupacionesIniciales();
    this.createForm();

    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
      this.listarGrupos(),
      this.listarAgrupaciones(),
      this.listarLocalidades(),
      this.listarUsuarios(),
    ]);

    if (this.data) {
      switch (this.data.agrupacion) {
        case 'Global': {
          this.cambioAgrupacion();
          break;
        }
        case 'Unidad de Negocio': {
          this.cambioUnidadNegocio();
          break;
        }
        case 'Centro Operativo': {
          this.cambioCentroOperativo();
          break;
        }
        case 'Grupo': {
          this.cambioGrupo();
          break;
        }
        case 'Agrupacion': {
          this.cambioIdAgrupacion();
          break;
        }
        case 'Localidad': {
          this.cambioLocalidad();
          break;
        }
      }
      console.log(this.usuariosFiltrados.length);
    }
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.agrupaciones$?.unsubscribe();
    this.usuarios$?.unsubscribe();
  }
}
