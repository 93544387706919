import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  IMedidorResidencialAgua,
  ICreateMedidorResidencialAgua,
  IUpdateMedidorResidencialAgua,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class MedidorResidencialesAguaService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IMedidorResidencialAgua>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/medidorresidencialagua`, { params });
  }

  public crear(
    dato: ICreateMedidorResidencialAgua,
  ): Observable<IMedidorResidencialAgua> {
    return this.http.post(`/medidorresidencialagua`, dato);
  }

  public listarPorId(id: string): Observable<IMedidorResidencialAgua> {
    return this.http.get(`/medidorresidencialagua/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateMedidorResidencialAgua,
  ): Observable<IMedidorResidencialAgua> {
    return this.http.put(`/medidorresidencialagua/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/medidorresidencialagua/${id}`);
  }
}
