<div class="fondo-85 container" style="border-bottom-left-radius: 30px">
  <div
    style="
      padding: 1em;
      height: calc(100vh - 1em - 170px);
      display: flex;
      flex-direction: column;
    "
  >
    <section class="layout">
      <div class="grow1">
        <mat-form-field>
          <mat-label>División</mat-label>
          <mat-select
            [(ngModel)]="division"
            (valueChange)="onChangeDivision($event)"
          >
            @for (d of divisiones; track d) {
              <mat-option [value]="d" [disabled]="d === 'Correctoras'">{{
                d
              }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grow1">
        <ng-select
          *ngIf="puntos"
          [items]="puntos"
          placeholder="Puntos de Medición"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          [(ngModel)]="puntosSeleccionados"
          [multiple]="true"
          bindLabel="nombre"
          [appendTo]="'body'"
        >
        </ng-select>
      </div>
    </section>

    <div style="height: 100%; overflow: auto">
      @if (puntosSeleccionados.length > 0) {
        <h2>Gráfico diario</h2>

        <app-grafico-min-max-multi
          [puntosMedicion]="puntosSeleccionados"
          [range]="range"
          [style]="
            'width: 100%; height: 600px; display: block; margin-top: 30px'
          "
        ></app-grafico-min-max-multi>

        <h2>Gráfico 5 minutos</h2>

        <mat-form-field>
          <mat-label>Día</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="fecha5Min"
            (dateChange)="cambioFecha5Min($event.value)"
          />
          <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <app-grafico-presion-5-min
          [puntosMedicion]="puntosSeleccionados"
          [range]="range5Min"
          [style]="
            'width: 100%; height: 600px; display: block; margin-top: 30px'
          "
        ></app-grafico-presion-5-min>
      } @else {
        <h2 style="text-align: center">Seleccione los puntos a comparar</h2>
      }
    </div>
  </div>
</div>
