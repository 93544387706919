<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="login"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- mensaje -->
      <div
        [innerHTML]="
          'Este proceso envía un SMS al dispositivo para que comience a enviar los datos a la plataforma'
        "
        class="bg-warn"
        style="
          text-align: center;
          border-radius: 7px;
          padding: 0.5em;
          font-size: 1.2em;
          margin-bottom: 2em;
        "
      ></div>

      <!-- Teléfono  -->
      <mat-form-field>
        <mat-label>Teléfono</mat-label>
        <input matInput type="string" formControlName="telefono" />
      </mat-form-field>

      <!-- Checkbox de migracion automática -->
      <mat-checkbox formControlName="migrar">
        Migración automática (periodicamente repite el envio del sms si el
        dispositivo aún no se ha migrado)
      </mat-checkbox>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="login"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="login"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
