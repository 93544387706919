import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  ILogTwilio,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-log-twilio',
  templateUrl: './listado-log-twilio.component.html',
  styleUrls: ['./listado-log-twilio.component.scss'],
})
export class ListadoLogTwilioComponent implements OnInit, OnChanges, OnDestroy {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() unidadPresion?: IUnidadPresion;

  ///// Tabla nueva
  public name = 'ListadoLogTwilioComponent';
  public datos?: ILogTwilio[] = [];
  public columnas?: IColumnas<ILogTwilio>[];
  public totalCount = 0;

  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaCreacion',
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // Listar

  public async listar(): Promise<void> {
    let filter: IFilter<ILogTwilio> | boolean = false;

    const deveui =
      this.puntoMedicion?.unidadPresion?.deveui || this.unidadPresion?.deveui;

    if (deveui) {
      filter = {
        'device.deveui': deveui,
      } as any;
    }

    if (filter) {
      this.query.filter = JSON.stringify(filter);

      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<ILogTwilio>>('logTwilios', this.query)
        .subscribe((data) => {
          console.log(`listado de logTwilios`, data);
          this.totalCount = data.totalCount;
          this.datos = data.datos;
        });
      await this.listadosService.getLastValue('logTwilios', this.query);
    }
  }

  private setColumnas() {
    this.columnas = [
      // Fecha
      {
        header: { label: 'Fecha', sortable: true, sort: 'fechaCreacion' },
        row: {
          parse(dato) {
            return dato.fechaCreacion;
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      // Telefono
      {
        header: { label: 'Teléfono' },
        row: {
          field: 'telefono',
        },
      },
      // Mensaje
      {
        header: { label: 'Mensaje' },
        row: {
          field: 'mensaje',
        },
      },
      // Respuesta
      {
        header: { label: 'Respuesta' },
        row: {
          html(dato) {
            const status = dato.respuesta?.status;
            const error = dato.respuesta?.errorMessage;
            let html = `<div>`;
            if (status) {
              html += `<div>${status}</div>`;
            }
            if (error) {
              html += `<div>${error}</div>`;
            }
            html += `</div>`;
            return html;
          },
        },
      },
    ];
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
