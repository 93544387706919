import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoCorrectorasComponent } from './listado-correctoras/listado-correctoras.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CorrectorasRoutingModule } from './correctoras.routing.module';
import { CrearEditarCorrectorasComponent } from './crear-editar-correctoras/crear-editar-correctoras.component';
import { DetallesCorrectorasComponent } from './detalles-correctoras/detalles-correctoras.component';
import { ListadoRegistrosComponent } from '../../standalone/listado-registros/listado-registros.component';
import { GeocodeService } from '../../auxiliares/geocode.service';
import { ListadoAplicacionCromatografiasComponent } from '../../standalone/listado-aplicacion-cromatografias/listado-aplicacion-cromatografias.component';
import { UltimoRegistroNucComponent } from '../../standalone/ultimo-registro-nuc/ultimo-registro-nuc.component';
import { UltimaCromatografiaComponent } from '../../standalone/ultima-cromatografia/ultima-cromatografia.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { ResumenCorrectoraComponent } from '../../standalone/resumen-correctora/resumen-correctora.component';
import { DetallesNucComponent } from '../../standalone/detalles-nuc/detalles-nuc.component';
import { ListadoRegistrosFaltantesComponent } from '../../standalone/listado-registros-faltantes/listado-registros-faltantes.component';
import { GraficoCorrectoraComponent } from '../../standalone/grafico-correctora/grafico-correctora.component';
import { ListadoAlertasComponent } from '../../standalone/listado-alertas/listado-alertas.component';

@NgModule({
  declarations: [
    ListadoCorrectorasComponent,
    CrearEditarCorrectorasComponent,
    DetallesCorrectorasComponent,
    // Detalles
    ChangelogComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    CorrectorasRoutingModule,
    ResumenCorrectoraComponent,
    UltimaCromatografiaComponent,
    DetallesNucComponent,
    UltimoRegistroNucComponent,
    ListadoAplicacionCromatografiasComponent,
    ListadoRegistrosComponent,
    ListadoRegistrosFaltantesComponent,
    GraficoCorrectoraComponent,
    ListadoAlertasComponent,
  ],
  providers: [GeocodeService],
})
export class CorrectorasModule {}
