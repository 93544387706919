import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDispositivo,
  ICreateConfigDispositivo,
  IConfigDispositivo,
  IConfigDispositivoNSP4G,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { MODOS_OPERACION_NSP } from '../../../../environments/datos';
import { ConfigDispositivosService } from '../../dispositivos/config-dispositivos.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-config-unidad-presion',
  templateUrl: './config-unidad-presion.component.html',
  styleUrls: ['./config-unidad-presion.component.scss'],
})
export class ConfigUnidadPresionComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public config?: IConfigDispositivoNSP4G;

  public modosOperacion = MODOS_OPERACION_NSP;

  // Listado Continuo
  public config$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ConfigUnidadPresionComponent>,
    private service: ConfigDispositivosService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Configuración de Alertas'
      : 'Crear Configuración de Alertas';

    this.form = this.fb.group({
      timestampBloqueo: [this.config?.timestampBloqueo || this.horaInicial()],
    });

    console.log(`this.form`, this.form);
  }

  public close(): void {
    this.dialogRef.close();
  }

  private horaInicial() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(6, 0, 0, 0);
    return date;
  }

  //

  private getUpsertData() {
    const timestamp = this.form?.value?.timestampBloqueo;
    const timestampBloqueo = timestamp
      ? Math.trunc(new Date(timestamp).getTime() / 1000)
      : 0;

    const config: IConfigDispositivoNSP4G = {
      timestampBloqueo,
    };
    const data: ICreateConfigDispositivo = {
      deveui: this.data.deveui,
      config,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpsertData();
        await firstValueFrom(this.service.upsert(data));
        this.helper.notifSuccess('Configuración actualizada');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarConfigDispositivo(): Promise<void> {
    const deveui = this.data.deveui || '';
    this.config$?.unsubscribe();
    this.config$ = this.listadosService
      .subscribe<IConfigDispositivo>('configDispositivo', deveui)
      .subscribe((data) => {
        this.config = data.config as IConfigDispositivoNSP4G;
        console.log(`listado de configDispositivo`, data);
      });
    await this.listadosService.getLastValue('configDispositivo', deveui);
  }

  private updateForm() {
    this.form?.patchValue({
      horaInicio: this.config?.horaInicio || this.data?.config?.horaInicio,
      modoEnv: this.config?.modoEnv || this.data?.config?.modoEnv,
      modoOperacion: [this.config?.modoOperacion, Validators.required],
      limiteMin: [this.config?.limiteMin, Validators.required],
      limiteMax: [this.config?.limiteMax, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([this.listarConfigDispositivo()]);
    this.updateForm();
  }

  ngOnDestroy(): void {
    this.config$?.unsubscribe();
  }
}
