<app-resumen-unidad-presion
  [unidadPresion]="unidadPresion"
></app-resumen-unidad-presion>

<!-- Ultimo Reporte - NSP/Veribox - Presion Actual -->
<div fxLayout="row" fxLayout.lt-md="column">
  <!-- Último Reporte y ultima alerta -->
  <div
    class="fondo-85"
    style="margin: 1em 1em 1em 0; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <app-ultimo-reporte-unidad-presion
      *ngIf="unidadPresion"
      [unidadPresion]="unidadPresion"
    ></app-ultimo-reporte-unidad-presion>
  </div>

  <!-- NSP / VERIBOX -->
  <div
    *ngIf="unidadPresion?.dispositivo"
    class="fondo-85"
    style="margin: 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <app-detalles-nsp
      *ngIf="unidadPresion?.dispositivo?.tipoDispositivo === 'NSP'"
      [unidadPresion]="unidadPresion"
      [configActual]="configActual"
      [configDeseada]="configDeseada"
    ></app-detalles-nsp>

    <app-detalles-veribox
      *ngIf="unidadPresion?.dispositivo?.tipoDispositivo === 'VERIBOX MICRO'"
      [unidadPresion]="unidadPresion"
      [configActual]="configActual"
      [configDeseada]="configDeseada"
      [fechaCreacion]="fechaCreacion"
    ></app-detalles-veribox>
  </div>

  <!-- Sin Dispositivo -->
  <div
    *ngIf="!unidadPresion?.dispositivo"
    class="fondo-85"
    style="margin: 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <div style="height: 100%; display: flex; flex-direction: column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
          <h1
            style="
              font-size: 18px;
              font-weight: 900;
              padding: 0.5em 0 0.5em 0.5em;
              margin: 0;
            "
          >
            Dispositivo
          </h1>
        </div>
      </div>

      <div
        style="
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <h3>
          <strong> Sin asignar </strong>
        </h3>
      </div>
    </div>
  </div>

  <!-- Grafico presion Actual -->
  <div
    class="fondo-85"
    style="margin: 1em 0 1em 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <app-grafico-presion-actual
      [unidadPresion]="unidadPresion"
    ></app-grafico-presion-actual>
  </div>
</div>

<!-- Grafico Max, Min y Agv / 15 dias y Grafico Alertas -->
<div fxLayout="row" fxLayout.lt-md="column" style="margin: 1em 0">
  <!-- Grafico Max, Min y Agv / 15 dias -->
  <div
    class="fondo-85"
    style="margin: 1em 1em 1em 0; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="64"
  >
    <app-grafico-min-max-prom
      [unidadPresion]="unidadPresion"
      [range]="range15Dias"
    >
    </app-grafico-min-max-prom>
  </div>

  <!-- Grafico Alertas -->
  <div
    class="fondo-85"
    style="margin: 1em 0 1em 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="34"
  >
    <app-grafico-alertas [unidadPresion]="unidadPresion"></app-grafico-alertas>
  </div>
</div>

<!-- Time Picker y Grafico Max y Min / 1 Año -->
<div
  style="
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 30px;
  "
  class="fondo-85"
>
  <!-- Titulo -->
  <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
    <h1
      style="
        font-size: 18px;
        font-weight: 900;
        padding: 0.5em 0 0.5em 0.5em;
        margin: 0;
      "
    >
      Presión Histórica
    </h1>
  </div>

  <!-- Time Picker -->
  <div style="margin: 1em 0 1em 0">
    <app-datetime-range-picker
      [(range)]="range"
      [presets]="presets"
    ></app-datetime-range-picker>
  </div>

  <!-- Grafico Max y Min / 1 Año-->
  <div class="fondo-85" style="border-bottom-left-radius: 30px">
    <app-grafico-min-max [unidadPresion]="unidadPresion" [range]="range">
    </app-grafico-min-max>
  </div>
</div>

<!-- Tablas -->
<div style="margin: 1em 0">
  <mat-tab-group>
    <!-- Reportes -->
    <mat-tab label="Reportes">
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-reportes
            [unidadPresion]="unidadPresion"
            [range]="range"
          ></app-listado-reportes>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Alertas -->
    <mat-tab label="Alertas">
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-alertas
            [unidadPresion]="unidadPresion"
          ></app-listado-alertas>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Auditorias -->
    <mat-tab
      label="Auditorias"
      *ngIf="unidadPresion?.dispositivo?.tipoDispositivo === 'VERIBOX MICRO'"
    >
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-auditorias-veribox
            [unidadPresion]="unidadPresion"
          ></app-listado-auditorias-veribox>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Repores Faltantes -->
    <mat-tab
      label="Reportes Faltantes"
      *ngIf="unidadPresion?.dispositivo?.tipoDispositivo !== 'VERIBOX MICRO'"
    >
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-reportes-faltantes
            [unidadPresion]="unidadPresion"
          ></app-listado-reportes-faltantes>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
