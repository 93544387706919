<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Medidor Residencial
      </h1>
      <img
        src="assets/iconos/editar.png"
        alt=""
        *ngIf="helper.puedeEditarMedidor(medidorResidencial)"
        matTooltip="Editar"
        style="width: 30px; margin-right: 0.5em; cursor: pointer"
        (click)="editarMedidorResidencial(medidorResidencial)"
      />
    </div>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <table style="width: 100%">
      <!-- Nombre -->
      <tr>
        <th>Nombre</th>
        <td style="text-align: end">
          {{ medidorResidencial?.nombre || "..." }}
        </td>
      </tr>
      <!-- Número de Serie -->
      <tr>
        <th>Número de Serie</th>
        <td style="text-align: end">
          {{ medidorResidencial?.deviceMeterNumber || "..." }}
        </td>
      </tr>
      <!-- Fecha de Asignación -->
      <tr>
        <th>Fecha de Asignación</th>
        <td style="text-align: end">
          {{
            puntoMedicion?.fechaAsignacionMedidorResidencial
              | date: "short" || "..."
          }}
        </td>
      </tr>
    </table>
  </div>
</div>
