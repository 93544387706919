import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICoordenadas } from 'modelos/src';
import { HelperService } from '../../helper.service';

@Component({
  selector: 'app-ubicar',
  templateUrl: './ubicar.component.html',
  styleUrls: ['./ubicar.component.scss'],
})
export class UbicarComponent implements OnInit {
  title = 'Ubicación';
  coordenadas: ICoordenadas;

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptions)
  );
  public markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  };

  constructor(
    public dialogRef: MatDialogRef<UbicarComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      coordenadas: ICoordenadas;
      title: string;
      markerDraggable: boolean;
    }
  ) {
    this.title = this.data.title || this.title;
    this.mapOptions.center = this.data.coordenadas;
    this.coordenadas = this.data.coordenadas;
    this.markerOptions.draggable = this.data.markerDraggable;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public guardar(): void {
    this.dialogRef.close(this.coordenadas);
  }

  public setLocation(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.coordenadas = event.latLng.toJSON();
    }
  }

  ngOnInit(): void {
    this.mapOptions.zoom = 16;
  }
}
