import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { INotificaciones, IToken, IUsuario } from 'modelos/src';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { api } from '../../../environments/environment';
import { HelperService } from '../../auxiliares/helper.service';
import { WebSocketService } from '../../auxiliares/websocket';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private webSocketService: WebSocketService,
    private router: Router,
  ) {}

  public tieneTelefono = false;

  public getUser: BehaviorSubject<IUsuario> = new BehaviorSubject<IUsuario>(
    LoginService.getUsuario(),
  );

  public isLogged() {
    if (sessionStorage.getItem('token')) {
      return true;
    }
    return false;
  }

  public static getToken() {
    return sessionStorage.getItem('token') || '';
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem('refreshToken') || '';
  }

  public static getUsuario(): IUsuario {
    const user = sessionStorage.getItem('usuario');
    return user ? JSON.parse(user) : undefined;
  }

  public static setUsuario(usuario: IUsuario) {
    sessionStorage.setItem('usuario', JSON.stringify(usuario));
  }

  public removeLoginInfo() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('usuario');
  }

  public setLoginInfo(token: IToken) {
    if (token.user?.datosPersonales?.telefono) {
      this.tieneTelefono = true;
    } else {
      this.tieneTelefono = false;
    }
    sessionStorage.setItem('token', token.accessToken!);
    sessionStorage.setItem('refreshToken', token.refreshToken || '');
    sessionStorage.setItem('usuario', JSON.stringify(token.user));
  }

  public updateNotificaciones(notificaciones: INotificaciones[]) {
    const usuario = LoginService.getUsuario();
    usuario.notificaciones = notificaciones;
    sessionStorage.setItem('usuario', JSON.stringify(usuario));
    this.getUser.next(usuario);
  }

  // LOGIN

  public _login(username: string, password: string): Observable<IToken> {
    return this.http.post<IToken>(`${api}/auth/login`, {
      username,
      password,
    });
  }

  public _forgotPassword(email: string): Observable<void> {
    return this.http.post<void>(`${api}/passwordresets/forgot`, {
      email,
    });
  }

  public _resetPassword(token: string, password: string): Observable<void> {
    return this.http.post<void>(`${api}/passwordresets/reset`, {
      token,
      password,
    });
  }

  public async login(username: string, password: string): Promise<IToken> {
    try {
      const token = await firstValueFrom(this._login(username, password));
      this.setLoginInfo(token!);
      this.webSocketService.initWs();
      return token!;
    } catch (error) {
      this.helper.notifError(error);
      throw error;
    }
  }

  public async forgotPassword(email: string): Promise<void> {
    try {
      await firstValueFrom(this._forgotPassword(email));
    } catch (error) {
      this.helper.notifError(error);
      throw error;
    }
  }

  public async resetPassword(token: string, password: string): Promise<void> {
    try {
      await firstValueFrom(this._resetPassword(token, password));
    } catch (error) {
      this.helper.notifError(error);
      throw error;
    }
  }

  // REFRESH TOKEN

  private _refreshToken(refresh_token: string): Observable<IToken> {
    return this.http.post<IToken>(`${api}/auth/refresh_token`, {
      refresh_token,
    });
  }

  public async refreshToken(): Promise<IToken> {
    try {
      console.log('refreshing token');
      this.webSocketService.closeWS();
      const refresh_token = this.getRefreshToken();
      const token = await firstValueFrom(this._refreshToken(refresh_token));
      this.setLoginInfo(token);
      this.webSocketService.initWs();
      return token;
      // "Invalid grant: refresh token is invalid"
    } catch (error) {
      this.router.navigate(['/login']);
      this.helper.notifError(error);
      throw error;
    }
  }
}
