<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Últimos 15 Días
      </h1>
    </div>
  </div>

  <div *ngIf="chart" class="transparente" style="margin: 1em">
    <app-chart
      [options]="chart"
      style="width: 100%; height: 400px; display: block; margin-top: 30px"
    >
    </app-chart>
  </div>

  <!-- NO hay ultimo registro -->
  <div
    *ngIf="!resumenReportes?.length"
    style="
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <h3>
      <strong> No hay registros </strong>
    </h3>
  </div>
</div>
