<div *ngIf="chart" class="transparente" style="margin: 1em">
  <app-chart [constructorType]="'stockChart'" [options]="chart" [style]="style">
  </app-chart>
</div>

<!-- NO hay ultimo registro -->
<div
  *ngIf="!reportes?.length"
  style="
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  "
>
  <h3>
    <strong> No hay registros </strong>
  </h3>
</div>
