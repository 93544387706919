import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAplicacionCromatografia, IListado, IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class AplicacionCromatografiasService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<IAplicacionCromatografia>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/aplicacioncromatografias`, { params });
  }

  public listarPorId(id: string): Observable<IAplicacionCromatografia> {
    return this.http.get(`/aplicacioncromatografias/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve, reject) => {
      let params = HelperService.getQueryParams(queryParams);

      this.http
        .get(`/aplicacioncromatografias/exportar`, {
          params,
          responseType: 'blob',
        })
        .subscribe(
          (response: any) => {
            try {
              const file = new File(
                [response],
                'aplicacioncromatografias.xlsx'
              );
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          }
        );
    });
  }
}
