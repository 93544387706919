import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HelperService } from '../../../auxiliares/helper.service';
import { Router } from '@angular/router';
import { CambiarPasswordService } from '../cambiar-password.service';
import { LoginService } from '../../login/login.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { firstValueFrom } from 'rxjs';
import { UsuariosService } from '../../usuarios/usuarios.service';

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.scss'],
})
export class CambiarPasswordComponent implements OnInit {
  public hide = true;
  public esModoOscuro = HelperService.esModoOscuro();
  public form: UntypedFormGroup = this.createForm();

  public tel?: string;
  public telActual?: string;
  private regTel = /^(?!0|15)\d{10}$/;

  public telValido = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: CambiarPasswordService,
    private loginService: LoginService,
    private router: Router,
    private helper: HelperService,
    public loading: LoadingService,
    private usuariosService: UsuariosService,
  ) {}

  public async onSubmit() {
    try {
      const password = this.form.get('password')?.value;
      const passwordNuevo = this.form.get('passwordNuevo')?.value;
      const user = await firstValueFrom(
        this.service.cambiarPassword(password, passwordNuevo),
      );

      if (user) {
        this.helper.notifSuccess('Contraseña cambiada con éxito');
        this.loginService.removeLoginInfo();
        this.router.navigateByUrl('login');
      }
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  private createForm() {
    return this.formBuilder.group({
      password: ['', Validators.required],
      passwordNuevo: ['', [Validators.required]],
    });
  }

  public checkTel() {
    if (!this.tel) {
      this.telValido = false;
      return;
    }
    this.telValido = this.regTel.test(this.tel);
  }

  public async cambiarTelefono() {
    if (!this.tel || !this.telValido) return;
    try {
      await firstValueFrom(this.usuariosService.cambiarTelefono(this.tel));
      this.telActual = this.tel;
      this.helper.notifSuccess('Teléfono actualizado correctamente');
    } catch (error) {
      console.error(error);
      this.helper.notifError('Error al actualizar el teléfono');
    }
  }

  ngOnInit(): void {
    const user = LoginService.getUsuario();
    this.telActual =
      user?.datosPersonales?.telefono || 'Sin Teléfono Configurado';
  }
}
