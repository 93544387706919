import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAlerta, IListado, IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class AlertasService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IAlerta>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/alertas`, { params });
  }

  public listarPorId(id: string): Observable<IAlerta> {
    return this.http.get(`/alertas/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve) => {
      const params = HelperService.getQueryParams(queryParams);

      this.http
        .get(`/alertas/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'alertas.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }
}
