import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IGrupo,
  ICreateGrupo,
  IListado,
  IQueryParam,
  IUpdateGrupo,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class GruposService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IGrupo>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/grupos`, { params });
  }

  public crear(dato: ICreateGrupo): Observable<IGrupo> {
    return this.http.post(`/grupos`, dato);
  }

  public listarPorId(id: string): Observable<IGrupo> {
    return this.http.get(`/grupos/${id}`);
  }

  public editar(id: string, dato: IUpdateGrupo): Observable<IGrupo> {
    return this.http.put(`/grupos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/grupos/${id}`);
  }
}
