<div class="mat-elevation-z8">
  <!-- Tabla faltantes -->
  <div [style]="totalCount === 0 ? 'display: none' : ''">
    <mat-table [dataSource]="dataSource">
      <!-- Columna Fecha -->
      <ng-container matColumnDef="fecha">
        <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span>{{ row | date: "short" }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnas; sticky: true">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <!-- Mensaje no hay reportes faltantes -->
  <div *ngIf="!loading.getLoading() && totalCount === 0">
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2 style="text-align: center">No hay reportes faltantes</h2>
    </div>
  </div>
</div>

<!-- Spinner de carga -->
<div
  class="mat-elevation-z8"
  *ngIf="loading.getLoading()"
  fxLayout="row"
  fxLayoutAlign="center center"
  style="padding: 1em"
>
  <mat-progress-spinner
    mode="indeterminate"
    diameter="100"
    strokeWidth="10"
  ></mat-progress-spinner>
</div>
