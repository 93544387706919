import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRegistro, IListado, IQueryParam, ICorrectora } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

export interface IInformeExportacion {
  correctoras: number;
  registros: number;
  faltantes: number;
}
export interface IResponseGetRegistro {
  registros: number[];
}

@Injectable({
  providedIn: 'root',
})
export class RegistrosService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IRegistro>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/registros`, { params });
  }

  public listarPorId(id: string): Observable<IRegistro> {
    return this.http.get(`/registros/${id}`);
  }

  public eliminar(id: string): Observable<IRegistro> {
    return this.http.delete(`/registros/${id}`);
  }

  public faltantes(idCorrectora?: string): Observable<IResponseGetRegistro> {
    return this.http.get(`/registros/faltantes/idCorrectora/${idCorrectora}`);
  }

  public faltantesPorPunto(id?: string): Observable<IResponseGetRegistro> {
    return this.http.get(`/registros/faltantes/puntoDeMedicion/${id}`);
  }

  public verificar(queryParams?: IQueryParam): Observable<IInformeExportacion> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/registros/verificar`, { params });
  }

  public async exportar(
    queryParams?: IQueryParam,
    filename = 'export.xlsx',
  ): Promise<void> {
    const params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/registros/exportar`, filename, params);
  }

  public async exportarFaltantes(
    queryParams?: IQueryParam,
    filename = 'export.xlsx',
  ): Promise<void> {
    const params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/registros/exportar/faltantes`, filename, params);
  }

  // Auxiliares
  public getCorrectedTotalizado(
    registro: IRegistro,
    correctora?: ICorrectora,
  ): number {
    if (this.isNumber(registro.correctedTotalizado)) {
      return registro.correctedTotalizado!;
    } else if (registro.corrected || registro.corrected === 0) {
      if (
        correctora?.modelo === 'Mercury' ||
        correctora?.modelo === 'Dresser2'
      ) {
        return registro.corrected;
      }
    }
    return -1;
  }
  public getCorrectedParcializado(
    registro: IRegistro,
    correctora?: ICorrectora,
  ): number {
    if (this.isNumber(registro.correctedParcializado)) {
      return registro.correctedParcializado!;
    } else if (registro.corrected || registro.corrected === 0) {
      if (correctora?.modelo === 'Proser' || correctora?.modelo === 'Corus') {
        return registro.corrected;
      }
    }
    return -1;
  }
  public getUncorrectedTotalizado(
    registro: IRegistro,
    correctora?: ICorrectora,
  ): number {
    if (this.isNumber(registro.uncorrectedTotalizado)) {
      return registro.uncorrectedTotalizado!;
    } else if (registro.uncorrected || registro.uncorrected === 0) {
      if (
        correctora?.modelo === 'Mercury' ||
        correctora?.modelo === 'Dresser2'
      ) {
        return registro.uncorrected;
      }
    }
    return -1;
  }
  public getUncorrectedParcializado(
    registro: IRegistro,
    correctora?: ICorrectora,
  ): number {
    if (this.isNumber(registro.uncorrectedParcializado)) {
      return registro.uncorrectedParcializado!;
    } else if (registro.uncorrected || registro.uncorrected === 0) {
      if (correctora?.modelo === 'Proser' || correctora?.modelo === 'Corus') {
        return registro.uncorrected;
      }
    }
    return -1;
  }

  private isNumber(value: any): boolean {
    return typeof value === 'number' && isFinite(value);
  }
}
