import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { HelperService } from '../../auxiliares/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { firstValueFrom } from 'rxjs';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { ReportesService } from '../../modulos/reportes/service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-importar-soxis',
  templateUrl: './importar-soxis.component.html',
  styleUrls: ['./importar-soxis.component.scss'],
})
export class ImportarSoxisComponent implements OnInit, OnDestroy {
  public filename?: string;

  constructor(
    private dialogRef: MatDialogRef<ImportarSoxisComponent>,
    private service: ReportesService,
    public loading: LoadingService,
    private helper: HelperService,
    private dialogService: DialogService,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  public async setFile(event: Event): Promise<void> {
    const filename = (event as any).target?.files?.item(0)?.name;
    const file = (event as any).target?.files?.item(0);

    if (!file) return;

    const resp = await this.dialogService.confirm(
      'Importar Registros',
      `¿Importar los registros del archivo ${filename} ?`,
    );
    if (!resp) return;

    try {
      const initTime = new Date().getTime();
      await firstValueFrom(this.service.importarSoxis(file));
      this.helper.notifSuccess('Registros importados correctamente');
      const endTime = new Date().getTime();
      console.log(
        'Tiempo de importación: ',
        Math.trunc((endTime - initTime) / 1000),
        'segundos',
      );
      this.close();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {}
}
