import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { UsuariosService } from 'src/app/modulos/usuarios/usuarios.service';
import { firstValueFrom } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-warning-telefono',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './warning-telefono.component.html',
  styleUrl: './warning-telefono.component.scss',
})
export class WarningTelefonoComponent {
  public tel?: string;
  private regTel = /^(?!0|15)\d{10}$/;

  public telValido = false;

  constructor(
    private usuariosService: UsuariosService,
    private dialogRef: MatDialogRef<WarningTelefonoComponent>,
    private helper: HelperService,
  ) {}

  public checkTel() {
    if (!this.tel) {
      this.telValido = false;
      return;
    }
    this.telValido = this.regTel.test(this.tel);
  }

  public async cambiarTelefono() {
    if (!this.tel || !this.telValido) return;
    try {
      await firstValueFrom(this.usuariosService.cambiarTelefono(this.tel));
      this.helper.notifSuccess('Teléfono actualizado correctamente');
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.helper.notifError('Error al actualizar el teléfono');
    }
  }
}
