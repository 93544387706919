import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRegistro, IListado, IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

export interface IInformeExportacion {
  correctoras: number;
  registros: number;
  faltantes: number;
}
export interface IResponseGetRegistro {
  reportes: number[];
}

@Injectable({
  providedIn: 'root',
})
export class ReportesService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IRegistro>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes`, { params });
  }

  public listarPorId(id: string): Observable<IRegistro> {
    return this.http.get(`/reportes/${id}`);
  }

  public faltantes(id: string): Observable<IResponseGetRegistro> {
    return this.http.get(`/reportes/faltantes/unidadDePresion/${id}`);
  }

  public faltantesPorPunto(id?: string): Observable<IResponseGetRegistro> {
    return this.http.get(`/reportes/faltantes/puntoDeMedicion/${id}`);
  }

  public verificar(queryParams?: IQueryParam): Observable<IInformeExportacion> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes/verificar`, { params });
  }

  public async exportar(
    queryParams?: IQueryParam,
    filename = 'export.xlsx',
  ): Promise<void> {
    const params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/reportes/exportar`, filename, params);
  }

  public async exportarFaltantes(
    queryParams?: IQueryParam,
    filename = 'export.xlsx',
  ): Promise<void> {
    const params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/reportes/exportar/faltantes`, filename, params);
  }

  public generarResumenHistorico(): Observable<void> {
    return this.http.post(`/reportes/generarResumenHistorico`, {});
  }

  public generarResumenFecha(from: string, to: string): Observable<void> {
    return this.http.post(`/reportes/generarResumenFecha/${from}/${to}`, {});
  }

  public importarSoxis(file: File): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`/reportes/importSoxis`, formData);
  }
}
