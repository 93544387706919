import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IAgrupacion, IListado, IQueryParam } from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import {
  IColumnas,
  ICrearBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { AgrupacionsService } from '../agrupacion.service';
import { CrearEditarAgrupacionsComponent } from '../crear-editar-agrupacion/crear-editar-agrupacion.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado-agrupacion',
  templateUrl: './listado-agrupacion.component.html',
  styleUrls: ['./listado-agrupacion.component.scss'],
})
export class ListadoAgrupacionsComponent implements OnInit, OnDestroy {
  // Tabla nueva
  public name = 'ListadoAgrupacionsComponent';
  public datos?: IAgrupacion[] = [];
  public columnas?: IColumnas<IAgrupacion>[];
  public totalCount = 0;
  public botonCrear: ICrearBoton = {
    mostrar: true,
    tooltip: 'Crear Agrupación',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public filtros: IFiltroTabla[] = [];

  // QUERY+
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: 'nombre',
  };

  // Listado Continuo
  public datos$?: Subscription;
  public unidadNegocios$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: AgrupacionsService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarAgrupacionsComponent, config);
  }

  public async editar(data: IAgrupacion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarAgrupacionsComponent, config);
  }

  public async eliminar(dato: IAgrupacion): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el agrupacion ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IAgrupacion>>('agrupacions', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de agrupacions`, data);
      });
    await this.listadosService.getLastValue('agrupacions', this.query);
  }

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Agrupacion',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'warn',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([this.listar()]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
