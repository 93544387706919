<div class="mat-elevation-z8" *ngIf="!loading.getLoading()">
  <!-- Tabla -->
  <div *ngIf="totalCount > 0">
    <mat-table [dataSource]="dataSource">
      <!-- Columna Fecha -->
      <ng-container matColumnDef="fecha">
        <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span>{{ row | date: "short" }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnas; sticky: true">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <div *ngIf="totalCount === 0">
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2 style="text-align: center">No hay reportes faltantes</h2>
    </div>
  </div>
  <div
    class="mat-elevation-z8"
    *ngIf="loading.getLoading()"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="padding: 1em"
  >
    <mat-progress-spinner
      mode="indeterminate"
      diameter="100"
      strokeWidth="10"
    ></mat-progress-spinner>
  </div>
</div>
