import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ILocalidad,
  ICreateLocalidad,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUpdateLocalidad,
  ICentroOperativo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { LocalidadService } from '../service';

@Component({
  selector: 'app-crear-editar-localidad',
  templateUrl: './crear-editar-localidad.component.html',
  styleUrls: ['./crear-editar-localidad.component.scss'],
})
export class CrearEditarLocalidadComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public unidadNegocios: IUnidadNegocio[] = [];
  public centrosOperativos: ICentroOperativo[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILocalidad,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarLocalidadComponent>,
    private service: LocalidadService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Localidad' : 'Crear Localidad';

    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idUnidadNegocio: [this.data?.idUnidadNegocio, Validators.required],
      idCentroOperativo: [this.data?.idCentroOperativo, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    return this.form?.value as ICreateLocalidad;
  }

  private getUpdateData() {
    return this.form?.value as IUpdateLocalidad;
  }

  public async onSubmit(): Promise<void> {
    this.enviando = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }

      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.enviando = false;
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentrosOperativos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('centroOperativos', query)
      .subscribe((data) => {
        this.centrosOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  //

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentrosOperativos(),
    ]);
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
  }
}
