import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  IEnvioSms,
  IUpdateEnvioSms,
  ICreateEnvioSms,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class EnvioSmssService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IEnvioSms>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/enviosmss`, { params });
  }

  public crear(dato: ICreateEnvioSms): Observable<IEnvioSms> {
    return this.http.post(`/enviosmss`, dato);
  }

  public listarPorId(id: string): Observable<IEnvioSms> {
    return this.http.get(`/enviosmss/${id}`);
  }

  public editar(id: string, dato: IUpdateEnvioSms): Observable<IEnvioSms> {
    return this.http.put(`/enviosmss/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/enviosmss/${id}`);
  }
}
