import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AuxiliaresModule } from './auxiliares/auxiliares.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// Locale
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
import { getEspPaginatorIntl } from './auxiliares/paginator-intl';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from './auxiliares/pwa.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './auxiliares/loading.interceptor';
import { version } from '../environments/version';
registerLocaleData(localeEs, 'es-AR');
//

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    AuxiliaresModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: MatPaginatorIntl, useValue: getEspPaginatorIntl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public pwaService: PwaService) {
    console.log(`App version: ${version}`);
  }
}
