import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { GruposRoutingModule } from './grupos.routing.module';
import { CrearEditarGruposComponent } from './crear-editar-grupos/crear-editar-grupos.component';
import { ListadoGruposComponent } from './listado-grupos/listado-grupos.component';

@NgModule({
  declarations: [CrearEditarGruposComponent, ListadoGruposComponent],
  imports: [CommonModule, AuxiliaresModule, GruposRoutingModule],
})
export class GruposModule {}
