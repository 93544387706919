import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetallesMedidorResidencialsComponent } from './detalles-medidores-residenciales/detalles-medidor-residencial.component';
import { ListadoMedidorResidencialsComponent } from './listado-medidores-residenciales/listado-medidores-residenciales.component';

const routes: Routes = [
  {
    path: '',
    component: ListadoMedidorResidencialsComponent,
  },
  {
    path: ':id',
    component: DetallesMedidorResidencialsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MedidorResidencialsRoutingModule {}
