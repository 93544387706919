<div
  *ngIf="puntoMedicion"
  class="fondo-85"
  style="padding: 0.5em; border-bottom-right-radius: 30px; overflow: auto"
  fxLayout.gt-sm="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="center center"
  fxLayoutGap="1em"
>
  <app-tabla
    [totalCount]="totalCount"
    [datos]="datos"
    [columnas]="columnas"
    [name]="name"
    [paginatorAbajo]="false"
    [selectorColumnas]="false"
  ></app-tabla>

  <div
    *ngIf="mapCenter"
    style="margin-right: 14px; border-radius: 30px; overflow: hidden"
  >
    <google-map
      width="300px"
      height="200px"
      [options]="mapOptions"
      [center]="mapCenter"
      [zoom]="mapOptions.zoom || 12"
    >
      <map-marker [options]="markerOptions"></map-marker>
    </google-map>
  </div>

  <!-- <div style="margin-right: 7px" *ngIf="puntoMedicion">
    <img
      src="assets/iconos/editar.png"
      alt="editar"
      *ngIf="helper.puedeEditarPuntosDeMedicion(puntoMedicion)"
      matTooltip="Editar"
      style="width: 48px; cursor: pointer"
      (click)="editar(puntoMedicion)"
    />

    <img
      src="assets/iconos/directions.png"
      alt="Abrir en Maps"
      matTooltip="Abrir en Maps"
      style="width: 48px; cursor: pointer"
      (click)="abrirEnBrowser(puntoMedicion)"
    />
  </div> -->
</div>
