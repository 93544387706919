import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICentroOperativo,
  ICorrectora,
  ICreateCorrectora,
  IDispositivo,
  IFilter,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUpdateCorrectora,
  ModeloCorrectora,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CorrectorasService } from '../correctoras.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar-correctoras',
  templateUrl: './crear-editar-correctoras.component.html',
  styleUrls: ['./crear-editar-correctoras.component.scss'],
})
export class CrearEditarCorrectorasComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public textoAsignado = '';
  public modelosCorrectora: ModeloCorrectora[] = [
    'Corus',
    'Dresser1',
    'Dresser2',
    'Mercury',
    'Proser',
    'Minicor',
    'AmericanMeter',
    'Elcor',
  ];

  public dispositivos: IDispositivo[] = [];
  public correctoras: ICorrectora[] = [];
  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public centrosUnidadNegocio: ICentroOperativo[] = [];

  // Listado Continuo
  public dispositivos$?: Subscription;
  public correctoras$?: Subscription;
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICorrectora,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarCorrectorasComponent>,
    private service: CorrectorasService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? `Editar Correctora ${this.data.modelo} S/N: ${this.data.numeroSerie}`
      : 'Crear Correctora';

    this.form = this.fb.group({
      idUnidadNegocio: [this.data?.idUnidadNegocio],
      idCentroOperativo: [this.data?.idCentroOperativo],
      estadoActual: [this.data?.estadoActual],
      numeroSerie: [this.data?.numeroSerie],
      deveui: [this.data?.deveui],
      modelo: [this.data?.modelo],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getUpdateData() {
    const data: IUpdateCorrectora = this.form?.value;
    return data;
  }

  private getCreateData() {
    const data: ICreateCorrectora = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Chequear Correctora Asignada
  public async checkDispositivoAsignado() {
    try {
      const deveui = this.form?.get('deveui')?.value;
      if (deveui) {
        await this.listarCorrectoraPorDeveui(deveui);
        if (this.correctoras.length > 0) {
          this.textoAsignado = `* El NUC seleccionado está asignado a la correctora: <strong>${this.correctoras[0]?.modelo} S/N: ${this.correctoras[0]?.numeroSerie}</strong>, si continúa será desasignada de la misma para asignarase a esta.`;
        } else {
          this.textoAsignado = '';
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  //
  public setDatos() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    const centrosUnidadNegocio = this.centroOperativos.filter(
      (c) => c.idUnidadNegocio === idUnidadNegocio,
    );

    this.centrosUnidadNegocio = centrosUnidadNegocio;
  }

  public selectDispositivo() {
    const deveui = this.form?.get('deveui')?.value;
    const dispositivo = this.dispositivos.find((d) => d.deveui === deveui);

    if (dispositivo) {
      const idUnidadNegocio = dispositivo?.idUnidadNegocio;
      const idCentroOperativo = dispositivo?.idCentroOperativo;

      this.form?.patchValue({ idUnidadNegocio });
      this.form?.patchValue({ idCentroOperativo });

      this.setDatos();
    }
  }

  public cambioUnidadNegocio() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;

    this.setDatos();

    if (this.centrosUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCentroOperativo: this.centrosUnidadNegocio[0]._id,
      });
    } else {
      const idCentroSeleccionado = this.form?.value.idCentroOperativo;
      const centroSeleccionado = this.centrosUnidadNegocio.find(
        (c) => c._id === idCentroSeleccionado,
      );
      if (centroSeleccionado?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCentroOperativo: null,
        });
      }
    }
  }

  // Listados
  private async listarDispositivos(): Promise<void> {
    const filter: IFilter<IDispositivo> = { tipoDispositivo: 'NUC' };

    const query: IQueryParam = {
      select: 'deveui deviceName idUnidadNegocio idCentroOperativo',
      sort: 'deviceName',
      filter: JSON.stringify(filter),
    };
    // const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    // if (idUnidadNegocio) {
    //   filter.idUnidadNegocio = idUnidadNegocio;
    //   query.filter = JSON.stringify(filter);
    // }
    this.dispositivos$?.unsubscribe();
    this.dispositivos$ = this.listadosService
      .subscribe<IListado<IDispositivo>>('dispositivos', query)
      .subscribe((data) => {
        this.dispositivos = data.datos;
        console.log(`listado de dispositivos`, data);
      });
    await this.listadosService.getLastValue('dispositivos', query);
  }

  private async listarCorrectoraPorDeveui(deveui: string): Promise<void> {
    const filter: IFilter<ICorrectora> = { deveui };

    const query: IQueryParam = {
      select: 'nombre modelo numeroSerie',
      filter: JSON.stringify(filter),
    };

    this.correctoras$?.unsubscribe();
    this.correctoras$ = this.listadosService
      .subscribe<IListado<ICorrectora>>('correctoras', query)
      .subscribe((data) => {
        this.correctoras = data.datos;
        console.log(`listado de correctoras`, data);
      });
    await this.listadosService.getLastValue('correctoras', query);
  }

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;

        if (this.unidadNegocios.length === 1) {
          this.form?.patchValue({
            idUnidadNegocio: this.unidadNegocios[0]._id,
          });
          this.setDatos();
        }

        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;

        if (this.centroOperativos.length === 1) {
          this.form?.patchValue({
            idCentroOperativo: this.centroOperativos[0]._id,
          });
        }

        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([
      this.listarDispositivos(),
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
    ]);
    this.setDatos();
  }

  ngOnDestroy(): void {
    this.dispositivos$?.unsubscribe();
    this.correctoras$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
  }
}
