<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="loginForm"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Teléfono  -->
      <mat-form-field>
        <mat-label>Teléfono</mat-label>
        <input
          matInput
          type="string"
          [(ngModel)]="telefono"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="true"
        />
      </mat-form-field>

      <!-- Frecuencia de Comunicación  -->
      <mat-form-field>
        <mat-label>Frecuencia de Comunicación</mat-label>
        <mat-select formControlName="frecuenciaComunicacion" required>
          <mat-option [value]="720">2 Veces por día</mat-option>
          <mat-option [value]="480">3 Veces por día</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Límite Mínimo  -->
      <mat-form-field>
        <mat-label>Límite Mínimo</mat-label>
        <input matInput type="number" formControlName="limiteMin" required />
      </mat-form-field>

      <!-- Límite Máximo  -->
      <mat-form-field>
        <mat-label>Límite Máximo</mat-label>
        <input matInput type="number" formControlName="limiteMax" required />
      </mat-form-field>

      <!-- APN  -->
      <mat-form-field>
        <mat-label>APN</mat-label>
        <mat-select
          required
          [(ngModel)]="apnLabel"
          [ngModelOptions]="{ standalone: true }"
          (selectionChange)="cambioApn($event.value)"
        >
          @for (apn of apns; track $index) {
            <mat-option [value]="apn.label">{{ apn.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <!-- APN  -->
      <!-- <mat-form-field>
        <mat-label>APN</mat-label>
        <input matInput type="string" formControlName="apn" required />
      </mat-form-field> -->

      <!-- Usuario  -->
      <!-- <mat-form-field>
        <mat-label>Usuario</mat-label>
        <input matInput type="string" formControlName="usuario" />
      </mat-form-field> -->

      <!-- Clave  -->
      <!-- <mat-form-field>
        <mat-label>Clave</mat-label>
        <input matInput type="string" formControlName="clave" />
      </mat-form-field> -->

      <mat-checkbox
        [(ngModel)]="enviarSms"
        [ngModelOptions]="{ standalone: true }"
      >
        Enviar SMS
      </mat-checkbox>

      <mat-checkbox
        [(ngModel)]="enviarInmediatamente"
        [ngModelOptions]="{ standalone: true }"
      >
        Enviar SMS Inmediatamente
      </mat-checkbox>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
