import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IEnvioSms,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUsuario,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarComponent } from '../crear-editar/crear-editar.component';
import { EnvioSmssService } from '../service';
import {
  IColumnas,
  ICrearBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
})
export class ListadoComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = 'ListadoComponentSms';
  public datos?: IEnvioSms[] = [];
  public columnas?: IColumnas<IEnvioSms>[];
  public totalCount = 0;
  public botonCrear: ICrearBoton = {
    mostrar: true,
    tooltip: 'Crear SMS',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public unidadNegocio: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idUnidadNegocio',
    },
    label: 'Unidad de Negocio',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public agrupacion: IFiltroTabla = {
    elementos: [
      { label: 'Global', value: 'Global' },
      { label: 'Unidad de Negocio', value: 'Unidad de Negocio' },
      { label: 'Centro Operativo', value: 'Centro Operativo' },
      { label: 'Localidad', value: 'Localidad' },
      { label: 'Grupo', value: 'Grupo' },
      { label: 'Agrupación', value: 'Agrupacion' },
    ],
    filter: {
      field: 'agrupacion',
    },
    label: 'Agrupación',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  public tipoEnvio: IFiltroTabla = {
    elementos: [
      { label: 'SMS', value: 'SMS' },
      { label: 'WhatsApp', value: 'WhatsApp' },
      { label: 'Llamada', value: 'Llamada' },
      { label: 'Notificación Push', value: 'Notificación Push' },
    ],
    filter: {
      field: 'tipoEnvio',
    },
    label: 'Tipo de envío',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  public tipoAlerta: IFiltroTabla = {
    elementos: [
      {
        label: 'Unidades Presión - Fuera de límite',
        value: 'Unidades Presión - Fuera de límite',
      },
      {
        label: 'Unidades Presión - Error en reporte de alarma',
        value: 'Unidades Presión - Error en reporte de alarma',
      },
    ],
    filter: {
      field: 'tiposAlerta',
    },
    label: 'Tipo de alerta',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [
    this.unidadNegocio,
    this.agrupacion,
    this.tipoEnvio,
    this.tipoAlerta,
  ];

  // QUERY+
  private populate = [
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'centroOperativo',
      select: 'nombre',
    },
    {
      path: 'grupo',
      select: 'nombre',
    },
    {
      path: 'agrupacion2',
      select: 'nombre',
    },
    {
      path: 'usuarios',
      select: 'username',
    },
    {
      path: 'localidad',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public unidadNegocios$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: EnvioSmssService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarComponent, config);
  }

  public async editar(data: IEnvioSms) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarComponent, config);
  }

  public async eliminar(dato: IEnvioSms): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el SMS?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IEnvioSms>>('envioSmss', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de envioSmss`, data);
      });
    await this.listadosService.getLastValue('envioSmss', this.query);
  }

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocio.elementos = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private setColumnas() {
    this.columnas = [
      // Tipos de Alerta
      {
        header: {
          label: 'Tipos de Alerta',
          sortable: true,
        },
        row: {
          field: 'tiposAlerta',
        },
      },
      // Agrupación
      {
        header: {
          label: 'Agrupación',
        },
        row: {
          field: 'agrupacion',
        },
      },
      // Nombre
      {
        header: {
          label: 'Nombre',
        },
        row: {
          html: (dato) => this.parseNombre(dato),
        },
      },
      // Usuarios
      {
        header: {
          label: 'Usuarios',
        },
        row: {
          // parse(dato) {
          //   return dato.usuarios?.map((u) => u.username).join(', ');
          // },
          html(dato) {
            return parseUsuarios(dato.usuarios!);
          },
        },
      },
      // Medio
      {
        header: {
          label: 'Medio',
        },
        row: {
          field: 'tipoEnvio',
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'warn',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  private parseNombre(dato: IEnvioSms) {
    const unidadNegocio = dato.unidadNegocio?.nombre;
    const centroOperativo = dato.centroOperativo?.nombre;
    const grupo = dato.grupo?.nombre;
    const agrupacion = dato.agrupacion2?.nombre;
    const localidad = dato.localidad?.nombre;

    switch (dato.agrupacion) {
      case 'Global':
        return 'Global';
      case 'Unidad de Negocio':
        return `${unidadNegocio}`;
      case 'Centro Operativo':
        return `${centroOperativo} (${unidadNegocio})`;
      case 'Grupo':
        return `${grupo} (${unidadNegocio} - ${
          centroOperativo
            ? centroOperativo
            : '<span class="no-info">Sin CO</span>'
        })`;
      case 'Agrupacion':
        return `${agrupacion}`;
      case 'Localidad':
        return `${localidad} (${unidadNegocio} - ${centroOperativo})`;
      default:
        return '';
    }
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([this.listar(), this.listarUnidadNegocios()]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
  }
}
function parseUsuarios(d: IUsuario[]) {
  let str = '';
  for (const u of d) {
    str = str + `<div>${u.username}</div>`;
  }
  return str;
}
