<div style="height: 100%; display: flex; flex-direction: column">
  <div class="titulo">
    <h1
      style="
        font-size: 18px;
        font-weight: 900;
        padding: 0.5em 0 0.5em 0.5em;
        margin: 0;
      "
    >
      Último Reporte
    </h1>
  </div>

  <div class="column-center" style="justify-content: center; height: 100%">
    <!-- Ultimo registro -->
    <table style="width: 100%" *ngIf="ultimoRegistro">
      <tr>
        <th>Fecha</th>
        <td>
          <span style="white-space: nowrap">
            {{ (ultimoRegistro.timestamp | date: "short") || "..." }}
          </span>
        </td>
      </tr>
      <tr *ngIf="ultimoRegistro.uncorrectedTotalizado !== -1">
        <th>Volumen Base Totalizado</th>
        <td>
          {{ ultimoRegistro.uncorrectedTotalizado | number }}
          m³
        </td>
      </tr>
      <tr *ngIf="ultimoRegistro.correctedTotalizado !== -1">
        <th>Volumen Corregido Totalizado</th>
        <td>
          {{ ultimoRegistro.correctedTotalizado | number }}
          m³
        </td>
      </tr>
      <tr *ngIf="ultimoRegistro.uncorrectedParcializado !== -1">
        <th>Volumen Base Horario</th>
        <td>
          {{ ultimoRegistro.uncorrectedParcializado | number }}
          m³
        </td>
      </tr>
      <tr *ngIf="ultimoRegistro.correctedParcializado !== -1">
        <th>Volumen Corregido Horario</th>
        <td>
          {{ ultimoRegistro.correctedParcializado | number }}
          m³
        </td>
      </tr>
      <tr>
        <th>Temperatura</th>
        <td>
          {{
            ultimoRegistro
              ? (ultimoRegistro.temperatura | number) + " °C"
              : "..."
          }}
        </td>
      </tr>
      <tr *ngIf="ultimoRegistro.presion !== -1">
        <th>Presión</th>
        <td>
          {{
            ultimoRegistro ? (ultimoRegistro.presion | number) + " bar" : "..."
          }}
        </td>
      </tr>
      <tr *ngIf="ultimoRegistro?.caudalPico !== -1">
        <th>Caudal Pico</th>
        <td>
          {{
            ultimoRegistro
              ? (ultimoRegistro.caudalPico | number) + " m³/h"
              : "..."
          }}
        </td>
      </tr>
      <tr *ngIf="ultimoRegistro?.caudalPromedio !== -1">
        <th>Caudal Promedio</th>
        <td>
          {{
            ultimoRegistro
              ? (ultimoRegistro.caudalPromedio | number) + " m³/h"
              : "..."
          }}
        </td>
      </tr>
    </table>

    <!-- NO hay ultimo registro -->
    <div
      *ngIf="!ultimoRegistro"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <h3>
        <strong> No hay registros </strong>
      </h3>
    </div>

    <mat-divider
      class="divider"
      *ngIf="ultimaAlerta"
      style="margin: 1em 1em 1em 1em"
    ></mat-divider>

    <!-- Ultima Alerta -->
    <table *ngIf="ultimaAlerta" style="width: 100%">
      <!-- Fecha -->
      <tr>
        <th>Fecha</th>
        <td>
          {{ (ultimaAlerta.timestamp | date: "short") || "..." }}
        </td>
      </tr>
      <!-- Alerta -->
      <tr>
        <th>Alerta</th>
        <td>
          {{ ultimaAlerta.mensaje || "..." }}
        </td>
      </tr>
    </table>
  </div>
</div>
