import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListadoLocalidadComponent } from './listado-localidad/listado-localidad.component';
import { CrearEditarLocalidadComponent } from './crear-editar-localidad/crear-editar-localidad.component';
import { RoutingModule } from './routing';

@NgModule({
  declarations: [CrearEditarLocalidadComponent, ListadoLocalidadComponent],
  imports: [CommonModule, AuxiliaresModule, RoutingModule],
})
export class LocalidadModule {}
