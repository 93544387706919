import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDispositivo,
  IConfigDispositivoNUC4G,
  ICreateConfigDispositivo,
  IConfigDispositivo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ConfigDispositivosService } from '../config-dispositivos.service';
import { MODOS_OPERACION } from '../../../../environments/datos';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-config-dispositivos-nuc',
  templateUrl: './config-dispositivos-nuc.component.html',
  styleUrls: ['./config-dispositivos-nuc.component.scss'],
})
export class ConfigDispositivosNucComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public config?: IConfigDispositivoNUC4G;

  public modosOperacion = MODOS_OPERACION;

  public modosEnv = [
    { value: 'TEST', viewValue: 'TEST' },
    { value: 'PROD', viewValue: 'PROD' },
  ];

  public horasInicio = [
    // { value: 0, viewValue: '00:00 Hs.' },
    // { value: 1, viewValue: '01:00 Hs.' },
    // { value: 2, viewValue: '02:00 Hs.' },
    // { value: 3, viewValue: '03:00 Hs.' },
    // { value: 4, viewValue: '04:00 Hs.' },
    // { value: 5, viewValue: '05:00 Hs.' },
    { value: 6, viewValue: '06:00 Hs.' },
    { value: 7, viewValue: '07:00 Hs.' },
    { value: 8, viewValue: '08:00 Hs.' },
    { value: 9, viewValue: '09:00 Hs.' },
    { value: 10, viewValue: '10:00 Hs.' },
    { value: 11, viewValue: '11:00 Hs.' },
    { value: 12, viewValue: '12:00 Hs.' },
  ];

  // Listado Continuo
  public config$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ConfigDispositivosNucComponent>,
    private service: ConfigDispositivosService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Configuración del Dispositivo'
      : 'Crear Configuración del Dispositivo';

    this.form = this.fb.group({
      claveMercury: [
        this.config?.claveMercury,
        [Validators.minLength(5), Validators.maxLength(5)],
      ],
      horaInicio: [this.config?.horaInicio, Validators.required],
      modoEnv: [this.config?.modoEnv, Validators.required],
      modoOperacion: [this.config?.modoOperacion, Validators.required],
      // modoRegistros: [this.config?.modoRegistros, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getUpsertData() {
    const config: IConfigDispositivoNUC4G = this.form?.value;
    const data: ICreateConfigDispositivo = {
      deveui: this.data.deveui,
      config,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpsertData();
        await firstValueFrom(this.service.upsert(data));
        this.helper.notifSuccess('Configuración actualizada');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarConfigDispositivo(): Promise<void> {
    const deveui = this.data.deveui || '';
    this.config$?.unsubscribe();
    this.config$ = this.listadosService
      .subscribe<IConfigDispositivo>('configDispositivo', deveui)
      .subscribe((data) => {
        this.config = data?.config as IConfigDispositivoNUC4G;
        console.log(`listado de configDispositivo`, data);
      });
    await this.listadosService.getLastValue('configDispositivo', deveui);
  }

  private updateForm() {
    this.form?.patchValue({
      claveMercury:
        this.config?.claveMercury || this.data?.config?.claveMercury,
      horaInicio: this.config?.horaInicio || this.data?.config?.horaInicio,
      modoEnv: this.config?.modoEnv || this.data?.config?.modoEnv,
      modoOperacion:
        this.config?.modoOperacion || this.data?.config?.modoOperacion,
      // modoRegistros: this.config?.modoRegistros,
    });
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([this.listarConfigDispositivo()]);
    this.updateForm();
  }

  ngOnDestroy(): void {
    this.config$?.unsubscribe();
  }
}
