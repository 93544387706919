import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IConfigDispositivoNSP4G,
  IDispositivo,
  IDispositivoNsp4G,
  IUnidadPresion,
} from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { ConfigDispositivosNspComponent } from 'src/app/modulos/dispositivos/config-dispositivos-nsp/config-dispositivos-nsp.component';
import { ConfigUnidadPresionComponent } from 'src/app/modulos/unidad-presion/config-unidad-presion/config-unidad-presion.component';
import { CrearEditarComponent } from 'src/app/modulos/unidad-presion/crear-editar/crear-editar.component';
import { UnidadPresionsService } from 'src/app/modulos/unidad-presion/service';
import { MODOS_OPERACION_NSP } from 'src/environments/datos';
import { ConfigDispositivosService } from 'src/app/modulos/dispositivos/config-dispositivos.service';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { env } from '../../../environments/environment';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-nsp',
  templateUrl: './detalles-nsp.component.html',
  styleUrls: ['./detalles-nsp.component.scss'],
})
export class DetallesNSPComponent implements OnInit, OnChanges {
  @Input() unidadPresion?: IUnidadPresion;
  @Input() configActual?: IDispositivoNsp4G;
  @Input() configDeseada?: IConfigDispositivoNSP4G;

  public env = env;

  public configAplicada?: boolean;

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
    public service: UnidadPresionsService,
    public listadosService: ListadosService,
    public configsService: ConfigDispositivosService,
    public dialog: DialogService,
  ) {}

  public async editarUnidadPresion(data: IUnidadPresion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarComponent, config);
  }

  public async configNsp(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigDispositivosNspComponent, config);
  }

  public async configAlertasNSP(data: IUnidadPresion) {
    const config: MatDialogConfig = {
      data: data.dispositivo,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigUnidadPresionComponent, config);
  }

  public textoModoOperacionNSP(modoOperacion?: string) {
    if (modoOperacion) {
      const modos = MODOS_OPERACION_NSP;
      const modo = modos.find((m) => m.value === modoOperacion);

      if (modo) {
        return modo.viewValue;
      }
    }
    return '...';
  }

  //

  private isDefined(value: any) {
    return value !== undefined && value !== null;
  }

  public difLimite(deseado?: number, aplicado?: number) {
    if (!this.isDefined(deseado) || !this.isDefined(aplicado)) {
      return 0;
    }
    return Math.abs(deseado! - aplicado!);
  }

  private verificarConfigAplicada() {
    const configActual = this.configActual as IConfigDispositivoNSP4G;
    const configDeseada = this.configDeseada as IConfigDispositivoNSP4G;

    if (this.configDeseada) {
      if (
        configDeseada?.horaInicio !== configActual?.horaInicio ||
        this.difLimite(configDeseada?.limiteMax, configActual?.limiteMax) >
          0.1 ||
        this.difLimite(configDeseada?.limiteMin, configActual?.limiteMin) >
          0.1 ||
        configDeseada?.modoOperacion !== configActual?.modoOperacion ||
        configDeseada?.modoEnv !== configActual?.modoEnv ||
        ((configDeseada?.telefono1 || configActual?.telefono1) &&
          configDeseada?.telefono1 !== configActual?.telefono1) ||
        ((configDeseada?.telefono2 || configActual?.telefono2) &&
          configDeseada?.telefono2 !== configActual?.telefono2) ||
        ((configDeseada?.telefono3 || configActual?.telefono3) &&
          configDeseada?.telefono3 !== configActual?.telefono3) ||
        configDeseada?.apn !== configActual?.apn ||
        configDeseada?.user !== configActual?.user ||
        configDeseada?.pass !== configActual?.pass
      ) {
        this.configAplicada = false;
      } else {
        this.configAplicada = true;
      }
    } else {
      this.configAplicada = undefined;
    }
  }

  ngOnChanges() {
    this.verificarConfigAplicada();
  }

  async ngOnInit() {
    this.verificarConfigAplicada();
  }
}
