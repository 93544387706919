import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IUsuario,
  ICreateUsuario,
  IListado,
  IQueryParam,
  IUpdateUsuario,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IUsuario>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/usuarios`, { params });
  }

  public listarPropio(): Observable<IUsuario> {
    return this.http.get(`/usuarios/propio`, {});
  }

  public crear(dato: ICreateUsuario): Observable<IUsuario> {
    return this.http.post(`/usuarios`, dato);
  }

  public listarPorId(id: string): Observable<IUsuario> {
    return this.http.get(`/usuarios/${id}`);
  }

  public editar(id: string, dato: IUpdateUsuario): Observable<IUsuario> {
    return this.http.put(`/usuarios/${id}`, dato);
  }

  public resetearPass(id: string): Observable<void> {
    return this.http.put(`/usuarios/reset-password/${id}`, {});
  }

  public editarPropio(dato: IUpdateUsuario): Observable<IUsuario> {
    return this.http.put(`/usuarios/propio`, dato);
  }

  public cambiarTelefono(tel: string): Observable<IUsuario> {
    return this.http.put(`/usuarios/telefono`, { tel });
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/usuarios/${id}`);
  }
}
