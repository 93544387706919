import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  ILogReporte,
  IUnidadPresion,
  ILogReporteVeribox,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-log-reportes-veribox',
  templateUrl: './listado-log-reportes-veribox.component.html',
  styleUrls: ['./listado-log-reportes-veribox.component.scss'],
})
export class ListadoLogReportesVeriboxComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() unidadPresion?: IUnidadPresion;

  ///// Tabla nueva
  public name = 'ListadoLogReportesVeriboxComponent';
  public datos?: ILogReporte[] = [];
  public columnas?: IColumnas<ILogReporte>[];
  public totalCount = 0;

  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaCreacion',
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // Listar

  public async listar(): Promise<void> {
    let filter: IFilter<ILogReporte> | boolean = false;
    if (this.unidadPresion) {
      filter = {
        idsAsignados: this.unidadPresion._id,
      };
    }
    if (this.puntoMedicion) {
      filter = {
        idsAsignados: this.puntoMedicion._id,
      };
    }

    if (filter) {
      this.query.filter = JSON.stringify(filter);

      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<ILogReporte>>('logReportes', this.query)
        .subscribe((data) => {
          console.log(`listado de logReportes`, data);

          this.totalCount = data.totalCount;
          this.datos = data.datos;
        });
      await this.listadosService.getLastValue('logReportes', this.query);
    }
  }

  private setColumnas() {
    this.columnas = [
      // Fecha
      {
        header: { label: 'Fecha', sortable: true, sort: 'fechaCreacion' },
        row: {
          parse(dato) {
            return dato.fechaCreacion;
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      // Cantidad Registros
      {
        header: { label: 'Cantidad de registros' },
        row: {
          parse(dato) {
            const valores = dato.valores as ILogReporteVeribox;
            return valores?.cantidadReportes;
          },
        },
      },
      // Alerta
      {
        header: { label: 'Alerta' },
        row: {
          parse(dato) {
            const valores = dato.valores as ILogReporteVeribox;
            return valores?.alerta ? 'Si' : 'No';
          },
        },
      },
    ];
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
