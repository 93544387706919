import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteNSP,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../auxiliares/helper.service';
import { ReportesService } from '../../modulos/reportes/service';
import { MatPaginator } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';

export interface IGetRegistro {
  deveui?: string;
  appkey?: string;
  numeroSerieCorrectora: number;
  numeroCorrectora: number;
  firmwareNuc: string;
  apiVersion: string;
}

export interface IResponseGetRegistro {
  reportes: number[];
}

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-reportes-faltantes',
  templateUrl: './listado-reportes-faltantes.component.html',
  styleUrls: ['./listado-reportes-faltantes.component.scss'],
})
export class ListadoReportesFaltantesComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() unidadPresion?: IUnidadPresion;

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  // TABLA
  public name = 'ListadoReportesFaltantesComponent';
  public dataSource = new MatTableDataSource<string>([]);
  public totalCount = 0;
  public columnas: string[] = ['fecha'];
  public nombreColumnas: string[] = ['Fecha'];
  public saveColumnas = 'cliente-gas-reportes-perdidos';

  // QUERY+
  public queryParams: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-timestamp',
    filter: JSON.stringify({}),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private dialogService: DialogService,
    private reportesService: ReportesService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  public registroFaltante(i: number, row: IReporte): boolean {
    const valores = row.valores as IReporteNSP;
    return valores.presion === -1;
  }

  //

  public async exportar() {
    const mensaje = `¿Desea exportar el listado de reportes?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje,
    );
    if (confirm) {
      this.queryParams.idUnidadPresion = this.unidadPresion?._id;
      try {
        await this.reportesService.exportar(this.queryParams);
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  private async listar(): Promise<void> {
    let resp: IResponseGetRegistro | boolean = false;
    if (this.unidadPresion) {
      resp = await firstValueFrom(
        this.reportesService.faltantes(this.unidadPresion._id!),
      );
    }
    if (this.puntoMedicion) {
      resp = await firstValueFrom(
        this.reportesService.faltantesPorPunto(this.puntoMedicion?._id),
      );
    }

    if (resp) {
      console.log(`registros faltantes`, resp);
      const fechas = resp?.reportes.map((reg) => new Date(reg).toISOString());
      this.dataSource.data = fechas;
      this.totalCount = resp?.reportes.length;
    }
  }

  // NG

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }
}
