export const environment = {
  production: true,
};
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCpbDtB9jbWL2tJyr8n9wJt6iHu_ob2NN8',
  authDomain: 'horatech-458c5.firebaseapp.com',
  projectId: 'horatech-458c5',
  storageBucket: 'horatech-458c5.appspot.com',
  messagingSenderId: '509244323026',
  appId: '1:509244323026:web:09ca8cb5f37de37ee4690b',
};
export const VAPID_KEY =
  'BEo1ADkRSqhD8y86epgR3Js4eKYS6zoPBWrkMgdwVB-lYaJkWvFgcmNXLYkF1j9vdIbjpoQr50hANqCCHdNnndg';
export const env: 'Local' | 'Test' | 'Dev' | 'Prod' = 'Test';
export const websocketServer = 'wss://apis.horatech.com.ar/gas-websocket-test';
export const api = 'https://apis.horatech.com.ar/gas-cliente-test';
