import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICromatografia,
  ICreateCromatografia,
  IListado,
  IQueryParam,
  IUpdateCromatografia,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class CromatografiasService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<ICromatografia>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/cromatografias`, { params });
  }

  public crear(dato: ICreateCromatografia): Observable<ICromatografia> {
    return this.http.post(`/cromatografias`, dato);
  }

  public listarPorId(id: string): Observable<ICromatografia> {
    return this.http.get(`/cromatografias/${id}`);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/cromatografias/${id}`);
  }

  public editar(id: string, body: IUpdateCromatografia): Observable<void> {
    return this.http.put(`/cromatografias/${id}`, body);
  }
}
