import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import {
  ICorrectora,
  IFilter,
  IListado,
  IQueryParam,
  IAplicacionCromatografia,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { CromatografiasService } from '../../modulos/cromatografias/cromatografias.service';
import { DetallesCromatografiaComponent } from '../../modulos/cromatografias/detalles-cromatografia/detalles-cromatografia.component';
import { AplicacionCromatografiasService } from '../../modulos/correctoras/aplicacionCromatografias';
import { IColumnas, IRowIcon } from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IRegExpSearch } from '../../auxiliares/tabla/filtro/filtro.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-aplicacion-cromatografias',
  templateUrl: './listado-aplicacion-cromatografias.component.html',
  styleUrls: ['./listado-aplicacion-cromatografias.component.scss'],
})
export class ListadoAplicacionCromatografiasComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() correctora?: ICorrectora;

  //// Tabla nueva
  public name = 'ListadoAplicacionCromatografiasComponent';
  public datos?: IAplicacionCromatografia[] = [];
  public columnas?: IColumnas<IAplicacionCromatografia>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  // QUERY+
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaCreacion',
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private dialogService: DialogService,
    private service: AplicacionCromatografiasService,
    private cromatografiasService: CromatografiasService,
  ) {}

  //

  public async exportar() {
    const mensaje = `¿Desea exportar el listado de cromatografias?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje,
    );
    if (confirm) {
      try {
        await this.service.exportar(this.query);
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async detalles(data: IAplicacionCromatografia) {
    try {
      const croma = await firstValueFrom(
        this.cromatografiasService.listarPorId(data.idCromatografia!),
      );
      console.log('detalles croma', croma);
      const config: MatDialogConfig = {
        data: croma,
        width: '1200px',

        panelClass: 'redondo-abajo-izquierda',
        // hasBackdrop: false,
        disableClose: true,
      };
      this.matDialog.open(DetallesCromatografiaComponent, config);
    } catch (err) {
      this.helper.notifError(err);
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    if (this.correctora) {
      const filter: IFilter<any> = {
        numeroSerieCorrectora: this.correctora.numeroSerie,
      };
      this.query.filter = JSON.stringify(filter);
      //
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IAplicacionCromatografia>>(
          'aplicacionCromatografias',
          this.query,
        )
        .subscribe((data) => {
          this.totalCount = data.totalCount;
          this.datos = data.datos;
          console.log(`listado de aplicacionCromatografias`, data);
        });
      await this.listadosService.getLastValue(
        'aplicacionCromatografias',
        this.query,
      );
    }
  }

  //
  private setColumnas() {
    this.columnas = [
      {
        header: { label: 'Fecha' },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: { label: 'Aplicada' },
        row: {
          icono: (dato) => this.parseAplicada(dato),
        },
      },
      {
        header: { label: 'Acciones' },
        row: {
          acciones: [
            {
              tipo: 'img',
              icon: 'assets/iconos/buscar.png',
              tooltip: 'Detalles',
              click: async (dato) => this.detalles(dato),
            },
          ],
        },
      },
    ];
  }

  private parseAplicada(d: IAplicacionCromatografia): IRowIcon {
    return {
      icon: d.aplicada ? 'check_circle' : 'cancel',
      color: d.aplicada ? 'green' : 'red',
      tooltip: d.aplicada ? 'Aplicada' : 'No aplicada',
      tipo: 'material',
    };
  }
  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([this.listar()]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
