import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoDispositivosComponent } from './listado-dispositivos/listado-dispositivos.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { DispositivosRoutingModule } from './dispositivos.routing.module';
import { CrearEditarDispositivosComponent } from './crear-editar-dispositivos/crear-editar-dispositivos.component';
import { ConfigDispositivosNucComponent } from './config-dispositivos-nuc/config-dispositivos-nuc.component';
import { ConfigDispositivosNspComponent } from './config-dispositivos-nsp/config-dispositivos-nsp.component';

import { MigrarDispositivosVeriboxComponent } from './migrar-dispositivos-veribox/migrar-dispositivos-veribox.component';
import { ConfigDispositivosVeriboxComponent } from '../../standalone/config-dispositivos-veribox/config-dispositivos-veribox.component';

@NgModule({
  declarations: [
    ListadoDispositivosComponent,
    CrearEditarDispositivosComponent,
    ConfigDispositivosNucComponent,
    ConfigDispositivosNspComponent,
    MigrarDispositivosVeriboxComponent,
  ],
  imports: [
    CommonModule,
    DispositivosRoutingModule,
    AuxiliaresModule,
    ConfigDispositivosVeriboxComponent,
  ],
})
export class DispositivosModule {}
