<!-- <app-resumen-mra [medidorResidencial]="medidorResidencial"></app-resumen-mra> -->
<div fxLayout="row" fxLayout.lt-md="column">
  <!-- Último Registro -->
  <div
    class="fondo-85"
    style="
      margin: 1em 1em 1em 0;
      border-bottom-left-radius: 30px;
      width: 33%;
      height: 250px;
    "
  >
    <!-- <app-ultimo-reporte-mra
      [ultimoReporte]="ultimoReporte"
    ></app-ultimo-reporte-mra> -->
  </div>
</div>

<div style="margin: 1em 0">
  <mat-tab-group>
    <!-- Registros -->
    <mat-tab label="Registros">
      <div style="overflow: hidden">
        <!-- <app-listado-reporteMras
          [medidorResidencial]="medidorResidencial"
        ></app-listado-reporteMras> -->
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
