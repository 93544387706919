<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="form"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <div>
        <!-- Estado Actual -->
        <mat-form-field>
          <mat-label>Estado Actual</mat-label>
          <mat-select formControlName="estadoActual">
            <mat-option value="Operativa"> Operativa </mat-option>
            <mat-option value="En Mantenimiento"> En Mantenimiento </mat-option>
            <!-- <mat-option value="Resolver"> Resolver </mat-option> -->
          </mat-select>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="15px">
          <!-- Modelo -->
          <mat-form-field>
            <mat-label>Modelo</mat-label>
            <mat-select formControlName="modelo">
              <mat-option *ngFor="let dato of modelosCorrectora" [value]="dato">
                {{ dato }}
              </mat-option>
            </mat-select>
            <button
              [disabled]="true"
              *ngIf="form?.get('modelo')?.value"
              matSuffix
              mat-icon-button
              type="button"
              aria-label="Clear"
              (click)="
                $event.stopPropagation(); form.patchValue({ modelo: null })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Número de Serie  -->
          <mat-form-field>
            <mat-label>Número de Serie</mat-label>
            <input matInput type="search" formControlName="numeroSerie" />
          </mat-form-field>
        </div>

        <!-- Dispositivos -->
        <div>
          <ng-select
            [items]="dispositivos"
            placeholder="Dispositivo Asociado"
            loadingText="Cargando..."
            typeToSearchText="Buscar..."
            notFoundText="No se encontraron resultados"
            formControlName="deveui"
            bindLabel="deviceName"
            bindValue="deveui"
            (change)="checkDispositivoAsignado(); selectDispositivo()"
            [appendTo]="'body'"
          >
          </ng-select>
          <div
            *ngIf="textoAsignado"
            [innerHTML]="textoAsignado"
            class="bg-warn"
            style="
              text-align: center;
              border-radius: 7px;
              padding: 0.5em;
              font-size: 1.2em;
            "
          ></div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" style="margin-top: 1em">
          <mat-form-field>
            <mat-label>Unidad de Negocio</mat-label>
            <mat-select
              formControlName="idUnidadNegocio"
              [disabled]="form.value?.deveui"
              (selectionChange)="cambioUnidadNegocio()"
            >
              <mat-option
                *ngFor="let dato of unidadNegocios"
                [value]="dato._id"
              >
                {{ dato.nombre }}
              </mat-option>
            </mat-select>
            <button
              [disabled]="form.value?.deveui"
              *ngIf="form.value?.idUnidadNegocio"
              matSuffix
              mat-icon-button
              type="button"
              aria-label="Clear"
              (click)="
                $event.stopPropagation();
                form.patchValue({ idUnidadNegocio: null });
                form.patchValue({ idCentroOperativo: null })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Centro Operativo</mat-label>
            <mat-select
              formControlName="idCentroOperativo"
              [disabled]="form.value?.deveui"
            >
              <ng-container *ngFor="let dato of centrosUnidadNegocio">
                <mat-option [value]="dato._id">
                  {{ dato.nombre }}
                </mat-option>
              </ng-container>
            </mat-select>
            <button
              [disabled]="form.value?.deveui"
              *ngIf="form.value?.idCentroOperativo"
              matSuffix
              mat-icon-button
              type="button"
              aria-label="Clear"
              (click)="
                $event.stopPropagation();
                form.patchValue({ idCentroOperativo: null })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <!-- Mensaje -->
        <div
          *ngIf="form?.value.deveui"
          class="bg-warn"
          style="
            text-align: center;
            border-radius: 7px;
            padding: 0.5em;
            font-size: 1.2em;
          "
        >
          <p>
            * Unidad de negocio y centro operativo determinados por el
            dispositivo seleccionado
          </p>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="form"
      color="accent"
      style="width: 50%"
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      style="width: 50%"
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
