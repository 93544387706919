import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteDireccionComponent } from './autocomplete-direccion/autocomplete-direccion.component';
import { MaterialModule } from '../material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AutocompleteDireccionComponent],
  imports: [CommonModule, MaterialModule, GoogleMapsModule, FormsModule],
  exports: [AutocompleteDireccionComponent],
})
export class AutocompleteDireccionModule {}
