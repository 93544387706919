<div class="map-container">
  <google-map
    #googleMap
    *ngIf="mapCenter"
    width="100%"
    height="100%"
    [options]="mapOptions"
    [center]="mapCenter"
    (boundsChanged)="guardarPos()"
  >
    <map-marker-clusterer [imagePath]="clusterer" [options]="clustererOptions">
      <ng-container *ngFor="let punto of puntos">
        <map-marker
          #marker="mapMarker"
          [icon]="punto.icono!"
          [position]="punto.ubicacion!"
          (mapClick)="infoWindow.open(marker)"
        ></map-marker>

        <map-info-window #infoWindow="mapInfoWindow">
          @if (punto.correctora) {
            <app-info-window-correctora
              [punto]="punto"
            ></app-info-window-correctora>
          }
          @if (punto.unidadPresion) {
            <app-info-window-unidad-presion
              [punto]="punto"
            ></app-info-window-unidad-presion>
          }
          @if (punto.medidorResidencial) {
            <app-info-window-residencial
              [punto]="punto"
            ></app-info-window-residencial>
          }
        </map-info-window>
      </ng-container>
    </map-marker-clusterer>
  </google-map>

  <div style="position: absolute; bottom: 2em; right: 2em; padding: 1em">
    <div
      fxLayout="row"
      fxLayoutAlign="space-around center"
      fxLayoutGap="15px"
      class="referencia"
    >
      <span fxFlex>Operativa</span>
      <img width="35px" src="assets/puntos/operativa.png" alt="" fxFlex="25" />
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-around center"
      fxLayoutGap="15px"
      class="referencia"
    >
      <span fxFlex>Sin Reportar</span>
      <img
        width="35px"
        src="assets/puntos/sinReportar.png"
        alt=""
        fxFlex="25"
      />
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-around center"
      fxLayoutGap="15px"
      class="referencia"
    >
      <span fxFlex>Alerta</span>
      <img width="35px" src="assets/puntos/alerta.png" alt="" fxFlex="25" />
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-around center"
      fxLayoutGap="15px"
      class="referencia"
    >
      <span fxFlex>Sin Asignar</span>
      <img width="35px" src="assets/puntos/sinAsignar.png" alt="" fxFlex="25" />
    </div>
  </div>
</div>
