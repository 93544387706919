<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 60vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Username  -->
      <mat-form-field>
        <mat-label>Nombre de usuario</mat-label>
        <input
          matInput
          type="search"
          placeholder="Nombre de usuario"
          formControlName="username"
          required
        />
      </mat-form-field>

      <!-- Password -->
      <mat-form-field>
        <mat-label>Clave</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'search'"
          [autocomplete]="!this.data ? 'new-password' : 'search'"
          placeholder="Clave"
          formControlName="clave"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="$event.stopPropagation(); hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-hint>
          <span *ngIf="!data">
            Dejar en blanco para autogenerar la contraseña
          </span>
          <span *ngIf="data"> Dejar en blanco para no modificar la clave</span>
        </mat-hint>
      </mat-form-field>

      <!-- Telefono  -->
      <mat-form-field [formGroup]="formGroupDatosPersonales">
        <mat-label>Teléfono</mat-label>
        <input
          matInput
          type="tel"
          placeholder="1160606060"
          formControlName="telefono"
        />
        <mat-hint>
          <span> Número de teléfono sin el 0 ni el 15 </span>
        </mat-hint>
        @if (formGroupDatosPersonales.get("telefono")?.invalid) {
          <mat-error> El teléfono ingresado es inválido</mat-error>
        }
      </mat-form-field>

      <!-- Email  -->
      <mat-form-field [formGroup]="formGroupDatosPersonales">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          placeholder="roberto@mail.com"
          formControlName="email"
        />
        @if (formGroupDatosPersonales.get("email")?.invalid) {
          <mat-error> El email ingresado es inválido</mat-error>
        }
      </mat-form-field>

      <!-- Permisos -->
      <div formArrayName="permisos">
        <div
          *ngFor="
            let permiso of formArrayPermisos?.controls;
            let i = index;
            let even = even
          "
        >
          <div
            [formGroupName]="i"
            style="padding: 0.5em; margin-bottom: 1em; border: 1px solid #ccc"
            [style.background]="even ? '#d3d3d31f' : 'transparent'"
          >
            <!-- Nivel -->
            <mat-form-field>
              <mat-select
                placeholder="Nivel"
                formControlName="nivel"
                required
                (selectionChange)="cambioNivel(i)"
              >
                <mat-option *ngFor="let dato of niveles" [value]="dato">
                  {{ dato }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Division -->
            <ng-container
              *ngIf="
                permiso.get('nivel')?.value &&
                permiso.get('nivel')?.value !== 'Global'
              "
            >
              <mat-form-field>
                <mat-select
                  placeholder="División"
                  formControlName="division"
                  required
                >
                  <mat-option *ngFor="let dato of divisiones" [value]="dato">
                    {{ dato }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <!-- Rol -->
            <mat-form-field>
              <mat-select placeholder="Rol" formControlName="rol" required>
                <mat-option *ngFor="let dato of roles" [value]="dato">
                  {{ dato }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Unidades de Negocio  -->
            <ng-container
              *ngIf="
                permiso.get('nivel')?.value &&
                permiso.get('nivel')?.value !== 'Global' &&
                permiso.get('nivel')?.value !== 'Agrupación'
              "
            >
              <mat-form-field>
                <mat-label>Unidades de Negocio</mat-label>
                <mat-select
                  formControlName="idsUnidadNegocios"
                  multiple
                  (selectionChange)="cambioUnidadNegocio(i)"
                >
                  <mat-option
                    *ngFor="let dato of unidadNegocios"
                    [value]="dato._id"
                  >
                    {{ dato.nombre }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <!-- Centro Operativo -->
            <ng-container
              *ngIf="
                permiso.get('nivel')?.value === 'Centro Operativo' ||
                permiso.get('nivel')?.value === 'Localidad'
              "
            >
              <mat-form-field>
                <mat-label>Centros Operativos</mat-label>
                <mat-select
                  formControlName="idsCentroOperativos"
                  multiple
                  (selectionChange)="cambioCentroOperativo(i)"
                >
                  <ng-container *ngFor="let dato of centroOperativos">
                    <mat-option
                      *ngIf="mostrarCentroOperativo(i, dato)"
                      [value]="dato._id"
                    >
                      {{ dato.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <!-- Localidad -->
            <ng-container *ngIf="permiso.get('nivel')?.value === 'Localidad'">
              <mat-form-field>
                <mat-label>Localidades</mat-label>
                <mat-select formControlName="idsLocalidades" multiple>
                  <ng-container *ngFor="let dato of localidads">
                    <mat-option
                      *ngIf="mostrarLocalidad(i, dato)"
                      [value]="dato._id"
                    >
                      {{ dato.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <button
                *ngIf="form?.value?.idsLocalidades"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="
                  $event.stopPropagation();
                  form?.patchValue({ idsLocalidades: null })
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>

            <!-- Cuencas / Zonas de Calidad -->
            <ng-container *ngIf="permiso.get('rol')?.value === 'Croma'">
              <mat-form-field>
                <mat-label>Zonas de Calidad</mat-label>
                <mat-select formControlName="idsCuencas" multiple required>
                  <ng-container *ngFor="let dato of cuencas">
                    <mat-option
                      *ngIf="mostrarCuenca(i, dato)"
                      [value]="dato._id"
                    >
                      {{ dato.nombre }} {{ dato.unidadNegocio?.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <!-- Agrupaciones -->
            <ng-container *ngIf="permiso.get('nivel')?.value === 'Agrupación'">
              <mat-form-field>
                <mat-label>Agrupaciones</mat-label>
                <mat-select formControlName="idsAgrupaciones" multiple required>
                  <ng-container *ngFor="let dato of agrupaciones">
                    <mat-option [value]="dato._id">
                      {{ dato.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </ng-container>

            <!-- Boton Eliminar Permiso -->
            <button
              mat-mini-fab
              color="warn"
              matTooltip="Eliminar"
              (click)="eliminarPermiso(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <!-- Boton Agregar Permiso -->
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="agregarPermiso()"
        >
          Agregar Permiso
        </button>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      style="width: 50%"
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      style="width: 50%"
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="this.loading.getLoading() ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
