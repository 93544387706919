/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IMedidorResidencial,
  IListado,
  IQueryParam,
  ICreateMedidorResidencial,
  IUpdateMedidorResidencial,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class MedidorResidencialsService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IMedidorResidencial>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/medidorresidencials`, { params });
  }

  public crear(
    dato: ICreateMedidorResidencial,
  ): Observable<IMedidorResidencial> {
    return this.http.post(`/medidorresidencials`, dato);
  }

  public listarPorId(id: string): Observable<IMedidorResidencial> {
    return this.http.get(`/medidorresidencials/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateMedidorResidencial,
  ): Observable<IMedidorResidencial> {
    return this.http.put(`/medidorresidencials/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/medidorresidencials/${id}`);
  }
}
