import { Injectable } from '@angular/core';
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { FIREBASE_CONFIG } from 'src/environments/environment';
import { HttpService } from './http.service';
import { Observable, firstValueFrom } from 'rxjs';
import { VAPID_KEY } from 'src/environments/environment';
import { IUsuario } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(private http: HttpService) {
    this.init();
  }

  private async init() {
    initializeApp(FIREBASE_CONFIG);
    this.iniciarPermisos();
  }

  //

  public async estadoPermisos() {
    let response: 'denied' | 'granted' | 'prompt' = 'prompt';
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { state } = await navigator?.permissions?.query({
      name: 'notifications' as any,
    });

    if (state === 'granted') {
      response = 'granted';
      await this.iniciarPermisos();
    }
    if (state === 'denied') {
      response = 'denied';
    }
  }

  public async iniciarPermisos() {
    await this.initWeb();
  }

  /// WEB

  public updateUser(token: string): Observable<IUsuario> {
    const url = `/usuarios/updateToken`;
    const body = { tokenPush: token };
    return this.http.put(url, body);
  }

  private async initWeb() {
    // NO ANDA EN FF
    if (await isSupported()) {
      await Notification.requestPermission();
      const messaging = getMessaging();
      const token = await this.getToken(messaging);
      if (token) {
        await firstValueFrom(this.updateUser(token));
        try {
          await this.listen(messaging);
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error(
          'No registration token available. Request permission to generate one.',
        );
      }
    } else {
      console.error('Notifications not supported');
    }
  }

  private async getToken(messaging: Messaging) {
    return getToken(messaging, { vapidKey: VAPID_KEY });
  }

  private async listen(messaging: Messaging) {
    onMessage(messaging, (payload) => {
      // Acá se pueden hacer cosas con el mensaje
      console.log('Message received. ', payload);
    });
  }
}
