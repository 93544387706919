import { Injectable } from '@angular/core';
import { UbicarComponent } from './ubicar/ubicar.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ICoordenadas } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class UbicarService {
  constructor(private matDialog: MatDialog) {}

  public async ubicar(
    coordenadas: ICoordenadas,
    title?: string,
    markerDraggable = false
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const config: MatDialogConfig = {
          data: {
            coordenadas,
            title,
            markerDraggable,
          },
          panelClass: 'redondo-abajo-izquierda',
          disableClose: true,
        };
        const modal = this.matDialog.open(UbicarComponent, config);
        modal.afterClosed().subscribe((result) => {
          resolve(result);
        });
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }
}
