<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="loginForm"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Tipo Alerta -->
      <mat-form-field>
        <mat-label>Tipo Alerta</mat-label>
        <mat-select formControlName="tiposAlerta" required multiple>
          <mat-option *ngFor="let dato of tiposAlertas" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Medio de envío -->
      <mat-form-field>
        <mat-label>Medio de envío</mat-label>
        <mat-select formControlName="tipoEnvio" required>
          <mat-option *ngFor="let dato of tiposEnvio" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Agrupación -->
      <mat-form-field>
        <mat-label>Agrupación</mat-label>
        <mat-select
          formControlName="agrupacion"
          required
          (selectionChange)="cambioAgrupacion()"
        >
          <mat-option *ngFor="let dato of agrupaciones" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Unidad de Negocio -->
      <mat-form-field *ngIf="form?.value?.agrupacion === 'Unidad de Negocio'">
        <mat-label>Unidad de Negocio</mat-label>
        <mat-select
          formControlName="idUnidadNegocio"
          required
          (selectionChange)="cambioUnidadNegocio()"
        >
          <mat-option *ngFor="let dato of unidadNegocios" [value]="dato._id">
            {{ dato.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Centro Operativo -->
      <mat-form-field *ngIf="form?.value?.agrupacion === 'Centro Operativo'">
        <mat-label>Centro Operativo</mat-label>
        <mat-select
          formControlName="idCentroOperativo"
          required
          (selectionChange)="cambioCentroOperativo()"
        >
          <mat-option *ngFor="let dato of centroOperativos" [value]="dato._id">
            {{ dato.nombre }} ({{ dato.unidadNegocio?.nombre }})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Grupos -->
      <mat-form-field *ngIf="form?.value?.agrupacion === 'Grupo'">
        <mat-label>Grupo</mat-label>
        <mat-select
          formControlName="idGrupo"
          required
          (selectionChange)="cambioGrupo()"
        >
          <mat-option *ngFor="let dato of grupos" [value]="dato._id">
            {{ dato.nombre }} ({{ dato.unidadNegocio?.nombre }})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Agrupaciones -->
      <mat-form-field *ngIf="form?.value?.agrupacion === 'Agrupacion'">
        <mat-label>Agrupación</mat-label>
        <mat-select
          formControlName="idAgrupacion"
          required
          (selectionChange)="cambioIdAgrupacion()"
        >
          <mat-option *ngFor="let dato of agrupaciones2" [value]="dato._id">
            {{ dato.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Localidad -->
      <mat-form-field *ngIf="form?.value?.agrupacion === 'Localidad'">
        <mat-label>Localidad</mat-label>
        <mat-select
          formControlName="idLocalidad"
          required
          (selectionChange)="cambioLocalidad()"
        >
          <mat-option *ngFor="let dato of localidads" [value]="dato._id">
            {{ dato.nombre }} ({{ dato.unidadNegocio?.nombre }})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Usuarios -->
      <ng-select
        [items]="usuariosFiltrados"
        placeholder="Usuarios"
        loadingText="Cargando..."
        typeToSearchText="Buscar..."
        notFoundText="No se encontraron resultados"
        formControlName="idsUsuarios"
        [multiple]="true"
        [searchFn]="searchUsuario"
        [appendTo]="'body'"
        [required]="true"
        [closeOnSelect]="false"
        (change)="cambioUsuarios()"
        [bindValue]="'_id'"
      >
        <ng-template ng-label-tmp let-item="item">
          <span>{{ item.username }} </span>
          <!-- <span style="font-size: 0.7em">
            ({{ item.datosPersonales?.telefono }})
          </span> -->
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div style="padding-bottom: 5px">
            <span>{{ item.username }} </span>
            <span style="font-size: 0.7em">
              ({{ item.datosPersonales?.telefono }})
            </span>
          </div>
        </ng-template>
      </ng-select>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
