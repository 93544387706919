@if (punto) {
  <div class="info-window">
    <div class="info-titulo">
      <p>{{ punto.nombre }}</p>
    </div>
    <br />
    <div class="info-subtitulo">
      <p>Correctora</p>
    </div>

    <!-- Batería -->
    <div class="item-list">
      <p>Batería:</p>
      @if (punto.correctora?.bateria) {
        <p>
          {{ helper.bateriaCorrectora(punto.correctora) }}
        </p>
      } @else {
        <p class="no-info">Indeterminada</p>
      }
    </div>

    <!-- Ultima Comunicacion -->
    <div class="item-list">
      <p>Última Comunicación:</p>
      @if (punto.correctora?.dispositivo?.fechaUltimaComunicacion) {
        <p>
          {{
            punto.correctora!.dispositivo?.fechaUltimaComunicacion
              | date: "dd/MM/yyyy HH:mm"
          }}
        </p>
      } @else {
        <p class="no-info">Sin comunicar</p>
      }
    </div>

    <!-- Alarma / Alerta -->
    <div class="item-list">
      <p>Alarma:</p>
      @if (punto.correctora?.ultimaAlerta) {
        <p>
          {{ punto.correctora!.ultimaAlerta!.mensaje }}
          ({{
            punto.correctora!.ultimaAlerta!.fechaCreacion
              | date: "dd/MM/yyyy HH:mm"
          }})
        </p>
      } @else {
        <p class="no-info">Sin alarmas</p>
      }
    </div>

    <!-- DETALLES -->
    <div style="width: 100%; display: flex; justify-content: center">
      <button
        mat-flat-button
        class="info-button"
        style="width: 90%"
        [routerLink]="['../puntosMedicion', punto._id]"
      >
        Detalles
      </button>
    </div>
  </div>
}
