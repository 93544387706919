<mat-form-field>
  <input
    matInput
    #inputDireccion
    placeholder="Dirección"
    [(ngModel)]="direccion"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onChangeDir($event.option.value)"
  >
    <mat-option *ngFor="let direccion of direcciones" [value]="direccion">
      {{ direccion }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
