import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { GeocodeService } from '../../auxiliares/geocode.service';
import { MedidorResidencialesAguaRoutingModule } from './medidores-residenciales-agua.routing.module';
import { ListadoMedidorResidencialAguaComponent } from './listado-medidores-residenciales-agua/listado-medidores-residenciales-agua.component';
import { CrearEditarMedidorResidencialAguaComponent } from './crear-editar-medidores-residenciales-agua/crear-editar-medidores-residenciales-agua.component';
import { DetallesMedidorResidencialesAguaComponent } from './detalles-medidores-residenciales-agua/detalles-medidores-residenciales-agua.component';

@NgModule({
  declarations: [
    ListadoMedidorResidencialAguaComponent,
    CrearEditarMedidorResidencialAguaComponent,
    DetallesMedidorResidencialesAguaComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    MedidorResidencialesAguaRoutingModule,
  ],
  providers: [GeocodeService],
})
export class MedidorResidencialesAguaModule {}
