import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ICentroOperativo,
  ICuenca,
  IFilter,
  IListado,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
  IUnidadNegocio,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import {
  IFiltroGlobal,
  NavigationService,
} from '../../navigation/navigation.service';

export interface IResumenUN {
  nombre: string;
  idUnidadNegocio: string;
  // Puntos
  total: number;
  operativos: number;
  enAlerta: number;
  aResolver: number;
  sinDispositivo: number;
  sinReportar: number;
  enMantenimiento: number;
  //
  centrosOperativos: number;
  cuencas: number;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  public filtroGlobal: IFiltroGlobal = {};

  public resumen: IResumenUN[] = [];

  private unidadNegocios: IUnidadNegocio[] = [];
  private cuencas: ICuenca[] = [];
  private centrosOperativos: ICentroOperativo[] = [];
  private puntosMedicion: IPuntoMedicion[] = [];

  // Listado Continuo
  public filtroGlobal$?: Subscription;
  public unidadNegocios$?: Subscription;
  public puntosMedicion$?: Subscription;
  public cuencas$?: Subscription;
  public centrosOperativos$?: Subscription;

  constructor(
    private navigationService: NavigationService,
    private listadosService: ListadosService,
    private router: Router,
    public helper: HelperService,
  ) {}

  private hacerResumen() {
    const resumen: IResumenUN[] = [];
    for (const unidadNegocio of this.unidadNegocios) {
      const puntos = this.puntosMedicion.filter(
        (p) => p.idUnidadNegocio === unidadNegocio._id,
      );
      const cuencas = this.cuencas.filter(
        (c) => c.idUnidadNegocio === unidadNegocio._id,
      );
      const centrosOperativos = this.centrosOperativos.filter(
        (c) => c.idUnidadNegocio === unidadNegocio._id,
      );

      resumen.push({
        idUnidadNegocio: unidadNegocio._id!,
        nombre: unidadNegocio.nombre!,
        centrosOperativos: centrosOperativos.length,
        cuencas: cuencas.length,
        enAlerta: puntos.filter((p) => p.estado === 'Alerta').length,
        aResolver: puntos.filter((p) => p.estado === 'Resolver').length,
        enMantenimiento: puntos.filter((p) => p.estado === 'En Mantenimiento')
          .length,
        operativos: puntos.filter((p) => p.estado === 'Operativa').length,
        sinDispositivo: puntos.filter((p) => p.estado === 'Sin Asignar').length,
        sinReportar: puntos.filter((p) => p.estado === 'Sin Reportar').length,
        total: puntos.length,
      });
    }

    // Puntos sin unidad de negocio
    const puntos = this.puntosMedicion.filter((p) => !p.idUnidadNegocio);
    if (puntos.length) {
      resumen.push({
        idUnidadNegocio: 'sinUnidadNegocio',
        nombre: 'Sin Unidad de Negocio',
        centrosOperativos: 0,
        cuencas: 0,
        enAlerta: puntos.filter((p) => p.estado === 'Alerta').length,
        aResolver: puntos.filter((p) => p.estado === 'Resolver').length,
        enMantenimiento: puntos.filter((p) => p.estado === 'En Mantenimiento')
          .length,
        operativos: puntos.filter((p) => p.estado === 'Operativa').length,
        sinDispositivo: puntos.filter((p) => p.estado === 'Sin Asignar').length,
        sinReportar: puntos.filter((p) => p.estado === 'Sin Reportar').length,
        total: puntos.length,
      });
    }

    this.resumen = resumen;
  }

  public claseCorrectorasUnidadNegocio(unidadNegocio: IResumenUN): string {
    const porcentaje = (unidadNegocio.operativos / unidadNegocio.total) * 100;
    if (unidadNegocio.total && (porcentaje || porcentaje === 0)) {
      if (porcentaje < 10) {
        return 'label-0';
      }
      if (porcentaje < 20) {
        return 'label-10';
      }
      if (porcentaje < 30) {
        return 'label-20';
      }
      if (porcentaje < 40) {
        return 'label-30';
      }
      if (porcentaje < 50) {
        return 'label-40';
      }
      if (porcentaje < 60) {
        return 'label-50';
      }
      if (porcentaje < 70) {
        return 'label-60';
      }
      if (porcentaje < 80) {
        return 'label-70';
      }
      if (porcentaje < 90) {
        return 'label-80';
      }
      if (porcentaje < 100) {
        return 'label-90';
      }
      return 'label-100';
    }
    return '';
  }

  public irAPuntos(nombreUnidadNegocio?: string) {
    const unidadNegocio = this.unidadNegocios.find(
      (un) => un.nombre === nombreUnidadNegocio,
    );
    if (unidadNegocio) {
      this.navigationService.setUnidadNegocio(unidadNegocio);
      this.router.navigate(['/main/puntosMedicion']);
    }
  }

  // LISTADOS

  private suscribeFiltroGlobal() {
    this.filtroGlobal$ = this.navigationService
      .subscribeFiltroGlobal()
      .subscribe(async (filtro) => {
        this.filtroGlobal = filtro;
        await this.listarPuntosMedicion();
        this.hacerResumen();
      });
    this.filtroGlobal = this.navigationService.getFiltroGlobal();
  }

  private async listarPuntosMedicion(): Promise<void> {
    // Agrega el filtro de unidad de negocio seleccionada
    const filter: IFilter<IPuntoMedicion> = {};

    if (this.filtroGlobal.division) {
      filter.division = this.filtroGlobal.division;
    } else {
      delete filter.division;
    }

    const query: IQueryParam = {
      limit: 0,
      filter: JSON.stringify(filter),
      sort: 'idUnidadNegocio',
      select: 'estado idUnidadNegocio',
    };

    // Listado
    this.puntosMedicion$?.unsubscribe();
    this.puntosMedicion$ = this.listadosService
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
      .subscribe((data) => {
        this.puntosMedicion = data.datos;
        console.debug(`listado de puntosMedicion`, data);
      });
    await this.listadosService.getLastValue('puntosMedicion', query);
  }

  private async listarCuencas(): Promise<void> {
    // Agrega el filtro de unidad de negocio seleccionada
    const filter: IFilter<ICuenca> = {};
    const populate: IPopulate[] = [];
    const query: IQueryParam = {
      limit: 0,
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      sort: 'nombre',
      select: 'nombre idUnidadNegocio',
    };

    // Listado
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<ICuenca>>('cuencas', query)
      .subscribe((data) => {
        this.cuencas = data.datos;
        console.debug(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  private async listarCentrosOperativos(): Promise<void> {
    // Agrega el filtro de unidad de negocio seleccionada
    const filter: IFilter<ICentroOperativo> = {};
    const populate: IPopulate[] = [];
    const query: IQueryParam = {
      limit: 0,
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      sort: 'nombre',
      select: 'nombre idUnidadNegocio',
    };

    // Listado
    this.centrosOperativos$?.unsubscribe();
    this.centrosOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centrosOperativos = data.datos;
        console.debug(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  //

  async ngOnInit(): Promise<void> {
    this.suscribeFiltroGlobal();
    await Promise.all([
      this.listarPuntosMedicion(),
      this.listarCuencas(),
      this.listarCentrosOperativos(),
      this.listarUnidadNegocios(),
    ]);
    this.hacerResumen();
  }

  ngOnDestroy(): void {
    this.filtroGlobal$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
    this.puntosMedicion$?.unsubscribe();
    this.cuencas$?.unsubscribe();
    this.centrosOperativos$?.unsubscribe();
  }
}
