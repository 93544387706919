@if (punto) {
  <div class="info-window">
    <div class="info-titulo">
      <p>{{ punto.nombre }}</p>
    </div>
    <br />
    <div class="info-subtitulo">
      <p>Medidor Residencial</p>
    </div>

    <!-- Batería -->
    <div class="item-list">
      <p>Batería:</p>
      @if (valores?.bateria) {
        <p>{{ valores?.bateria }} v.</p>
      } @else {
        <p class="no-info">Indeterminada</p>
      }
    </div>

    <!-- Ultima Comunicacion -->
    <div class="item-list">
      <p>Última Comunicación:</p>
      @if (punto.medidorResidencial?.dispositivo?.fechaUltimaComunicacion) {
        <p>
          {{
            punto.medidorResidencial!.dispositivo?.fechaUltimaComunicacion
              | date: "dd/MM/yyyy HH:mm"
          }}
        </p>
      } @else {
        <p class="no-info">Sin comunicar</p>
      }
    </div>

    <!-- Alarma / Alerta -->
    <!-- <div class="item-list">
        <p>Alarma:</p>
        @if (punto.unidadPresion?.ultimaAlerta) {
          <p>
            {{ (punto.unidadPresion?.ultimaAlerta)!.mensaje }}
            ({{
              (punto.unidadPresion?.ultimaAlerta)!.fechaCreacion
                | date: "dd/MM/yyyy HH:mm"
            }})
          </p>
        } @else {
          <p class="no-info">Sin alarmas</p>
        }
      </div> -->

    <!-- Consumo -->
    <div class="item-list">
      <p>Consumo Acumulado:</p>
      @if (valores?.consumo) {
        <p>
          {{ valores?.consumo }}
          m<sup>3</sup>
        </p>
      } @else {
        <p class="no-info">Sin Reportar</p>
      }
    </div>

    <!-- DETALLES -->
    <div style="width: 100%; display: flex; justify-content: center">
      <button
        mat-flat-button
        class="info-button"
        style="width: 90%"
        [routerLink]="['../puntosMedicion', punto._id]"
      >
        Detalles
      </button>
    </div>
  </div>
}
