<!-- Titulo -->
<div fxLayour="row" fxLayoutAlign="space-between center">
  <h1 style="padding: 0 0.5em">Configuración de Notificaciones</h1>
</div>
<mat-divider></mat-divider>

<br />

<form id="form" [formGroup]="form!" autocomplete="off" (ngSubmit)="guardar()">
  <!-- Notificaciones -->
  <ng-container formArrayName="notificaciones">
    <ng-container
      *ngFor="
        let notificacion of notificacionesForm?.controls;
        let i = index;
        let last = last
      "
    >
      <div [formGroupName]="i" style="padding: 0.5em; margin-bottom: 1em">
        <!-- Nivel -->
        <mat-form-field>
          <mat-select placeholder="Nivel" formControlName="nivel" required>
            <mat-option *ngFor="let dato of niveles" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Unidades de Negocio  -->
        <ng-container
          *ngIf="notificacion.get('nivel')?.value === 'Unidad de Negocio'"
        >
          <mat-form-field>
            <mat-label>Unidades de Negocio</mat-label>
            <mat-select formControlName="idUnidadNegocio" required>
              <mat-option
                *ngFor="let dato of unidadNegocios"
                [value]="dato._id"
              >
                {{ dato.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <!-- Centro Operativo -->
        <ng-container
          *ngIf="notificacion.get('nivel')?.value === 'Centro Operativo'"
        >
          <mat-form-field>
            <mat-label>Centro Operativo</mat-label>
            <mat-select formControlName="idCentroOperativo" required>
              <ng-container *ngFor="let dato of centroOperativos">
                <mat-option [value]="dato._id">
                  {{ dato.nombre }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <!-- Tipo de Notificación -->
        <ng-container
          *ngFor="let habilitada of habilitadasForm(i)?.controls; let j = index"
        >
          <ng-container formArrayName="habilitados">
            <div fxLayout="column" [formGroupName]="j">
              <mat-checkbox formControlName="habilitado">
                {{ notificaciones[habilitada.get("codigo")?.value] }}
              </mat-checkbox>
            </div>
          </ng-container>
        </ng-container>

        <br />

        <!-- Boton Eliminar -->
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(i)"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <!-- Boton Agregar -->
        <button
          *ngIf="last"
          mat-mini-fab
          color="primary"
          matTooltip="Agregar"
          type="button"
          (click)="agregar()"
          style="margin-left: 0.5em"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>

  <br />

  <div fxLayoutGap="1em" style="margin: 0 0 1em 0; text-align: right">
    <!-- Boton Guardar -->
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="form"
      color="accent"
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
  </div>
</form>
