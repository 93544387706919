<div class="fondo-85" style="border-bottom-left-radius: 30px">
  <div
    class="titulo"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="margin-bottom: 0.5em"
  >
    <h1
      style="
        font-size: 25px;
        font-weight: 900;
        padding: 0.8em 0 0.8em 0.8em;
        margin: 0;
      "
    >
      Exportar
    </h1>
  </div>
  <div style="padding: 1.5em">
    <!-- Formato -->
    <mat-form-field>
      <mat-label>Formato</mat-label>
      <mat-select [(value)]="query.formato">
        <mat-option value="xls">XLS</mat-option>
        <!-- <mat-option value="xls-original">XLS (Original)</mat-option> -->
        <mat-option value="simec">SIMEC</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Tipo de exportacion / Rango o Mes -->
    <mat-form-field>
      <mat-label>Tipo de Exportación</mat-label>
      <mat-select [(value)]="query.dayPicker">
        <mat-option value="mes">Mes</mat-option>
        <mat-option value="rango">Rango</mat-option>
        <mat-option value="anio">Año</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Rango -->
    <mat-form-field
      style="margin-bottom: 1em"
      *ngIf="query.dayPicker === 'rango'"
    >
      <mat-label>Periodo (de 7:00 a 6:00)</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="Fecha Inicial"
          [(ngModel)]="query.desde"
          (dateChange)="informeExportacion = undefined"
        />
        <input
          matEndDate
          placeholder="Fecha Final"
          [(ngModel)]="query.hasta"
          (dateChange)="informeExportacion = undefined"
        />
      </mat-date-range-input>
      <mat-hint>DD/MM/AAAA – DD/MM/AAAA</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <!-- Mes -->
    <app-monthSelect
      [mostrar]="query.dayPicker === 'mes'"
      (onChange)="query.mes = $event"
    ></app-monthSelect>

    <!-- Año -->
    <app-yearSelect
      [mostrar]="query.dayPicker === 'anio'"
      (onChange)="query.anio = $event"
    ></app-yearSelect>

    <!-- Agrupacion Temporal -->
    <mat-form-field>
      <mat-label>Agrupación Temporal</mat-label>
      <mat-select
        [(ngModel)]="query.agrupacionTemporal"
        (selectionChange)="informeExportacion = undefined"
      >
        <ng-container *ngFor="let agrupacion of agrupacionesTemporales">
          <mat-option [value]="agrupacion">
            {{ agrupacion | titlecase }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <!-- Agrupacion -->
    <mat-form-field>
      <mat-label>Agrupación</mat-label>
      <mat-select
        [(ngModel)]="query.agrupacion"
        (selectionChange)="informeExportacion = undefined"
      >
        <ng-container *ngFor="let agrupacion of agrupaciones">
          <ng-container *ngIf="nivelDeUsuario(agrupacion.niveles)">
            <mat-option [value]="agrupacion.value">
              {{ agrupacion.value }}
            </mat-option>
          </ng-container>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <!-- Unidad de Negocio -->
    <mat-form-field *ngIf="query.agrupacion === 'Unidad de Negocio'">
      <mat-label>Unidad de Negocio</mat-label>
      <mat-select
        [(ngModel)]="unidadNegocio"
        (selectionChange)="informeExportacion = undefined"
      >
        <mat-option *ngFor="let dato of unidadNegocios" [value]="dato">
          {{ dato.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Centro Operativo -->
    <mat-form-field *ngIf="query.agrupacion === 'Centro Operativo'">
      <mat-label>Centro Operativo</mat-label>
      <mat-select
        [(ngModel)]="centroOperativo"
        (selectionChange)="informeExportacion = undefined"
      >
        <mat-option *ngFor="let dato of centroOperativos" [value]="dato">
          {{ dato.nombre }} - {{ dato.unidadNegocio?.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Cuenca -->
    <mat-form-field *ngIf="query.agrupacion === 'Zona de Calidad'">
      <mat-label>Zona de Calidad</mat-label>
      <mat-select
        [(ngModel)]="cuenca"
        (selectionChange)="informeExportacion = undefined"
      >
        <mat-option *ngFor="let dato of cuencas" [value]="dato">
          {{ dato.nombre }} - {{ dato.unidadNegocio?.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Grupo -->
    <mat-form-field *ngIf="query.agrupacion === 'Grupo'">
      <mat-label>Grupo</mat-label>
      <mat-select
        [(ngModel)]="grupo"
        (selectionChange)="informeExportacion = undefined"
      >
        <mat-option *ngFor="let dato of grupos" [value]="dato">
          {{ dato.nombre }} - {{ dato.unidadNegocio?.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Correctoras -->
    <ng-select
      *ngIf="query.agrupacion === 'Correctora'"
      [items]="correctoras"
      placeholder="Correctoras"
      loadingText="Cargando..."
      typeToSearchText="Buscar..."
      notFoundText="No se encontraron resultados"
      [(ngModel)]="correctora"
      (change)="informeExportacion = undefined"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <span>{{ item.numeroSerie }} </span>
        <span style="font-size: 1em"> ({{ item.modelo }})</span>
      </ng-template>

      <ng-template
        ng-label-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <span>{{ item.numeroSerie }} </span>
        <span style="font-size: 1em"> ({{ item.modelo }})</span>
      </ng-template>
    </ng-select>

    <!-- NUC -->
    <ng-select
      *ngIf="query.agrupacion === 'NUC'"
      [items]="dispositivos"
      placeholder="NUC"
      bindLabel="deviceName"
      loadingText="Cargando..."
      typeToSearchText="Buscar..."
      notFoundText="No se encontraron resultados"
      [(ngModel)]="dispositivo"
      (change)="informeExportacion = undefined"
    >
    </ng-select>

    <!-- Puntos de Medicion -->
    <ng-select
      *ngIf="query.agrupacion === 'Punto de Medición'"
      [items]="puntoMedicions"
      placeholder="Punto de medición"
      bindLabel="nombre"
      loadingText="Cargando..."
      typeToSearchText="Buscar..."
      notFoundText="No se encontraron resultados"
      [(ngModel)]="puntoMedicion"
      (change)="informeExportacion = undefined"
    >
    </ng-select>

    <!-- Botones -->
    <mat-card-actions fxLayoutGap="1em" *ngIf="query.agrupacion !== 'NUC'">
      <button
        mat-raised-button
        class="boton-guardar"
        type="button"
        form="loginForm"
        color="accent"
        fxFlex
        [disabled]="!valido() || enviando"
        (click)="verificar()"
      >
        VERIFICAR DATOS
      </button>
    </mat-card-actions>
    <div
      style="margin: 1em 0 1em 0; border-bottom-left-radius: 30px"
      *ngIf="informeExportacion || query.agrupacion === 'NUC'"
    >
      <div *ngIf="informeExportacion" style="margin-left: 0.5em">
        <h3>Datos a Exportar</h3>
        <p>Correctoras: {{ informeExportacion.correctoras }}</p>
        <p>Registros: {{ informeExportacion.registros }}</p>
        <p>Faltantes: {{ informeExportacion.faltantes }}</p>
      </div>

      <mat-card-actions fxLayoutGap="1em">
        <button
          mat-raised-button
          class="boton-guardar"
          type="button"
          form="loginForm"
          color="accent"
          fxFlex
          [disabled]="!valido() || enviando"
          (click)="exportar('datos')"
        >
          EXPORTAR DATOS
        </button>

        <button
          mat-raised-button
          class="boton-guardar"
          type="button"
          form="loginForm"
          color="accent"
          fxFlex
          [disabled]="!valido() || enviando"
          (click)="exportar('faltantes')"
        >
          EXPORTAR FALTANTES
        </button>
      </mat-card-actions>
    </div>
  </div>
</div>
