import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IPuntoMedicion, IMedidorResidencial } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CrearEditarMedidorResidencialsComponent } from '../../modulos/medidores-residenciales/crear-editar-medidores-residenciales/crear-editar-medidores-residenciales.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-medidor-residencial',
  templateUrl: './detalles-medidor-residencial.component.html',
  styleUrls: ['./detalles-medidor-residencial.component.scss'],
})
export class DetallesMedidorResidencialComponent implements OnInit {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() medidorResidencial?: IMedidorResidencial;

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
  ) {}

  public async editarMedidorResidencial(data?: IMedidorResidencial) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMedidorResidencialsComponent, config);
  }

  ngOnInit(): void {}
}
